import { 
  GET_MARKETPLACE,
  GET_MARKETPLACES,
  GET_MARKETPLACES_ALL,
  GET_MARKETPLACES_COUNT,
  ADD_MARKETPLACE,
  DELETE_MARKETPLACE,
  UPDATE_MARKETPLACE } from "../action"

// ** Initial Statee
const initialState = {
  data: [],
  allData: [],
  selectedProducto: {},
  gruposAtributos: [],
  atributos: [],
  categorias: [],
  familias: [],
  marketplaces: [],
  selectedMarketPlace: {},
  params: {},
  processing: false
}

const prefix = 'marketplaces'

const marketplaces = (state = initialState, action) => {
  switch (action.type) {
    case `${prefix}/GET_ALL_DATA`:
      return { ...state, allData: action.data }
      case GET_MARKETPLACES:
      return {
        ...state,
        data: action.marketplaces,
        total: action.total || 0,
        processing: false,
        params: action.params || {}
      }
      case GET_MARKETPLACES_ALL:
      return {
          ...state,
          marketplaces: action.marketplaces,
          processing: false
      }
     case GET_MARKETPLACE:
       return { ...state, selectedMarketPlace: action.selectedMarketPlace,  ultimoIdMarketPlace: action.ultimoIdMarketPlace }
    case ADD_MARKETPLACE:
      return { ...state, data: state.data.concat(action.data) }
    case DELETE_MARKETPLACE:
      return { ...state, data: [...state.data].filter(marketPlace => marketPlace.id !== action.data)}
    case GET_MARKETPLACES_COUNT:
      return { ...state, total: action.total }
    case UPDATE_MARKETPLACE:
      return { ...state, data: state.data.map(marketPlace => (marketPlace.id === action.data.id ? action.data : marketPlace)) }
    default:
      return { ...state }
  }
}
export default marketplaces
