import { TwFunctionsControllerApi, ConexionNavisionControllerApi, IconosControllerApi, ProductoIconosControllerApi, MarcaControllerApi, SituacionesControllerApi, 
  UsuariosControllerApi, MultimediaControllerApi, ProductoMultimediaControllerApi, ProductoGeneralControllerApi, ProductoCaracteristicasControllerApi, 
  ProductoAtributosControllerApi, ProductosControllerApi, AtributosControllerApi, CategoriasControllerApi, EmpresasControllerApi, FamiliasControllerApi, 
  GruposAtributosControllerApi, FileUploadControllerApi, ProductoDatosCatalogoControllerApi, SincronizacionBcControllerApi, settings, 
  VistaMarketPlacePromptsControllerApi,
  TextosGeneradosControllerApi} from '@api/backend'
import { displayErrorMsg, displaySuccessMsg, getUserData } from '@src/utility/Utils'
import { obtenerTextosGenerados_API_SVAN, guardarTextosGenerados } from '@src/views/marketplace-prompts/store/action'
import axios from 'axios'
import Swal from 'sweetalert2'

const apiFileUpload = new FileUploadControllerApi(settings)
const apiAtributos = new AtributosControllerApi(settings)
const apiCategorias = new CategoriasControllerApi(settings)
const apiFamilias = new FamiliasControllerApi(settings)
const apiGruposAtributos = new GruposAtributosControllerApi(settings)
const apiUsuarios = new UsuariosControllerApi(settings)
const apiProductos = new ProductosControllerApi(settings)
const apiProductoAtributos = new ProductoAtributosControllerApi(settings)
const apiCaracteristicasProducto = new ProductoCaracteristicasControllerApi(settings)
const apiMultimediaProducto = new ProductoMultimediaControllerApi(settings)
const apiGeneralProducto = new ProductoGeneralControllerApi(settings)
const apiMultimedia = new MultimediaControllerApi(settings)
const apiEmpresas = new EmpresasControllerApi(settings)
const apiMarca = new MarcaControllerApi(settings)
const apiSituaciones = new SituacionesControllerApi(settings)
const apiSoap = new TwFunctionsControllerApi(settings)
const apiNavisionNew = new ConexionNavisionControllerApi(settings)
const apiSincronizacionBC = new SincronizacionBcControllerApi(settings)
const apiIcono = new IconosControllerApi(settings)
const apiIconos = new ProductoIconosControllerApi(settings)
const apiProductoDatosCatalogo = new ProductoDatosCatalogoControllerApi(settings)
const apiVistaMarketPlacePrompts = new VistaMarketPlacePromptsControllerApi(settings)
const apiTextosGenerados = new TextosGeneradosControllerApi(settings)

const prefix = 'productos'

export const GET_DATA = `${prefix}/GET_DATA`
export const UPDATE_ATRIBUTO = `${prefix}/UPDATE`
export const INIT_DATA_NECESARIA = `${prefix}/INIT_DATA_NECESARIA`
export const GET_PRODUCT_DATA = `${prefix}/GET_PRODUCT_DATA`
export const REFRESH_SELECTED_EMPRESA = `${prefix}/REFRESH_SELECTED_EMPRESA`
export const REFRESH_MULTIMEDIA = `${prefix}/REFRESH_MULTIMEDIA`
export const CREATE_PRODUCTO = `${prefix}/CREATE_PRODUCTO`
export const UPDATE_PRODUCTO_DATA = `${prefix}/UPDATE_PRODUCTO_DATA`
export const GET_MULTIMEDIA = `${prefix}/GET_MULTIMEDIA`
export const GET_MERCADOS = `${prefix}/GET_MERCADOS`
export const ADD_PRODUCTO_MULTIMEDIA = `${prefix}/ADD_PRODUCTO_MULTIMEDIA`
export const DELETE_PRODUCT_DATA = `${prefix}/DELETE_PRODUCT_DATA`

export const generarNuevaCategoria = (id, categoria) => {
  return async () => {
    try {
      const filter = { where: { ['productoId']: { eq: `${id}` } } }
      await apiProductoAtributos.productoAtributosControllerFind(JSON.stringify(filter)).then(async resp => {
        const dataProdAtt = resp.data
        await apiAtributos.atributosControllerFind().then(respAtributos => {
          //
          //Filtra los atributos que son de la categoria buscada
          //
          const attDeCategoria = respAtributos.data.filter(att => {
            const categorias = att.categorias.split(',')
            return categorias.includes(categoria)
          }
          )
          let atributo, prodAtributo
          const atributosMantenidos = []
          //Recorre los atributos del producto, si es valido para la categoria lo mantiene, si no se lo carga
          dataProdAtt.forEach(async att => {
            atributo = attDeCategoria.find(el => el.nombre.toLowerCase().replaceAll(" ", "") == att.nombre.toLowerCase().replaceAll(" ", ""))
            if (atributo == undefined) {
              await apiProductoAtributos.productoAtributosControllerDeleteById(att.id)
            } else {
              atributosMantenidos.push(atributo)
            }
          })
          //Comprueba los atributos de la categoria que no se han mantenido y los crea
          attDeCategoria.forEach(async att => {
            atributo = atributosMantenidos.find(el => el.nombre.toLowerCase().replaceAll(" ", "") == att.nombre.toLowerCase().replaceAll(" ", ""))
            if (atributo == undefined) {
              prodAtributo = {
                productoId: id,
                atributoId: att.id,
                valor: "",
                nombre: att.nombre,
                unidad: att.unidad || ""
              }
              await apiProductoAtributos.productoAtributosControllerCreate(prodAtributo)
            }
          })
        })
      })
    } catch (err) {
      console.error(err.message)
    }
  }
}

export const buscarNuevosAtributos = (id, categoria, selectedProducto) => {
  return async (dispatch) => {
    try {
      let prodAtributo
      const filter = { where: { ['productoId']: { eq: `${id}` } } }
      categoria = categoria === '' ? undefined : categoria // -> Comprobamos categoria para que no nos traiga los datos inecesarios y relentice la pagina
      const filterAtributos = { where: { ['categorias']: { like: `%${categoria}%` } } }
      const dataProductoAtributos = await apiProductoAtributos.productoAtributosControllerFind(JSON.stringify(filter))
      const dataAtributos = await apiAtributos.atributosControllerFind(JSON.stringify(filterAtributos))

      // Sacamos los atributos nuevos que hay que añadir mas adelante
      const atributosAanyadir = dataAtributos.data.filter(
        atributo => !dataProductoAtributos.data.some(
          item => item.atributoId === atributo.id // Reemplaza 'id' por el campo que debas comparar
        )
      )
      // Sacamos los atributos que tenemos ya pero que ya no estan en la categoria, luego mas adelante se tiene que borrar
      const atributosAeliminar = dataProductoAtributos.data.filter(
        atributo => !dataAtributos.data.some(
          item => item.id === atributo.atributoId // Reemplaza 'id' por el campo que debas comparar
        )
      )

      //if para en casos de que el array de añadir sea mayor que 0 añada a la tabla de producto-atributo y actualice el redux 
      if (atributosAanyadir.length > 0) {
        atributosAanyadir.forEach(async attNuevo => {
          prodAtributo = {
            productoId: id,
            atributoId: attNuevo.id,
            valor: "",
            nombre: attNuevo.nombre,
            unidad: attNuevo.unidad || ""
          }
          apiProductoAtributos.productoAtributosControllerCreate(prodAtributo).then(async () => {
            const dataNuevosProductoAtributos = await apiProductoAtributos.productoAtributosControllerFind(JSON.stringify(filter))
            //actualizamos el objeto y luego la pasamos al dispatch
            const actualizarObjData = {
              ...selectedProducto,
              dataAtributos: dataNuevosProductoAtributos.data
            }
            console.log(actualizarObjData)
            dispatch({
              type: GET_PRODUCT_DATA,
              selectedProducto: actualizarObjData
            })
          })
        })
      }
      //if para en casos de que el array de eliminar sea mayor que 0 elimine de la tabla producto-atributo y actualice el redux
      if (atributosAeliminar.length > 0) {
        atributosAeliminar.forEach(async attEliminar => {
          apiProductoAtributos.productoAtributosControllerDeleteById(attEliminar.id).then(async () => {
            const dataActualizadoProductoAtributos = await apiProductoAtributos.productoAtributosControllerFind(JSON.stringify(filter))
            //actualizamos el objeto y luego la pasamos al dispatch
            const actualizarObjData = {
              ...selectedProducto,
              dataAtributos: dataActualizadoProductoAtributos.data
            }
            console.log(actualizarObjData)
            dispatch({
              type: GET_PRODUCT_DATA,
              selectedProducto: actualizarObjData
            })
          })
        })
      }
    } catch (err) {
      console.error(err.message)
    }
  }
}

export async function generarEAN(categoria) {
  try {
    const objNew = { itemCategoryCode: categoria }
    const objJSON = JSON.stringify(objNew) // <-- creamos el JSON a enviar
    //const { data } = await apiSoap.twFunctionsControllerNewEANCode(categoria) //Siempe le tenemos que pasar una categoría para que el código de Nav nos devueva el EAN, como puede ser cualquier categoría usamos "CATALOGO" 
    const { data } = await apiNavisionNew.conexionNavisionControllerPostEndpointBC("TW_Functions_NewEANCode", objJSON)
    return data.mensaje
  } catch (err) {
    console.error(err.message)
  }
}

export const handleImageUpload = (folder, filename, fileObject, prodId, registrosMultimedia) => {
  return async () => {
    try {
      // Encontrar la última aparición del punto en el nombre del archivo
      const lastIndex = fileObject.name.lastIndexOf('.')
      // Obtener la extensión del archivo
      const extension = fileObject.name.substring(lastIndex + 1)

      const nombreBonito = filename.replaceAll("_", " ").replaceAll("  ", " ")
      filename = filename.replaceAll("__", "_")
      const filter = JSON.stringify({
        where: {
          ['productoId']: { eq: `${prodId}` }
        }
      })
      const { data: dataProductoMultimedia } = await apiMultimediaProducto.productoMultimediaControllerFind(filter)
      const pMultimedia = dataProductoMultimedia.find(el => el.nombre.toLowerCase() == nombreBonito.toLowerCase())

      if (pMultimedia != undefined) {
        // Filtramos la lista de multimedias para encontrar el id de la multimedia y asociarlo al objeto producto_multimedia
        const objMultimediaUpdate = registrosMultimedia.filter(objeto => {
          return objeto.clave === pMultimedia.nombre
        })
        //Existe prodMultimedia -> update
        const item = pMultimedia
        const prodMultimediaObj = {
          nombre: nombreBonito,
          multimediaId: objMultimediaUpdate[0].id,
          categoria: item.categoria || "",
          formato: item.formato || "",
          objetivo: item.objetivo || "",
          principalSN: item.principalSN || "",
          productoId: item.productoId,
          publicoSN: item.publicoSN || "",
          tagAecoc: item.tagAecoc || "",
          tipo: item.tipo || "",
          fichero: `multimedia/${folder}/${filename.concat(".").concat(extension)}`
        }
        await apiMultimediaProducto.productoMultimediaControllerUpdateById(item.id, prodMultimediaObj)
      } else {
        // Filtramos la lista de multimedias para encontrar el id de la multimedia y asociarlo al objeto producto_multimedia
        const objMultimediaCreate = registrosMultimedia.filter(objeto => {
          return objeto.clave === nombreBonito
        })
        //No existe -> create
        const prodMultimediaObj = {
          nombre: nombreBonito,
          multimediaId: objMultimediaCreate[0].id,
          categoria: "",
          formato: "",
          objetivo: "",
          principalSN: "",
          productoId: prodId,
          publicoSN: "",
          tagAecoc: "",
          tipo: (extension == 'png' || extension == 'jpg' || extension == 'jpeg') ? 'Imagen' : 'Documento',
          fichero: `multimedia/${folder}/${filename.concat(".").concat(extension)}`
        }
        await apiMultimediaProducto.productoMultimediaControllerCreate(prodMultimediaObj)
      }
      await apiFileUpload.fileUploadControllerImageUpload(folder, filename, fileObject)
    } catch (err) {
      console.error(err.message)
    }
  }
}

export const handleVideoUpload = (folder, filename, fileObject, prodId, registrosMultimedia) => {
  return async () => {
    try {
      // const extension = fileObject.name.split(".")[1]
      const nombreBonito = filename.replaceAll("_", " ").replaceAll("  ", " ")
      filename = filename.replaceAll("__", "_")
      const filter = JSON.stringify({
        where: {
          ['productoId']: { eq: `${prodId}` }
        }
      })
      const { data: dataProductoMultimedia } = await apiMultimediaProducto.productoMultimediaControllerFind(filter)
      const pMultimedia = dataProductoMultimedia.find(el => el.nombre.toLowerCase() == nombreBonito.toLowerCase())
      if (pMultimedia != undefined) {
        // Filtramos la lista de multimedias para encontrar el id de la multimedia y asociarlo al objeto producto_multimedia
        const objMultimediaUpdate = registrosMultimedia.filter(objeto => {
          return objeto.clave === pMultimedia.nombre
        })
        //Existe prodMultimedia -> update
        const item = pMultimedia
        const prodMultimediaObj = {
          nombre: nombreBonito,
          multimediaId: objMultimediaUpdate[0].id,
          categoria: item.categoria || "",
          formato: item.formato || "",
          objetivo: item.objetivo || "",
          principalSN: item.principalSN || "",
          productoId: item.productoId,
          publicoSN: item.publicoSN || "",
          tagAecoc: item.tagAecoc || "",
          tipo: item.tipo || "",
          fichero: fileObject
        }
        await apiMultimediaProducto.productoMultimediaControllerUpdateById(item.id, prodMultimediaObj)
      } else {
        // Filtramos la lista de multimedias para encontrar el id de la multimedia y asociarlo al objeto producto_multimedia
        const objMultimediaCreate = registrosMultimedia.filter(objeto => {
          return objeto.clave === nombreBonito
        })
        //No existe -> create
        const prodMultimediaObj = {
          nombre: nombreBonito,
          multimediaId: objMultimediaCreate[0].id,
          categoria: "",
          formato: "",
          objetivo: "",
          principalSN: "",
          productoId: prodId,
          publicoSN: "",
          tagAecoc: "",
          tipo: 'Video',
          fichero: fileObject
        }
        await apiMultimediaProducto.productoMultimediaControllerCreate(prodMultimediaObj)
      }
      await apiFileUpload.fileUploadControllerImageUpload(folder, filename, fileObject)
    } catch (err) {
      console.error(err.message)
    }
  }
}

export async function handleImageUploadPrincipal(folder, filename, fileObject, selectedProducto) {
  try {
    /*const { data: dataProducto } = await apiProductos.productosControllerFindById(selectedProducto.id)
    dataProducto.enviarAecoc = dataProducto.enviarAecoc == null ? "N" : dataProducto.enviarAecoc
    dataProducto.finalizadoSAT = dataProducto.finalizadoSAT == null ? "N" : dataProducto.finalizadoSAT
    dataProducto.ordenAtributos = dataProducto.ordenAtributos == null ? "" : dataProducto.ordenAtributos
    delete dataProducto.fechaModificacion*/

    const finalProductoObj = {
      imagen: `multimedia/${folder}/800x600_${filename}.png`
    }
    await apiProductos.productosControllerUpdateById(selectedProducto.id, finalProductoObj)
    await apiFileUpload.fileUploadControllerImageUpload(folder, filename, fileObject)
  } catch (err) {
    console.error(err.message)
    console.log(err.message)
  }
}


export const productoMultimedia = (multimedia) => {
  return async (dispatch, getState) => {
    try {
      const { data: multimediaNew } = await apiMultimediaProducto.productoMultimediaControllerCreate(JSON.stringify(multimedia))
      dispatch({
        type: ADD_PRODUCTO_MULTIMEDIA,
        multimediaNew
      })
    } catch (err) {
      console.error(err.message)
    }
  }
}

export const getDatosNecesariosProductos = (productoId = null) => {
  return async (dispatch, getState) => {
    try {
      const empresaId = getState().layout.selectedEmpresaId
      //let filter = {where: {['empresaId']: {eq: `${empresaId}`}}}

      let filterDataProductoGeneral,
        filterDataProductoMult,
        filterDataProductoAtributo,
        filteDataProducto = { where: { ['empresaId']: { eq: `${empresaId}` } } }

      const filteDataAtributos = { where: { ['empresaId']: { eq: `${empresaId}` } } }

      if (productoId !== null) {

        filterDataProductoGeneral = JSON.stringify({ where: { ['productoId']: { eq: `${productoId}` } } })
        filterDataProductoMult = JSON.stringify({ where: { ['productoId']: { eq: `${productoId}` } } })
        filterDataProductoAtributo = JSON.stringify({ where: { ['productoId']: { eq: `${productoId}` } } })
        filteDataProducto = { where: { ['id']: { eq: `${productoId}` } } }

      }

      const { data: dataProductoGeneral } = await apiGeneralProducto.productoGeneralControllerFind(filterDataProductoGeneral)
      const { data: dataProductoMult } = await apiMultimediaProducto.productoMultimediaControllerFind(filterDataProductoMult)
      const { data: dataProductoAtributo } = await apiProductoAtributos.productoAtributosControllerFind(filterDataProductoAtributo)
      const { data: dataProducto } = await apiProductos.productosControllerFind(JSON.stringify(filteDataProducto))
      const { data: dataGruposAtributos } = await apiGruposAtributos.gruposAtributosControllerFind(JSON.stringify(filteDataAtributos))
      const idsProductoAtributoSinDuplicar = [...new Set(dataProductoAtributo.map(attr => attr.atributoId))]

      let dataAtributos = []
      const limit = 100
      // Hicimos que se consultara de 100 en 100 por que el codigo petaba cuando se consultan todos los ids, que son mas de 1000
      for (let i = 0; i < idsProductoAtributoSinDuplicar.length; i += limit) {
        const filterAtributos = JSON.stringify({
          where: {
            id: { inq: idsProductoAtributoSinDuplicar.slice(i, i + limit) }
          }
        })

        const { data } = await apiAtributos.atributosControllerFind(filterAtributos)
        dataAtributos = dataAtributos.concat(data)
      }
      const { data: dataCategorias } = await apiCategorias.categoriasControllerFind()
      const { data: dataEmpresas } = await apiEmpresas.empresasControllerFind()
      const { data: dataSituaciones } = await apiSituaciones.situacionesControllerFind()
      const { data: dataMarcas } = await apiMarca.marcaControllerFind()
      const { data: dataIconos } = await apiIcono.iconosControllerFind()
      const { data: dataProductoDatosCatalogo } = await apiProductoDatosCatalogo.productoDatosCatalogoControllerFind(filterDataProductoAtributo)

      dispatch({
        type: INIT_DATA_NECESARIA,
        productosG: dataProductoGeneral,
        productosM: dataProductoMult,
        productosA: dataProductoAtributo,
        productos: dataProducto,
        gruposAtributos: dataGruposAtributos,
        atributos: dataAtributos,
        categorias: dataCategorias,
        categoriasData: dataCategorias,
        empresas: dataEmpresas,
        situaciones: dataSituaciones,
        iconos: dataIconos,
        marcas: dataMarcas,
        productosDatosCatalogo: dataProductoDatosCatalogo
      })
    } catch (err) {
      console.error(err.message)
    }
  }
}

export const createNewProducto = (intl, formValues) => {
  return async (dispatch, getState) => {
    try {
      // Obtenemos next ID previo a la creación
      const { data: dataNextId } = await apiProductos.productosControllerGetNextId()
      const nextId = dataNextId[0].AUTO_INCREMENT

      if (nextId) {
        const fechaYHoraActual = new Date().toISOString()
        const productoObj = {
          empresaId: getState().layout.selectedEmpresaId,
          nombre: formValues.nombre,
          imagen: `multimedia/${formValues.nombre}/800x600_imagen_principal.png`,
          categorias: "",
          familias: "",
          fechaCreacion: fechaYHoraActual,
          fechaModificacion: fechaYHoraActual
        }
        const { data: dataCreateProducto } = await apiProductos.productosControllerCreate(productoObj)
        await handleImageUploadPrincipal(formValues.nombre, "imagen_principal", formValues.imagenPrincipal, dataCreateProducto.id)
        await apiGeneralProducto.productoGeneralControllerCreate({ productoId: dataCreateProducto.id })
        displaySuccessMsg(`${intl.formatMessage({ id: 'Producto' })} '${productoObj.nombre}' ${intl.formatMessage({ id: 'creado' })}`)

        return dispatch({
          type: CREATE_PRODUCTO,
          id: dataCreateProducto.id
        })

      } else {
        return console.error("Error obteniendo data de próximo id")
      }
    } catch (err) {
      console.error(err.message)
    }
  }
}

export const refreshBySelectedEmpresa = () => {
  return async (dispatch, getState) => {
    try {
      const empresaId = getState().layout.selectedEmpresaId
      const filter = JSON.stringify({
        where: {
          ['empresaId']: { eq: `${empresaId}` }
        }
      })
      const { data: dataUsuarios } = await apiUsuarios.usuariosControllerFind(filter)
      const { data: dataGruposAtributos } = await apiGruposAtributos.gruposAtributosControllerFind(filter)
      const { data: dataCategorias } = await apiCategorias.categoriasControllerFind(filter)
      const { data: dataFamilias } = await apiFamilias.familiasControllerFind(filter)

      dispatch({
        type: REFRESH_SELECTED_EMPRESA,
        gruposAtributos: dataGruposAtributos,
        categorias: dataCategorias,
        familias: dataFamilias,
        usuarios: dataUsuarios
      })
    } catch (err) {
      console.error(err.message)
    }
  }
}

export const getProductById = productId => {
  return async (dispatch, getState) => {
    try {
      const empresaId = getState().layout.selectedEmpresaId
      const id = parseInt(productId)
      const { data: dataProducto } = await apiProductos.productosControllerFindById(id)

      if (empresaId !== dataProducto.empresaId) {
        return dispatch({
          type: GET_PRODUCT_DATA,
          selectedProducto: {}
        })
      }
      const filter = JSON.stringify({
        where: {
          ['productoId']: { eq: `${id}` }
        }
      })
      const { data: dataProductoAtributos } = await apiProductoAtributos.productoAtributosControllerFind(filter)
      const { data: dataProductoMultimedia } = await apiMultimediaProducto.productoMultimediaControllerFind(filter)
      const { data: dataProductoGeneral } = await apiGeneralProducto.productoGeneralControllerFind(filter)
      const { data: dataMultimedia } = await apiMultimedia.multimediaControllerFind(filter)
      const { data: dataProductoIconos } = await apiIconos.productoIconosControllerFind(filter)
      const { data: dataProductoDatosCatalogo } = await apiProductoDatosCatalogo.productoDatosCatalogoControllerFind(filter)

      //Combinamos todo para dejar en un solo objeto
      const fullObjData = {
        ...dataProducto,
        dataGeneral: dataProductoGeneral[0],
        dataAtributos: dataProductoAtributos || [],
        dataMultimediaProducto: dataProductoMultimedia || [],
        dataMultimediaTipos: dataMultimedia || [],
        dataIconos: dataProductoIconos || [],
        dataProductoDatosCatalogo: dataProductoDatosCatalogo || []
      }
      dispatch({
        type: GET_PRODUCT_DATA,
        selectedProducto: fullObjData
      })
    } catch (err) {
      console.error(err.message)
      Swal.fire({
        timer: 5000,
        timerProgressBar: true,
        didOpen: () => {
          Swal.showLoading()
        }
      })
      setTimeout(() => { location.reload() }, 5000)
    }
  }
}

export const obtenerRegistrosTablaMultimedia = () => {
  return async () => {
    const { data: dataMultimedia } = await apiMultimedia.multimediaControllerFind()
    return dataMultimedia
  }
}

export const updateProductoGeneral = (productoId, data) => {
  return async (dispatch) => {
    try {
      const id = parseInt(productoId)
      const filter = JSON.stringify({
        where: {
          ['productoId']: { eq: `${id}` }
        }
      })
      const dataGeneral = {
        descripcion: data.descripcion,
        descripcionLarga: data.descripcionLarga,
        ean: data.ean,
        estadoReferencia: data.estadoReferencia,
        id: data.id,
        marca: data.marca,
        palabrasClave: data.palabrasClave,
        productoId: data.productoId,
        sku: data.sku,
        titulo: data.titulo,
        GLN: data.GLN || "",
        formatoRP: data.formatoRP,
        mercados: "724",
        color: data.color,
        dimensiones: data.dimensiones || "",
        referenciaAgrupacion: data.referenciaAgrupacion || "",
        descripcionAecoc: data.descripcionAecoc || ""
      }
      const { data: dataProductoGeneral } = await apiGeneralProducto.productoGeneralControllerFind(filter)
      // Si no viene data es que es la primera vez entonces creamos
      if (dataProductoGeneral.length === 0) {
        await apiGeneralProducto.productoGeneralControllerCreate({ ...dataGeneral, productoId })
      } else { // Sino editamos mediante la id que viene en posición 0
        await apiGeneralProducto.productoGeneralControllerUpdateById(dataProductoGeneral[0].id, dataGeneral)
      }
      return dispatch({
        type: UPDATE_PRODUCTO_DATA
      })
    } catch (err) {
      console.error(err.message)
    }
  }
}

export const updateProductoAtributos = (productoId, atributos, atributosValues, unidad) => {
  return async (dispatch) => {
    try {
      atributos.forEach(async atributo => {
        //Dispatcheamos sólo si tiene valor
        if (atributosValues[atributo.nombre]) {
          if (atributo.productoId) {
            // Atributo existente -> editamos
            const finalAttObj = {
              productoId,
              atributoId: atributo.id,
              nombre: atributo.nombre,
              valor: atributosValues[atributo.nombre],
              ordenEnGrupo: atributo.ordenEnGrupo || "0",
              unidad: unidad[atributo.nombre]
            }
            await apiProductoAtributos.productoAtributosControllerUpdateById(atributo.id, finalAttObj)
          } else {
            // Nuevo atributo -> creamos
            const finalAttObj = {
              productoId,
              atributoId: atributo.id,
              nombre: atributo.nombre,
              valor: atributosValues[atributo.nombre]
            }
            await apiProductoAtributos.productoAtributosControllerCreate(finalAttObj)
          }
        }
      })

      return dispatch({
        type: UPDATE_PRODUCTO_DATA
      })
    } catch (err) {
      console.error(err.message)
    }
  }
}


export const updateProductoMultimedias = (productoId, multimedias, registrosMultimedia) => {
  return async (dispatch) => {
    multimedias.forEach(async multimedia => {
      // Filtramos la lista de multimedias para encontrar el id de la multimedia y asociarlo al objeto producto_multimedia
      const objMultimedia = registrosMultimedia.filter(objeto => {
        return objeto.clave === multimedia.nombre
      })
      const finalMultObj = {
        productoId,
        tipo: multimedia.tipo,
        multimediaId: objMultimedia[0].id,
        nombre: multimedia.nombre,
        objetivo: multimedia.objetivo,
        formato: multimedia.formato,
        tagAecoc: multimedia.tagAecoc,
        principalSN: multimedia.principalSN,
        publicoSN: multimedia.publicoSN
      }
      console.log("para")
      if (multimedia.id) {
        // Multimedia existente -> editamos
        try {
          console.log("para")
          await apiMultimediaProducto.productoMultimediaControllerUpdateById(multimedia.id, finalMultObj)
        } catch (err) { console.error('error al editar', err) }
      } else {
        // Nuevo multimedia -> creamos
        try {
          console.log("para")
          await apiMultimediaProducto.productoMultimediaControllerCreate(finalMultObj)
        } catch (err) { console.error('error al crear', err) }
      }
    })

    return dispatch({
      type: UPDATE_PRODUCTO_DATA
    })
  }
}

export const updateProductoDatosCatalogo = (id, datos) => {
  return async (dispatch) => {
    try {
      if (id) {
        await apiProductoDatosCatalogo.productoDatosCatalogoControllerUpdateById(id, datos)
      } else {
        await apiProductoDatosCatalogo.productoDatosCatalogoControllerCreate(datos)
      }
    } catch (err) {
      console.error(err.message)
    }
  }
}

export const deleteProductoAtributoById = (attId) => {
  return async (dispatch) => {
    try {
      const id = parseInt(attId)
      await apiProductoAtributos.productoAtributosControllerDeleteById(id)
      return dispatch({
        type: UPDATE_PRODUCTO_DATA
      })
    } catch (err) {
      console.error(err.message)
    }
  }
}

export const deleteProductoCaracteristicaById = (caracId) => {
  return async (dispatch) => {
    try {
      const id = parseInt(caracId)
      await apiCaracteristicasProducto.productoCaracteristicasControllerDeleteById(id)
      return dispatch({
        type: UPDATE_PRODUCTO_DATA
      })
    } catch (err) {
      console.error(err.message)
    }
  }
}

export const deleteProductoMultimediaById = (multClave, prodId) => {
  return async (dispatch) => {
    try {
      const filter = JSON.stringify({
        where: {
          ['productoId']: { eq: `${prodId}` }
        }
      })
      const { data: productosMultimedia } = await apiMultimediaProducto.productoMultimediaControllerFind(filter)
      const prodMultimedia = productosMultimedia.find(el => el.nombre.replaceAll("_", " ").toLowerCase() == multClave.toLowerCase())
      await apiMultimediaProducto.productoMultimediaControllerDeleteById(prodMultimedia.id)
      return dispatch({
        type: UPDATE_PRODUCTO_DATA
      })
    } catch (err) {
      console.error(err.message)
    }
  }
}

export const getData = params => {
  return async (dispatch, getState) => {
    try {
      const empresaId = getState().layout.selectedEmpresaId
      //const { page = 1, perPage = 10,  q = "", search, sortBy = "", searchInputsNombre = '', searchInputsCategorias = '', searchInputsFinalizado} = params
      const { page = 1, perPage = 10, sortBy = "", q = "", filtrosBusqueda } = params

      const filter = {
        offset: (page - 1) * perPage,
        limit: perPage,
        skip: ((page - 1) * perPage),
        order: sortBy || "nombre ASC",
        where: {
          ['empresaId']: { eq: `${empresaId}` },
          ['and']: [
            { ['nombre']: { like: `%${filtrosBusqueda.searchInputsNombre}%`, options: 'i' } },
            { ['categorias']: { like: `%${filtrosBusqueda.searchInputsCategorias}%`, options: 'i' } }
          ]
        }
      }

      // Añade la condición de búsqueda por 'finalizado' con 'and' de modo que se sumará a la búsqueda que ya había por 'nombre' y 'categorias', únicamente cuando se haya seleccionado en el desplegable 'Finalizado' que equivale a 'S' o 'No finalizado' que equivale a 'N'
      if (filtrosBusqueda.searchInputsFinalizado === 'S' || filtrosBusqueda.searchInputsFinalizado === 'N') {
        if (!filter.where['and']) {
          filter.where['and'] = []  // En caso que no se haya escrito nada en 'nombre' ni 'categorias' crea un nuevo arreglo vacío para poder añadir posteriormente el filtro 'finalizado'
        }
        filter.where['and'].push({ ['finalizado']: { eq: filtrosBusqueda.searchInputsFinalizado, options: 'i' } })
      }

      const { data: dataProductos } = await apiProductos.productosControllerFind(JSON.stringify(filter))
      const { data: dataProductosCount } = await apiProductos.productosControllerCount(JSON.stringify(filter.where))
      const dataAtributos = []
      const dataGeneral = []
      const dataCategorias = []

      for (const producto of dataProductos) {
        try {
            const idProducto = producto.id // Suponiendo que el campo de ID es 'id'
            const activoEnMarketplace = producto.activoEnMarketplace // Verificamos si está activo en Marketplace
            
            let estadoRevisado = "Pendiente" // Valor por defecto
    
            if (activoEnMarketplace === null) {
                estadoRevisado = "No incluido"
            } else {
                const { data: textosGenerados } = await apiTextosGenerados.textosGeneradosControllerObtenerTodosLosTextosGeneradosParaElProducto(idProducto)
                
                console.log(`Textos generados para producto ${idProducto}:`, textosGenerados)
                
                if (textosGenerados.length === 0) {
                    estadoRevisado = "Pendiente"
                } else {
                    const estadosRevisadoSN = textosGenerados.map(texto => texto.revisado_sn)
    
                    if (estadosRevisadoSN.every(estado => estado === 'S')) {
                        estadoRevisado = "Revisado"
                    } else if (estadosRevisadoSN.every(estado => estado === 'N')) {
                        estadoRevisado = "No revisado"
                    } else {
                        estadoRevisado = "Revisado parcial"
                    }
                }
            }
    
            // Agregamos el campo revisado a la estructura de dataProductos
            producto.revisado = estadoRevisado
    
        } catch (error) {
            console.error(`Error obteniendo textos generados para producto ${producto.id}:`, error)
        }
      }

      dispatch({
        type: GET_DATA,
        allData: dataProductos,
        data: dataProductos,
        total: dataProductosCount.count || 0,
        atributos: dataAtributos,
        general: dataGeneral,
        categorias: dataCategorias,
        params
      })
    } catch (err) {
      console.error(err.message)
    }
  }
}

export const updateOrdenAtributo = (id, atributo) => {
  return async (dispatch) => {
    try {
      await apiAtributos.atributosControllerUpdateById(id, atributo)
      return dispatch({
        type: UPDATE_ATRIBUTO
      })
    } catch (err) {
      console.error(err.message)
    }
  }
}

export const updateProductoIconos = (productoId, iconos) => {
  return async () => {
    try {
      console.log(productoId)

      // Primero elimina todos los iconos existentes para este producto
      await apiIconos.productoIconosControllerDeleteByIdProducto(productoId)

      // Una vez eliminados, crea los iconos nuevos
      for (let i = 0; i < iconos.length; i++) {
        await apiIconos.productoIconosControllerCreate({
          idProducto: productoId,
          idIcono: iconos[i].idIcono,
          texto: iconos[i].texto
        })
      }

      // Espera a que se resuelvan todas las promesas antes de continuar

    } catch (error) {
      console.error(error.message)
    }
  }
}

const getValorAtributo = (att) => {
  if (att == undefined) { return 0 }
  const valor = parseFloat((att.valor).replace(",", "."))
  if (valor == undefined || isNaN(valor)) { return 0 }
  return valor
}

export const updateFullProducto = (data, intl) => {
  return async (dispatch) => {
    try {
      const { productoId: id,
        nombre: newNombre,
        familias: newFamilias,
        categorias: newCategorias,
        valoresGenerales: generalValues,
        atributosProducto: atributos,
        atributosValues,
        registrosMultimedia,
        multimediaProducto,
        Finalizado,
        FinalizadoSAT,
        ordenAtributos,
        unidad,
        iconosValues,
        enviarAecoc,
        sincronizoConBC,
        respuestaVolumen,
        sincronizarConBC,
        activoEnMarketplace,
        ensamblado,
        idDatosCatalogo,
        codigoFabricante,
        skuProvisional,
        capacidadCarga,
        contenedor,
        precioFabrica,
        costoAlmacen,
        costeEstandar,
        envio,
        arancel,
        detallesProducto,
        necesidadesProducto
      } = data

      const fechaActual = new Date()

      // Obtener los componentes individuales de la fecha y hora
      const año = fechaActual.getFullYear()
      let mes = fechaActual.getMonth() + 1
      let dia = fechaActual.getDate()
      let horas = fechaActual.getHours()
      let minutos = fechaActual.getMinutes()
      let segundos = fechaActual.getSeconds()

      // Formatear los componentes para asegurarse de que tengan dos dígitos
      if (mes < 10) {
        mes = `0${mes}`
      }
      if (dia < 10) {
        dia = `0${dia}`
      }
      if (horas < 10) {
        horas = `0${horas}`
      }
      if (minutos < 10) {
        minutos = `0${minutos}`
      }
      if (segundos < 10) {
        segundos = `0${segundos}`
      }

      const fechaFormateada = `${año}-${mes}-${dia} ${horas}:${minutos}:${segundos}`

      const objPrimario = {
        nombre: newNombre,
        familias: newFamilias.join("; "),
        categorias: newCategorias,
        finalizado: Finalizado,
        finalizadoSAT: FinalizadoSAT,
        enviarAecoc,
        sincronizarConBC: sincronizoConBC,
        activoEnMarketplace: activoEnMarketplace ? activoEnMarketplace : "N",
        ensamblado: ensamblado ? ensamblado : "N",
        ordenAtributos,
        fechaModificacion: fechaFormateada
      }

      await apiProductos.productosControllerUpdateById(id, objPrimario)
      // SECCION GENERAL
      await dispatch(updateProductoGeneral(id, generalValues))
      // SECCION ATRIBUTOS
      await dispatch(updateProductoAtributos(id, atributos, atributosValues, unidad))
      // SECCION ICONOS
      await dispatch(updateProductoIconos(id, iconosValues))
      // SECCION MULTIMEDIA
      await dispatch(updateProductoMultimedias(id, multimediaProducto, registrosMultimedia))
      //
      //Insertamos producto-datos-catalogo
      //
      const objProductoDatosCatalogo = {
        productoId: id,
        codigoFabricante,
        skuProvisional,
        capacidadCarga,
        contenedor,
        precioFabrica,
        costoAlmacen,
        costeEstandar,
        envio,
        arancel,
        detallesProducto,
        necesidadesProducto
      }
      await dispatch(updateProductoDatosCatalogo(idDatosCatalogo, objProductoDatosCatalogo))

      if (sincronizoConBC === 'S') {
        const respuestaSincroProductoEnBC = await apiSincronizacionBC.sincronizacionBcControllerSincronizaProductosManual(id)
        console.log(respuestaSincroProductoEnBC)
      }

      if (respuestaVolumen === 'volumen NO' && sincronizarConBC === 'S') {
        Swal.fire({
          position: 'center',
          icon: 'warning',
          showCloseButton: false,
          title: "Volumen de producto",
          text: `${intl.formatMessage({ id: `El producto se ha actualizado en el sistema, pero no se sincronizará con BC debido a que su volumen es menor que 0.` })}`
        }).then(() => {
          Swal.fire({
            position: 'center',
            icon: 'success',
            showCloseButton: false,
            title: "Producto actualizado correctamente"
          }).then(() => {
            // Recarga la página una vez que la modal se haya cerrado
            location.reload()
          })
        })
      } else {
        Swal.fire({
          position: 'center',
          icon: 'success',
          showCloseButton: false,
          title: "Producto actualizado correctamente"
        }).then(() => {
          // Recarga la página una vez que la modal se haya cerrado
          location.reload()
        })
      }

      return dispatch({
        type: UPDATE_PRODUCTO_DATA
      })
    } catch (err) {
      displayErrorMsg(intl.formatMessage({ id: 'Error actualizando el producto' }))
      console.error(err.message)
    }
  }

}

export const eliminarProductoDeBC = (data, intl) => {
  return async () => {
    try {
      const { nombreProducto, marcaProducto } = data

      const dataJson = { items: [] }
      const dataP = {
        no: nombreProducto,
        marca: marcaProducto
      }
      dataJson.items.push(dataP)

      const objJSON = JSON.stringify(dataJson)
      const objJSONCambiado = objJSON.replace(/''/g, "")
      // Utiliza un await para esperar la respuesta de cada solicitud
      return await apiNavisionNew.conexionNavisionControllerPostEliminarProductoDeBC("TW_Functions_DeleteItem", objJSONCambiado)

    } catch (err) {
      displayErrorMsg(intl.formatMessage({ id: 'Error eliminando el producto' }))
      console.error(err.message)
    }
  }
}

export const eliminarProductoDelPIM = (productoId) => {
  return async () => {
    try {
      // En la BD la eliminación de un producto esta configurada en CASCADA, por lo que al eliminar el producto se eliminan también los registros de las tablas relacionadas
      const resultado = await apiProductos.productosControllerDeleteById(productoId)

      if (resultado.status === 204) {
        return 'OK'
      } else {
        return 'ERROR'
      }

    } catch (err) {
      console.error(err.message)
    }
  }
}

export const borrarSelectedProducto = () => {
  // Borramos el campo selectedProducto del estado
  return async (dispatch, getState) => {
    try {
      dispatch({
        type: DELETE_PRODUCT_DATA
      })
    } catch (err) {
      console.error(err.message)
    }
  }
}

export const replicarProducto = (productoId, intl) => {
  return async () => {
    try {
      // Elimina el producto de la base de datos
      await apiProductos.productosControllerReplica(productoId)
      displaySuccessMsg(intl.formatMessage({ id: 'Producto replicado correctamente' }))
    } catch (err) {
      displayErrorMsg(intl.formatMessage({ id: 'Error replicando el producto' }))
      console.error(err.message)
    }
  }
}

export const actualizaChecks = (productoId, intl) => {
  return async () => {
    try {
      await apiProductos.productosControllerActualizachecksporcatalogo(productoId)
    } catch (err) {
      displayErrorMsg(intl.formatMessage({ id: 'Error replicando el producto' }))
      console.error(err.message)
    }
  }
}

// O B T E N E R - D A T O S - P R O D U C T O - G E N E R A L - P O R - I D - (Detalle del producto)
export const getDatosProductoGeneralPorId = (productoId) => {
  return async () => {
    try {
      const id = parseInt(productoId)
      const filter = JSON.stringify({
        where: {
          ['productoId']: { eq: `${id}` }
        }
      })
      const { data: dataProductoGeneral } = await apiGeneralProducto.productoGeneralControllerFind(filter)
      // Si no viene data es que el producto solo existe en la tabla de productos, y no tiene detalle (productosGeneral)
      if (dataProductoGeneral.length === 0) {
        return []
      } else {
        return dataProductoGeneral[0]
      }
    } catch (err) {
      console.error(err.message)
    }
  }
}

export const buscarMarketPlaces = params => {
  return async (dispatch, getState) => {
    try {

      console.log(params)
      const productosArray = Object.values(params.productos || {}) // Convertir a array
      const categoriaDos = params.categoriaDos || "" 
      const categoriaTres = params.categoriaTres || ""
      const categoriaTresDefinida = params.categoriaTresDefinida  || "" 
      const idMarketPlace = params.idMarketPlace || ""
      const contextoPeticion = params.contexto || ""
      const jsonaEnviarSimulado = params.jsonaEnviarSimulado || ""
      const idMarketPlacePrompt = params.idMarketPlacePrompt || ""

      console.log(productosArray)
      console.log("buscarMarketPlaces")
      // Realizar la consulta pasandole los productos
      if (contextoPeticion === "obtenerSoloTextos") {
        console.log(JSON.stringify(jsonaEnviarSimulado))
      
        const jsonString = JSON.stringify(jsonaEnviarSimulado) // Convertir a JSON
        const base64String = btoa(jsonString)  // Convertir a Base64
      
        try {
          const jsonaEnviar = {
            base64Data: base64String
          }
          return await dispatch(obtenerTextosGenerados_API_SVAN("pim/process-base64", jsonaEnviar))
        } catch (error) {
          console.error("Error en buscarMarketPlaces:", error.message)
        }

      } else {
        const emailUsuarioLogueado = getUserData()?.mail
        const { data: dataVistaMarketPlacePrompts } = await apiVistaMarketPlacePrompts.vistaMarketPlacePromptsControllerObtenerRegistrosVistaMarketPlaceProductoGeneral(JSON.stringify(productosArray), categoriaDos, categoriaTresDefinida, idMarketPlace, idMarketPlacePrompt, emailUsuarioLogueado)

        //JSON.stringify(productosArray), 'KIT SOLAR', 'PLACA SOLAR', "AMZ"

        console.log(dataVistaMarketPlacePrompts)
        // Recorrer todos los registros de la respuesta
        for (const item of dataVistaMarketPlacePrompts) {
          const { idPrompt, productos } = item
        
          console.log(idPrompt)
          console.log(JSON.stringify(productos))
        
          const jsonStringAenviar = JSON.stringify(productos) // Convertir a JSON
          const base64StringAenviar = btoa(jsonStringAenviar)  // Convertir a Base64
        
          try {
            const jsonFinalAEnviar = {
              base64Data: base64StringAenviar
            }
            await dispatch(obtenerTextosGenerados_API_SVAN("pim/process-base64-direct", jsonFinalAEnviar))
          } catch (error) {
            console.error("Error en buscarMarketPlaces:", error.message)
          }
        }
        return dataVistaMarketPlacePrompts.length > 0 ? { data: { status: "Ejecutado" } } : { data: { status: "NoEjecutado"} }
      }
    } catch (err) {
      console.error("Error en buscarMarketPlaces:", err.message)
    }
  }
}

export const buscarRevisados = params => {
  return async (dispatch, getState) => {
    try {
      const productos = params.productos || ""
      const categoriaDos = params.categoriaDos || "" 
      const categoriaTres = params.categoriaTres || ""
      const categoriaTresDefinida = params.categoriaTresDefinida  || "" 
      const idMarketPlace = params.idMarketPlace || ""

      const { data: dataRevisados } = await apiTextosGenerados.textosGeneradosControllerObtenerTodosLosTextosGeneradosEnRevisadoParaTodosLosProductos(productos, categoriaDos, categoriaTresDefinida, idMarketPlace)
      console.log(dataRevisados)
      return dataRevisados
    } catch (err) {
      console.error("Error en buscarMarketPlaces:", err.message)
    }
  }
}