import { MarketPlacesControllerApi, settings } from '@api/backend'
import { displaySuccessMsg, displayErrorMsg } from '@src/utility/Utils'

const prefix = 'marketplaces'

export const GET_MARKETPLACE = `${prefix}/GET_DATA_marketplace`
export const GET_MARKETPLACES = `${prefix}/GET_DATA`
export const GET_MARKETPLACES_ALL = `${prefix}/GET_DATA_ALL`
export const GET_MARKETPLACES_COUNT = `${prefix}/GET_DATA_COUNT`
export const ADD_MARKETPLACE = `${prefix}/ADD`
export const DELETE_MARKETPLACE = `${prefix}/DELETE`
export const UPDATE_MARKETPLACE = `${prefix}/UPDATE`
export const INIT_DATA_NECESARIA = `${prefix}/INIT_DATA_NECESARIA`

const apiMarketPlaces = new MarketPlacesControllerApi(settings)

// Función global para manejar errores en la API
const manejarErrorAPI = (err, intl) => {
  if (err.response) {
    const { status, data } = err.response

    if (status === 422) {
      const errorCode = data?.error?.code
      const errorMessage = data?.error?.message

      if (errorCode === 'ER_DUP_ENTRY') {
        displayErrorMsg(intl.formatMessage({ id: 'El código de marketplace ya existe. Por favor, usa uno diferente.' }))
      } else {
        displayErrorMsg(intl.formatMessage({ id: errorMessage || 'Error al guardar el marketplace.' }))
      }
    } else {
      displayErrorMsg(intl.formatMessage({ id: 'Ocurrió un error inesperado. Intente nuevamente.' }))
    }
  } else {
    displayErrorMsg(intl.formatMessage({ id: 'Error de conexión. Verifique su red e intente de nuevo.' }))
  }

  console.error('Error:', err.message)
}

export const getMarketPlaces = params => {
  return async (dispatch, getState) => {
    try {
      const { page = 1, perPage = 10, sortBy = "", filtrosBusqueda } = params
      const { searchCodigo, searchDescripcion } = filtrosBusqueda || {}

      const filter = {
        offset: (page - 1) * perPage,
        limit: perPage,
        skip: ((page - 1) * perPage),
        order: sortBy || "codigo ASC",
        where: {
          and: []
        }
      }

      // Siempre incluimos estos filtros ya que tienen valores por defecto
      filter.where.and.push({ codigo: { like: searchCodigo ? `%${searchCodigo}%` : '%%' } })
      filter.where.and.push({ descripcion: { like: searchDescripcion ? `%${searchDescripcion}%` : '%%' } })

      const { data: dataMarketPlace } = await apiMarketPlaces.marketPlacesControllerFind(JSON.stringify(filter))
      const { data: dataMarketPlaceCount } = await apiMarketPlaces.marketPlacesControllerCount(JSON.stringify(filter.where))

      dispatch({
        type: GET_MARKETPLACES,
        marketplaces: dataMarketPlace,
        total: dataMarketPlaceCount.count || 0,
        params
      })
    } catch (err) {
      console.error(err.message)
    }
  }
}

export const addMarketPlace = (marketplace, intl) => {
  return async (dispatch) => {
    try {
      const { data } = await apiMarketPlaces.marketPlacesControllerCreate(marketplace)
      displaySuccessMsg(intl.formatMessage({ id: 'Marketplace creado' }))
      localStorage.setItem("actualizar_Marketplace", "true")
      dispatch({
        type: ADD_MARKETPLACE,
        data
      })
      // Limpiamos el marketplace seleccionado
      dispatch({
        type: GET_MARKETPLACE,
        selectedCompany: null
      })
    } catch (err) {
      manejarErrorAPI(err, intl)
    }
  }
}

export const updateMarketPlace = (id, marketplace, intl) => {
  return async (dispatch) => {
    try {
      const { data } = await apiMarketPlaces.marketPlacesControllerUpdateById(id, marketplace)
      displaySuccessMsg(intl.formatMessage({ id: 'Marketplace actualizado' }))
      localStorage.setItem("actualizar_Marketplace", "true")
      dispatch({
        type: UPDATE_MARKETPLACE,
        data
      })
      // Limpiamos el marketplace seleccionadoo
      dispatch({
        type: GET_MARKETPLACE,
        selectedCompany: null
      })
    } catch (err) {
      manejarErrorAPI(err, intl)
    }
  }
}

export const deleteMarketPlace = (marketPlaceId, intl) => {
  return async (dispatch) => {
    try {
      await apiMarketPlaces.marketPlacesControllerDeleteById(marketPlaceId)
      displaySuccessMsg(intl.formatMessage({ id: 'Marketplace eliminado' }))
      dispatch({
        type: DELETE_MARKETPLACE,
        data: marketPlaceId
      })
    } catch (err) {
      console.error(err.message)
    }
  }
}

export const getMarketPlace = (marketplace) => {
  return async (dispatch) => {
    try {
      const { data: dataMarketplace } = await apiMarketPlaces.marketPlacesControllerFindById(marketplace)
      dispatch({
        type: GET_MARKETPLACE,
        selectedMarketPlace: dataMarketplace,
        ultimoIdMarketPlace: marketplace
      })
    } catch (err) {
      console.error(err.message)
    }
  }
}

export const initMarketPlace = () => {
  return async (dispatch) => {
    dispatch({
      type: GET_MARKETPLACE,
      selectedCompany: null
    })
  }
}

export const obtenerTodosLosMarketPlaces = () => {
  return async () => {
    try {
      const { data: listaMarketPlaces } = await apiMarketPlaces.marketPlacesControllerFind()
      return listaMarketPlaces
    } catch (err) {
      console.error(err.message)
    }
  }
}
