import { 
  GET_LOG_SINCRONIZACION,
  GET_LOGS_SINCRONIZACION,
  GET_LOGS_SINCRONIZACION_ALL,
  GET_LOGS_SINCRONIZACION_COUNT,
  GET_FILTROS_LOGS_SINCRONIZACION } from "../action"

// ** Initial State
const initialState = {
  data: [],
  allData: [],
  selectedProducto: {},
  gruposAtributos: [],
  atributos: [],
  categorias: [],
  familias: [],
  logsincronizacion: [],
  selectedsustitutoId: 1,
  params: {},
  processing: false,
  filtros: {}
}

const prefix = 'logsincronizacion'

const logsincronizacion = (state = initialState, action) => {
  switch (action.type) {
    case `${prefix}/GET_ALL_DATA`:
      return { ...state, allData: action.data }
      case GET_LOGS_SINCRONIZACION:
      return {
        ...state,
        data: action.logsincronizacion,
        total: action.total || 0,
        processing: false,
        params: action.params
      }
      case GET_LOGS_SINCRONIZACION_ALL:
      return {
          ...state,
          logsincronizacion: action.logsincronizacion,
          processing: false
      }
     case GET_LOG_SINCRONIZACION:
       return { ...state, selectedLogSincronizacion: action.selectedLogSincronizacion }
    case GET_LOGS_SINCRONIZACION_COUNT:
      return { ...state, total: action.total }
    case GET_FILTROS_LOGS_SINCRONIZACION:
      return {
        ...state,
        filtros: action.filtros
      }
    default:
      return { ...state }
  }
}
export default logsincronizacion
