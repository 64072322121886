import { SituacionesControllerApi, settings } from '@api/backend'
import { displaySuccessMsg } from '@src/utility/Utils'

const prefix = 'Situaciones'

export const GET_SITUACION = `${prefix}/GET_DATA_Situacion`
export const GET_SITUACIONES = `${prefix}/GET_DATA`
export const GET_SITUACIONES_ALL = `${prefix}/GET_DATA_ALL`
export const GET_SITUACIONES_COUNT = `${prefix}/GET_DATA_COUNT`
export const ADD_SITUACION = `${prefix}/ADD`
export const DELETE_SITUACION = `${prefix}/DELETE`
export const UPDATE_SITUACION = `${prefix}/UPDATE`
export const INIT_DATA_NECESARIA = `${prefix}/INIT_DATA_NECESARIA`

const apiSituaciones = new SituacionesControllerApi(settings)

// ** Get all Data

export const getSituaciones = params => {
  return async (dispatch, getState) => {
    try {

      const { page = 1, perPage = 10, sortBy = "", filtrosBusqueda } = params
      const { searchNombre } = filtrosBusqueda || {}
      const empresaId = getState().layout.selectedEmpresaId

      const filter = {
        offset: (page - 1) * perPage,
        limit: perPage,
        skip: ((page - 1) * perPage),
        order: sortBy || "nombre ASC",
        where: {
          and: []
        }
      }

      // Siempre incluimos estos filtros ya que tienen valores por defecto
      filter.where.and.push({ empresaId: { eq: `${empresaId}` } })
      filter.where.and.push({ nombre: { like: searchNombre ? `%${searchNombre}%` : '%%' } })

      const { data: dataSituaciones } = await apiSituaciones.situacionesControllerFind(JSON.stringify(filter))
      const { data: dataSituacionesCount } = await apiSituaciones.situacionesControllerCount(JSON.stringify(filter.where))
      dispatch({
        type: GET_SITUACIONES,
        situaciones: dataSituaciones,
        total: dataSituacionesCount.count || 0,
        params
      })
    } catch (err) {
      console.error(err.message)
    }
  }
}

export const addSituacion = (Situacion, intl) => {
  return async (dispatch) => {
    try {
      const { data } = await apiSituaciones.situacionesControllerCreate(Situacion)
      displaySuccessMsg(intl.formatMessage({ id: 'Situacion creada' }))
      localStorage.setItem("actualizar_Situaciones", "true")
      dispatch({
        type: ADD_SITUACION,
        data
      })
      // Limpiamos la situacion seleccionada
      dispatch({
        type: GET_SITUACION,
        selectedCompany: null
      })
    } catch (err) {
      console.error(err.message)
    }
  }
}

export const updateSituacion = (id, Situacion, intl) => {
  return async (dispatch) => {
    try {
      const { data } = await apiSituaciones.situacionesControllerUpdateById(id, Situacion)
      displaySuccessMsg(intl.formatMessage({ id: 'Situacion actualizada' }))
      localStorage.setItem("actualizar_Situaciones", "true")
      dispatch({
        type: UPDATE_SITUACION,
        data
      })
      // Limpiamos la situacion seleccionada
      dispatch({
        type: GET_SITUACION,
        selectedCompany: null
      })
    } catch (err) {
      console.error(err.message)
    }
  }
}

export const deleteSituacion = (SituacionId, intl) => {
  return async (dispatch) => {
    try {
      await apiSituaciones.situacionesControllerDeleteById(SituacionId)
      displaySuccessMsg(intl.formatMessage({ id: 'Situacion eliminada' }))
      dispatch({
        type: DELETE_SITUACION,
        data: SituacionId
      })
    } catch (err) {
      console.error(err.message)
    }
  }
}

export const getSituacion = (Situacion) => {
  return async (dispatch) => {
    try {
      const { data: dataSituacion } = await apiSituaciones.situacionesControllerFindById(Situacion)
      dispatch({
        type: GET_SITUACION,
        selectedSituacion: dataSituacion,
        ultimoIdSituacion: Situacion
      })
    } catch (err) {
      console.error(err.message)
    }
  }
}

export const initSituacion = () => {
  return async (dispatch) => {
    dispatch({
      type: GET_SITUACION,
      selectedCompany: null
    })
  }
}
