import { MarketPlacePromptsControllerApi, VistaMarketPlacePromptsControllerApi, B2bProductosControllerApi, TextosGeneradosControllerApi, settings, ProductosControllerApi } from '@api/backend'
import { displaySuccessMsg, displayErrorMsg } from '@src/utility/Utils'

const prefix = 'marketplaceprompts'

export const GET_MARKETPLACE_PROMPT = `${prefix}/GET_DATA_marketplace_prompt`
export const GET_MARKETPLACE_PROMPTS = `${prefix}/GET_DATA`
export const GET_MARKETPLACE_PROMPTS_ALL = `${prefix}/GET_DATA_ALL`
export const GET_MARKETPLACE_PROMPTS_COUNT = `${prefix}/GET_DATA_COUNT`
export const ADD_MARKETPLACE_PROMPT = `${prefix}/ADD`
export const DELETE_MARKETPLACE_PROMPT = `${prefix}/DELETE`
export const UPDATE_MARKETPLACE_PROMPT = `${prefix}/UPDATE`
export const INIT_DATA_NECESARIA = `${prefix}/INIT_DATA_NECESARIA`

const apiMarketPlacePrompts = new MarketPlacePromptsControllerApi(settings)
const apiVistaMarketPlacePrompts = new VistaMarketPlacePromptsControllerApi(settings)
const apiVistaB2bProductos = new B2bProductosControllerApi(settings)
const apiTextosGenerados = new TextosGeneradosControllerApi(settings)
const apiProductos = new ProductosControllerApi(settings)

// Función global para manejar errores en la API
const manejarErrorAPI = (err, intl) => {
  if (err.response) {
    const { status, data } = err.response

    if (status === 422) {
      const errorCode = data?.error?.code
      const errorMessage = data?.error?.message

      if (errorCode === 'ER_DUP_ENTRY') {
        displayErrorMsg(intl.formatMessage({ id: 'El código de marketplacePrompts ya existe. Por favor, usa uno diferente.' }))
      } else {
        displayErrorMsg(intl.formatMessage({ id: errorMessage || 'Error al guardar el marketplacePrompts.' }))
      }
    } else {
      displayErrorMsg(intl.formatMessage({ id: 'Ocurrió un error inesperado. Intente nuevamente.' }))
    }
  } else {
    displayErrorMsg(intl.formatMessage({ id: 'Error de conexión. Verifique su red e intente de nuevo.' }))
  }

  console.error('Error:', err.message)
}

export const getMarketPlacePrompts = params => {
  return async (dispatch, getState) => {
    try {
      const { page = 1, perPage = 10, sortBy = "", filtrosBusqueda } = params
      const {
        searchMarketplace,
        categoriaNivelDos_Categoria_Agrupada, categoriaNivelTres_Subcategoria_Agrupada, categoriaNivelTres_Subcategoria_Definida,
        searchCampoUno, searchCampoDos, searchCampoTres, searchCampoCuatro, searchCampoCinco
      } = filtrosBusqueda || {}
      const filter = {
        offset: (page - 1) * perPage,
        limit: perPage,
        skip: ((page - 1) * perPage),
        order: sortBy || "marketplaceId ASC"
      }

      // Creamos una lista para los filtros AND
      const andConditions = []

      // Si hay un filtro por marketplace, se agrega con AND
      if (searchMarketplace) {
        andConditions.push({ marketplaceId: { eq: `${searchMarketplace}` } })
      }

      // Creamos un OR solo con los filtros ingresados (sin valores por defecto)
      const orConditions = []

      if (categoriaNivelTres_Subcategoria_Agrupada) {
        // Si categoriaNivelTres_Subcategoria_Agrupada tiene valor, solo filtramos por él
        orConditions.push({ categoriaCodigo: { like: `%${categoriaNivelTres_Subcategoria_Agrupada}%` } })
      } else {
        // Si NO tiene valor, aplicamos las demás condiciones
        if (categoriaNivelDos_Categoria_Agrupada) {
          orConditions.push({ categoriaCodigo: { like: `%${categoriaNivelDos_Categoria_Agrupada}%` } })
        }

        if (categoriaNivelTres_Subcategoria_Definida) {
          orConditions.push({ categoriaCodigo: { like: `%${categoriaNivelTres_Subcategoria_Definida}%` } })
        }
      }
      if (searchCampoUno) orConditions.push({ campoUno: { like: `%${searchCampoUno}%` } })
      if (searchCampoDos) orConditions.push({ campoDos: { like: `%${searchCampoDos}%` } })
      if (searchCampoTres) orConditions.push({ campoTres: { like: `%${searchCampoTres}%` } })
      if (searchCampoCuatro) orConditions.push({ campoCuatro: { like: `%${searchCampoCuatro}%` } })
      if (searchCampoCinco) orConditions.push({ campoCinco: { like: `%${searchCampoCinco}%` } })

      // Solo agregamos el OR si hay condiciones dentro
      if (orConditions.length > 0) {
        andConditions.push({ or: orConditions })
      }

      // Si hay filtros, agregamos where si no, lo dejamos vacío para traer todo
      if (andConditions.length > 0) {
        filter.where = { and: andConditions }
      }

      const { data: dataVistaMarketPlacePrompts } = await apiVistaMarketPlacePrompts.vistaMarketPlacePromptsControllerFind(JSON.stringify(filter))
      const { data: dataVistaMarketPlacePromptsCount } = await apiVistaMarketPlacePrompts.vistaMarketPlacePromptsControllerCount(JSON.stringify(filter.where))

      dispatch({
        type: GET_MARKETPLACE_PROMPTS,
        marketplaceprompts: dataVistaMarketPlacePrompts,
        total: dataVistaMarketPlacePromptsCount.count || 0,
        params
      })
    } catch (err) {
      console.error(err.message)
    }
  }
}

export const addMarketPlacePrompt = (marketplacePrompts, intl) => {
  return async (dispatch) => {
    try {
      const { data } = await apiMarketPlacePrompts.marketPlacePromptsControllerCreate(marketplacePrompts)
      displaySuccessMsg(intl.formatMessage({ id: 'MarketPlacePrompts creado' }))
      localStorage.setItem("actualizar_MarketPlacePrompts", "true")
      dispatch({
        type: ADD_MARKETPLACE_PROMPT,
        data
      })
      // Limpiamos el marketplacePrompts seleccionado
      dispatch({
        type: GET_MARKETPLACE_PROMPT,
        selectedCompany: null
      })
    } catch (err) {
      manejarErrorAPI(err, intl)
    }
  }
}

export const updateMarketPlacePrompt = (id, marketplacePrompts, intl) => {
  return async (dispatch) => {
    try {
      const { data } = await apiMarketPlacePrompts.marketPlacePromptsControllerUpdateById(id, marketplacePrompts)
      displaySuccessMsg(intl.formatMessage({ id: 'MarketPlacePrompts actualizado' }))
      localStorage.setItem("actualizar_MarketPlacePrompts", "true")
      dispatch({
        type: UPDATE_MARKETPLACE_PROMPT,
        data
      })
      // Limpiamos el marketplacePrompts seleccionado
      dispatch({
        type: GET_MARKETPLACE_PROMPT,
        selectedCompany: null
      })
    } catch (err) {
      manejarErrorAPI(err, intl)
    }
  }
}

export const deleteMarketPlacePrompt = (marketPlacePromptId, intl) => {
  return async (dispatch) => {
    try {
      await apiMarketPlacePrompts.marketPlacePromptsControllerDeleteById(marketPlacePromptId)
      displaySuccessMsg(intl.formatMessage({ id: 'MarketPlacePrompts eliminado' }))
      dispatch({
        type: DELETE_MARKETPLACE_PROMPT,
        data: marketPlacePromptId
      })
    } catch (err) {
      console.error(err.message)
    }
  }
}

export const getMarketPlacePrompt = (marketplacePrompt) => {
  return async (dispatch) => {
    try {
      const { data: dataMarketPlacePrompt } = await apiMarketPlacePrompts.marketPlacePromptsControllerFindById(marketplacePrompt)
      dispatch({
        type: GET_MARKETPLACE_PROMPT,
        selectedMarketPlacePrompt: dataMarketPlacePrompt,
        ultimoIdMarketPlacePrompt: marketplacePrompt
      })
    } catch (err) {
      console.error(err.message)
    }
  }
}

export const initMarketPlacePrompt = () => {
  return async (dispatch) => {
    dispatch({
      type: GET_MARKETPLACE_PROMPT,
      selectedCompany: null
    })
  }
}

export const comprobarSiExisteElMarketplaceConLaMismaCategoria = (marketplaceId, categoriaId, idExcluir) => {
  return async () => {
    try {
      const { data: respuesta } = await apiMarketPlacePrompts.marketPlacePromptsControllerExisteMarketPlaceYCategoria(marketplaceId, categoriaId, idExcluir)
      return respuesta
    } catch (err) {
      console.error(err.message)
    }
  }
}

export const obtenerTextosGenerados_API_SVAN = (endpoint, jsonaEnviar) => {
  return async () => {
    try {
      return await apiMarketPlacePrompts.marketPlacePromptsControllerGenerarTextosIAAPISVAN(endpoint, jsonaEnviar)
    } catch (error) {
      console.error(error)
    }
    //return await apiMarketPlacePrompts.marketPlacePromptsControllerGenerarTextosIAAPISVAN("pim/process-base64", jsonaEnviar)
  }
}

export const guardarTextosGenerados = (idMarketPlacePrompt, jsonTextosGenerados) => {
  return async () => {
    console.log('jsonTextosGeneradoss', jsonTextosGenerados)
    return await apiTextosGenerados.textosGeneradosControllerGuardarTextosGenerados(idMarketPlacePrompt, jsonTextosGenerados)
  }
}

export const actualizarTextosGeneradosParaElProducto = (id, objTextosGenerados, intl) => {
  return async () => {
    try {
      const { data } = await apiTextosGenerados.textosGeneradosControllerUpdateById(id, objTextosGenerados)
      displaySuccessMsg(intl.formatMessage({ id: 'Textos Generados para el producto, actualizados correctamente' }))
    } catch (err) {
      manejarErrorAPI(err, intl)
    }
  }
}

export const obtenerProductosPorCategoria = (tipo, codigoCategoria, codigoPadre) => {
  return async () => {
    try {
      let filter

      if (tipo === 'categoria') {
        filter = {
          where: {
            and: [
              { activoEnMarketplace: 'S' },
              {categoriaPadre: codigoCategoria}
            ]
          }
        }
      } else if (tipo === 'subcategoria') {
        filter = {
          where: {
            and: [
              { activoEnMarketplace: 'S' },
              { categorias: codigoCategoria },
              { categoriaPadre: codigoPadre }
            ]
          }
        }
      } else {
        throw new Error('Tipo no válido')
      }

      const response = await apiVistaB2bProductos.b2bProductosControllerFind(JSON.stringify(filter))
      return response.data
    } catch (err) {
      console.error('Error obteniendo productos por categoría:', err.message)
      throw err
    }
  }
}

// Obtiene todos los textos generados para el producto en todos los marketplaces prompts donde aparezca
// Consulta la vista de textos generados
export const obtenerTextosGeneradosParaElProducto = (idProducto) => {
  return async () => {
    console.log('idProducto', idProducto)
    return await apiTextosGenerados.textosGeneradosControllerObtenerTodosLosTextosGeneradosParaElProducto(idProducto)
  }
}

export const obtenerNombreProducto = async (idProducto) => {
  try {
    console.log('idProducto', idProducto)
    return await apiProductos.productosControllerFindById(idProducto)
  } catch (err) {
    console.error(err.message)
  }
}