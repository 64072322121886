import { 
  GET_MARKETPLACE_PROMPT,
  GET_MARKETPLACE_PROMPTS,
  GET_MARKETPLACE_PROMPTS_ALL,
  GET_MARKETPLACE_PROMPTS_COUNT,
  ADD_MARKETPLACE_PROMPT,
  DELETE_MARKETPLACE_PROMPT,
  UPDATE_MARKETPLACE_PROMPT } from "../action"

// ** Initial Statee
const initialState = {
  data: [],
  allData: [],
  selectedProducto: {},
  gruposAtributos: [],
  atributos: [],
  categorias: [],
  familias: [],
  marketplaceprompts: [],
  selectedMarketPlacePrompt: {},
  params: {},
  processing: false
}

const prefix = 'marketplaceprompts'

const marketplaceprompts = (state = initialState, action) => {
  switch (action.type) {
    case `${prefix}/GET_ALL_DATA`:
      return { ...state, allData: action.data }
      case GET_MARKETPLACE_PROMPTS:
      return {
        ...state,
        data: action.marketplaceprompts,
        total: action.total || 0,
        processing: false,
        params: action.params || {}
      }
      case GET_MARKETPLACE_PROMPTS_ALL:
      return {
          ...state,
          marketplaceprompts: action.marketplaceprompts,
          processing: false
      }
     case GET_MARKETPLACE_PROMPT:
       return { ...state, selectedMarketPlacePrompt: action.selectedMarketPlacePrompt,  ultimoIdMarketPlacePrompt: action.ultimoIdMarketPlacePrompt }
    case ADD_MARKETPLACE_PROMPT:
      return { ...state, data: state.data.concat(action.data) }
    case DELETE_MARKETPLACE_PROMPT:
      return { ...state, data: [...state.data].filter(marketPlacePrompt => marketPlacePrompt.id !== action.data)}
    case GET_MARKETPLACE_PROMPTS_COUNT:
      return { ...state, total: action.total }
    case UPDATE_MARKETPLACE_PROMPT:
      return { ...state, data: state.data.map(marketPlacePrompt => (marketPlacePrompt.id === action.data.id ? action.data : marketPlacePrompt)) }
    default:
      return { ...state }
  }
}
export default marketplaceprompts
