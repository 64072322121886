import { IconosControllerApi, settings } from '@api/backend'
import { displaySuccessMsg } from '@src/utility/Utils'

const prefix = 'iconos'

export const GET_ICONO = `${prefix}/GET_DATA_icono`
export const GET_ICONOS = `${prefix}/GET_DATA`
export const GET_ICONOS_ALL = `${prefix}/GET_DATA_ALL`
export const GET_ICONOS_COUNT = `${prefix}/GET_DATA_COUNT`
export const ADD_ICONO = `${prefix}/ADD`
export const DELETE_ICONO = `${prefix}/DELETE`
export const UPDATE_ICONO = `${prefix}/UPDATE`
export const INIT_DATA_NECESARIA = `${prefix}/INIT_DATA_NECESARIA`

const apiIconos = new IconosControllerApi(settings)

export const getIconos = params => {
  return async (dispatch, getState) => {
    try {
      const { page = 1, perPage = 10, sortBy = "", filtrosBusqueda } = params
      const { searchNombre, searchTexto } = filtrosBusqueda || {}
      const empresaId = getState().layout.selectedEmpresaId

      const filter = {
        offset: (page - 1) * perPage,
        limit: perPage,
        skip: ((page - 1) * perPage),
        order: sortBy || "nombre ASC",
        where: {
          and: []
        }
      }

      // Siempre incluimos estos filtros ya que tienen valores por defecto
      filter.where.and.push({ empresaId: { eq: `${empresaId}` } })
      filter.where.and.push({ nombre: { like: searchNombre ? `%${searchNombre}%` : '%%' } })
      filter.where.and.push({ posiblesValores: { like: searchTexto ? `%${searchTexto}%` : '%%' } })

      const { data: dataIconos } = await apiIconos.iconosControllerFind(JSON.stringify(filter))
      const { data: dataIconosCount } = await apiIconos.iconosControllerCount(JSON.stringify(filter.where))

      dispatch({
        type: GET_ICONOS,
        iconos: dataIconos,
        total: dataIconosCount.count || 0,
        params
      })
    } catch (err) {
      console.error(err.message)
    }
  }
}

export const addIcono = (icono, intl) => {
  return async (dispatch) => {
    try {
      const { data } = await apiIconos.iconosControllerCreate(icono)
      displaySuccessMsg(intl.formatMessage({ id: 'Icono creado' }))
      localStorage.setItem("actualizar_Iconos", "true")
      dispatch({
        type: ADD_ICONO,
        data
      })
      // Limpiamos el icono seleccionado
      dispatch({
        type: GET_ICONO,
        selectedCompany: null
      })
    } catch (err) {
      console.error(err.message)
    }
  }
}

export const updateIcono = (id, icono, intl) => {
  return async (dispatch) => {
    try {
      const { data } = await apiIconos.iconosControllerUpdateById(id, icono)
      displaySuccessMsg(intl.formatMessage({ id: 'Icono actualizado' }))
      localStorage.setItem("actualizar_Iconos", "true")
      dispatch({
        type: UPDATE_ICONO,
        data
      })
      // Limpiamos el icono seleccionado
      dispatch({
        type: GET_ICONO,
        selectedCompany: null
      })
    } catch (err) {
      console.error(err.message)
    }
  }
}

export const deleteIcono = (iconoId, intl) => {
  return async (dispatch) => {
    try {
      await apiIconos.iconosControllerDeleteById(iconoId)
      displaySuccessMsg(intl.formatMessage({ id: 'Icono eliminado' }))
      dispatch({
        type: DELETE_ICONO,
        data: iconoId
      })
    } catch (err) {
      console.error(err.message)
    }
  }
}

export const getIcono = (icono) => {
  return async (dispatch) => {
    try {
      const { data: dataicono } = await apiIconos.iconosControllerFindById(icono)
      dispatch({
        type: GET_ICONO,
        selectedIcono: dataicono,
        ultimoIdIcono: icono
      })
    } catch (err) {
      console.error(err.message)
    }
  }
}

export const initIcono = () => {
  return async (dispatch) => {
    dispatch({
      type: GET_ICONO,
      selectedCompany: null
    })
  }
}
