/* tslint:disable */
/* eslint-disable */
/**
 * Svan application
 * Pim API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface Aecoc
 */
export interface Aecoc {
    /**
     * 
     * @type {string}
     * @memberof Aecoc
     */
    'texto': string;
    /**
     * 
     * @type {string}
     * @memberof Aecoc
     */
    'imagenes': string;
    /**
     * 
     * @type {string}
     * @memberof Aecoc
     */
    'gln': string;
}
/**
 * 
 * @export
 * @interface Atributos
 */
export interface Atributos {
    /**
     * 
     * @type {number}
     * @memberof Atributos
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof Atributos
     */
    'grupoAtributosId'?: number;
    /**
     * 
     * @type {number}
     * @memberof Atributos
     */
    'codigo'?: number;
    /**
     * 
     * @type {string}
     * @memberof Atributos
     */
    'nombre'?: string;
    /**
     * 
     * @type {string}
     * @memberof Atributos
     */
    'unidadSN'?: string;
    /**
     * 
     * @type {string}
     * @memberof Atributos
     */
    'unidad'?: string;
    /**
     * 
     * @type {string}
     * @memberof Atributos
     */
    'unidadAecoc'?: string;
    /**
     * 
     * @type {string}
     * @memberof Atributos
     */
    'tagUOM'?: string;
    /**
     * 
     * @type {string}
     * @memberof Atributos
     */
    'tipoCampo'?: string;
    /**
     * 
     * @type {string}
     * @memberof Atributos
     */
    'tagAecoc'?: string;
    /**
     * 
     * @type {string}
     * @memberof Atributos
     */
    'filtro'?: string;
    /**
     * 
     * @type {string}
     * @memberof Atributos
     */
    'posiblesValores'?: string;
    /**
     * 
     * @type {string}
     * @memberof Atributos
     */
    'posiblesValoresAecoc'?: string;
    /**
     * 
     * @type {string}
     * @memberof Atributos
     */
    'categorias'?: string;
    /**
     * 
     * @type {number}
     * @memberof Atributos
     */
    'repeticiones'?: number;
    /**
     * 
     * @type {string}
     * @memberof Atributos
     */
    'valorDefecto'?: string;
    /**
     * 
     * @type {string}
     * @memberof Atributos
     */
    'tipoDescripcionSN'?: string;
}
/**
 * 
 * @export
 * @interface AtributosFilter
 */
export interface AtributosFilter {
    /**
     * 
     * @type {number}
     * @memberof AtributosFilter
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof AtributosFilter
     */
    'limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof AtributosFilter
     */
    'skip'?: number;
    /**
     * 
     * @type {string | Array<string>}
     * @memberof AtributosFilter
     */
    'order'?: string | Array<string>;
    /**
     * 
     * @type {object | Set<string>}
     * @memberof AtributosFilter
     */
    'fields'?: object | Set<string>;
}
/**
 * 
 * @export
 * @interface AtributosFilter1
 */
export interface AtributosFilter1 {
    /**
     * 
     * @type {number}
     * @memberof AtributosFilter1
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof AtributosFilter1
     */
    'limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof AtributosFilter1
     */
    'skip'?: number;
    /**
     * 
     * @type {string | Array<string>}
     * @memberof AtributosFilter1
     */
    'order'?: string | Array<string>;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof AtributosFilter1
     */
    'where'?: { [key: string]: object; };
    /**
     * 
     * @type {object | Set<string>}
     * @memberof AtributosFilter1
     */
    'fields'?: object | Set<string>;
}
/**
 * (tsType: Partial<Atributos>, schemaOptions: { partial: true })
 * @export
 * @interface AtributosPartial
 */
export interface AtributosPartial {
    /**
     * 
     * @type {number}
     * @memberof AtributosPartial
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof AtributosPartial
     */
    'grupoAtributosId'?: number;
    /**
     * 
     * @type {number}
     * @memberof AtributosPartial
     */
    'codigo'?: number;
    /**
     * 
     * @type {string}
     * @memberof AtributosPartial
     */
    'nombre'?: string;
    /**
     * 
     * @type {string}
     * @memberof AtributosPartial
     */
    'unidadSN'?: string;
    /**
     * 
     * @type {string}
     * @memberof AtributosPartial
     */
    'unidad'?: string;
    /**
     * 
     * @type {string}
     * @memberof AtributosPartial
     */
    'unidadAecoc'?: string;
    /**
     * 
     * @type {string}
     * @memberof AtributosPartial
     */
    'tagUOM'?: string;
    /**
     * 
     * @type {string}
     * @memberof AtributosPartial
     */
    'tipoCampo'?: string;
    /**
     * 
     * @type {string}
     * @memberof AtributosPartial
     */
    'tagAecoc'?: string;
    /**
     * 
     * @type {string}
     * @memberof AtributosPartial
     */
    'filtro'?: string;
    /**
     * 
     * @type {string}
     * @memberof AtributosPartial
     */
    'posiblesValores'?: string;
    /**
     * 
     * @type {string}
     * @memberof AtributosPartial
     */
    'posiblesValoresAecoc'?: string;
    /**
     * 
     * @type {string}
     * @memberof AtributosPartial
     */
    'categorias'?: string;
    /**
     * 
     * @type {number}
     * @memberof AtributosPartial
     */
    'repeticiones'?: number;
    /**
     * 
     * @type {string}
     * @memberof AtributosPartial
     */
    'valorDefecto'?: string;
    /**
     * 
     * @type {string}
     * @memberof AtributosPartial
     */
    'tipoDescripcionSN'?: string;
}
/**
 * (tsType: AtributosWithRelations, schemaOptions: { includeRelations: true })
 * @export
 * @interface AtributosWithRelations
 */
export interface AtributosWithRelations {
    /**
     * 
     * @type {number}
     * @memberof AtributosWithRelations
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof AtributosWithRelations
     */
    'grupoAtributosId'?: number;
    /**
     * 
     * @type {number}
     * @memberof AtributosWithRelations
     */
    'codigo'?: number;
    /**
     * 
     * @type {string}
     * @memberof AtributosWithRelations
     */
    'nombre'?: string;
    /**
     * 
     * @type {string}
     * @memberof AtributosWithRelations
     */
    'unidadSN'?: string;
    /**
     * 
     * @type {string}
     * @memberof AtributosWithRelations
     */
    'unidad'?: string;
    /**
     * 
     * @type {string}
     * @memberof AtributosWithRelations
     */
    'unidadAecoc'?: string;
    /**
     * 
     * @type {string}
     * @memberof AtributosWithRelations
     */
    'tagUOM'?: string;
    /**
     * 
     * @type {string}
     * @memberof AtributosWithRelations
     */
    'tipoCampo'?: string;
    /**
     * 
     * @type {string}
     * @memberof AtributosWithRelations
     */
    'tagAecoc'?: string;
    /**
     * 
     * @type {string}
     * @memberof AtributosWithRelations
     */
    'filtro'?: string;
    /**
     * 
     * @type {string}
     * @memberof AtributosWithRelations
     */
    'posiblesValores'?: string;
    /**
     * 
     * @type {string}
     * @memberof AtributosWithRelations
     */
    'posiblesValoresAecoc'?: string;
    /**
     * 
     * @type {string}
     * @memberof AtributosWithRelations
     */
    'categorias'?: string;
    /**
     * 
     * @type {number}
     * @memberof AtributosWithRelations
     */
    'repeticiones'?: number;
    /**
     * 
     * @type {string}
     * @memberof AtributosWithRelations
     */
    'valorDefecto'?: string;
    /**
     * 
     * @type {string}
     * @memberof AtributosWithRelations
     */
    'tipoDescripcionSN'?: string;
}
/**
 * 
 * @export
 * @interface B2bProductosFilter
 */
export interface B2bProductosFilter {
    /**
     * 
     * @type {number}
     * @memberof B2bProductosFilter
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof B2bProductosFilter
     */
    'limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof B2bProductosFilter
     */
    'skip'?: number;
    /**
     * 
     * @type {string | Array<string>}
     * @memberof B2bProductosFilter
     */
    'order'?: string | Array<string>;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof B2bProductosFilter
     */
    'where'?: { [key: string]: object; };
    /**
     * 
     * @type {object | Set<string>}
     * @memberof B2bProductosFilter
     */
    'fields'?: object | Set<string>;
}
/**
 * (tsType: B2bProductosWithRelations, schemaOptions: { includeRelations: true })
 * @export
 * @interface B2bProductosWithRelations
 */
export interface B2bProductosWithRelations {
    /**
     * 
     * @type {number}
     * @memberof B2bProductosWithRelations
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof B2bProductosWithRelations
     */
    'nombre'?: string;
    /**
     * 
     * @type {string}
     * @memberof B2bProductosWithRelations
     */
    'estado_referencia'?: string;
    /**
     * 
     * @type {string}
     * @memberof B2bProductosWithRelations
     */
    'ean'?: string;
    /**
     * 
     * @type {string}
     * @memberof B2bProductosWithRelations
     */
    'marca'?: string;
    /**
     * 
     * @type {string}
     * @memberof B2bProductosWithRelations
     */
    'palabras_clave'?: string;
    /**
     * 
     * @type {string}
     * @memberof B2bProductosWithRelations
     */
    'descripcion_larga'?: string;
    /**
     * 
     * @type {string}
     * @memberof B2bProductosWithRelations
     */
    'titulo'?: string;
    /**
     * 
     * @type {string}
     * @memberof B2bProductosWithRelations
     */
    'imagen'?: string;
    /**
     * 
     * @type {string}
     * @memberof B2bProductosWithRelations
     */
    'codigo'?: string;
    /**
     * 
     * @type {string}
     * @memberof B2bProductosWithRelations
     */
    'volumen'?: string;
    /**
     * 
     * @type {string}
     * @memberof B2bProductosWithRelations
     */
    'categorias'?: string;
    /**
     * 
     * @type {string}
     * @memberof B2bProductosWithRelations
     */
    'categoriaPadre'?: string;
    /**
     * 
     * @type {string}
     * @memberof B2bProductosWithRelations
     */
    'sku'?: string;
    /**
     * 
     * @type {string}
     * @memberof B2bProductosWithRelations
     */
    'referenciaAgrupacion'?: string;
    /**
     * 
     * @type {string}
     * @memberof B2bProductosWithRelations
     */
    'fechaCreacion'?: string;
    /**
     * 
     * @type {string}
     * @memberof B2bProductosWithRelations
     */
    'fechaModificacion'?: string;
    /**
     * 
     * @type {string}
     * @memberof B2bProductosWithRelations
     */
    'activoEnMarketplace'?: string;
}
/**
 * 
 * @export
 * @interface Catalogos
 */
export interface Catalogos {
    /**
     * 
     * @type {number}
     * @memberof Catalogos
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof Catalogos
     */
    'empresaId'?: number;
    /**
     * 
     * @type {string}
     * @memberof Catalogos
     */
    'codigo'?: string;
    /**
     * 
     * @type {string}
     * @memberof Catalogos
     */
    'nombre'?: string;
    /**
     * 
     * @type {string}
     * @memberof Catalogos
     */
    'descripcion'?: string;
    /**
     * 
     * @type {string}
     * @memberof Catalogos
     */
    'activoSN'?: string;
    /**
     * 
     * @type {string}
     * @memberof Catalogos
     */
    'anyo'?: string;
    /**
     * 
     * @type {string}
     * @memberof Catalogos
     */
    'FechaCreacion'?: string;
    /**
     * 
     * @type {string}
     * @memberof Catalogos
     */
    'FechaModificacion'?: string;
}
/**
 * 
 * @export
 * @interface CatalogosFilter
 */
export interface CatalogosFilter {
    /**
     * 
     * @type {number}
     * @memberof CatalogosFilter
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof CatalogosFilter
     */
    'limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof CatalogosFilter
     */
    'skip'?: number;
    /**
     * 
     * @type {string | Array<string>}
     * @memberof CatalogosFilter
     */
    'order'?: string | Array<string>;
    /**
     * 
     * @type {object | Set<string>}
     * @memberof CatalogosFilter
     */
    'fields'?: object | Set<string>;
}
/**
 * 
 * @export
 * @interface CatalogosFilter1
 */
export interface CatalogosFilter1 {
    /**
     * 
     * @type {number}
     * @memberof CatalogosFilter1
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof CatalogosFilter1
     */
    'limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof CatalogosFilter1
     */
    'skip'?: number;
    /**
     * 
     * @type {string | Array<string>}
     * @memberof CatalogosFilter1
     */
    'order'?: string | Array<string>;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof CatalogosFilter1
     */
    'where'?: { [key: string]: object; };
    /**
     * 
     * @type {object | Set<string>}
     * @memberof CatalogosFilter1
     */
    'fields'?: object | Set<string>;
}
/**
 * (tsType: Partial<Catalogos>, schemaOptions: { partial: true })
 * @export
 * @interface CatalogosPartial
 */
export interface CatalogosPartial {
    /**
     * 
     * @type {number}
     * @memberof CatalogosPartial
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof CatalogosPartial
     */
    'empresaId'?: number;
    /**
     * 
     * @type {string}
     * @memberof CatalogosPartial
     */
    'codigo'?: string;
    /**
     * 
     * @type {string}
     * @memberof CatalogosPartial
     */
    'nombre'?: string;
    /**
     * 
     * @type {string}
     * @memberof CatalogosPartial
     */
    'descripcion'?: string;
    /**
     * 
     * @type {string}
     * @memberof CatalogosPartial
     */
    'activoSN'?: string;
    /**
     * 
     * @type {string}
     * @memberof CatalogosPartial
     */
    'anyo'?: string;
    /**
     * 
     * @type {string}
     * @memberof CatalogosPartial
     */
    'FechaCreacion'?: string;
    /**
     * 
     * @type {string}
     * @memberof CatalogosPartial
     */
    'FechaModificacion'?: string;
}
/**
 * 
 * @export
 * @interface CatalogosProductos
 */
export interface CatalogosProductos {
    /**
     * 
     * @type {number}
     * @memberof CatalogosProductos
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof CatalogosProductos
     */
    'catalogoId'?: number;
    /**
     * 
     * @type {number}
     * @memberof CatalogosProductos
     */
    'productoId'?: number;
}
/**
 * 
 * @export
 * @interface CatalogosProductosFilter
 */
export interface CatalogosProductosFilter {
    /**
     * 
     * @type {number}
     * @memberof CatalogosProductosFilter
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof CatalogosProductosFilter
     */
    'limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof CatalogosProductosFilter
     */
    'skip'?: number;
    /**
     * 
     * @type {string | Array<string>}
     * @memberof CatalogosProductosFilter
     */
    'order'?: string | Array<string>;
    /**
     * 
     * @type {object | Set<string>}
     * @memberof CatalogosProductosFilter
     */
    'fields'?: object | Set<string>;
}
/**
 * 
 * @export
 * @interface CatalogosProductosFilter1
 */
export interface CatalogosProductosFilter1 {
    /**
     * 
     * @type {number}
     * @memberof CatalogosProductosFilter1
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof CatalogosProductosFilter1
     */
    'limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof CatalogosProductosFilter1
     */
    'skip'?: number;
    /**
     * 
     * @type {string | Array<string>}
     * @memberof CatalogosProductosFilter1
     */
    'order'?: string | Array<string>;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof CatalogosProductosFilter1
     */
    'where'?: { [key: string]: object; };
    /**
     * 
     * @type {object | Set<string>}
     * @memberof CatalogosProductosFilter1
     */
    'fields'?: object | Set<string>;
}
/**
 * (tsType: Partial<CatalogosProductos>, schemaOptions: { partial: true })
 * @export
 * @interface CatalogosProductosPartial
 */
export interface CatalogosProductosPartial {
    /**
     * 
     * @type {number}
     * @memberof CatalogosProductosPartial
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof CatalogosProductosPartial
     */
    'catalogoId'?: number;
    /**
     * 
     * @type {number}
     * @memberof CatalogosProductosPartial
     */
    'productoId'?: number;
}
/**
 * (tsType: CatalogosProductosWithRelations, schemaOptions: { includeRelations: true })
 * @export
 * @interface CatalogosProductosWithRelations
 */
export interface CatalogosProductosWithRelations {
    /**
     * 
     * @type {number}
     * @memberof CatalogosProductosWithRelations
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof CatalogosProductosWithRelations
     */
    'catalogoId'?: number;
    /**
     * 
     * @type {number}
     * @memberof CatalogosProductosWithRelations
     */
    'productoId'?: number;
}
/**
 * (tsType: CatalogosWithRelations, schemaOptions: { includeRelations: true })
 * @export
 * @interface CatalogosWithRelations
 */
export interface CatalogosWithRelations {
    /**
     * 
     * @type {number}
     * @memberof CatalogosWithRelations
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof CatalogosWithRelations
     */
    'empresaId'?: number;
    /**
     * 
     * @type {string}
     * @memberof CatalogosWithRelations
     */
    'codigo'?: string;
    /**
     * 
     * @type {string}
     * @memberof CatalogosWithRelations
     */
    'nombre'?: string;
    /**
     * 
     * @type {string}
     * @memberof CatalogosWithRelations
     */
    'descripcion'?: string;
    /**
     * 
     * @type {string}
     * @memberof CatalogosWithRelations
     */
    'activoSN'?: string;
    /**
     * 
     * @type {string}
     * @memberof CatalogosWithRelations
     */
    'anyo'?: string;
    /**
     * 
     * @type {string}
     * @memberof CatalogosWithRelations
     */
    'FechaCreacion'?: string;
    /**
     * 
     * @type {string}
     * @memberof CatalogosWithRelations
     */
    'FechaModificacion'?: string;
}
/**
 * 
 * @export
 * @interface Categorias
 */
export interface Categorias {
    /**
     * 
     * @type {number}
     * @memberof Categorias
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof Categorias
     */
    'empresaId': number;
    /**
     * 
     * @type {string}
     * @memberof Categorias
     */
    'tagAecoc'?: string;
    /**
     * 
     * @type {string}
     * @memberof Categorias
     */
    'codigo'?: string;
    /**
     * 
     * @type {string}
     * @memberof Categorias
     */
    'codigoAECOC'?: string;
    /**
     * 
     * @type {string}
     * @memberof Categorias
     */
    'nombre'?: string;
    /**
     * 
     * @type {string}
     * @memberof Categorias
     */
    'padre'?: string;
    /**
     * 
     * @type {string}
     * @memberof Categorias
     */
    'web'?: string;
}
/**
 * 
 * @export
 * @interface CategoriasFilter
 */
export interface CategoriasFilter {
    /**
     * 
     * @type {number}
     * @memberof CategoriasFilter
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof CategoriasFilter
     */
    'limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof CategoriasFilter
     */
    'skip'?: number;
    /**
     * 
     * @type {string | Array<string>}
     * @memberof CategoriasFilter
     */
    'order'?: string | Array<string>;
    /**
     * 
     * @type {object | Set<string>}
     * @memberof CategoriasFilter
     */
    'fields'?: object | Set<string>;
}
/**
 * 
 * @export
 * @interface CategoriasFilter1
 */
export interface CategoriasFilter1 {
    /**
     * 
     * @type {number}
     * @memberof CategoriasFilter1
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof CategoriasFilter1
     */
    'limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof CategoriasFilter1
     */
    'skip'?: number;
    /**
     * 
     * @type {string | Array<string>}
     * @memberof CategoriasFilter1
     */
    'order'?: string | Array<string>;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof CategoriasFilter1
     */
    'where'?: { [key: string]: object; };
    /**
     * 
     * @type {object | Set<string>}
     * @memberof CategoriasFilter1
     */
    'fields'?: object | Set<string>;
}
/**
 * (tsType: Partial<Categorias>, schemaOptions: { partial: true })
 * @export
 * @interface CategoriasPartial
 */
export interface CategoriasPartial {
    /**
     * 
     * @type {number}
     * @memberof CategoriasPartial
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof CategoriasPartial
     */
    'empresaId'?: number;
    /**
     * 
     * @type {string}
     * @memberof CategoriasPartial
     */
    'tagAecoc'?: string;
    /**
     * 
     * @type {string}
     * @memberof CategoriasPartial
     */
    'codigo'?: string;
    /**
     * 
     * @type {string}
     * @memberof CategoriasPartial
     */
    'codigoAECOC'?: string;
    /**
     * 
     * @type {string}
     * @memberof CategoriasPartial
     */
    'nombre'?: string;
    /**
     * 
     * @type {string}
     * @memberof CategoriasPartial
     */
    'padre'?: string;
    /**
     * 
     * @type {string}
     * @memberof CategoriasPartial
     */
    'web'?: string;
}
/**
 * (tsType: CategoriasWithRelations, schemaOptions: { includeRelations: true })
 * @export
 * @interface CategoriasWithRelations
 */
export interface CategoriasWithRelations {
    /**
     * 
     * @type {number}
     * @memberof CategoriasWithRelations
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof CategoriasWithRelations
     */
    'empresaId': number;
    /**
     * 
     * @type {string}
     * @memberof CategoriasWithRelations
     */
    'tagAecoc'?: string;
    /**
     * 
     * @type {string}
     * @memberof CategoriasWithRelations
     */
    'codigo'?: string;
    /**
     * 
     * @type {string}
     * @memberof CategoriasWithRelations
     */
    'codigoAECOC'?: string;
    /**
     * 
     * @type {string}
     * @memberof CategoriasWithRelations
     */
    'nombre'?: string;
    /**
     * 
     * @type {string}
     * @memberof CategoriasWithRelations
     */
    'padre'?: string;
    /**
     * 
     * @type {string}
     * @memberof CategoriasWithRelations
     */
    'web'?: string;
}
/**
 * 
 * @export
 * @interface Clubs
 */
export interface Clubs {
    /**
     * 
     * @type {number}
     * @memberof Clubs
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof Clubs
     */
    'nombre'?: string;
    /**
     * 
     * @type {string}
     * @memberof Clubs
     */
    'descripcion'?: string;
    /**
     * 
     * @type {string}
     * @memberof Clubs
     */
    'filtroMarcas'?: string;
    /**
     * 
     * @type {string}
     * @memberof Clubs
     */
    'filtroCategorias'?: string;
    /**
     * 
     * @type {string}
     * @memberof Clubs
     */
    'imagenMiniatura'?: string;
    /**
     * 
     * @type {string}
     * @memberof Clubs
     */
    'imagenGrande'?: string;
    /**
     * 
     * @type {number}
     * @memberof Clubs
     */
    'orden'?: number;
    /**
     * 
     * @type {string}
     * @memberof Clubs
     */
    'ultimaFechaModificacionBC'?: string;
    /**
     * 
     * @type {string}
     * @memberof Clubs
     */
    'ultimaFechaCreacionBC'?: string;
}
/**
 * 
 * @export
 * @interface ClubsFilter
 */
export interface ClubsFilter {
    /**
     * 
     * @type {number}
     * @memberof ClubsFilter
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof ClubsFilter
     */
    'limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof ClubsFilter
     */
    'skip'?: number;
    /**
     * 
     * @type {string | Array<string>}
     * @memberof ClubsFilter
     */
    'order'?: string | Array<string>;
    /**
     * 
     * @type {object | Set<string>}
     * @memberof ClubsFilter
     */
    'fields'?: object | Set<string>;
}
/**
 * 
 * @export
 * @interface ClubsFilter1
 */
export interface ClubsFilter1 {
    /**
     * 
     * @type {number}
     * @memberof ClubsFilter1
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof ClubsFilter1
     */
    'limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof ClubsFilter1
     */
    'skip'?: number;
    /**
     * 
     * @type {string | Array<string>}
     * @memberof ClubsFilter1
     */
    'order'?: string | Array<string>;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof ClubsFilter1
     */
    'where'?: { [key: string]: object; };
    /**
     * 
     * @type {object | Set<string>}
     * @memberof ClubsFilter1
     */
    'fields'?: object | Set<string>;
}
/**
 * (tsType: Partial<Clubs>, schemaOptions: { partial: true })
 * @export
 * @interface ClubsPartial
 */
export interface ClubsPartial {
    /**
     * 
     * @type {number}
     * @memberof ClubsPartial
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ClubsPartial
     */
    'nombre'?: string;
    /**
     * 
     * @type {string}
     * @memberof ClubsPartial
     */
    'descripcion'?: string;
    /**
     * 
     * @type {string}
     * @memberof ClubsPartial
     */
    'filtroMarcas'?: string;
    /**
     * 
     * @type {string}
     * @memberof ClubsPartial
     */
    'filtroCategorias'?: string;
    /**
     * 
     * @type {string}
     * @memberof ClubsPartial
     */
    'imagenMiniatura'?: string;
    /**
     * 
     * @type {string}
     * @memberof ClubsPartial
     */
    'imagenGrande'?: string;
    /**
     * 
     * @type {number}
     * @memberof ClubsPartial
     */
    'orden'?: number;
    /**
     * 
     * @type {string}
     * @memberof ClubsPartial
     */
    'ultimaFechaModificacionBC'?: string;
    /**
     * 
     * @type {string}
     * @memberof ClubsPartial
     */
    'ultimaFechaCreacionBC'?: string;
}
/**
 * (tsType: ClubsWithRelations, schemaOptions: { includeRelations: true })
 * @export
 * @interface ClubsWithRelations
 */
export interface ClubsWithRelations {
    /**
     * 
     * @type {number}
     * @memberof ClubsWithRelations
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ClubsWithRelations
     */
    'nombre'?: string;
    /**
     * 
     * @type {string}
     * @memberof ClubsWithRelations
     */
    'descripcion'?: string;
    /**
     * 
     * @type {string}
     * @memberof ClubsWithRelations
     */
    'filtroMarcas'?: string;
    /**
     * 
     * @type {string}
     * @memberof ClubsWithRelations
     */
    'filtroCategorias'?: string;
    /**
     * 
     * @type {string}
     * @memberof ClubsWithRelations
     */
    'imagenMiniatura'?: string;
    /**
     * 
     * @type {string}
     * @memberof ClubsWithRelations
     */
    'imagenGrande'?: string;
    /**
     * 
     * @type {number}
     * @memberof ClubsWithRelations
     */
    'orden'?: number;
    /**
     * 
     * @type {string}
     * @memberof ClubsWithRelations
     */
    'ultimaFechaModificacionBC'?: string;
    /**
     * 
     * @type {string}
     * @memberof ClubsWithRelations
     */
    'ultimaFechaCreacionBC'?: string;
}
/**
 * 
 * @export
 * @interface Empresas
 */
export interface Empresas {
    /**
     * 
     * @type {number}
     * @memberof Empresas
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof Empresas
     */
    'codigo'?: string;
    /**
     * 
     * @type {string}
     * @memberof Empresas
     */
    'nombre'?: string;
    /**
     * 
     * @type {string}
     * @memberof Empresas
     */
    'GLN'?: string;
    /**
     * 
     * @type {string}
     * @memberof Empresas
     */
    'descripcion'?: string;
}
/**
 * 
 * @export
 * @interface EmpresasBC
 */
export interface EmpresasBC {
    /**
     * 
     * @type {number}
     * @memberof EmpresasBC
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof EmpresasBC
     */
    'idEmpresa': number;
    /**
     * 
     * @type {number}
     * @memberof EmpresasBC
     */
    'orden': number;
    /**
     * 
     * @type {string}
     * @memberof EmpresasBC
     */
    'idCompany'?: string;
    /**
     * 
     * @type {number}
     * @memberof EmpresasBC
     */
    'idMarca'?: number;
    /**
     * 
     * @type {string}
     * @memberof EmpresasBC
     */
    'activoSN'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmpresasBC
     */
    'esPrimaria'?: string;
}
/**
 * 
 * @export
 * @interface EmpresasBCFilter
 */
export interface EmpresasBCFilter {
    /**
     * 
     * @type {number}
     * @memberof EmpresasBCFilter
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof EmpresasBCFilter
     */
    'limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof EmpresasBCFilter
     */
    'skip'?: number;
    /**
     * 
     * @type {string | Array<string>}
     * @memberof EmpresasBCFilter
     */
    'order'?: string | Array<string>;
    /**
     * 
     * @type {object | Set<string>}
     * @memberof EmpresasBCFilter
     */
    'fields'?: object | Set<string>;
}
/**
 * 
 * @export
 * @interface EmpresasBCFilter1
 */
export interface EmpresasBCFilter1 {
    /**
     * 
     * @type {number}
     * @memberof EmpresasBCFilter1
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof EmpresasBCFilter1
     */
    'limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof EmpresasBCFilter1
     */
    'skip'?: number;
    /**
     * 
     * @type {string | Array<string>}
     * @memberof EmpresasBCFilter1
     */
    'order'?: string | Array<string>;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof EmpresasBCFilter1
     */
    'where'?: { [key: string]: object; };
    /**
     * 
     * @type {object | Set<string>}
     * @memberof EmpresasBCFilter1
     */
    'fields'?: object | Set<string>;
}
/**
 * (tsType: Partial<EmpresasBC>, schemaOptions: { partial: true })
 * @export
 * @interface EmpresasBCPartial
 */
export interface EmpresasBCPartial {
    /**
     * 
     * @type {number}
     * @memberof EmpresasBCPartial
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof EmpresasBCPartial
     */
    'idEmpresa'?: number;
    /**
     * 
     * @type {number}
     * @memberof EmpresasBCPartial
     */
    'orden'?: number;
    /**
     * 
     * @type {string}
     * @memberof EmpresasBCPartial
     */
    'idCompany'?: string;
    /**
     * 
     * @type {number}
     * @memberof EmpresasBCPartial
     */
    'idMarca'?: number;
    /**
     * 
     * @type {string}
     * @memberof EmpresasBCPartial
     */
    'activoSN'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmpresasBCPartial
     */
    'esPrimaria'?: string;
}
/**
 * (tsType: EmpresasBCWithRelations, schemaOptions: { includeRelations: true })
 * @export
 * @interface EmpresasBCWithRelations
 */
export interface EmpresasBCWithRelations {
    /**
     * 
     * @type {number}
     * @memberof EmpresasBCWithRelations
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof EmpresasBCWithRelations
     */
    'idEmpresa': number;
    /**
     * 
     * @type {number}
     * @memberof EmpresasBCWithRelations
     */
    'orden': number;
    /**
     * 
     * @type {string}
     * @memberof EmpresasBCWithRelations
     */
    'idCompany'?: string;
    /**
     * 
     * @type {number}
     * @memberof EmpresasBCWithRelations
     */
    'idMarca'?: number;
    /**
     * 
     * @type {string}
     * @memberof EmpresasBCWithRelations
     */
    'activoSN'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmpresasBCWithRelations
     */
    'esPrimaria'?: string;
}
/**
 * 
 * @export
 * @interface EmpresasFilter
 */
export interface EmpresasFilter {
    /**
     * 
     * @type {number}
     * @memberof EmpresasFilter
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof EmpresasFilter
     */
    'limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof EmpresasFilter
     */
    'skip'?: number;
    /**
     * 
     * @type {string | Array<string>}
     * @memberof EmpresasFilter
     */
    'order'?: string | Array<string>;
    /**
     * 
     * @type {object | Set<string>}
     * @memberof EmpresasFilter
     */
    'fields'?: object | Set<string>;
}
/**
 * 
 * @export
 * @interface EmpresasFilter1
 */
export interface EmpresasFilter1 {
    /**
     * 
     * @type {number}
     * @memberof EmpresasFilter1
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof EmpresasFilter1
     */
    'limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof EmpresasFilter1
     */
    'skip'?: number;
    /**
     * 
     * @type {string | Array<string>}
     * @memberof EmpresasFilter1
     */
    'order'?: string | Array<string>;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof EmpresasFilter1
     */
    'where'?: { [key: string]: object; };
    /**
     * 
     * @type {object | Set<string>}
     * @memberof EmpresasFilter1
     */
    'fields'?: object | Set<string>;
}
/**
 * (tsType: Partial<Empresas>, schemaOptions: { partial: true })
 * @export
 * @interface EmpresasPartial
 */
export interface EmpresasPartial {
    /**
     * 
     * @type {number}
     * @memberof EmpresasPartial
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof EmpresasPartial
     */
    'codigo'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmpresasPartial
     */
    'nombre'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmpresasPartial
     */
    'GLN'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmpresasPartial
     */
    'descripcion'?: string;
}
/**
 * (tsType: EmpresasWithRelations, schemaOptions: { includeRelations: true })
 * @export
 * @interface EmpresasWithRelations
 */
export interface EmpresasWithRelations {
    /**
     * 
     * @type {number}
     * @memberof EmpresasWithRelations
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof EmpresasWithRelations
     */
    'codigo'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmpresasWithRelations
     */
    'nombre'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmpresasWithRelations
     */
    'GLN'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmpresasWithRelations
     */
    'descripcion'?: string;
}
/**
 * 
 * @export
 * @interface Ensamblados
 */
export interface Ensamblados {
    /**
     * 
     * @type {number}
     * @memberof Ensamblados
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof Ensamblados
     */
    'skuPrincipal'?: string;
    /**
     * 
     * @type {string}
     * @memberof Ensamblados
     */
    'skuEnsamblado'?: string;
    /**
     * 
     * @type {string}
     * @memberof Ensamblados
     */
    'descripcion'?: string;
    /**
     * 
     * @type {string}
     * @memberof Ensamblados
     */
    'cantidad'?: string;
    /**
     * 
     * @type {string}
     * @memberof Ensamblados
     */
    'fechaModificacion'?: string;
    /**
     * 
     * @type {string}
     * @memberof Ensamblados
     */
    'fechaCreacion'?: string;
    /**
     * 
     * @type {string}
     * @memberof Ensamblados
     */
    'fechaSincronizacionConBC'?: string;
    /**
     * 
     * @type {string}
     * @memberof Ensamblados
     */
    'fechaEjecucionDeProcesoSincroBC'?: string;
    /**
     * 
     * @type {string}
     * @memberof Ensamblados
     */
    'motivoErrorSincroConBC'?: string;
}
/**
 * 
 * @export
 * @interface EnsambladosFilter
 */
export interface EnsambladosFilter {
    /**
     * 
     * @type {number}
     * @memberof EnsambladosFilter
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof EnsambladosFilter
     */
    'limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof EnsambladosFilter
     */
    'skip'?: number;
    /**
     * 
     * @type {string | Array<string>}
     * @memberof EnsambladosFilter
     */
    'order'?: string | Array<string>;
    /**
     * 
     * @type {object | Set<string>}
     * @memberof EnsambladosFilter
     */
    'fields'?: object | Set<string>;
}
/**
 * 
 * @export
 * @interface EnsambladosFilter1
 */
export interface EnsambladosFilter1 {
    /**
     * 
     * @type {number}
     * @memberof EnsambladosFilter1
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof EnsambladosFilter1
     */
    'limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof EnsambladosFilter1
     */
    'skip'?: number;
    /**
     * 
     * @type {string | Array<string>}
     * @memberof EnsambladosFilter1
     */
    'order'?: string | Array<string>;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof EnsambladosFilter1
     */
    'where'?: { [key: string]: object; };
    /**
     * 
     * @type {object | Set<string>}
     * @memberof EnsambladosFilter1
     */
    'fields'?: object | Set<string>;
}
/**
 * (tsType: Partial<Ensamblados>, schemaOptions: { partial: true })
 * @export
 * @interface EnsambladosPartial
 */
export interface EnsambladosPartial {
    /**
     * 
     * @type {number}
     * @memberof EnsambladosPartial
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof EnsambladosPartial
     */
    'skuPrincipal'?: string;
    /**
     * 
     * @type {string}
     * @memberof EnsambladosPartial
     */
    'skuEnsamblado'?: string;
    /**
     * 
     * @type {string}
     * @memberof EnsambladosPartial
     */
    'descripcion'?: string;
    /**
     * 
     * @type {string}
     * @memberof EnsambladosPartial
     */
    'cantidad'?: string;
    /**
     * 
     * @type {string}
     * @memberof EnsambladosPartial
     */
    'fechaModificacion'?: string;
    /**
     * 
     * @type {string}
     * @memberof EnsambladosPartial
     */
    'fechaCreacion'?: string;
    /**
     * 
     * @type {string}
     * @memberof EnsambladosPartial
     */
    'fechaSincronizacionConBC'?: string;
    /**
     * 
     * @type {string}
     * @memberof EnsambladosPartial
     */
    'fechaEjecucionDeProcesoSincroBC'?: string;
    /**
     * 
     * @type {string}
     * @memberof EnsambladosPartial
     */
    'motivoErrorSincroConBC'?: string;
}
/**
 * (tsType: EnsambladosWithRelations, schemaOptions: { includeRelations: true })
 * @export
 * @interface EnsambladosWithRelations
 */
export interface EnsambladosWithRelations {
    /**
     * 
     * @type {number}
     * @memberof EnsambladosWithRelations
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof EnsambladosWithRelations
     */
    'skuPrincipal'?: string;
    /**
     * 
     * @type {string}
     * @memberof EnsambladosWithRelations
     */
    'skuEnsamblado'?: string;
    /**
     * 
     * @type {string}
     * @memberof EnsambladosWithRelations
     */
    'descripcion'?: string;
    /**
     * 
     * @type {string}
     * @memberof EnsambladosWithRelations
     */
    'cantidad'?: string;
    /**
     * 
     * @type {string}
     * @memberof EnsambladosWithRelations
     */
    'fechaModificacion'?: string;
    /**
     * 
     * @type {string}
     * @memberof EnsambladosWithRelations
     */
    'fechaCreacion'?: string;
    /**
     * 
     * @type {string}
     * @memberof EnsambladosWithRelations
     */
    'fechaSincronizacionConBC'?: string;
    /**
     * 
     * @type {string}
     * @memberof EnsambladosWithRelations
     */
    'fechaEjecucionDeProcesoSincroBC'?: string;
    /**
     * 
     * @type {string}
     * @memberof EnsambladosWithRelations
     */
    'motivoErrorSincroConBC'?: string;
}
/**
 * 
 * @export
 * @interface Familias
 */
export interface Familias {
    /**
     * 
     * @type {number}
     * @memberof Familias
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof Familias
     */
    'empresaId': number;
    /**
     * 
     * @type {string}
     * @memberof Familias
     */
    'codigo'?: string;
    /**
     * 
     * @type {string}
     * @memberof Familias
     */
    'nombre'?: string;
    /**
     * 
     * @type {string}
     * @memberof Familias
     */
    'padre'?: string;
}
/**
 * 
 * @export
 * @interface FamiliasFilter
 */
export interface FamiliasFilter {
    /**
     * 
     * @type {number}
     * @memberof FamiliasFilter
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof FamiliasFilter
     */
    'limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof FamiliasFilter
     */
    'skip'?: number;
    /**
     * 
     * @type {string | Array<string>}
     * @memberof FamiliasFilter
     */
    'order'?: string | Array<string>;
    /**
     * 
     * @type {object | Set<string>}
     * @memberof FamiliasFilter
     */
    'fields'?: object | Set<string>;
}
/**
 * 
 * @export
 * @interface FamiliasFilter1
 */
export interface FamiliasFilter1 {
    /**
     * 
     * @type {number}
     * @memberof FamiliasFilter1
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof FamiliasFilter1
     */
    'limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof FamiliasFilter1
     */
    'skip'?: number;
    /**
     * 
     * @type {string | Array<string>}
     * @memberof FamiliasFilter1
     */
    'order'?: string | Array<string>;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof FamiliasFilter1
     */
    'where'?: { [key: string]: object; };
    /**
     * 
     * @type {object | Set<string>}
     * @memberof FamiliasFilter1
     */
    'fields'?: object | Set<string>;
}
/**
 * (tsType: Partial<Familias>, schemaOptions: { partial: true })
 * @export
 * @interface FamiliasPartial
 */
export interface FamiliasPartial {
    /**
     * 
     * @type {number}
     * @memberof FamiliasPartial
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof FamiliasPartial
     */
    'empresaId'?: number;
    /**
     * 
     * @type {string}
     * @memberof FamiliasPartial
     */
    'codigo'?: string;
    /**
     * 
     * @type {string}
     * @memberof FamiliasPartial
     */
    'nombre'?: string;
    /**
     * 
     * @type {string}
     * @memberof FamiliasPartial
     */
    'padre'?: string;
}
/**
 * (tsType: FamiliasWithRelations, schemaOptions: { includeRelations: true })
 * @export
 * @interface FamiliasWithRelations
 */
export interface FamiliasWithRelations {
    /**
     * 
     * @type {number}
     * @memberof FamiliasWithRelations
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof FamiliasWithRelations
     */
    'empresaId': number;
    /**
     * 
     * @type {string}
     * @memberof FamiliasWithRelations
     */
    'codigo'?: string;
    /**
     * 
     * @type {string}
     * @memberof FamiliasWithRelations
     */
    'nombre'?: string;
    /**
     * 
     * @type {string}
     * @memberof FamiliasWithRelations
     */
    'padre'?: string;
}
/**
 * 
 * @export
 * @interface GruposAtributos
 */
export interface GruposAtributos {
    /**
     * 
     * @type {number}
     * @memberof GruposAtributos
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof GruposAtributos
     */
    'empresaId': number;
    /**
     * 
     * @type {string}
     * @memberof GruposAtributos
     */
    'codigo'?: string;
    /**
     * 
     * @type {string}
     * @memberof GruposAtributos
     */
    'nombre'?: string;
}
/**
 * 
 * @export
 * @interface GruposAtributosFilter
 */
export interface GruposAtributosFilter {
    /**
     * 
     * @type {number}
     * @memberof GruposAtributosFilter
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof GruposAtributosFilter
     */
    'limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof GruposAtributosFilter
     */
    'skip'?: number;
    /**
     * 
     * @type {string | Array<string>}
     * @memberof GruposAtributosFilter
     */
    'order'?: string | Array<string>;
    /**
     * 
     * @type {object | Set<string>}
     * @memberof GruposAtributosFilter
     */
    'fields'?: object | Set<string>;
}
/**
 * 
 * @export
 * @interface GruposAtributosFilter1
 */
export interface GruposAtributosFilter1 {
    /**
     * 
     * @type {number}
     * @memberof GruposAtributosFilter1
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof GruposAtributosFilter1
     */
    'limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof GruposAtributosFilter1
     */
    'skip'?: number;
    /**
     * 
     * @type {string | Array<string>}
     * @memberof GruposAtributosFilter1
     */
    'order'?: string | Array<string>;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof GruposAtributosFilter1
     */
    'where'?: { [key: string]: object; };
    /**
     * 
     * @type {object | Set<string>}
     * @memberof GruposAtributosFilter1
     */
    'fields'?: object | Set<string>;
}
/**
 * (tsType: Partial<GruposAtributos>, schemaOptions: { partial: true })
 * @export
 * @interface GruposAtributosPartial
 */
export interface GruposAtributosPartial {
    /**
     * 
     * @type {number}
     * @memberof GruposAtributosPartial
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof GruposAtributosPartial
     */
    'empresaId'?: number;
    /**
     * 
     * @type {string}
     * @memberof GruposAtributosPartial
     */
    'codigo'?: string;
    /**
     * 
     * @type {string}
     * @memberof GruposAtributosPartial
     */
    'nombre'?: string;
}
/**
 * (tsType: GruposAtributosWithRelations, schemaOptions: { includeRelations: true })
 * @export
 * @interface GruposAtributosWithRelations
 */
export interface GruposAtributosWithRelations {
    /**
     * 
     * @type {number}
     * @memberof GruposAtributosWithRelations
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof GruposAtributosWithRelations
     */
    'empresaId': number;
    /**
     * 
     * @type {string}
     * @memberof GruposAtributosWithRelations
     */
    'codigo'?: string;
    /**
     * 
     * @type {string}
     * @memberof GruposAtributosWithRelations
     */
    'nombre'?: string;
}
/**
 * 
 * @export
 * @interface Iconos
 */
export interface Iconos {
    /**
     * 
     * @type {number}
     * @memberof Iconos
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof Iconos
     */
    'nombre'?: string;
    /**
     * 
     * @type {string}
     * @memberof Iconos
     */
    'posiblesValores'?: string;
    /**
     * 
     * @type {string}
     * @memberof Iconos
     */
    'imagen'?: string;
    /**
     * 
     * @type {string}
     * @memberof Iconos
     */
    'categorias'?: string;
}
/**
 * 
 * @export
 * @interface IconosFilter
 */
export interface IconosFilter {
    /**
     * 
     * @type {number}
     * @memberof IconosFilter
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof IconosFilter
     */
    'limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof IconosFilter
     */
    'skip'?: number;
    /**
     * 
     * @type {string | Array<string>}
     * @memberof IconosFilter
     */
    'order'?: string | Array<string>;
    /**
     * 
     * @type {object | Set<string>}
     * @memberof IconosFilter
     */
    'fields'?: object | Set<string>;
}
/**
 * 
 * @export
 * @interface IconosFilter1
 */
export interface IconosFilter1 {
    /**
     * 
     * @type {number}
     * @memberof IconosFilter1
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof IconosFilter1
     */
    'limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof IconosFilter1
     */
    'skip'?: number;
    /**
     * 
     * @type {string | Array<string>}
     * @memberof IconosFilter1
     */
    'order'?: string | Array<string>;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof IconosFilter1
     */
    'where'?: { [key: string]: object; };
    /**
     * 
     * @type {object | Set<string>}
     * @memberof IconosFilter1
     */
    'fields'?: object | Set<string>;
}
/**
 * (tsType: Partial<Iconos>, schemaOptions: { partial: true })
 * @export
 * @interface IconosPartial
 */
export interface IconosPartial {
    /**
     * 
     * @type {number}
     * @memberof IconosPartial
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof IconosPartial
     */
    'nombre'?: string;
    /**
     * 
     * @type {string}
     * @memberof IconosPartial
     */
    'posiblesValores'?: string;
    /**
     * 
     * @type {string}
     * @memberof IconosPartial
     */
    'imagen'?: string;
    /**
     * 
     * @type {string}
     * @memberof IconosPartial
     */
    'categorias'?: string;
}
/**
 * (tsType: IconosWithRelations, schemaOptions: { includeRelations: true })
 * @export
 * @interface IconosWithRelations
 */
export interface IconosWithRelations {
    /**
     * 
     * @type {number}
     * @memberof IconosWithRelations
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof IconosWithRelations
     */
    'nombre'?: string;
    /**
     * 
     * @type {string}
     * @memberof IconosWithRelations
     */
    'posiblesValores'?: string;
    /**
     * 
     * @type {string}
     * @memberof IconosWithRelations
     */
    'imagen'?: string;
    /**
     * 
     * @type {string}
     * @memberof IconosWithRelations
     */
    'categorias'?: string;
}
/**
 * 
 * @export
 * @interface Idiomas
 */
export interface Idiomas {
    /**
     * 
     * @type {number}
     * @memberof Idiomas
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof Idiomas
     */
    'nombre'?: string;
    /**
     * 
     * @type {string}
     * @memberof Idiomas
     */
    'descripcion'?: string;
    /**
     * 
     * @type {string}
     * @memberof Idiomas
     */
    'activoSn'?: string;
    /**
     * 
     * @type {string}
     * @memberof Idiomas
     */
    'locale'?: string;
}
/**
 * 
 * @export
 * @interface IdiomasFilter
 */
export interface IdiomasFilter {
    /**
     * 
     * @type {number}
     * @memberof IdiomasFilter
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof IdiomasFilter
     */
    'limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof IdiomasFilter
     */
    'skip'?: number;
    /**
     * 
     * @type {string | Array<string>}
     * @memberof IdiomasFilter
     */
    'order'?: string | Array<string>;
    /**
     * 
     * @type {object | Set<string>}
     * @memberof IdiomasFilter
     */
    'fields'?: object | Set<string>;
}
/**
 * 
 * @export
 * @interface IdiomasFilter1
 */
export interface IdiomasFilter1 {
    /**
     * 
     * @type {number}
     * @memberof IdiomasFilter1
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof IdiomasFilter1
     */
    'limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof IdiomasFilter1
     */
    'skip'?: number;
    /**
     * 
     * @type {string | Array<string>}
     * @memberof IdiomasFilter1
     */
    'order'?: string | Array<string>;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof IdiomasFilter1
     */
    'where'?: { [key: string]: object; };
    /**
     * 
     * @type {object | Set<string>}
     * @memberof IdiomasFilter1
     */
    'fields'?: object | Set<string>;
}
/**
 * (tsType: Partial<Idiomas>, schemaOptions: { partial: true })
 * @export
 * @interface IdiomasPartial
 */
export interface IdiomasPartial {
    /**
     * 
     * @type {number}
     * @memberof IdiomasPartial
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof IdiomasPartial
     */
    'nombre'?: string;
    /**
     * 
     * @type {string}
     * @memberof IdiomasPartial
     */
    'descripcion'?: string;
    /**
     * 
     * @type {string}
     * @memberof IdiomasPartial
     */
    'activoSn'?: string;
    /**
     * 
     * @type {string}
     * @memberof IdiomasPartial
     */
    'locale'?: string;
}
/**
 * (tsType: IdiomasWithRelations, schemaOptions: { includeRelations: true })
 * @export
 * @interface IdiomasWithRelations
 */
export interface IdiomasWithRelations {
    /**
     * 
     * @type {number}
     * @memberof IdiomasWithRelations
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof IdiomasWithRelations
     */
    'nombre'?: string;
    /**
     * 
     * @type {string}
     * @memberof IdiomasWithRelations
     */
    'descripcion'?: string;
    /**
     * 
     * @type {string}
     * @memberof IdiomasWithRelations
     */
    'activoSn'?: string;
    /**
     * 
     * @type {string}
     * @memberof IdiomasWithRelations
     */
    'locale'?: string;
}
/**
 * 
 * @export
 * @interface InlineObject
 */
export interface InlineObject {
    /**
     * 
     * @type {string}
     * @memberof InlineObject
     */
    'mail': string;
    /**
     * 
     * @type {string}
     * @memberof InlineObject
     */
    'password': string;
}
/**
 * 
 * @export
 * @interface InlineObject1
 */
export interface InlineObject1 {
    /**
     * 
     * @type {string}
     * @memberof InlineObject1
     */
    'refreshToken': string;
}
/**
 * 
 * @export
 * @interface InlineObject2
 */
export interface InlineObject2 {
    /**
     * 
     * @type {number}
     * @memberof InlineObject2
     */
    'catalogoId': number;
    /**
     * 
     * @type {Array<number>}
     * @memberof InlineObject2
     */
    'productoIds': Array<number>;
}
/**
 * 
 * @export
 * @interface InlineObject4
 */
export interface InlineObject4 {
    /**
     * 
     * @type {string}
     * @memberof InlineObject4
     */
    'base64Data': string;
}
/**
 * 
 * @export
 * @interface InlineObject6
 */
export interface InlineObject6 {
    /**
     * 
     * @type {string}
     * @memberof InlineObject6
     */
    'mail': string;
    /**
     * 
     * @type {string}
     * @memberof InlineObject6
     */
    'password': string;
}
/**
 * 
 * @export
 * @interface InlineResponse200
 */
export interface InlineResponse200 {
    /**
     * 
     * @type {string}
     * @memberof InlineResponse200
     */
    'token'?: string;
}
/**
 * 
 * @export
 * @interface InlineResponse2001
 */
export interface InlineResponse2001 {
    /**
     * 
     * @type {object}
     * @memberof InlineResponse2001
     */
    'accessToken'?: object;
}
/**
 * 
 * @export
 * @interface InlineResponse2002
 */
export interface InlineResponse2002 {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse2002
     */
    'deletedCount'?: number;
}
/**
 * 
 * @export
 * @interface InlineResponse2003
 */
export interface InlineResponse2003 {
    /**
     * 
     * @type {string}
     * @memberof InlineResponse2003
     */
    'accessToken'?: string;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse2003
     */
    'refreshToken'?: string;
}
/**
 * 
 * @export
 * @interface InlineResponse2004
 */
export interface InlineResponse2004 {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse2004
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse2004
     */
    'sku'?: string;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse2004
     */
    'ean'?: string;
}
/**
 * 
 * @export
 * @interface InsertItem
 */
export interface InsertItem {
    /**
     * 
     * @type {Array<object>}
     * @memberof InsertItem
     */
    'items'?: Array<object>;
}
/**
 * 
 * @export
 * @interface Logsnav
 */
export interface Logsnav {
    /**
     * 
     * @type {number}
     * @memberof Logsnav
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof Logsnav
     */
    'texto'?: string;
}
/**
 * 
 * @export
 * @interface LogsnavFilter
 */
export interface LogsnavFilter {
    /**
     * 
     * @type {number}
     * @memberof LogsnavFilter
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof LogsnavFilter
     */
    'limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof LogsnavFilter
     */
    'skip'?: number;
    /**
     * 
     * @type {string | Array<string>}
     * @memberof LogsnavFilter
     */
    'order'?: string | Array<string>;
    /**
     * 
     * @type {object | Set<string>}
     * @memberof LogsnavFilter
     */
    'fields'?: object | Set<string>;
}
/**
 * 
 * @export
 * @interface LogsnavFilter1
 */
export interface LogsnavFilter1 {
    /**
     * 
     * @type {number}
     * @memberof LogsnavFilter1
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof LogsnavFilter1
     */
    'limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof LogsnavFilter1
     */
    'skip'?: number;
    /**
     * 
     * @type {string | Array<string>}
     * @memberof LogsnavFilter1
     */
    'order'?: string | Array<string>;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof LogsnavFilter1
     */
    'where'?: { [key: string]: object; };
    /**
     * 
     * @type {object | Set<string>}
     * @memberof LogsnavFilter1
     */
    'fields'?: object | Set<string>;
}
/**
 * (tsType: Partial<Logsnav>, schemaOptions: { partial: true })
 * @export
 * @interface LogsnavPartial
 */
export interface LogsnavPartial {
    /**
     * 
     * @type {number}
     * @memberof LogsnavPartial
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof LogsnavPartial
     */
    'texto'?: string;
}
/**
 * (tsType: LogsnavWithRelations, schemaOptions: { includeRelations: true })
 * @export
 * @interface LogsnavWithRelations
 */
export interface LogsnavWithRelations {
    /**
     * 
     * @type {number}
     * @memberof LogsnavWithRelations
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof LogsnavWithRelations
     */
    'texto'?: string;
}
/**
 * 
 * @export
 * @interface LoopbackCount
 */
export interface LoopbackCount {
    /**
     * 
     * @type {number}
     * @memberof LoopbackCount
     */
    'count'?: number;
}
/**
 * 
 * @export
 * @interface Marca
 */
export interface Marca {
    /**
     * 
     * @type {number}
     * @memberof Marca
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof Marca
     */
    'nombre'?: string;
    /**
     * 
     * @type {string}
     * @memberof Marca
     */
    'codigo'?: string;
    /**
     * 
     * @type {string}
     * @memberof Marca
     */
    'color'?: string;
    /**
     * 
     * @type {string}
     * @memberof Marca
     */
    'web'?: string;
    /**
     * 
     * @type {string}
     * @memberof Marca
     */
    'logo'?: string;
    /**
     * 
     * @type {string}
     * @memberof Marca
     */
    'visibleb2b'?: string;
}
/**
 * 
 * @export
 * @interface MarcaFilter
 */
export interface MarcaFilter {
    /**
     * 
     * @type {number}
     * @memberof MarcaFilter
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof MarcaFilter
     */
    'limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof MarcaFilter
     */
    'skip'?: number;
    /**
     * 
     * @type {string | Array<string>}
     * @memberof MarcaFilter
     */
    'order'?: string | Array<string>;
    /**
     * 
     * @type {object | Set<string>}
     * @memberof MarcaFilter
     */
    'fields'?: object | Set<string>;
}
/**
 * 
 * @export
 * @interface MarcaFilter1
 */
export interface MarcaFilter1 {
    /**
     * 
     * @type {number}
     * @memberof MarcaFilter1
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof MarcaFilter1
     */
    'limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof MarcaFilter1
     */
    'skip'?: number;
    /**
     * 
     * @type {string | Array<string>}
     * @memberof MarcaFilter1
     */
    'order'?: string | Array<string>;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof MarcaFilter1
     */
    'where'?: { [key: string]: object; };
    /**
     * 
     * @type {object | Set<string>}
     * @memberof MarcaFilter1
     */
    'fields'?: object | Set<string>;
}
/**
 * (tsType: Partial<Marca>, schemaOptions: { partial: true })
 * @export
 * @interface MarcaPartial
 */
export interface MarcaPartial {
    /**
     * 
     * @type {number}
     * @memberof MarcaPartial
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof MarcaPartial
     */
    'nombre'?: string;
    /**
     * 
     * @type {string}
     * @memberof MarcaPartial
     */
    'codigo'?: string;
    /**
     * 
     * @type {string}
     * @memberof MarcaPartial
     */
    'color'?: string;
    /**
     * 
     * @type {string}
     * @memberof MarcaPartial
     */
    'web'?: string;
    /**
     * 
     * @type {string}
     * @memberof MarcaPartial
     */
    'logo'?: string;
    /**
     * 
     * @type {string}
     * @memberof MarcaPartial
     */
    'visibleb2b'?: string;
}
/**
 * (tsType: MarcaWithRelations, schemaOptions: { includeRelations: true })
 * @export
 * @interface MarcaWithRelations
 */
export interface MarcaWithRelations {
    /**
     * 
     * @type {number}
     * @memberof MarcaWithRelations
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof MarcaWithRelations
     */
    'nombre'?: string;
    /**
     * 
     * @type {string}
     * @memberof MarcaWithRelations
     */
    'codigo'?: string;
    /**
     * 
     * @type {string}
     * @memberof MarcaWithRelations
     */
    'color'?: string;
    /**
     * 
     * @type {string}
     * @memberof MarcaWithRelations
     */
    'web'?: string;
    /**
     * 
     * @type {string}
     * @memberof MarcaWithRelations
     */
    'logo'?: string;
    /**
     * 
     * @type {string}
     * @memberof MarcaWithRelations
     */
    'visibleb2b'?: string;
}
/**
 * 
 * @export
 * @interface MarketPlacePrompts
 */
export interface MarketPlacePrompts {
    /**
     * 
     * @type {number}
     * @memberof MarketPlacePrompts
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof MarketPlacePrompts
     */
    'marketplaceId': number;
    /**
     * 
     * @type {number}
     * @memberof MarketPlacePrompts
     */
    'categoriaId'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof MarketPlacePrompts
     */
    'campoUno'?: string;
    /**
     * 
     * @type {string}
     * @memberof MarketPlacePrompts
     */
    'promptUno'?: string;
    /**
     * 
     * @type {string}
     * @memberof MarketPlacePrompts
     */
    'campoDos'?: string;
    /**
     * 
     * @type {string}
     * @memberof MarketPlacePrompts
     */
    'promptDos'?: string;
    /**
     * 
     * @type {string}
     * @memberof MarketPlacePrompts
     */
    'campoTres'?: string;
    /**
     * 
     * @type {string}
     * @memberof MarketPlacePrompts
     */
    'promptTres'?: string;
    /**
     * 
     * @type {string}
     * @memberof MarketPlacePrompts
     */
    'campoCuatro'?: string;
    /**
     * 
     * @type {string}
     * @memberof MarketPlacePrompts
     */
    'promptCuatro'?: string;
    /**
     * 
     * @type {string}
     * @memberof MarketPlacePrompts
     */
    'campoCinco'?: string;
    /**
     * 
     * @type {string}
     * @memberof MarketPlacePrompts
     */
    'promptCinco'?: string;
}
/**
 * 
 * @export
 * @interface MarketPlacePromptsFilter
 */
export interface MarketPlacePromptsFilter {
    /**
     * 
     * @type {number}
     * @memberof MarketPlacePromptsFilter
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof MarketPlacePromptsFilter
     */
    'limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof MarketPlacePromptsFilter
     */
    'skip'?: number;
    /**
     * 
     * @type {string | Array<string>}
     * @memberof MarketPlacePromptsFilter
     */
    'order'?: string | Array<string>;
    /**
     * 
     * @type {object | Set<string>}
     * @memberof MarketPlacePromptsFilter
     */
    'fields'?: object | Set<string>;
}
/**
 * 
 * @export
 * @interface MarketPlacePromptsFilter1
 */
export interface MarketPlacePromptsFilter1 {
    /**
     * 
     * @type {number}
     * @memberof MarketPlacePromptsFilter1
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof MarketPlacePromptsFilter1
     */
    'limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof MarketPlacePromptsFilter1
     */
    'skip'?: number;
    /**
     * 
     * @type {string | Array<string>}
     * @memberof MarketPlacePromptsFilter1
     */
    'order'?: string | Array<string>;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof MarketPlacePromptsFilter1
     */
    'where'?: { [key: string]: object; };
    /**
     * 
     * @type {object | Set<string>}
     * @memberof MarketPlacePromptsFilter1
     */
    'fields'?: object | Set<string>;
}
/**
 * (tsType: Partial<MarketPlacePrompts>, schemaOptions: { partial: true })
 * @export
 * @interface MarketPlacePromptsPartial
 */
export interface MarketPlacePromptsPartial {
    /**
     * 
     * @type {number}
     * @memberof MarketPlacePromptsPartial
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof MarketPlacePromptsPartial
     */
    'marketplaceId'?: number;
    /**
     * 
     * @type {number}
     * @memberof MarketPlacePromptsPartial
     */
    'categoriaId'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof MarketPlacePromptsPartial
     */
    'campoUno'?: string;
    /**
     * 
     * @type {string}
     * @memberof MarketPlacePromptsPartial
     */
    'promptUno'?: string;
    /**
     * 
     * @type {string}
     * @memberof MarketPlacePromptsPartial
     */
    'campoDos'?: string;
    /**
     * 
     * @type {string}
     * @memberof MarketPlacePromptsPartial
     */
    'promptDos'?: string;
    /**
     * 
     * @type {string}
     * @memberof MarketPlacePromptsPartial
     */
    'campoTres'?: string;
    /**
     * 
     * @type {string}
     * @memberof MarketPlacePromptsPartial
     */
    'promptTres'?: string;
    /**
     * 
     * @type {string}
     * @memberof MarketPlacePromptsPartial
     */
    'campoCuatro'?: string;
    /**
     * 
     * @type {string}
     * @memberof MarketPlacePromptsPartial
     */
    'promptCuatro'?: string;
    /**
     * 
     * @type {string}
     * @memberof MarketPlacePromptsPartial
     */
    'campoCinco'?: string;
    /**
     * 
     * @type {string}
     * @memberof MarketPlacePromptsPartial
     */
    'promptCinco'?: string;
}
/**
 * (tsType: MarketPlacePromptsWithRelations, schemaOptions: { includeRelations: true })
 * @export
 * @interface MarketPlacePromptsWithRelations
 */
export interface MarketPlacePromptsWithRelations {
    /**
     * 
     * @type {number}
     * @memberof MarketPlacePromptsWithRelations
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof MarketPlacePromptsWithRelations
     */
    'marketplaceId': number;
    /**
     * 
     * @type {number}
     * @memberof MarketPlacePromptsWithRelations
     */
    'categoriaId'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof MarketPlacePromptsWithRelations
     */
    'campoUno'?: string;
    /**
     * 
     * @type {string}
     * @memberof MarketPlacePromptsWithRelations
     */
    'promptUno'?: string;
    /**
     * 
     * @type {string}
     * @memberof MarketPlacePromptsWithRelations
     */
    'campoDos'?: string;
    /**
     * 
     * @type {string}
     * @memberof MarketPlacePromptsWithRelations
     */
    'promptDos'?: string;
    /**
     * 
     * @type {string}
     * @memberof MarketPlacePromptsWithRelations
     */
    'campoTres'?: string;
    /**
     * 
     * @type {string}
     * @memberof MarketPlacePromptsWithRelations
     */
    'promptTres'?: string;
    /**
     * 
     * @type {string}
     * @memberof MarketPlacePromptsWithRelations
     */
    'campoCuatro'?: string;
    /**
     * 
     * @type {string}
     * @memberof MarketPlacePromptsWithRelations
     */
    'promptCuatro'?: string;
    /**
     * 
     * @type {string}
     * @memberof MarketPlacePromptsWithRelations
     */
    'campoCinco'?: string;
    /**
     * 
     * @type {string}
     * @memberof MarketPlacePromptsWithRelations
     */
    'promptCinco'?: string;
}
/**
 * 
 * @export
 * @interface MarketPlaces
 */
export interface MarketPlaces {
    /**
     * 
     * @type {number}
     * @memberof MarketPlaces
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof MarketPlaces
     */
    'codigo'?: string;
    /**
     * 
     * @type {string}
     * @memberof MarketPlaces
     */
    'descripcion'?: string;
}
/**
 * 
 * @export
 * @interface MarketPlacesFilter
 */
export interface MarketPlacesFilter {
    /**
     * 
     * @type {number}
     * @memberof MarketPlacesFilter
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof MarketPlacesFilter
     */
    'limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof MarketPlacesFilter
     */
    'skip'?: number;
    /**
     * 
     * @type {string | Array<string>}
     * @memberof MarketPlacesFilter
     */
    'order'?: string | Array<string>;
    /**
     * 
     * @type {object | Set<string>}
     * @memberof MarketPlacesFilter
     */
    'fields'?: object | Set<string>;
}
/**
 * 
 * @export
 * @interface MarketPlacesFilter1
 */
export interface MarketPlacesFilter1 {
    /**
     * 
     * @type {number}
     * @memberof MarketPlacesFilter1
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof MarketPlacesFilter1
     */
    'limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof MarketPlacesFilter1
     */
    'skip'?: number;
    /**
     * 
     * @type {string | Array<string>}
     * @memberof MarketPlacesFilter1
     */
    'order'?: string | Array<string>;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof MarketPlacesFilter1
     */
    'where'?: { [key: string]: object; };
    /**
     * 
     * @type {object | Set<string>}
     * @memberof MarketPlacesFilter1
     */
    'fields'?: object | Set<string>;
}
/**
 * (tsType: Partial<MarketPlaces>, schemaOptions: { partial: true })
 * @export
 * @interface MarketPlacesPartial
 */
export interface MarketPlacesPartial {
    /**
     * 
     * @type {number}
     * @memberof MarketPlacesPartial
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof MarketPlacesPartial
     */
    'codigo'?: string;
    /**
     * 
     * @type {string}
     * @memberof MarketPlacesPartial
     */
    'descripcion'?: string;
}
/**
 * (tsType: MarketPlacesWithRelations, schemaOptions: { includeRelations: true })
 * @export
 * @interface MarketPlacesWithRelations
 */
export interface MarketPlacesWithRelations {
    /**
     * 
     * @type {number}
     * @memberof MarketPlacesWithRelations
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof MarketPlacesWithRelations
     */
    'codigo'?: string;
    /**
     * 
     * @type {string}
     * @memberof MarketPlacesWithRelations
     */
    'descripcion'?: string;
}
/**
 * 
 * @export
 * @interface Multimedia
 */
export interface Multimedia {
    /**
     * 
     * @type {number}
     * @memberof Multimedia
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof Multimedia
     */
    'empresaId': number;
    /**
     * 
     * @type {string}
     * @memberof Multimedia
     */
    'clave'?: string;
    /**
     * 
     * @type {string}
     * @memberof Multimedia
     */
    'tipo'?: string;
    /**
     * 
     * @type {string}
     * @memberof Multimedia
     */
    'categoria': string;
    /**
     * 
     * @type {string}
     * @memberof Multimedia
     */
    'defectosn'?: string;
    /**
     * 
     * @type {string}
     * @memberof Multimedia
     */
    'tipoAecoc'?: string;
}
/**
 * 
 * @export
 * @interface MultimediaFilter
 */
export interface MultimediaFilter {
    /**
     * 
     * @type {number}
     * @memberof MultimediaFilter
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof MultimediaFilter
     */
    'limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof MultimediaFilter
     */
    'skip'?: number;
    /**
     * 
     * @type {string | Array<string>}
     * @memberof MultimediaFilter
     */
    'order'?: string | Array<string>;
    /**
     * 
     * @type {object | Set<string>}
     * @memberof MultimediaFilter
     */
    'fields'?: object | Set<string>;
}
/**
 * 
 * @export
 * @interface MultimediaFilter1
 */
export interface MultimediaFilter1 {
    /**
     * 
     * @type {number}
     * @memberof MultimediaFilter1
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof MultimediaFilter1
     */
    'limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof MultimediaFilter1
     */
    'skip'?: number;
    /**
     * 
     * @type {string | Array<string>}
     * @memberof MultimediaFilter1
     */
    'order'?: string | Array<string>;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof MultimediaFilter1
     */
    'where'?: { [key: string]: object; };
    /**
     * 
     * @type {object | Set<string>}
     * @memberof MultimediaFilter1
     */
    'fields'?: object | Set<string>;
}
/**
 * (tsType: Partial<Multimedia>, schemaOptions: { partial: true })
 * @export
 * @interface MultimediaPartial
 */
export interface MultimediaPartial {
    /**
     * 
     * @type {number}
     * @memberof MultimediaPartial
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof MultimediaPartial
     */
    'empresaId'?: number;
    /**
     * 
     * @type {string}
     * @memberof MultimediaPartial
     */
    'clave'?: string;
    /**
     * 
     * @type {string}
     * @memberof MultimediaPartial
     */
    'tipo'?: string;
    /**
     * 
     * @type {string}
     * @memberof MultimediaPartial
     */
    'categoria'?: string;
    /**
     * 
     * @type {string}
     * @memberof MultimediaPartial
     */
    'defectosn'?: string;
    /**
     * 
     * @type {string}
     * @memberof MultimediaPartial
     */
    'tipoAecoc'?: string;
}
/**
 * (tsType: MultimediaWithRelations, schemaOptions: { includeRelations: true })
 * @export
 * @interface MultimediaWithRelations
 */
export interface MultimediaWithRelations {
    /**
     * 
     * @type {number}
     * @memberof MultimediaWithRelations
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof MultimediaWithRelations
     */
    'empresaId': number;
    /**
     * 
     * @type {string}
     * @memberof MultimediaWithRelations
     */
    'clave'?: string;
    /**
     * 
     * @type {string}
     * @memberof MultimediaWithRelations
     */
    'tipo'?: string;
    /**
     * 
     * @type {string}
     * @memberof MultimediaWithRelations
     */
    'categoria': string;
    /**
     * 
     * @type {string}
     * @memberof MultimediaWithRelations
     */
    'defectosn'?: string;
    /**
     * 
     * @type {string}
     * @memberof MultimediaWithRelations
     */
    'tipoAecoc'?: string;
}
/**
 * (tsType: Omit<Atributos, \'id\'>, schemaOptions: { title: \'NewAtributos\', exclude: [ \'id\' ] })
 * @export
 * @interface NewAtributos
 */
export interface NewAtributos {
    /**
     * 
     * @type {number}
     * @memberof NewAtributos
     */
    'grupoAtributosId'?: number;
    /**
     * 
     * @type {number}
     * @memberof NewAtributos
     */
    'codigo'?: number;
    /**
     * 
     * @type {string}
     * @memberof NewAtributos
     */
    'nombre'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewAtributos
     */
    'unidadSN'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewAtributos
     */
    'unidad'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewAtributos
     */
    'unidadAecoc'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewAtributos
     */
    'tagUOM'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewAtributos
     */
    'tipoCampo'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewAtributos
     */
    'tagAecoc'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewAtributos
     */
    'filtro'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewAtributos
     */
    'posiblesValores'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewAtributos
     */
    'posiblesValoresAecoc'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewAtributos
     */
    'categorias'?: string;
    /**
     * 
     * @type {number}
     * @memberof NewAtributos
     */
    'repeticiones'?: number;
    /**
     * 
     * @type {string}
     * @memberof NewAtributos
     */
    'valorDefecto'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewAtributos
     */
    'tipoDescripcionSN'?: string;
}
/**
 * (tsType: Omit<Catalogos, \'id\'>, schemaOptions: { title: \'NewCatalogos\', exclude: [ \'id\' ] })
 * @export
 * @interface NewCatalogos
 */
export interface NewCatalogos {
    /**
     * 
     * @type {number}
     * @memberof NewCatalogos
     */
    'empresaId'?: number;
    /**
     * 
     * @type {string}
     * @memberof NewCatalogos
     */
    'codigo'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewCatalogos
     */
    'nombre'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewCatalogos
     */
    'descripcion'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewCatalogos
     */
    'activoSN'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewCatalogos
     */
    'anyo'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewCatalogos
     */
    'FechaCreacion'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewCatalogos
     */
    'FechaModificacion'?: string;
}
/**
 * (tsType: Omit<CatalogosProductos, \'id\'>, schemaOptions: { title: \'NewCatalogosProductos\', exclude: [ \'id\' ] })
 * @export
 * @interface NewCatalogosProductos
 */
export interface NewCatalogosProductos {
    /**
     * 
     * @type {number}
     * @memberof NewCatalogosProductos
     */
    'catalogoId'?: number;
    /**
     * 
     * @type {number}
     * @memberof NewCatalogosProductos
     */
    'productoId'?: number;
}
/**
 * (tsType: Omit<Categorias, \'id\'>, schemaOptions: { title: \'NewCategorias\', exclude: [ \'id\' ] })
 * @export
 * @interface NewCategorias
 */
export interface NewCategorias {
    /**
     * 
     * @type {number}
     * @memberof NewCategorias
     */
    'empresaId': number;
    /**
     * 
     * @type {string}
     * @memberof NewCategorias
     */
    'tagAecoc'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewCategorias
     */
    'codigo'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewCategorias
     */
    'codigoAECOC'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewCategorias
     */
    'nombre'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewCategorias
     */
    'padre'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewCategorias
     */
    'web'?: string;
}
/**
 * (tsType: Omit<Clubs, \'id\'>, schemaOptions: { title: \'NewClubs\', exclude: [ \'id\' ] })
 * @export
 * @interface NewClubs
 */
export interface NewClubs {
    /**
     * 
     * @type {string}
     * @memberof NewClubs
     */
    'nombre'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewClubs
     */
    'descripcion'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewClubs
     */
    'filtroMarcas'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewClubs
     */
    'filtroCategorias'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewClubs
     */
    'imagenMiniatura'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewClubs
     */
    'imagenGrande'?: string;
    /**
     * 
     * @type {number}
     * @memberof NewClubs
     */
    'orden'?: number;
    /**
     * 
     * @type {string}
     * @memberof NewClubs
     */
    'ultimaFechaModificacionBC'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewClubs
     */
    'ultimaFechaCreacionBC'?: string;
}
/**
 * (tsType: Omit<Empresas, \'id\'>, schemaOptions: { title: \'NewEmpresas\', exclude: [ \'id\' ] })
 * @export
 * @interface NewEmpresas
 */
export interface NewEmpresas {
    /**
     * 
     * @type {string}
     * @memberof NewEmpresas
     */
    'codigo'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewEmpresas
     */
    'nombre'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewEmpresas
     */
    'GLN'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewEmpresas
     */
    'descripcion'?: string;
}
/**
 * (tsType: Omit<EmpresasBC, \'id\'>, schemaOptions: { title: \'NewEmpresasBC\', exclude: [ \'id\' ] })
 * @export
 * @interface NewEmpresasBC
 */
export interface NewEmpresasBC {
    /**
     * 
     * @type {number}
     * @memberof NewEmpresasBC
     */
    'idEmpresa': number;
    /**
     * 
     * @type {number}
     * @memberof NewEmpresasBC
     */
    'orden': number;
    /**
     * 
     * @type {string}
     * @memberof NewEmpresasBC
     */
    'idCompany'?: string;
    /**
     * 
     * @type {number}
     * @memberof NewEmpresasBC
     */
    'idMarca'?: number;
    /**
     * 
     * @type {string}
     * @memberof NewEmpresasBC
     */
    'activoSN'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewEmpresasBC
     */
    'esPrimaria'?: string;
}
/**
 * (tsType: Omit<Ensamblados, \'id\'>, schemaOptions: { title: \'NewEnsamblados\', exclude: [ \'id\' ] })
 * @export
 * @interface NewEnsamblados
 */
export interface NewEnsamblados {
    /**
     * 
     * @type {string}
     * @memberof NewEnsamblados
     */
    'skuPrincipal'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewEnsamblados
     */
    'skuEnsamblado'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewEnsamblados
     */
    'descripcion'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewEnsamblados
     */
    'cantidad'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewEnsamblados
     */
    'fechaModificacion'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewEnsamblados
     */
    'fechaCreacion'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewEnsamblados
     */
    'fechaSincronizacionConBC'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewEnsamblados
     */
    'fechaEjecucionDeProcesoSincroBC'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewEnsamblados
     */
    'motivoErrorSincroConBC'?: string;
}
/**
 * (tsType: Omit<Familias, \'id\'>, schemaOptions: { title: \'NewFamilias\', exclude: [ \'id\' ] })
 * @export
 * @interface NewFamilias
 */
export interface NewFamilias {
    /**
     * 
     * @type {number}
     * @memberof NewFamilias
     */
    'empresaId': number;
    /**
     * 
     * @type {string}
     * @memberof NewFamilias
     */
    'codigo'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewFamilias
     */
    'nombre'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewFamilias
     */
    'padre'?: string;
}
/**
 * (tsType: Omit<GruposAtributos, \'id\'>, schemaOptions: { title: \'NewGruposAtributos\', exclude: [ \'id\' ] })
 * @export
 * @interface NewGruposAtributos
 */
export interface NewGruposAtributos {
    /**
     * 
     * @type {number}
     * @memberof NewGruposAtributos
     */
    'empresaId': number;
    /**
     * 
     * @type {string}
     * @memberof NewGruposAtributos
     */
    'codigo'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewGruposAtributos
     */
    'nombre'?: string;
}
/**
 * (tsType: Omit<Iconos, \'id\'>, schemaOptions: { title: \'NewIconos\', exclude: [ \'id\' ] })
 * @export
 * @interface NewIconos
 */
export interface NewIconos {
    /**
     * 
     * @type {string}
     * @memberof NewIconos
     */
    'nombre'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewIconos
     */
    'posiblesValores'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewIconos
     */
    'imagen'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewIconos
     */
    'categorias'?: string;
}
/**
 * (tsType: Omit<Idiomas, \'id\'>, schemaOptions: { title: \'NewIdiomas\', exclude: [ \'id\' ] })
 * @export
 * @interface NewIdiomas
 */
export interface NewIdiomas {
    /**
     * 
     * @type {string}
     * @memberof NewIdiomas
     */
    'nombre'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewIdiomas
     */
    'descripcion'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewIdiomas
     */
    'activoSn'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewIdiomas
     */
    'locale'?: string;
}
/**
 * (tsType: Omit<Logsnav, \'id\'>, schemaOptions: { title: \'NewLogsnav\', exclude: [ \'id\' ] })
 * @export
 * @interface NewLogsnav
 */
export interface NewLogsnav {
    /**
     * 
     * @type {string}
     * @memberof NewLogsnav
     */
    'texto'?: string;
}
/**
 * (tsType: Omit<Marca, \'id\'>, schemaOptions: { title: \'NewMarca\', exclude: [ \'id\' ] })
 * @export
 * @interface NewMarca
 */
export interface NewMarca {
    /**
     * 
     * @type {string}
     * @memberof NewMarca
     */
    'nombre'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewMarca
     */
    'codigo'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewMarca
     */
    'color'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewMarca
     */
    'web'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewMarca
     */
    'logo'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewMarca
     */
    'visibleb2b'?: string;
}
/**
 * (tsType: Omit<MarketPlacePrompts, \'id\'>, schemaOptions: { title: \'NewMarketPlacePrompts\', exclude: [ \'id\' ] })
 * @export
 * @interface NewMarketPlacePrompts
 */
export interface NewMarketPlacePrompts {
    /**
     * 
     * @type {number}
     * @memberof NewMarketPlacePrompts
     */
    'marketplaceId': number;
    /**
     * 
     * @type {number}
     * @memberof NewMarketPlacePrompts
     */
    'categoriaId'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof NewMarketPlacePrompts
     */
    'campoUno'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewMarketPlacePrompts
     */
    'promptUno'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewMarketPlacePrompts
     */
    'campoDos'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewMarketPlacePrompts
     */
    'promptDos'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewMarketPlacePrompts
     */
    'campoTres'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewMarketPlacePrompts
     */
    'promptTres'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewMarketPlacePrompts
     */
    'campoCuatro'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewMarketPlacePrompts
     */
    'promptCuatro'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewMarketPlacePrompts
     */
    'campoCinco'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewMarketPlacePrompts
     */
    'promptCinco'?: string;
}
/**
 * (tsType: Omit<MarketPlaces, \'id\'>, schemaOptions: { title: \'NewMarketPlaces\', exclude: [ \'id\' ] })
 * @export
 * @interface NewMarketPlaces
 */
export interface NewMarketPlaces {
    /**
     * 
     * @type {string}
     * @memberof NewMarketPlaces
     */
    'codigo'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewMarketPlaces
     */
    'descripcion'?: string;
}
/**
 * (tsType: Omit<Multimedia, \'id\'>, schemaOptions: { title: \'NewMultimedia\', exclude: [ \'id\' ] })
 * @export
 * @interface NewMultimedia
 */
export interface NewMultimedia {
    /**
     * 
     * @type {number}
     * @memberof NewMultimedia
     */
    'empresaId': number;
    /**
     * 
     * @type {string}
     * @memberof NewMultimedia
     */
    'clave'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewMultimedia
     */
    'tipo'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewMultimedia
     */
    'categoria': string;
    /**
     * 
     * @type {string}
     * @memberof NewMultimedia
     */
    'defectosn'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewMultimedia
     */
    'tipoAecoc'?: string;
}
/**
 * (tsType: Omit<ParametrosGlobales, \'id\'>, schemaOptions: { title: \'newParametrosGlobales\', exclude: [ \'id\' ] })
 * @export
 * @interface NewParametrosGlobales
 */
export interface NewParametrosGlobales {
    /**
     * 
     * @type {number}
     * @memberof NewParametrosGlobales
     */
    'minutosCierreSesion'?: number;
    /**
     * 
     * @type {string}
     * @memberof NewParametrosGlobales
     */
    'correosEnvioLogSincro'?: string;
    /**
     * 
     * @type {number}
     * @memberof NewParametrosGlobales
     */
    'segundosTimeOutBC'?: number;
}
/**
 * (tsType: Omit<Permisos, \'id\'>, schemaOptions: { title: \'NewPermisos\', exclude: [ \'id\' ] })
 * @export
 * @interface NewPermisos
 */
export interface NewPermisos {
    /**
     * 
     * @type {string}
     * @memberof NewPermisos
     */
    'moduleName'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewPermisos
     */
    'controllerName'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewPermisos
     */
    'actionName'?: string;
    /**
     * 
     * @type {number}
     * @memberof NewPermisos
     */
    'rolesId'?: number;
}
/**
 * (tsType: Omit<Plantilla, \'id\'>, schemaOptions: { title: \'NewPlantilla\', exclude: [ \'id\' ] })
 * @export
 * @interface NewPlantilla
 */
export interface NewPlantilla {
    /**
     * 
     * @type {string}
     * @memberof NewPlantilla
     */
    'nombre'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewPlantilla
     */
    'tabla'?: string;
    /**
     * 
     * @type {number}
     * @memberof NewPlantilla
     */
    'idEmpresa'?: number;
    /**
     * 
     * @type {string}
     * @memberof NewPlantilla
     */
    'cabecera'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewPlantilla
     */
    'descripcion'?: string;
}
/**
 * (tsType: Omit<ProductoAtributos, \'id\'>, schemaOptions: { title: \'NewProductoAtributos\', exclude: [ \'id\' ] })
 * @export
 * @interface NewProductoAtributos
 */
export interface NewProductoAtributos {
    /**
     * 
     * @type {number}
     * @memberof NewProductoAtributos
     */
    'productoId': number;
    /**
     * 
     * @type {number}
     * @memberof NewProductoAtributos
     */
    'atributoId': number;
    /**
     * 
     * @type {string}
     * @memberof NewProductoAtributos
     */
    'nombre'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewProductoAtributos
     */
    'valor'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewProductoAtributos
     */
    'unidad'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewProductoAtributos
     */
    'ordenEnGrupo'?: string;
}
/**
 * (tsType: Omit<ProductoCaracteristicas, \'id\'>, schemaOptions: { title: \'NewProductoCaracteristicas\', exclude: [ \'id\' ] })
 * @export
 * @interface NewProductoCaracteristicas
 */
export interface NewProductoCaracteristicas {
    /**
     * 
     * @type {number}
     * @memberof NewProductoCaracteristicas
     */
    'productoId': number;
    /**
     * 
     * @type {string}
     * @memberof NewProductoCaracteristicas
     */
    'nombre'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewProductoCaracteristicas
     */
    'valor'?: string;
}
/**
 * (tsType: Omit<ProductoDatosCatalogo, \'id\'>, schemaOptions: { title: \'NewProductoDatosCatalogo\', exclude: [ \'id\' ] })
 * @export
 * @interface NewProductoDatosCatalogo
 */
export interface NewProductoDatosCatalogo {
    /**
     * 
     * @type {number}
     * @memberof NewProductoDatosCatalogo
     */
    'productoId': number;
    /**
     * 
     * @type {string}
     * @memberof NewProductoDatosCatalogo
     */
    'codigoFabricante'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewProductoDatosCatalogo
     */
    'skuProvisional'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewProductoDatosCatalogo
     */
    'capacidadCarga'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewProductoDatosCatalogo
     */
    'contenedor'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewProductoDatosCatalogo
     */
    'precioFabrica'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewProductoDatosCatalogo
     */
    'costoAlmacen'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewProductoDatosCatalogo
     */
    'costeEstandar'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewProductoDatosCatalogo
     */
    'envio'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewProductoDatosCatalogo
     */
    'arancel'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewProductoDatosCatalogo
     */
    'detallesProducto'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewProductoDatosCatalogo
     */
    'necesidadesProducto'?: string;
}
/**
 * (tsType: Omit<ProductoGeneral, \'id\'>, schemaOptions: { title: \'NewProductoGeneral\', exclude: [ \'id\' ] })
 * @export
 * @interface NewProductoGeneral
 */
export interface NewProductoGeneral {
    /**
     * 
     * @type {number}
     * @memberof NewProductoGeneral
     */
    'productoId': number;
    /**
     * 
     * @type {string}
     * @memberof NewProductoGeneral
     */
    'sku'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewProductoGeneral
     */
    'ean'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewProductoGeneral
     */
    'marca'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewProductoGeneral
     */
    'estadoReferencia'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewProductoGeneral
     */
    'descripcion'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewProductoGeneral
     */
    'titulo'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewProductoGeneral
     */
    'descripcionLarga'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewProductoGeneral
     */
    'palabrasClave'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewProductoGeneral
     */
    'GLN'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewProductoGeneral
     */
    'dimensiones'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewProductoGeneral
     */
    'formatoRP'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewProductoGeneral
     */
    'color'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewProductoGeneral
     */
    'mercados'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewProductoGeneral
     */
    'fechaModificacion'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewProductoGeneral
     */
    'referenciaAgrupacion'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewProductoGeneral
     */
    'descripcionAecoc'?: string;
}
/**
 * (tsType: Omit<ProductoIconos, \'id\'>, schemaOptions: { title: \'NewProductoIconos\', exclude: [ \'id\' ] })
 * @export
 * @interface NewProductoIconos
 */
export interface NewProductoIconos {
    /**
     * 
     * @type {number}
     * @memberof NewProductoIconos
     */
    'idIcono': number;
    /**
     * 
     * @type {number}
     * @memberof NewProductoIconos
     */
    'idProducto': number;
    /**
     * 
     * @type {string}
     * @memberof NewProductoIconos
     */
    'texto'?: string;
}
/**
 * (tsType: Omit<ProductoMultimedia, \'id\'>, schemaOptions: { title: \'NewProductoMultimedia\', exclude: [ \'id\' ] })
 * @export
 * @interface NewProductoMultimedia
 */
export interface NewProductoMultimedia {
    /**
     * 
     * @type {number}
     * @memberof NewProductoMultimedia
     */
    'productoId': number;
    /**
     * 
     * @type {string}
     * @memberof NewProductoMultimedia
     */
    'tipo'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewProductoMultimedia
     */
    'fichero'?: string;
    /**
     * 
     * @type {number}
     * @memberof NewProductoMultimedia
     */
    'multimediaId': number;
    /**
     * 
     * @type {string}
     * @memberof NewProductoMultimedia
     */
    'nombre'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewProductoMultimedia
     */
    'categoria'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewProductoMultimedia
     */
    'publicoSN'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewProductoMultimedia
     */
    'principalSN'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewProductoMultimedia
     */
    'formato'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewProductoMultimedia
     */
    'objetivo'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewProductoMultimedia
     */
    'tagAecoc'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewProductoMultimedia
     */
    'fechaModificacion'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewProductoMultimedia
     */
    'fechaCreacion'?: string;
}
/**
 * (tsType: Omit<Productos, \'id\'>, schemaOptions: { title: \'NewProductos\', exclude: [ \'id\' ] })
 * @export
 * @interface NewProductos
 */
export interface NewProductos {
    /**
     * 
     * @type {number}
     * @memberof NewProductos
     */
    'empresaId': number;
    /**
     * 
     * @type {string}
     * @memberof NewProductos
     */
    'nombre'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewProductos
     */
    'imagen'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewProductos
     */
    'categorias'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewProductos
     */
    'familias'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewProductos
     */
    'finalizado'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewProductos
     */
    'ordenAtributos'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewProductos
     */
    'finalizadoSAT'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewProductos
     */
    'enviarAecoc'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewProductos
     */
    'sincronizarConBC'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewProductos
     */
    'fechaSincronizacionConBC'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewProductos
     */
    'fechaEjecucionDeProcesoSincroBC'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewProductos
     */
    'motivoErrorSincroConBC'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewProductos
     */
    'ensamblado'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewProductos
     */
    'fechaModificacion'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewProductos
     */
    'FechaModificacionparaAecoc'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewProductos
     */
    'fechaCreacion'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewProductos
     */
    'activoEnMarketplace'?: string;
}
/**
 * (tsType: Omit<ProductosPorRango, \'id\'>, schemaOptions: { title: \'NewProductosPorRango\', exclude: [ \'id\' ] })
 * @export
 * @interface NewProductosPorRango
 */
export interface NewProductosPorRango {
    /**
     * 
     * @type {string}
     * @memberof NewProductosPorRango
     */
    'skuProducto'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewProductosPorRango
     */
    'desdeFecha'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewProductosPorRango
     */
    'hastaFecha'?: string;
    /**
     * 
     * @type {number}
     * @memberof NewProductosPorRango
     */
    'rangoId': number;
    /**
     * 
     * @type {number}
     * @memberof NewProductosPorRango
     */
    'clubId': number;
}
/**
 * (tsType: @loopback/repository-json-schema#Optional<Omit<Permisos, \'id\'>, \'rolesId\'>, schemaOptions: { title: \'NewRelacionRolesPermisos\', exclude: [ \'id\' ], optional: [ \'rolesId\' ] })
 * @export
 * @interface NewRelacionRolesPermisos
 */
export interface NewRelacionRolesPermisos {
    /**
     * 
     * @type {string}
     * @memberof NewRelacionRolesPermisos
     */
    'moduleName'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewRelacionRolesPermisos
     */
    'controllerName'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewRelacionRolesPermisos
     */
    'actionName'?: string;
    /**
     * 
     * @type {number}
     * @memberof NewRelacionRolesPermisos
     */
    'rolesId'?: number;
}
/**
 * (tsType: Omit<Repuestos, \'id\'>, schemaOptions: { title: \'NewRepuestos\', exclude: [ \'id\' ] })
 * @export
 * @interface NewRepuestos
 */
export interface NewRepuestos {
    /**
     * 
     * @type {string}
     * @memberof NewRepuestos
     */
    'skuPrincipal'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewRepuestos
     */
    'skuRepuesto'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewRepuestos
     */
    'descripcion'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewRepuestos
     */
    'posicion'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewRepuestos
     */
    'fechaInicio'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewRepuestos
     */
    'fechaFin'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewRepuestos
     */
    'fechaModificacion'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewRepuestos
     */
    'fechaCreacion'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewRepuestos
     */
    'fechaSincronizacionConBC'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewRepuestos
     */
    'fechaEjecucionDeProcesoSincroBC'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewRepuestos
     */
    'motivoErrorSincroConBC'?: string;
}
/**
 * (tsType: Omit<Roles, \'id\'>, schemaOptions: { title: \'NewRoles\', exclude: [ \'id\' ] })
 * @export
 * @interface NewRoles
 */
export interface NewRoles {
    /**
     * 
     * @type {string}
     * @memberof NewRoles
     */
    'nombre'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewRoles
     */
    'activo'?: string;
}
/**
 * (tsType: Omit<Situaciones, \'id\'>, schemaOptions: { title: \'NewSituaciones\', exclude: [ \'id\' ] })
 * @export
 * @interface NewSituaciones
 */
export interface NewSituaciones {
    /**
     * 
     * @type {string}
     * @memberof NewSituaciones
     */
    'nombre'?: string;
}
/**
 * (tsType: Omit<Sustitutos, \'id\'>, schemaOptions: { title: \'NewSustitutos\', exclude: [ \'id\' ] })
 * @export
 * @interface NewSustitutos
 */
export interface NewSustitutos {
    /**
     * 
     * @type {string}
     * @memberof NewSustitutos
     */
    'skuPrincipal'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewSustitutos
     */
    'skuSustituto'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewSustitutos
     */
    'descripcion'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewSustitutos
     */
    'intercambiable'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewSustitutos
     */
    'fechaInicio'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewSustitutos
     */
    'fechaFin'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewSustitutos
     */
    'fechaModificacion'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewSustitutos
     */
    'fechaCreacion'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewSustitutos
     */
    'fechaSincronizacionConBC'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewSustitutos
     */
    'fechaEjecucionDeProcesoSincroBC'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewSustitutos
     */
    'motivoErrorSincroConBC'?: string;
}
/**
 * (tsType: Omit<Tablas, \'id\'>, schemaOptions: { title: \'NewTablas\', exclude: [ \'id\' ] })
 * @export
 * @interface NewTablas
 */
export interface NewTablas {
    /**
     * 
     * @type {string}
     * @memberof NewTablas
     */
    'nombreTabla'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewTablas
     */
    'campo'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewTablas
     */
    'nullable'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewTablas
     */
    'tipo'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewTablas
     */
    'isKey'?: string;
}
/**
 * (tsType: Omit<TextosGenerados, \'id\'>, schemaOptions: { title: \'NewTextosGenerados\', exclude: [ \'id\' ] })
 * @export
 * @interface NewTextosGenerados
 */
export interface NewTextosGenerados {
    /**
     * 
     * @type {number}
     * @memberof NewTextosGenerados
     */
    'productoId': number;
    /**
     * 
     * @type {number}
     * @memberof NewTextosGenerados
     */
    'marketplacePromptId': number;
    /**
     * 
     * @type {number}
     * @memberof NewTextosGenerados
     */
    'marketplaceId': number;
    /**
     * 
     * @type {string}
     * @memberof NewTextosGenerados
     */
    'campoUno'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewTextosGenerados
     */
    'textoIaCampoUno'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewTextosGenerados
     */
    'campoDos'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewTextosGenerados
     */
    'textoIaCampoDos'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewTextosGenerados
     */
    'campoTres'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewTextosGenerados
     */
    'textoIaCampoTres'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewTextosGenerados
     */
    'campoCuatro'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewTextosGenerados
     */
    'textoIaCampoCuatro'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewTextosGenerados
     */
    'campoCinco'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewTextosGenerados
     */
    'textoIaCampoCinco'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewTextosGenerados
     */
    'revisadoSn'?: string;
}
/**
 * (tsType: Omit<TiposRango, \'id\'>, schemaOptions: { title: \'NewTiposRango\', exclude: [ \'id\' ] })
 * @export
 * @interface NewTiposRango
 */
export interface NewTiposRango {
    /**
     * 
     * @type {string}
     * @memberof NewTiposRango
     */
    'rango'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewTiposRango
     */
    'imagen'?: string;
    /**
     * 
     * @type {number}
     * @memberof NewTiposRango
     */
    'orden': number;
}
/**
 * (tsType: Omit<Traducciones, \'id\'>, schemaOptions: { title: \'NewTraducciones\', exclude: [ \'id\' ] })
 * @export
 * @interface NewTraducciones
 */
export interface NewTraducciones {
    /**
     * 
     * @type {string}
     * @memberof NewTraducciones
     */
    'idiomaId'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewTraducciones
     */
    'clave'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewTraducciones
     */
    'valor'?: string;
}
/**
 * (tsType: Omit<Usuarios, \'id\'>, schemaOptions: { title: \'NewUsuarios\', exclude: [ \'id\' ] })
 * @export
 * @interface NewUsuarios
 */
export interface NewUsuarios {
    /**
     * 
     * @type {number}
     * @memberof NewUsuarios
     */
    'empresaId': number;
    /**
     * 
     * @type {string}
     * @memberof NewUsuarios
     */
    'nombre'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewUsuarios
     */
    'mail'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewUsuarios
     */
    'activoSn'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewUsuarios
     */
    'telefono'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewUsuarios
     */
    'avatar'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewUsuarios
     */
    'password'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewUsuarios
     */
    'role'?: string;
    /**
     * 
     * @type {number}
     * @memberof NewUsuarios
     */
    'rolesId'?: number;
    /**
     * 
     * @type {string}
     * @memberof NewUsuarios
     */
    'filtroCategorias'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewUsuarios
     */
    'filtroMarcas'?: string;
}
/**
 * (tsType: Omit<VistaCategoriasConNiveles, \'id\'>, schemaOptions: { title: \'NewVistaCategoriasConNiveles\', exclude: [ \'id\' ] })
 * @export
 * @interface NewVistaCategoriasConNiveles
 */
export interface NewVistaCategoriasConNiveles {
    /**
     * 
     * @type {number}
     * @memberof NewVistaCategoriasConNiveles
     */
    'empresaId': number;
    /**
     * 
     * @type {string}
     * @memberof NewVistaCategoriasConNiveles
     */
    'tagAecoc'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewVistaCategoriasConNiveles
     */
    'codigo'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewVistaCategoriasConNiveles
     */
    'codigoAECOC'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewVistaCategoriasConNiveles
     */
    'nombre'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewVistaCategoriasConNiveles
     */
    'padre'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewVistaCategoriasConNiveles
     */
    'web'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewVistaCategoriasConNiveles
     */
    'nivel'?: string;
}
/**
 * (tsType: Omit<VistaMarketPlacePrompts, \'id\'>, schemaOptions: { title: \'NewVistaMarketPlacePrompts\', exclude: [ \'id\' ] })
 * @export
 * @interface NewVistaMarketPlacePrompts
 */
export interface NewVistaMarketPlacePrompts {
    /**
     * 
     * @type {number}
     * @memberof NewVistaMarketPlacePrompts
     */
    'marketplaceId': number;
    /**
     * 
     * @type {string}
     * @memberof NewVistaMarketPlacePrompts
     */
    'marketplaceCodigo'?: string;
    /**
     * 
     * @type {number}
     * @memberof NewVistaMarketPlacePrompts
     */
    'categoriaId': number;
    /**
     * 
     * @type {string}
     * @memberof NewVistaMarketPlacePrompts
     */
    'categoriaCodigo'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewVistaMarketPlacePrompts
     */
    'categoriaPadre'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewVistaMarketPlacePrompts
     */
    'categoriaNivel'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewVistaMarketPlacePrompts
     */
    'campoUno'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewVistaMarketPlacePrompts
     */
    'promptUno'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewVistaMarketPlacePrompts
     */
    'campoDos'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewVistaMarketPlacePrompts
     */
    'promptDos'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewVistaMarketPlacePrompts
     */
    'campoTres'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewVistaMarketPlacePrompts
     */
    'promptTres'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewVistaMarketPlacePrompts
     */
    'campoCuatro'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewVistaMarketPlacePrompts
     */
    'promptCuatro'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewVistaMarketPlacePrompts
     */
    'campoCinco'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewVistaMarketPlacePrompts
     */
    'promptCinco'?: string;
}
/**
 * 
 * @export
 * @interface ParametrosGlobales
 */
export interface ParametrosGlobales {
    /**
     * 
     * @type {number}
     * @memberof ParametrosGlobales
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof ParametrosGlobales
     */
    'minutosCierreSesion'?: number;
    /**
     * 
     * @type {string}
     * @memberof ParametrosGlobales
     */
    'correosEnvioLogSincro'?: string;
    /**
     * 
     * @type {number}
     * @memberof ParametrosGlobales
     */
    'segundosTimeOutBC'?: number;
}
/**
 * 
 * @export
 * @interface ParametrosGlobalesFilter
 */
export interface ParametrosGlobalesFilter {
    /**
     * 
     * @type {number}
     * @memberof ParametrosGlobalesFilter
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof ParametrosGlobalesFilter
     */
    'limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof ParametrosGlobalesFilter
     */
    'skip'?: number;
    /**
     * 
     * @type {string | Array<string>}
     * @memberof ParametrosGlobalesFilter
     */
    'order'?: string | Array<string>;
    /**
     * 
     * @type {object | Set<string>}
     * @memberof ParametrosGlobalesFilter
     */
    'fields'?: object | Set<string>;
}
/**
 * 
 * @export
 * @interface ParametrosGlobalesFilter1
 */
export interface ParametrosGlobalesFilter1 {
    /**
     * 
     * @type {number}
     * @memberof ParametrosGlobalesFilter1
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof ParametrosGlobalesFilter1
     */
    'limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof ParametrosGlobalesFilter1
     */
    'skip'?: number;
    /**
     * 
     * @type {string | Array<string>}
     * @memberof ParametrosGlobalesFilter1
     */
    'order'?: string | Array<string>;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof ParametrosGlobalesFilter1
     */
    'where'?: { [key: string]: object; };
    /**
     * 
     * @type {object | Set<string>}
     * @memberof ParametrosGlobalesFilter1
     */
    'fields'?: object | Set<string>;
}
/**
 * (tsType: Partial<ParametrosGlobales>, schemaOptions: { partial: true })
 * @export
 * @interface ParametrosGlobalesPartial
 */
export interface ParametrosGlobalesPartial {
    /**
     * 
     * @type {number}
     * @memberof ParametrosGlobalesPartial
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof ParametrosGlobalesPartial
     */
    'minutosCierreSesion'?: number;
    /**
     * 
     * @type {string}
     * @memberof ParametrosGlobalesPartial
     */
    'correosEnvioLogSincro'?: string;
    /**
     * 
     * @type {number}
     * @memberof ParametrosGlobalesPartial
     */
    'segundosTimeOutBC'?: number;
}
/**
 * (tsType: ParametrosGlobalesWithRelations, schemaOptions: { includeRelations: true })
 * @export
 * @interface ParametrosGlobalesWithRelations
 */
export interface ParametrosGlobalesWithRelations {
    /**
     * 
     * @type {number}
     * @memberof ParametrosGlobalesWithRelations
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof ParametrosGlobalesWithRelations
     */
    'minutosCierreSesion'?: number;
    /**
     * 
     * @type {string}
     * @memberof ParametrosGlobalesWithRelations
     */
    'correosEnvioLogSincro'?: string;
    /**
     * 
     * @type {number}
     * @memberof ParametrosGlobalesWithRelations
     */
    'segundosTimeOutBC'?: number;
}
/**
 * 
 * @export
 * @interface Permisos
 */
export interface Permisos {
    /**
     * 
     * @type {number}
     * @memberof Permisos
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof Permisos
     */
    'moduleName'?: string;
    /**
     * 
     * @type {string}
     * @memberof Permisos
     */
    'controllerName'?: string;
    /**
     * 
     * @type {string}
     * @memberof Permisos
     */
    'actionName'?: string;
    /**
     * 
     * @type {number}
     * @memberof Permisos
     */
    'rolesId'?: number;
}
/**
 * 
 * @export
 * @interface PermisosFilter
 */
export interface PermisosFilter {
    /**
     * 
     * @type {number}
     * @memberof PermisosFilter
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof PermisosFilter
     */
    'limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof PermisosFilter
     */
    'skip'?: number;
    /**
     * 
     * @type {string | Array<string>}
     * @memberof PermisosFilter
     */
    'order'?: string | Array<string>;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof PermisosFilter
     */
    'where'?: { [key: string]: object; };
    /**
     * 
     * @type {object | Set<string>}
     * @memberof PermisosFilter
     */
    'fields'?: object | Set<string>;
}
/**
 * 
 * @export
 * @interface PermisosFilter1
 */
export interface PermisosFilter1 {
    /**
     * 
     * @type {number}
     * @memberof PermisosFilter1
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof PermisosFilter1
     */
    'limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof PermisosFilter1
     */
    'skip'?: number;
    /**
     * 
     * @type {string | Array<string>}
     * @memberof PermisosFilter1
     */
    'order'?: string | Array<string>;
    /**
     * 
     * @type {object | Set<string>}
     * @memberof PermisosFilter1
     */
    'fields'?: object | Set<string>;
}
/**
 * (tsType: Partial<Permisos>, schemaOptions: { partial: true })
 * @export
 * @interface PermisosPartial
 */
export interface PermisosPartial {
    /**
     * 
     * @type {number}
     * @memberof PermisosPartial
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof PermisosPartial
     */
    'moduleName'?: string;
    /**
     * 
     * @type {string}
     * @memberof PermisosPartial
     */
    'controllerName'?: string;
    /**
     * 
     * @type {string}
     * @memberof PermisosPartial
     */
    'actionName'?: string;
    /**
     * 
     * @type {number}
     * @memberof PermisosPartial
     */
    'rolesId'?: number;
}
/**
 * (tsType: PermisosWithRelations, schemaOptions: { includeRelations: true })
 * @export
 * @interface PermisosWithRelations
 */
export interface PermisosWithRelations {
    /**
     * 
     * @type {number}
     * @memberof PermisosWithRelations
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof PermisosWithRelations
     */
    'moduleName'?: string;
    /**
     * 
     * @type {string}
     * @memberof PermisosWithRelations
     */
    'controllerName'?: string;
    /**
     * 
     * @type {string}
     * @memberof PermisosWithRelations
     */
    'actionName'?: string;
    /**
     * 
     * @type {number}
     * @memberof PermisosWithRelations
     */
    'rolesId'?: number;
}
/**
 * 
 * @export
 * @interface PingResponse
 */
export interface PingResponse {
    /**
     * 
     * @type {string}
     * @memberof PingResponse
     */
    'greeting'?: string;
    /**
     * 
     * @type {string}
     * @memberof PingResponse
     */
    'date'?: string;
    /**
     * 
     * @type {string}
     * @memberof PingResponse
     */
    'url'?: string;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof PingResponse
     */
    'headers'?: { [key: string]: object; };
}
/**
 * 
 * @export
 * @interface Plantilla
 */
export interface Plantilla {
    /**
     * 
     * @type {number}
     * @memberof Plantilla
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof Plantilla
     */
    'nombre'?: string;
    /**
     * 
     * @type {string}
     * @memberof Plantilla
     */
    'tabla'?: string;
    /**
     * 
     * @type {number}
     * @memberof Plantilla
     */
    'idEmpresa'?: number;
    /**
     * 
     * @type {string}
     * @memberof Plantilla
     */
    'cabecera'?: string;
    /**
     * 
     * @type {string}
     * @memberof Plantilla
     */
    'descripcion'?: string;
}
/**
 * 
 * @export
 * @interface PlantillaFilter
 */
export interface PlantillaFilter {
    /**
     * 
     * @type {number}
     * @memberof PlantillaFilter
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof PlantillaFilter
     */
    'limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof PlantillaFilter
     */
    'skip'?: number;
    /**
     * 
     * @type {string | Array<string>}
     * @memberof PlantillaFilter
     */
    'order'?: string | Array<string>;
    /**
     * 
     * @type {object | Set<string>}
     * @memberof PlantillaFilter
     */
    'fields'?: object | Set<string>;
}
/**
 * 
 * @export
 * @interface PlantillaFilter1
 */
export interface PlantillaFilter1 {
    /**
     * 
     * @type {number}
     * @memberof PlantillaFilter1
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof PlantillaFilter1
     */
    'limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof PlantillaFilter1
     */
    'skip'?: number;
    /**
     * 
     * @type {string | Array<string>}
     * @memberof PlantillaFilter1
     */
    'order'?: string | Array<string>;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof PlantillaFilter1
     */
    'where'?: { [key: string]: object; };
    /**
     * 
     * @type {object | Set<string>}
     * @memberof PlantillaFilter1
     */
    'fields'?: object | Set<string>;
}
/**
 * (tsType: Partial<Plantilla>, schemaOptions: { partial: true })
 * @export
 * @interface PlantillaPartial
 */
export interface PlantillaPartial {
    /**
     * 
     * @type {number}
     * @memberof PlantillaPartial
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof PlantillaPartial
     */
    'nombre'?: string;
    /**
     * 
     * @type {string}
     * @memberof PlantillaPartial
     */
    'tabla'?: string;
    /**
     * 
     * @type {number}
     * @memberof PlantillaPartial
     */
    'idEmpresa'?: number;
    /**
     * 
     * @type {string}
     * @memberof PlantillaPartial
     */
    'cabecera'?: string;
    /**
     * 
     * @type {string}
     * @memberof PlantillaPartial
     */
    'descripcion'?: string;
}
/**
 * (tsType: PlantillaWithRelations, schemaOptions: { includeRelations: true })
 * @export
 * @interface PlantillaWithRelations
 */
export interface PlantillaWithRelations {
    /**
     * 
     * @type {number}
     * @memberof PlantillaWithRelations
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof PlantillaWithRelations
     */
    'nombre'?: string;
    /**
     * 
     * @type {string}
     * @memberof PlantillaWithRelations
     */
    'tabla'?: string;
    /**
     * 
     * @type {number}
     * @memberof PlantillaWithRelations
     */
    'idEmpresa'?: number;
    /**
     * 
     * @type {string}
     * @memberof PlantillaWithRelations
     */
    'cabecera'?: string;
    /**
     * 
     * @type {string}
     * @memberof PlantillaWithRelations
     */
    'descripcion'?: string;
}
/**
 * 
 * @export
 * @interface ProductoAtributos
 */
export interface ProductoAtributos {
    /**
     * 
     * @type {number}
     * @memberof ProductoAtributos
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductoAtributos
     */
    'productoId': number;
    /**
     * 
     * @type {number}
     * @memberof ProductoAtributos
     */
    'atributoId': number;
    /**
     * 
     * @type {string}
     * @memberof ProductoAtributos
     */
    'nombre'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductoAtributos
     */
    'valor'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductoAtributos
     */
    'unidad'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductoAtributos
     */
    'ordenEnGrupo'?: string;
}
/**
 * (tsType: ProductoAtributos, schemaOptions: { includeRelations: false })
 * @export
 * @interface ProductoAtributos1
 */
export interface ProductoAtributos1 {
    /**
     * 
     * @type {number}
     * @memberof ProductoAtributos1
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductoAtributos1
     */
    'productoId': number;
    /**
     * 
     * @type {number}
     * @memberof ProductoAtributos1
     */
    'atributoId': number;
    /**
     * 
     * @type {string}
     * @memberof ProductoAtributos1
     */
    'nombre'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductoAtributos1
     */
    'valor'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductoAtributos1
     */
    'unidad'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductoAtributos1
     */
    'ordenEnGrupo'?: string;
}
/**
 * 
 * @export
 * @interface ProductoAtributosFilter
 */
export interface ProductoAtributosFilter {
    /**
     * 
     * @type {number}
     * @memberof ProductoAtributosFilter
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductoAtributosFilter
     */
    'limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductoAtributosFilter
     */
    'skip'?: number;
    /**
     * 
     * @type {string | Array<string>}
     * @memberof ProductoAtributosFilter
     */
    'order'?: string | Array<string>;
    /**
     * 
     * @type {object | Set<string>}
     * @memberof ProductoAtributosFilter
     */
    'fields'?: object | Set<string>;
}
/**
 * 
 * @export
 * @interface ProductoAtributosFilter1
 */
export interface ProductoAtributosFilter1 {
    /**
     * 
     * @type {number}
     * @memberof ProductoAtributosFilter1
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductoAtributosFilter1
     */
    'limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductoAtributosFilter1
     */
    'skip'?: number;
    /**
     * 
     * @type {string | Array<string>}
     * @memberof ProductoAtributosFilter1
     */
    'order'?: string | Array<string>;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof ProductoAtributosFilter1
     */
    'where'?: { [key: string]: object; };
    /**
     * 
     * @type {object | Set<string>}
     * @memberof ProductoAtributosFilter1
     */
    'fields'?: object | Set<string>;
}
/**
 * (tsType: Partial<ProductoAtributos>, schemaOptions: { partial: true })
 * @export
 * @interface ProductoAtributosPartial
 */
export interface ProductoAtributosPartial {
    /**
     * 
     * @type {number}
     * @memberof ProductoAtributosPartial
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductoAtributosPartial
     */
    'productoId'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductoAtributosPartial
     */
    'atributoId'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductoAtributosPartial
     */
    'nombre'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductoAtributosPartial
     */
    'valor'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductoAtributosPartial
     */
    'unidad'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductoAtributosPartial
     */
    'ordenEnGrupo'?: string;
}
/**
 * (tsType: ProductoAtributosWithRelations, schemaOptions: { includeRelations: true })
 * @export
 * @interface ProductoAtributosWithRelations
 */
export interface ProductoAtributosWithRelations {
    /**
     * 
     * @type {number}
     * @memberof ProductoAtributosWithRelations
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductoAtributosWithRelations
     */
    'productoId': number;
    /**
     * 
     * @type {number}
     * @memberof ProductoAtributosWithRelations
     */
    'atributoId': number;
    /**
     * 
     * @type {string}
     * @memberof ProductoAtributosWithRelations
     */
    'nombre'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductoAtributosWithRelations
     */
    'valor'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductoAtributosWithRelations
     */
    'unidad'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductoAtributosWithRelations
     */
    'ordenEnGrupo'?: string;
}
/**
 * 
 * @export
 * @interface ProductoCaracteristicas
 */
export interface ProductoCaracteristicas {
    /**
     * 
     * @type {number}
     * @memberof ProductoCaracteristicas
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductoCaracteristicas
     */
    'productoId': number;
    /**
     * 
     * @type {string}
     * @memberof ProductoCaracteristicas
     */
    'nombre'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductoCaracteristicas
     */
    'valor'?: string;
}
/**
 * 
 * @export
 * @interface ProductoCaracteristicasFilter
 */
export interface ProductoCaracteristicasFilter {
    /**
     * 
     * @type {number}
     * @memberof ProductoCaracteristicasFilter
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductoCaracteristicasFilter
     */
    'limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductoCaracteristicasFilter
     */
    'skip'?: number;
    /**
     * 
     * @type {string | Array<string>}
     * @memberof ProductoCaracteristicasFilter
     */
    'order'?: string | Array<string>;
    /**
     * 
     * @type {object | Set<string>}
     * @memberof ProductoCaracteristicasFilter
     */
    'fields'?: object | Set<string>;
}
/**
 * 
 * @export
 * @interface ProductoCaracteristicasFilter1
 */
export interface ProductoCaracteristicasFilter1 {
    /**
     * 
     * @type {number}
     * @memberof ProductoCaracteristicasFilter1
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductoCaracteristicasFilter1
     */
    'limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductoCaracteristicasFilter1
     */
    'skip'?: number;
    /**
     * 
     * @type {string | Array<string>}
     * @memberof ProductoCaracteristicasFilter1
     */
    'order'?: string | Array<string>;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof ProductoCaracteristicasFilter1
     */
    'where'?: { [key: string]: object; };
    /**
     * 
     * @type {object | Set<string>}
     * @memberof ProductoCaracteristicasFilter1
     */
    'fields'?: object | Set<string>;
}
/**
 * (tsType: Partial<ProductoCaracteristicas>, schemaOptions: { partial: true })
 * @export
 * @interface ProductoCaracteristicasPartial
 */
export interface ProductoCaracteristicasPartial {
    /**
     * 
     * @type {number}
     * @memberof ProductoCaracteristicasPartial
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductoCaracteristicasPartial
     */
    'productoId'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductoCaracteristicasPartial
     */
    'nombre'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductoCaracteristicasPartial
     */
    'valor'?: string;
}
/**
 * (tsType: ProductoCaracteristicasWithRelations, schemaOptions: { includeRelations: true })
 * @export
 * @interface ProductoCaracteristicasWithRelations
 */
export interface ProductoCaracteristicasWithRelations {
    /**
     * 
     * @type {number}
     * @memberof ProductoCaracteristicasWithRelations
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductoCaracteristicasWithRelations
     */
    'productoId': number;
    /**
     * 
     * @type {string}
     * @memberof ProductoCaracteristicasWithRelations
     */
    'nombre'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductoCaracteristicasWithRelations
     */
    'valor'?: string;
}
/**
 * 
 * @export
 * @interface ProductoDatosCatalogo
 */
export interface ProductoDatosCatalogo {
    /**
     * 
     * @type {number}
     * @memberof ProductoDatosCatalogo
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductoDatosCatalogo
     */
    'productoId': number;
    /**
     * 
     * @type {string}
     * @memberof ProductoDatosCatalogo
     */
    'codigoFabricante'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductoDatosCatalogo
     */
    'skuProvisional'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductoDatosCatalogo
     */
    'capacidadCarga'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductoDatosCatalogo
     */
    'contenedor'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductoDatosCatalogo
     */
    'precioFabrica'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductoDatosCatalogo
     */
    'costoAlmacen'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductoDatosCatalogo
     */
    'costeEstandar'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductoDatosCatalogo
     */
    'envio'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductoDatosCatalogo
     */
    'arancel'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductoDatosCatalogo
     */
    'detallesProducto'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductoDatosCatalogo
     */
    'necesidadesProducto'?: string;
}
/**
 * 
 * @export
 * @interface ProductoDatosCatalogoFilter
 */
export interface ProductoDatosCatalogoFilter {
    /**
     * 
     * @type {number}
     * @memberof ProductoDatosCatalogoFilter
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductoDatosCatalogoFilter
     */
    'limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductoDatosCatalogoFilter
     */
    'skip'?: number;
    /**
     * 
     * @type {string | Array<string>}
     * @memberof ProductoDatosCatalogoFilter
     */
    'order'?: string | Array<string>;
    /**
     * 
     * @type {object | Set<string>}
     * @memberof ProductoDatosCatalogoFilter
     */
    'fields'?: object | Set<string>;
}
/**
 * 
 * @export
 * @interface ProductoDatosCatalogoFilter1
 */
export interface ProductoDatosCatalogoFilter1 {
    /**
     * 
     * @type {number}
     * @memberof ProductoDatosCatalogoFilter1
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductoDatosCatalogoFilter1
     */
    'limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductoDatosCatalogoFilter1
     */
    'skip'?: number;
    /**
     * 
     * @type {string | Array<string>}
     * @memberof ProductoDatosCatalogoFilter1
     */
    'order'?: string | Array<string>;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof ProductoDatosCatalogoFilter1
     */
    'where'?: { [key: string]: object; };
    /**
     * 
     * @type {object | Set<string>}
     * @memberof ProductoDatosCatalogoFilter1
     */
    'fields'?: object | Set<string>;
}
/**
 * (tsType: Partial<ProductoDatosCatalogo>, schemaOptions: { partial: true })
 * @export
 * @interface ProductoDatosCatalogoPartial
 */
export interface ProductoDatosCatalogoPartial {
    /**
     * 
     * @type {number}
     * @memberof ProductoDatosCatalogoPartial
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductoDatosCatalogoPartial
     */
    'productoId'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductoDatosCatalogoPartial
     */
    'codigoFabricante'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductoDatosCatalogoPartial
     */
    'skuProvisional'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductoDatosCatalogoPartial
     */
    'capacidadCarga'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductoDatosCatalogoPartial
     */
    'contenedor'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductoDatosCatalogoPartial
     */
    'precioFabrica'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductoDatosCatalogoPartial
     */
    'costoAlmacen'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductoDatosCatalogoPartial
     */
    'costeEstandar'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductoDatosCatalogoPartial
     */
    'envio'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductoDatosCatalogoPartial
     */
    'arancel'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductoDatosCatalogoPartial
     */
    'detallesProducto'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductoDatosCatalogoPartial
     */
    'necesidadesProducto'?: string;
}
/**
 * (tsType: ProductoDatosCatalogoWithRelations, schemaOptions: { includeRelations: true })
 * @export
 * @interface ProductoDatosCatalogoWithRelations
 */
export interface ProductoDatosCatalogoWithRelations {
    /**
     * 
     * @type {number}
     * @memberof ProductoDatosCatalogoWithRelations
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductoDatosCatalogoWithRelations
     */
    'productoId': number;
    /**
     * 
     * @type {string}
     * @memberof ProductoDatosCatalogoWithRelations
     */
    'codigoFabricante'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductoDatosCatalogoWithRelations
     */
    'skuProvisional'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductoDatosCatalogoWithRelations
     */
    'capacidadCarga'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductoDatosCatalogoWithRelations
     */
    'contenedor'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductoDatosCatalogoWithRelations
     */
    'precioFabrica'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductoDatosCatalogoWithRelations
     */
    'costoAlmacen'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductoDatosCatalogoWithRelations
     */
    'costeEstandar'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductoDatosCatalogoWithRelations
     */
    'envio'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductoDatosCatalogoWithRelations
     */
    'arancel'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductoDatosCatalogoWithRelations
     */
    'detallesProducto'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductoDatosCatalogoWithRelations
     */
    'necesidadesProducto'?: string;
}
/**
 * 
 * @export
 * @interface ProductoGeneral
 */
export interface ProductoGeneral {
    /**
     * 
     * @type {number}
     * @memberof ProductoGeneral
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductoGeneral
     */
    'productoId': number;
    /**
     * 
     * @type {string}
     * @memberof ProductoGeneral
     */
    'sku'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductoGeneral
     */
    'ean'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductoGeneral
     */
    'marca'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductoGeneral
     */
    'estadoReferencia'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductoGeneral
     */
    'descripcion'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductoGeneral
     */
    'titulo'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductoGeneral
     */
    'descripcionLarga'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductoGeneral
     */
    'palabrasClave'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductoGeneral
     */
    'GLN'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductoGeneral
     */
    'dimensiones'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductoGeneral
     */
    'formatoRP'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductoGeneral
     */
    'color'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductoGeneral
     */
    'mercados'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductoGeneral
     */
    'fechaModificacion'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductoGeneral
     */
    'referenciaAgrupacion'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductoGeneral
     */
    'descripcionAecoc'?: string;
}
/**
 * 
 * @export
 * @interface ProductoGeneralFilter
 */
export interface ProductoGeneralFilter {
    /**
     * 
     * @type {number}
     * @memberof ProductoGeneralFilter
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductoGeneralFilter
     */
    'limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductoGeneralFilter
     */
    'skip'?: number;
    /**
     * 
     * @type {string | Array<string>}
     * @memberof ProductoGeneralFilter
     */
    'order'?: string | Array<string>;
    /**
     * 
     * @type {object | Set<string>}
     * @memberof ProductoGeneralFilter
     */
    'fields'?: object | Set<string>;
}
/**
 * 
 * @export
 * @interface ProductoGeneralFilter1
 */
export interface ProductoGeneralFilter1 {
    /**
     * 
     * @type {number}
     * @memberof ProductoGeneralFilter1
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductoGeneralFilter1
     */
    'limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductoGeneralFilter1
     */
    'skip'?: number;
    /**
     * 
     * @type {string | Array<string>}
     * @memberof ProductoGeneralFilter1
     */
    'order'?: string | Array<string>;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof ProductoGeneralFilter1
     */
    'where'?: { [key: string]: object; };
    /**
     * 
     * @type {object | Set<string>}
     * @memberof ProductoGeneralFilter1
     */
    'fields'?: object | Set<string>;
}
/**
 * (tsType: Partial<ProductoGeneral>, schemaOptions: { partial: true })
 * @export
 * @interface ProductoGeneralPartial
 */
export interface ProductoGeneralPartial {
    /**
     * 
     * @type {number}
     * @memberof ProductoGeneralPartial
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductoGeneralPartial
     */
    'productoId'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductoGeneralPartial
     */
    'sku'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductoGeneralPartial
     */
    'ean'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductoGeneralPartial
     */
    'marca'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductoGeneralPartial
     */
    'estadoReferencia'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductoGeneralPartial
     */
    'descripcion'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductoGeneralPartial
     */
    'titulo'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductoGeneralPartial
     */
    'descripcionLarga'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductoGeneralPartial
     */
    'palabrasClave'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductoGeneralPartial
     */
    'GLN'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductoGeneralPartial
     */
    'dimensiones'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductoGeneralPartial
     */
    'formatoRP'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductoGeneralPartial
     */
    'color'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductoGeneralPartial
     */
    'mercados'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductoGeneralPartial
     */
    'fechaModificacion'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductoGeneralPartial
     */
    'referenciaAgrupacion'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductoGeneralPartial
     */
    'descripcionAecoc'?: string;
}
/**
 * (tsType: ProductoGeneralWithRelations, schemaOptions: { includeRelations: true })
 * @export
 * @interface ProductoGeneralWithRelations
 */
export interface ProductoGeneralWithRelations {
    /**
     * 
     * @type {number}
     * @memberof ProductoGeneralWithRelations
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductoGeneralWithRelations
     */
    'productoId': number;
    /**
     * 
     * @type {string}
     * @memberof ProductoGeneralWithRelations
     */
    'sku'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductoGeneralWithRelations
     */
    'ean'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductoGeneralWithRelations
     */
    'marca'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductoGeneralWithRelations
     */
    'estadoReferencia'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductoGeneralWithRelations
     */
    'descripcion'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductoGeneralWithRelations
     */
    'titulo'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductoGeneralWithRelations
     */
    'descripcionLarga'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductoGeneralWithRelations
     */
    'palabrasClave'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductoGeneralWithRelations
     */
    'GLN'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductoGeneralWithRelations
     */
    'dimensiones'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductoGeneralWithRelations
     */
    'formatoRP'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductoGeneralWithRelations
     */
    'color'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductoGeneralWithRelations
     */
    'mercados'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductoGeneralWithRelations
     */
    'fechaModificacion'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductoGeneralWithRelations
     */
    'referenciaAgrupacion'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductoGeneralWithRelations
     */
    'descripcionAecoc'?: string;
}
/**
 * 
 * @export
 * @interface ProductoIconos
 */
export interface ProductoIconos {
    /**
     * 
     * @type {number}
     * @memberof ProductoIconos
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductoIconos
     */
    'idIcono': number;
    /**
     * 
     * @type {number}
     * @memberof ProductoIconos
     */
    'idProducto': number;
    /**
     * 
     * @type {string}
     * @memberof ProductoIconos
     */
    'texto'?: string;
}
/**
 * 
 * @export
 * @interface ProductoIconosFilter
 */
export interface ProductoIconosFilter {
    /**
     * 
     * @type {number}
     * @memberof ProductoIconosFilter
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductoIconosFilter
     */
    'limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductoIconosFilter
     */
    'skip'?: number;
    /**
     * 
     * @type {string | Array<string>}
     * @memberof ProductoIconosFilter
     */
    'order'?: string | Array<string>;
    /**
     * 
     * @type {object | Set<string>}
     * @memberof ProductoIconosFilter
     */
    'fields'?: object | Set<string>;
}
/**
 * 
 * @export
 * @interface ProductoIconosFilter1
 */
export interface ProductoIconosFilter1 {
    /**
     * 
     * @type {number}
     * @memberof ProductoIconosFilter1
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductoIconosFilter1
     */
    'limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductoIconosFilter1
     */
    'skip'?: number;
    /**
     * 
     * @type {string | Array<string>}
     * @memberof ProductoIconosFilter1
     */
    'order'?: string | Array<string>;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof ProductoIconosFilter1
     */
    'where'?: { [key: string]: object; };
    /**
     * 
     * @type {object | Set<string>}
     * @memberof ProductoIconosFilter1
     */
    'fields'?: object | Set<string>;
}
/**
 * (tsType: Partial<ProductoIconos>, schemaOptions: { partial: true })
 * @export
 * @interface ProductoIconosPartial
 */
export interface ProductoIconosPartial {
    /**
     * 
     * @type {number}
     * @memberof ProductoIconosPartial
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductoIconosPartial
     */
    'idIcono'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductoIconosPartial
     */
    'idProducto'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductoIconosPartial
     */
    'texto'?: string;
}
/**
 * (tsType: ProductoIconosWithRelations, schemaOptions: { includeRelations: true })
 * @export
 * @interface ProductoIconosWithRelations
 */
export interface ProductoIconosWithRelations {
    /**
     * 
     * @type {number}
     * @memberof ProductoIconosWithRelations
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductoIconosWithRelations
     */
    'idIcono': number;
    /**
     * 
     * @type {number}
     * @memberof ProductoIconosWithRelations
     */
    'idProducto': number;
    /**
     * 
     * @type {string}
     * @memberof ProductoIconosWithRelations
     */
    'texto'?: string;
}
/**
 * 
 * @export
 * @interface ProductoMultimedia
 */
export interface ProductoMultimedia {
    /**
     * 
     * @type {number}
     * @memberof ProductoMultimedia
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductoMultimedia
     */
    'productoId': number;
    /**
     * 
     * @type {string}
     * @memberof ProductoMultimedia
     */
    'tipo'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductoMultimedia
     */
    'fichero'?: string;
    /**
     * 
     * @type {number}
     * @memberof ProductoMultimedia
     */
    'multimediaId': number;
    /**
     * 
     * @type {string}
     * @memberof ProductoMultimedia
     */
    'nombre'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductoMultimedia
     */
    'categoria'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductoMultimedia
     */
    'publicoSN'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductoMultimedia
     */
    'principalSN'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductoMultimedia
     */
    'formato'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductoMultimedia
     */
    'objetivo'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductoMultimedia
     */
    'tagAecoc'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductoMultimedia
     */
    'fechaModificacion'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductoMultimedia
     */
    'fechaCreacion'?: string;
}
/**
 * (tsType: ProductoMultimedia, schemaOptions: { includeRelations: false })
 * @export
 * @interface ProductoMultimedia1
 */
export interface ProductoMultimedia1 {
    /**
     * 
     * @type {number}
     * @memberof ProductoMultimedia1
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductoMultimedia1
     */
    'productoId': number;
    /**
     * 
     * @type {string}
     * @memberof ProductoMultimedia1
     */
    'tipo'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductoMultimedia1
     */
    'fichero'?: string;
    /**
     * 
     * @type {number}
     * @memberof ProductoMultimedia1
     */
    'multimediaId': number;
    /**
     * 
     * @type {string}
     * @memberof ProductoMultimedia1
     */
    'nombre'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductoMultimedia1
     */
    'categoria'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductoMultimedia1
     */
    'publicoSN'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductoMultimedia1
     */
    'principalSN'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductoMultimedia1
     */
    'formato'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductoMultimedia1
     */
    'objetivo'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductoMultimedia1
     */
    'tagAecoc'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductoMultimedia1
     */
    'fechaModificacion'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductoMultimedia1
     */
    'fechaCreacion'?: string;
}
/**
 * 
 * @export
 * @interface ProductoMultimediaFilter
 */
export interface ProductoMultimediaFilter {
    /**
     * 
     * @type {number}
     * @memberof ProductoMultimediaFilter
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductoMultimediaFilter
     */
    'limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductoMultimediaFilter
     */
    'skip'?: number;
    /**
     * 
     * @type {string | Array<string>}
     * @memberof ProductoMultimediaFilter
     */
    'order'?: string | Array<string>;
    /**
     * 
     * @type {object | Set<string>}
     * @memberof ProductoMultimediaFilter
     */
    'fields'?: object | Set<string>;
}
/**
 * 
 * @export
 * @interface ProductoMultimediaFilter1
 */
export interface ProductoMultimediaFilter1 {
    /**
     * 
     * @type {number}
     * @memberof ProductoMultimediaFilter1
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductoMultimediaFilter1
     */
    'limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductoMultimediaFilter1
     */
    'skip'?: number;
    /**
     * 
     * @type {string | Array<string>}
     * @memberof ProductoMultimediaFilter1
     */
    'order'?: string | Array<string>;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof ProductoMultimediaFilter1
     */
    'where'?: { [key: string]: object; };
    /**
     * 
     * @type {object | Set<string>}
     * @memberof ProductoMultimediaFilter1
     */
    'fields'?: object | Set<string>;
}
/**
 * (tsType: Partial<ProductoMultimedia>, schemaOptions: { partial: true })
 * @export
 * @interface ProductoMultimediaPartial
 */
export interface ProductoMultimediaPartial {
    /**
     * 
     * @type {number}
     * @memberof ProductoMultimediaPartial
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductoMultimediaPartial
     */
    'productoId'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductoMultimediaPartial
     */
    'tipo'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductoMultimediaPartial
     */
    'fichero'?: string;
    /**
     * 
     * @type {number}
     * @memberof ProductoMultimediaPartial
     */
    'multimediaId'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductoMultimediaPartial
     */
    'nombre'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductoMultimediaPartial
     */
    'categoria'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductoMultimediaPartial
     */
    'publicoSN'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductoMultimediaPartial
     */
    'principalSN'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductoMultimediaPartial
     */
    'formato'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductoMultimediaPartial
     */
    'objetivo'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductoMultimediaPartial
     */
    'tagAecoc'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductoMultimediaPartial
     */
    'fechaModificacion'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductoMultimediaPartial
     */
    'fechaCreacion'?: string;
}
/**
 * (tsType: ProductoMultimediaWithRelations, schemaOptions: { includeRelations: true })
 * @export
 * @interface ProductoMultimediaWithRelations
 */
export interface ProductoMultimediaWithRelations {
    /**
     * 
     * @type {number}
     * @memberof ProductoMultimediaWithRelations
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductoMultimediaWithRelations
     */
    'productoId': number;
    /**
     * 
     * @type {string}
     * @memberof ProductoMultimediaWithRelations
     */
    'tipo'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductoMultimediaWithRelations
     */
    'fichero'?: string;
    /**
     * 
     * @type {number}
     * @memberof ProductoMultimediaWithRelations
     */
    'multimediaId': number;
    /**
     * 
     * @type {string}
     * @memberof ProductoMultimediaWithRelations
     */
    'nombre'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductoMultimediaWithRelations
     */
    'categoria'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductoMultimediaWithRelations
     */
    'publicoSN'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductoMultimediaWithRelations
     */
    'principalSN'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductoMultimediaWithRelations
     */
    'formato'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductoMultimediaWithRelations
     */
    'objetivo'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductoMultimediaWithRelations
     */
    'tagAecoc'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductoMultimediaWithRelations
     */
    'fechaModificacion'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductoMultimediaWithRelations
     */
    'fechaCreacion'?: string;
}
/**
 * 
 * @export
 * @interface Productos
 */
export interface Productos {
    /**
     * 
     * @type {number}
     * @memberof Productos
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof Productos
     */
    'empresaId': number;
    /**
     * 
     * @type {string}
     * @memberof Productos
     */
    'nombre'?: string;
    /**
     * 
     * @type {string}
     * @memberof Productos
     */
    'imagen'?: string;
    /**
     * 
     * @type {string}
     * @memberof Productos
     */
    'categorias'?: string;
    /**
     * 
     * @type {string}
     * @memberof Productos
     */
    'familias'?: string;
    /**
     * 
     * @type {string}
     * @memberof Productos
     */
    'finalizado'?: string;
    /**
     * 
     * @type {string}
     * @memberof Productos
     */
    'ordenAtributos'?: string;
    /**
     * 
     * @type {string}
     * @memberof Productos
     */
    'finalizadoSAT'?: string;
    /**
     * 
     * @type {string}
     * @memberof Productos
     */
    'enviarAecoc'?: string;
    /**
     * 
     * @type {string}
     * @memberof Productos
     */
    'sincronizarConBC'?: string;
    /**
     * 
     * @type {string}
     * @memberof Productos
     */
    'fechaSincronizacionConBC'?: string;
    /**
     * 
     * @type {string}
     * @memberof Productos
     */
    'fechaEjecucionDeProcesoSincroBC'?: string;
    /**
     * 
     * @type {string}
     * @memberof Productos
     */
    'motivoErrorSincroConBC'?: string;
    /**
     * 
     * @type {string}
     * @memberof Productos
     */
    'ensamblado'?: string;
    /**
     * 
     * @type {string}
     * @memberof Productos
     */
    'fechaModificacion'?: string;
    /**
     * 
     * @type {string}
     * @memberof Productos
     */
    'FechaModificacionparaAecoc'?: string;
    /**
     * 
     * @type {string}
     * @memberof Productos
     */
    'fechaCreacion'?: string;
    /**
     * 
     * @type {string}
     * @memberof Productos
     */
    'activoEnMarketplace'?: string;
}
/**
 * 
 * @export
 * @interface ProductosFilter
 */
export interface ProductosFilter {
    /**
     * 
     * @type {number}
     * @memberof ProductosFilter
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductosFilter
     */
    'limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductosFilter
     */
    'skip'?: number;
    /**
     * 
     * @type {string | Array<string>}
     * @memberof ProductosFilter
     */
    'order'?: string | Array<string>;
    /**
     * 
     * @type {object | Set<string>}
     * @memberof ProductosFilter
     */
    'fields'?: object | Set<string>;
}
/**
 * 
 * @export
 * @interface ProductosFilter1
 */
export interface ProductosFilter1 {
    /**
     * 
     * @type {number}
     * @memberof ProductosFilter1
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductosFilter1
     */
    'limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductosFilter1
     */
    'skip'?: number;
    /**
     * 
     * @type {string | Array<string>}
     * @memberof ProductosFilter1
     */
    'order'?: string | Array<string>;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof ProductosFilter1
     */
    'where'?: { [key: string]: object; };
    /**
     * 
     * @type {object | Set<string>}
     * @memberof ProductosFilter1
     */
    'fields'?: object | Set<string>;
}
/**
 * (tsType: Partial<Productos>, schemaOptions: { partial: true })
 * @export
 * @interface ProductosPartial
 */
export interface ProductosPartial {
    /**
     * 
     * @type {number}
     * @memberof ProductosPartial
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductosPartial
     */
    'empresaId'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductosPartial
     */
    'nombre'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductosPartial
     */
    'imagen'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductosPartial
     */
    'categorias'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductosPartial
     */
    'familias'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductosPartial
     */
    'finalizado'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductosPartial
     */
    'ordenAtributos'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductosPartial
     */
    'finalizadoSAT'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductosPartial
     */
    'enviarAecoc'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductosPartial
     */
    'sincronizarConBC'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductosPartial
     */
    'fechaSincronizacionConBC'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductosPartial
     */
    'fechaEjecucionDeProcesoSincroBC'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductosPartial
     */
    'motivoErrorSincroConBC'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductosPartial
     */
    'ensamblado'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductosPartial
     */
    'fechaModificacion'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductosPartial
     */
    'FechaModificacionparaAecoc'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductosPartial
     */
    'fechaCreacion'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductosPartial
     */
    'activoEnMarketplace'?: string;
}
/**
 * 
 * @export
 * @interface ProductosPorRango
 */
export interface ProductosPorRango {
    /**
     * 
     * @type {number}
     * @memberof ProductosPorRango
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductosPorRango
     */
    'skuProducto'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductosPorRango
     */
    'desdeFecha'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductosPorRango
     */
    'hastaFecha'?: string;
    /**
     * 
     * @type {number}
     * @memberof ProductosPorRango
     */
    'rangoId': number;
    /**
     * 
     * @type {number}
     * @memberof ProductosPorRango
     */
    'clubId': number;
}
/**
 * 
 * @export
 * @interface ProductosPorRangoFilter
 */
export interface ProductosPorRangoFilter {
    /**
     * 
     * @type {number}
     * @memberof ProductosPorRangoFilter
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductosPorRangoFilter
     */
    'limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductosPorRangoFilter
     */
    'skip'?: number;
    /**
     * 
     * @type {string | Array<string>}
     * @memberof ProductosPorRangoFilter
     */
    'order'?: string | Array<string>;
    /**
     * 
     * @type {object | Set<string>}
     * @memberof ProductosPorRangoFilter
     */
    'fields'?: object | Set<string>;
}
/**
 * 
 * @export
 * @interface ProductosPorRangoFilter1
 */
export interface ProductosPorRangoFilter1 {
    /**
     * 
     * @type {number}
     * @memberof ProductosPorRangoFilter1
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductosPorRangoFilter1
     */
    'limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductosPorRangoFilter1
     */
    'skip'?: number;
    /**
     * 
     * @type {string | Array<string>}
     * @memberof ProductosPorRangoFilter1
     */
    'order'?: string | Array<string>;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof ProductosPorRangoFilter1
     */
    'where'?: { [key: string]: object; };
    /**
     * 
     * @type {object | Set<string>}
     * @memberof ProductosPorRangoFilter1
     */
    'fields'?: object | Set<string>;
}
/**
 * (tsType: Partial<ProductosPorRango>, schemaOptions: { partial: true })
 * @export
 * @interface ProductosPorRangoPartial
 */
export interface ProductosPorRangoPartial {
    /**
     * 
     * @type {number}
     * @memberof ProductosPorRangoPartial
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductosPorRangoPartial
     */
    'skuProducto'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductosPorRangoPartial
     */
    'desdeFecha'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductosPorRangoPartial
     */
    'hastaFecha'?: string;
    /**
     * 
     * @type {number}
     * @memberof ProductosPorRangoPartial
     */
    'rangoId'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductosPorRangoPartial
     */
    'clubId'?: number;
}
/**
 * (tsType: ProductosPorRangoWithRelations, schemaOptions: { includeRelations: true })
 * @export
 * @interface ProductosPorRangoWithRelations
 */
export interface ProductosPorRangoWithRelations {
    /**
     * 
     * @type {number}
     * @memberof ProductosPorRangoWithRelations
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductosPorRangoWithRelations
     */
    'skuProducto'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductosPorRangoWithRelations
     */
    'desdeFecha'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductosPorRangoWithRelations
     */
    'hastaFecha'?: string;
    /**
     * 
     * @type {number}
     * @memberof ProductosPorRangoWithRelations
     */
    'rangoId': number;
    /**
     * 
     * @type {number}
     * @memberof ProductosPorRangoWithRelations
     */
    'clubId': number;
}
/**
 * (tsType: ProductosWithRelations, schemaOptions: { includeRelations: true })
 * @export
 * @interface ProductosWithRelations
 */
export interface ProductosWithRelations {
    /**
     * 
     * @type {number}
     * @memberof ProductosWithRelations
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductosWithRelations
     */
    'empresaId': number;
    /**
     * 
     * @type {string}
     * @memberof ProductosWithRelations
     */
    'nombre'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductosWithRelations
     */
    'imagen'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductosWithRelations
     */
    'categorias'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductosWithRelations
     */
    'familias'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductosWithRelations
     */
    'finalizado'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductosWithRelations
     */
    'ordenAtributos'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductosWithRelations
     */
    'finalizadoSAT'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductosWithRelations
     */
    'enviarAecoc'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductosWithRelations
     */
    'sincronizarConBC'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductosWithRelations
     */
    'fechaSincronizacionConBC'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductosWithRelations
     */
    'fechaEjecucionDeProcesoSincroBC'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductosWithRelations
     */
    'motivoErrorSincroConBC'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductosWithRelations
     */
    'ensamblado'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductosWithRelations
     */
    'fechaModificacion'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductosWithRelations
     */
    'FechaModificacionparaAecoc'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductosWithRelations
     */
    'fechaCreacion'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductosWithRelations
     */
    'activoEnMarketplace'?: string;
}
/**
 * 
 * @export
 * @interface Repuestos
 */
export interface Repuestos {
    /**
     * 
     * @type {number}
     * @memberof Repuestos
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof Repuestos
     */
    'skuPrincipal'?: string;
    /**
     * 
     * @type {string}
     * @memberof Repuestos
     */
    'skuRepuesto'?: string;
    /**
     * 
     * @type {string}
     * @memberof Repuestos
     */
    'descripcion'?: string;
    /**
     * 
     * @type {string}
     * @memberof Repuestos
     */
    'posicion'?: string;
    /**
     * 
     * @type {string}
     * @memberof Repuestos
     */
    'fechaInicio'?: string;
    /**
     * 
     * @type {string}
     * @memberof Repuestos
     */
    'fechaFin'?: string;
    /**
     * 
     * @type {string}
     * @memberof Repuestos
     */
    'fechaModificacion'?: string;
    /**
     * 
     * @type {string}
     * @memberof Repuestos
     */
    'fechaCreacion'?: string;
    /**
     * 
     * @type {string}
     * @memberof Repuestos
     */
    'fechaSincronizacionConBC'?: string;
    /**
     * 
     * @type {string}
     * @memberof Repuestos
     */
    'fechaEjecucionDeProcesoSincroBC'?: string;
    /**
     * 
     * @type {string}
     * @memberof Repuestos
     */
    'motivoErrorSincroConBC'?: string;
}
/**
 * 
 * @export
 * @interface RepuestosFilter
 */
export interface RepuestosFilter {
    /**
     * 
     * @type {number}
     * @memberof RepuestosFilter
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof RepuestosFilter
     */
    'limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof RepuestosFilter
     */
    'skip'?: number;
    /**
     * 
     * @type {string | Array<string>}
     * @memberof RepuestosFilter
     */
    'order'?: string | Array<string>;
    /**
     * 
     * @type {object | Set<string>}
     * @memberof RepuestosFilter
     */
    'fields'?: object | Set<string>;
}
/**
 * 
 * @export
 * @interface RepuestosFilter1
 */
export interface RepuestosFilter1 {
    /**
     * 
     * @type {number}
     * @memberof RepuestosFilter1
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof RepuestosFilter1
     */
    'limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof RepuestosFilter1
     */
    'skip'?: number;
    /**
     * 
     * @type {string | Array<string>}
     * @memberof RepuestosFilter1
     */
    'order'?: string | Array<string>;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof RepuestosFilter1
     */
    'where'?: { [key: string]: object; };
    /**
     * 
     * @type {object | Set<string>}
     * @memberof RepuestosFilter1
     */
    'fields'?: object | Set<string>;
}
/**
 * (tsType: Partial<Repuestos>, schemaOptions: { partial: true })
 * @export
 * @interface RepuestosPartial
 */
export interface RepuestosPartial {
    /**
     * 
     * @type {number}
     * @memberof RepuestosPartial
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof RepuestosPartial
     */
    'skuPrincipal'?: string;
    /**
     * 
     * @type {string}
     * @memberof RepuestosPartial
     */
    'skuRepuesto'?: string;
    /**
     * 
     * @type {string}
     * @memberof RepuestosPartial
     */
    'descripcion'?: string;
    /**
     * 
     * @type {string}
     * @memberof RepuestosPartial
     */
    'posicion'?: string;
    /**
     * 
     * @type {string}
     * @memberof RepuestosPartial
     */
    'fechaInicio'?: string;
    /**
     * 
     * @type {string}
     * @memberof RepuestosPartial
     */
    'fechaFin'?: string;
    /**
     * 
     * @type {string}
     * @memberof RepuestosPartial
     */
    'fechaModificacion'?: string;
    /**
     * 
     * @type {string}
     * @memberof RepuestosPartial
     */
    'fechaCreacion'?: string;
    /**
     * 
     * @type {string}
     * @memberof RepuestosPartial
     */
    'fechaSincronizacionConBC'?: string;
    /**
     * 
     * @type {string}
     * @memberof RepuestosPartial
     */
    'fechaEjecucionDeProcesoSincroBC'?: string;
    /**
     * 
     * @type {string}
     * @memberof RepuestosPartial
     */
    'motivoErrorSincroConBC'?: string;
}
/**
 * (tsType: RepuestosWithRelations, schemaOptions: { includeRelations: true })
 * @export
 * @interface RepuestosWithRelations
 */
export interface RepuestosWithRelations {
    /**
     * 
     * @type {number}
     * @memberof RepuestosWithRelations
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof RepuestosWithRelations
     */
    'skuPrincipal'?: string;
    /**
     * 
     * @type {string}
     * @memberof RepuestosWithRelations
     */
    'skuRepuesto'?: string;
    /**
     * 
     * @type {string}
     * @memberof RepuestosWithRelations
     */
    'descripcion'?: string;
    /**
     * 
     * @type {string}
     * @memberof RepuestosWithRelations
     */
    'posicion'?: string;
    /**
     * 
     * @type {string}
     * @memberof RepuestosWithRelations
     */
    'fechaInicio'?: string;
    /**
     * 
     * @type {string}
     * @memberof RepuestosWithRelations
     */
    'fechaFin'?: string;
    /**
     * 
     * @type {string}
     * @memberof RepuestosWithRelations
     */
    'fechaModificacion'?: string;
    /**
     * 
     * @type {string}
     * @memberof RepuestosWithRelations
     */
    'fechaCreacion'?: string;
    /**
     * 
     * @type {string}
     * @memberof RepuestosWithRelations
     */
    'fechaSincronizacionConBC'?: string;
    /**
     * 
     * @type {string}
     * @memberof RepuestosWithRelations
     */
    'fechaEjecucionDeProcesoSincroBC'?: string;
    /**
     * 
     * @type {string}
     * @memberof RepuestosWithRelations
     */
    'motivoErrorSincroConBC'?: string;
}
/**
 * 
 * @export
 * @interface Roles
 */
export interface Roles {
    /**
     * 
     * @type {number}
     * @memberof Roles
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof Roles
     */
    'nombre'?: string;
    /**
     * 
     * @type {string}
     * @memberof Roles
     */
    'activo'?: string;
}
/**
 * 
 * @export
 * @interface RolesFilter
 */
export interface RolesFilter {
    /**
     * 
     * @type {number}
     * @memberof RolesFilter
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof RolesFilter
     */
    'limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof RolesFilter
     */
    'skip'?: number;
    /**
     * 
     * @type {string | Array<string>}
     * @memberof RolesFilter
     */
    'order'?: string | Array<string>;
    /**
     * 
     * @type {object | Set<string>}
     * @memberof RolesFilter
     */
    'fields'?: object | Set<string>;
    /**
     * 
     * @type {Array<RolesIncludeFilterItems | string>}
     * @memberof RolesFilter
     */
    'include'?: Array<RolesIncludeFilterItems | string>;
}
/**
 * 
 * @export
 * @interface RolesFilter1
 */
export interface RolesFilter1 {
    /**
     * 
     * @type {number}
     * @memberof RolesFilter1
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof RolesFilter1
     */
    'limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof RolesFilter1
     */
    'skip'?: number;
    /**
     * 
     * @type {string | Array<string>}
     * @memberof RolesFilter1
     */
    'order'?: string | Array<string>;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof RolesFilter1
     */
    'where'?: { [key: string]: object; };
    /**
     * 
     * @type {object | Set<string>}
     * @memberof RolesFilter1
     */
    'fields'?: object | Set<string>;
    /**
     * 
     * @type {Array<RolesIncludeFilterItems | string>}
     * @memberof RolesFilter1
     */
    'include'?: Array<RolesIncludeFilterItems | string>;
}
/**
 * 
 * @export
 * @interface RolesIncludeFilterItems
 */
export interface RolesIncludeFilterItems {
    /**
     * 
     * @type {string}
     * @memberof RolesIncludeFilterItems
     */
    'relation'?: string;
    /**
     * 
     * @type {RolesScopeFilter}
     * @memberof RolesIncludeFilterItems
     */
    'scope'?: RolesScopeFilter;
}
/**
 * (tsType: Partial<Roles>, schemaOptions: { partial: true })
 * @export
 * @interface RolesPartial
 */
export interface RolesPartial {
    /**
     * 
     * @type {number}
     * @memberof RolesPartial
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof RolesPartial
     */
    'nombre'?: string;
    /**
     * 
     * @type {string}
     * @memberof RolesPartial
     */
    'activo'?: string;
}
/**
 * 
 * @export
 * @interface RolesScopeFilter
 */
export interface RolesScopeFilter {
    /**
     * 
     * @type {number}
     * @memberof RolesScopeFilter
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof RolesScopeFilter
     */
    'limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof RolesScopeFilter
     */
    'skip'?: number;
    /**
     * 
     * @type {string | Array<string>}
     * @memberof RolesScopeFilter
     */
    'order'?: string | Array<string>;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof RolesScopeFilter
     */
    'where'?: { [key: string]: object; };
    /**
     * 
     * @type {any | Set<string>}
     * @memberof RolesScopeFilter
     */
    'fields'?: any | Set<string>;
    /**
     * 
     * @type {Array<{ [key: string]: object; }>}
     * @memberof RolesScopeFilter
     */
    'include'?: Array<{ [key: string]: object; }>;
}
/**
 * (tsType: RolesWithRelations, schemaOptions: { includeRelations: true })
 * @export
 * @interface RolesWithRelations
 */
export interface RolesWithRelations {
    /**
     * 
     * @type {number}
     * @memberof RolesWithRelations
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof RolesWithRelations
     */
    'nombre'?: string;
    /**
     * 
     * @type {string}
     * @memberof RolesWithRelations
     */
    'activo'?: string;
    /**
     * 
     * @type {Array<PermisosWithRelations>}
     * @memberof RolesWithRelations
     */
    'permisos'?: Array<PermisosWithRelations>;
}
/**
 * 
 * @export
 * @interface Situaciones
 */
export interface Situaciones {
    /**
     * 
     * @type {number}
     * @memberof Situaciones
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof Situaciones
     */
    'nombre'?: string;
}
/**
 * 
 * @export
 * @interface SituacionesFilter
 */
export interface SituacionesFilter {
    /**
     * 
     * @type {number}
     * @memberof SituacionesFilter
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof SituacionesFilter
     */
    'limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof SituacionesFilter
     */
    'skip'?: number;
    /**
     * 
     * @type {string | Array<string>}
     * @memberof SituacionesFilter
     */
    'order'?: string | Array<string>;
    /**
     * 
     * @type {object | Set<string>}
     * @memberof SituacionesFilter
     */
    'fields'?: object | Set<string>;
}
/**
 * 
 * @export
 * @interface SituacionesFilter1
 */
export interface SituacionesFilter1 {
    /**
     * 
     * @type {number}
     * @memberof SituacionesFilter1
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof SituacionesFilter1
     */
    'limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof SituacionesFilter1
     */
    'skip'?: number;
    /**
     * 
     * @type {string | Array<string>}
     * @memberof SituacionesFilter1
     */
    'order'?: string | Array<string>;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof SituacionesFilter1
     */
    'where'?: { [key: string]: object; };
    /**
     * 
     * @type {object | Set<string>}
     * @memberof SituacionesFilter1
     */
    'fields'?: object | Set<string>;
}
/**
 * (tsType: Partial<Situaciones>, schemaOptions: { partial: true })
 * @export
 * @interface SituacionesPartial
 */
export interface SituacionesPartial {
    /**
     * 
     * @type {number}
     * @memberof SituacionesPartial
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof SituacionesPartial
     */
    'nombre'?: string;
}
/**
 * (tsType: SituacionesWithRelations, schemaOptions: { includeRelations: true })
 * @export
 * @interface SituacionesWithRelations
 */
export interface SituacionesWithRelations {
    /**
     * 
     * @type {number}
     * @memberof SituacionesWithRelations
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof SituacionesWithRelations
     */
    'nombre'?: string;
}
/**
 * 
 * @export
 * @interface Sustitutos
 */
export interface Sustitutos {
    /**
     * 
     * @type {number}
     * @memberof Sustitutos
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof Sustitutos
     */
    'skuPrincipal'?: string;
    /**
     * 
     * @type {string}
     * @memberof Sustitutos
     */
    'skuSustituto'?: string;
    /**
     * 
     * @type {string}
     * @memberof Sustitutos
     */
    'descripcion'?: string;
    /**
     * 
     * @type {string}
     * @memberof Sustitutos
     */
    'intercambiable'?: string;
    /**
     * 
     * @type {string}
     * @memberof Sustitutos
     */
    'fechaInicio'?: string;
    /**
     * 
     * @type {string}
     * @memberof Sustitutos
     */
    'fechaFin'?: string;
    /**
     * 
     * @type {string}
     * @memberof Sustitutos
     */
    'fechaModificacion'?: string;
    /**
     * 
     * @type {string}
     * @memberof Sustitutos
     */
    'fechaCreacion'?: string;
    /**
     * 
     * @type {string}
     * @memberof Sustitutos
     */
    'fechaSincronizacionConBC'?: string;
    /**
     * 
     * @type {string}
     * @memberof Sustitutos
     */
    'fechaEjecucionDeProcesoSincroBC'?: string;
    /**
     * 
     * @type {string}
     * @memberof Sustitutos
     */
    'motivoErrorSincroConBC'?: string;
}
/**
 * 
 * @export
 * @interface SustitutosFilter
 */
export interface SustitutosFilter {
    /**
     * 
     * @type {number}
     * @memberof SustitutosFilter
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof SustitutosFilter
     */
    'limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof SustitutosFilter
     */
    'skip'?: number;
    /**
     * 
     * @type {string | Array<string>}
     * @memberof SustitutosFilter
     */
    'order'?: string | Array<string>;
    /**
     * 
     * @type {object | Set<string>}
     * @memberof SustitutosFilter
     */
    'fields'?: object | Set<string>;
}
/**
 * 
 * @export
 * @interface SustitutosFilter1
 */
export interface SustitutosFilter1 {
    /**
     * 
     * @type {number}
     * @memberof SustitutosFilter1
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof SustitutosFilter1
     */
    'limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof SustitutosFilter1
     */
    'skip'?: number;
    /**
     * 
     * @type {string | Array<string>}
     * @memberof SustitutosFilter1
     */
    'order'?: string | Array<string>;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof SustitutosFilter1
     */
    'where'?: { [key: string]: object; };
    /**
     * 
     * @type {object | Set<string>}
     * @memberof SustitutosFilter1
     */
    'fields'?: object | Set<string>;
}
/**
 * (tsType: Partial<Sustitutos>, schemaOptions: { partial: true })
 * @export
 * @interface SustitutosPartial
 */
export interface SustitutosPartial {
    /**
     * 
     * @type {number}
     * @memberof SustitutosPartial
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof SustitutosPartial
     */
    'skuPrincipal'?: string;
    /**
     * 
     * @type {string}
     * @memberof SustitutosPartial
     */
    'skuSustituto'?: string;
    /**
     * 
     * @type {string}
     * @memberof SustitutosPartial
     */
    'descripcion'?: string;
    /**
     * 
     * @type {string}
     * @memberof SustitutosPartial
     */
    'intercambiable'?: string;
    /**
     * 
     * @type {string}
     * @memberof SustitutosPartial
     */
    'fechaInicio'?: string;
    /**
     * 
     * @type {string}
     * @memberof SustitutosPartial
     */
    'fechaFin'?: string;
    /**
     * 
     * @type {string}
     * @memberof SustitutosPartial
     */
    'fechaModificacion'?: string;
    /**
     * 
     * @type {string}
     * @memberof SustitutosPartial
     */
    'fechaCreacion'?: string;
    /**
     * 
     * @type {string}
     * @memberof SustitutosPartial
     */
    'fechaSincronizacionConBC'?: string;
    /**
     * 
     * @type {string}
     * @memberof SustitutosPartial
     */
    'fechaEjecucionDeProcesoSincroBC'?: string;
    /**
     * 
     * @type {string}
     * @memberof SustitutosPartial
     */
    'motivoErrorSincroConBC'?: string;
}
/**
 * (tsType: SustitutosWithRelations, schemaOptions: { includeRelations: true })
 * @export
 * @interface SustitutosWithRelations
 */
export interface SustitutosWithRelations {
    /**
     * 
     * @type {number}
     * @memberof SustitutosWithRelations
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof SustitutosWithRelations
     */
    'skuPrincipal'?: string;
    /**
     * 
     * @type {string}
     * @memberof SustitutosWithRelations
     */
    'skuSustituto'?: string;
    /**
     * 
     * @type {string}
     * @memberof SustitutosWithRelations
     */
    'descripcion'?: string;
    /**
     * 
     * @type {string}
     * @memberof SustitutosWithRelations
     */
    'intercambiable'?: string;
    /**
     * 
     * @type {string}
     * @memberof SustitutosWithRelations
     */
    'fechaInicio'?: string;
    /**
     * 
     * @type {string}
     * @memberof SustitutosWithRelations
     */
    'fechaFin'?: string;
    /**
     * 
     * @type {string}
     * @memberof SustitutosWithRelations
     */
    'fechaModificacion'?: string;
    /**
     * 
     * @type {string}
     * @memberof SustitutosWithRelations
     */
    'fechaCreacion'?: string;
    /**
     * 
     * @type {string}
     * @memberof SustitutosWithRelations
     */
    'fechaSincronizacionConBC'?: string;
    /**
     * 
     * @type {string}
     * @memberof SustitutosWithRelations
     */
    'fechaEjecucionDeProcesoSincroBC'?: string;
    /**
     * 
     * @type {string}
     * @memberof SustitutosWithRelations
     */
    'motivoErrorSincroConBC'?: string;
}
/**
 * 
 * @export
 * @interface Tablas
 */
export interface Tablas {
    /**
     * 
     * @type {number}
     * @memberof Tablas
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof Tablas
     */
    'nombreTabla'?: string;
    /**
     * 
     * @type {string}
     * @memberof Tablas
     */
    'campo'?: string;
    /**
     * 
     * @type {string}
     * @memberof Tablas
     */
    'nullable'?: string;
    /**
     * 
     * @type {string}
     * @memberof Tablas
     */
    'tipo'?: string;
    /**
     * 
     * @type {string}
     * @memberof Tablas
     */
    'isKey'?: string;
}
/**
 * 
 * @export
 * @interface TablasFilter
 */
export interface TablasFilter {
    /**
     * 
     * @type {number}
     * @memberof TablasFilter
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof TablasFilter
     */
    'limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof TablasFilter
     */
    'skip'?: number;
    /**
     * 
     * @type {string | Array<string>}
     * @memberof TablasFilter
     */
    'order'?: string | Array<string>;
    /**
     * 
     * @type {object | Set<string>}
     * @memberof TablasFilter
     */
    'fields'?: object | Set<string>;
}
/**
 * 
 * @export
 * @interface TablasFilter1
 */
export interface TablasFilter1 {
    /**
     * 
     * @type {number}
     * @memberof TablasFilter1
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof TablasFilter1
     */
    'limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof TablasFilter1
     */
    'skip'?: number;
    /**
     * 
     * @type {string | Array<string>}
     * @memberof TablasFilter1
     */
    'order'?: string | Array<string>;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof TablasFilter1
     */
    'where'?: { [key: string]: object; };
    /**
     * 
     * @type {object | Set<string>}
     * @memberof TablasFilter1
     */
    'fields'?: object | Set<string>;
}
/**
 * (tsType: Partial<Tablas>, schemaOptions: { partial: true })
 * @export
 * @interface TablasPartial
 */
export interface TablasPartial {
    /**
     * 
     * @type {number}
     * @memberof TablasPartial
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TablasPartial
     */
    'nombreTabla'?: string;
    /**
     * 
     * @type {string}
     * @memberof TablasPartial
     */
    'campo'?: string;
    /**
     * 
     * @type {string}
     * @memberof TablasPartial
     */
    'nullable'?: string;
    /**
     * 
     * @type {string}
     * @memberof TablasPartial
     */
    'tipo'?: string;
    /**
     * 
     * @type {string}
     * @memberof TablasPartial
     */
    'isKey'?: string;
}
/**
 * (tsType: TablasWithRelations, schemaOptions: { includeRelations: true })
 * @export
 * @interface TablasWithRelations
 */
export interface TablasWithRelations {
    /**
     * 
     * @type {number}
     * @memberof TablasWithRelations
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TablasWithRelations
     */
    'nombreTabla'?: string;
    /**
     * 
     * @type {string}
     * @memberof TablasWithRelations
     */
    'campo'?: string;
    /**
     * 
     * @type {string}
     * @memberof TablasWithRelations
     */
    'nullable'?: string;
    /**
     * 
     * @type {string}
     * @memberof TablasWithRelations
     */
    'tipo'?: string;
    /**
     * 
     * @type {string}
     * @memberof TablasWithRelations
     */
    'isKey'?: string;
}
/**
 * 
 * @export
 * @interface TextosGenerados
 */
export interface TextosGenerados {
    /**
     * 
     * @type {number}
     * @memberof TextosGenerados
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof TextosGenerados
     */
    'productoId': number;
    /**
     * 
     * @type {number}
     * @memberof TextosGenerados
     */
    'marketplacePromptId': number;
    /**
     * 
     * @type {number}
     * @memberof TextosGenerados
     */
    'marketplaceId': number;
    /**
     * 
     * @type {string}
     * @memberof TextosGenerados
     */
    'campoUno'?: string;
    /**
     * 
     * @type {string}
     * @memberof TextosGenerados
     */
    'textoIaCampoUno'?: string;
    /**
     * 
     * @type {string}
     * @memberof TextosGenerados
     */
    'campoDos'?: string;
    /**
     * 
     * @type {string}
     * @memberof TextosGenerados
     */
    'textoIaCampoDos'?: string;
    /**
     * 
     * @type {string}
     * @memberof TextosGenerados
     */
    'campoTres'?: string;
    /**
     * 
     * @type {string}
     * @memberof TextosGenerados
     */
    'textoIaCampoTres'?: string;
    /**
     * 
     * @type {string}
     * @memberof TextosGenerados
     */
    'campoCuatro'?: string;
    /**
     * 
     * @type {string}
     * @memberof TextosGenerados
     */
    'textoIaCampoCuatro'?: string;
    /**
     * 
     * @type {string}
     * @memberof TextosGenerados
     */
    'campoCinco'?: string;
    /**
     * 
     * @type {string}
     * @memberof TextosGenerados
     */
    'textoIaCampoCinco'?: string;
    /**
     * 
     * @type {string}
     * @memberof TextosGenerados
     */
    'revisadoSn'?: string;
}
/**
 * 
 * @export
 * @interface TextosGeneradosFilter
 */
export interface TextosGeneradosFilter {
    /**
     * 
     * @type {number}
     * @memberof TextosGeneradosFilter
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof TextosGeneradosFilter
     */
    'limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof TextosGeneradosFilter
     */
    'skip'?: number;
    /**
     * 
     * @type {string | Array<string>}
     * @memberof TextosGeneradosFilter
     */
    'order'?: string | Array<string>;
    /**
     * 
     * @type {object | Set<string>}
     * @memberof TextosGeneradosFilter
     */
    'fields'?: object | Set<string>;
}
/**
 * 
 * @export
 * @interface TextosGeneradosFilter1
 */
export interface TextosGeneradosFilter1 {
    /**
     * 
     * @type {number}
     * @memberof TextosGeneradosFilter1
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof TextosGeneradosFilter1
     */
    'limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof TextosGeneradosFilter1
     */
    'skip'?: number;
    /**
     * 
     * @type {string | Array<string>}
     * @memberof TextosGeneradosFilter1
     */
    'order'?: string | Array<string>;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof TextosGeneradosFilter1
     */
    'where'?: { [key: string]: object; };
    /**
     * 
     * @type {object | Set<string>}
     * @memberof TextosGeneradosFilter1
     */
    'fields'?: object | Set<string>;
}
/**
 * (tsType: Partial<TextosGenerados>, schemaOptions: { partial: true })
 * @export
 * @interface TextosGeneradosPartial
 */
export interface TextosGeneradosPartial {
    /**
     * 
     * @type {number}
     * @memberof TextosGeneradosPartial
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof TextosGeneradosPartial
     */
    'productoId'?: number;
    /**
     * 
     * @type {number}
     * @memberof TextosGeneradosPartial
     */
    'marketplacePromptId'?: number;
    /**
     * 
     * @type {number}
     * @memberof TextosGeneradosPartial
     */
    'marketplaceId'?: number;
    /**
     * 
     * @type {string}
     * @memberof TextosGeneradosPartial
     */
    'campoUno'?: string;
    /**
     * 
     * @type {string}
     * @memberof TextosGeneradosPartial
     */
    'textoIaCampoUno'?: string;
    /**
     * 
     * @type {string}
     * @memberof TextosGeneradosPartial
     */
    'campoDos'?: string;
    /**
     * 
     * @type {string}
     * @memberof TextosGeneradosPartial
     */
    'textoIaCampoDos'?: string;
    /**
     * 
     * @type {string}
     * @memberof TextosGeneradosPartial
     */
    'campoTres'?: string;
    /**
     * 
     * @type {string}
     * @memberof TextosGeneradosPartial
     */
    'textoIaCampoTres'?: string;
    /**
     * 
     * @type {string}
     * @memberof TextosGeneradosPartial
     */
    'campoCuatro'?: string;
    /**
     * 
     * @type {string}
     * @memberof TextosGeneradosPartial
     */
    'textoIaCampoCuatro'?: string;
    /**
     * 
     * @type {string}
     * @memberof TextosGeneradosPartial
     */
    'campoCinco'?: string;
    /**
     * 
     * @type {string}
     * @memberof TextosGeneradosPartial
     */
    'textoIaCampoCinco'?: string;
    /**
     * 
     * @type {string}
     * @memberof TextosGeneradosPartial
     */
    'revisadoSn'?: string;
}
/**
 * (tsType: TextosGeneradosWithRelations, schemaOptions: { includeRelations: true })
 * @export
 * @interface TextosGeneradosWithRelations
 */
export interface TextosGeneradosWithRelations {
    /**
     * 
     * @type {number}
     * @memberof TextosGeneradosWithRelations
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof TextosGeneradosWithRelations
     */
    'productoId': number;
    /**
     * 
     * @type {number}
     * @memberof TextosGeneradosWithRelations
     */
    'marketplacePromptId': number;
    /**
     * 
     * @type {number}
     * @memberof TextosGeneradosWithRelations
     */
    'marketplaceId': number;
    /**
     * 
     * @type {string}
     * @memberof TextosGeneradosWithRelations
     */
    'campoUno'?: string;
    /**
     * 
     * @type {string}
     * @memberof TextosGeneradosWithRelations
     */
    'textoIaCampoUno'?: string;
    /**
     * 
     * @type {string}
     * @memberof TextosGeneradosWithRelations
     */
    'campoDos'?: string;
    /**
     * 
     * @type {string}
     * @memberof TextosGeneradosWithRelations
     */
    'textoIaCampoDos'?: string;
    /**
     * 
     * @type {string}
     * @memberof TextosGeneradosWithRelations
     */
    'campoTres'?: string;
    /**
     * 
     * @type {string}
     * @memberof TextosGeneradosWithRelations
     */
    'textoIaCampoTres'?: string;
    /**
     * 
     * @type {string}
     * @memberof TextosGeneradosWithRelations
     */
    'campoCuatro'?: string;
    /**
     * 
     * @type {string}
     * @memberof TextosGeneradosWithRelations
     */
    'textoIaCampoCuatro'?: string;
    /**
     * 
     * @type {string}
     * @memberof TextosGeneradosWithRelations
     */
    'campoCinco'?: string;
    /**
     * 
     * @type {string}
     * @memberof TextosGeneradosWithRelations
     */
    'textoIaCampoCinco'?: string;
    /**
     * 
     * @type {string}
     * @memberof TextosGeneradosWithRelations
     */
    'revisadoSn'?: string;
}
/**
 * 
 * @export
 * @interface TiposRango
 */
export interface TiposRango {
    /**
     * 
     * @type {number}
     * @memberof TiposRango
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TiposRango
     */
    'rango'?: string;
    /**
     * 
     * @type {string}
     * @memberof TiposRango
     */
    'imagen'?: string;
    /**
     * 
     * @type {number}
     * @memberof TiposRango
     */
    'orden': number;
}
/**
 * 
 * @export
 * @interface TiposRangoFilter
 */
export interface TiposRangoFilter {
    /**
     * 
     * @type {number}
     * @memberof TiposRangoFilter
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof TiposRangoFilter
     */
    'limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof TiposRangoFilter
     */
    'skip'?: number;
    /**
     * 
     * @type {string | Array<string>}
     * @memberof TiposRangoFilter
     */
    'order'?: string | Array<string>;
    /**
     * 
     * @type {object | Set<string>}
     * @memberof TiposRangoFilter
     */
    'fields'?: object | Set<string>;
}
/**
 * 
 * @export
 * @interface TiposRangoFilter1
 */
export interface TiposRangoFilter1 {
    /**
     * 
     * @type {number}
     * @memberof TiposRangoFilter1
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof TiposRangoFilter1
     */
    'limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof TiposRangoFilter1
     */
    'skip'?: number;
    /**
     * 
     * @type {string | Array<string>}
     * @memberof TiposRangoFilter1
     */
    'order'?: string | Array<string>;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof TiposRangoFilter1
     */
    'where'?: { [key: string]: object; };
    /**
     * 
     * @type {object | Set<string>}
     * @memberof TiposRangoFilter1
     */
    'fields'?: object | Set<string>;
}
/**
 * (tsType: Partial<TiposRango>, schemaOptions: { partial: true })
 * @export
 * @interface TiposRangoPartial
 */
export interface TiposRangoPartial {
    /**
     * 
     * @type {number}
     * @memberof TiposRangoPartial
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TiposRangoPartial
     */
    'rango'?: string;
    /**
     * 
     * @type {string}
     * @memberof TiposRangoPartial
     */
    'imagen'?: string;
    /**
     * 
     * @type {number}
     * @memberof TiposRangoPartial
     */
    'orden'?: number;
}
/**
 * (tsType: TiposRangoWithRelations, schemaOptions: { includeRelations: true })
 * @export
 * @interface TiposRangoWithRelations
 */
export interface TiposRangoWithRelations {
    /**
     * 
     * @type {number}
     * @memberof TiposRangoWithRelations
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TiposRangoWithRelations
     */
    'rango'?: string;
    /**
     * 
     * @type {string}
     * @memberof TiposRangoWithRelations
     */
    'imagen'?: string;
    /**
     * 
     * @type {number}
     * @memberof TiposRangoWithRelations
     */
    'orden': number;
}
/**
 * 
 * @export
 * @interface Traducciones
 */
export interface Traducciones {
    /**
     * 
     * @type {number}
     * @memberof Traducciones
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof Traducciones
     */
    'idiomaId'?: string;
    /**
     * 
     * @type {string}
     * @memberof Traducciones
     */
    'clave'?: string;
    /**
     * 
     * @type {string}
     * @memberof Traducciones
     */
    'valor'?: string;
}
/**
 * 
 * @export
 * @interface TraduccionesFilter
 */
export interface TraduccionesFilter {
    /**
     * 
     * @type {number}
     * @memberof TraduccionesFilter
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof TraduccionesFilter
     */
    'limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof TraduccionesFilter
     */
    'skip'?: number;
    /**
     * 
     * @type {string | Array<string>}
     * @memberof TraduccionesFilter
     */
    'order'?: string | Array<string>;
    /**
     * 
     * @type {object | Set<string>}
     * @memberof TraduccionesFilter
     */
    'fields'?: object | Set<string>;
}
/**
 * 
 * @export
 * @interface TraduccionesFilter1
 */
export interface TraduccionesFilter1 {
    /**
     * 
     * @type {number}
     * @memberof TraduccionesFilter1
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof TraduccionesFilter1
     */
    'limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof TraduccionesFilter1
     */
    'skip'?: number;
    /**
     * 
     * @type {string | Array<string>}
     * @memberof TraduccionesFilter1
     */
    'order'?: string | Array<string>;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof TraduccionesFilter1
     */
    'where'?: { [key: string]: object; };
    /**
     * 
     * @type {object | Set<string>}
     * @memberof TraduccionesFilter1
     */
    'fields'?: object | Set<string>;
}
/**
 * (tsType: Partial<Traducciones>, schemaOptions: { partial: true })
 * @export
 * @interface TraduccionesPartial
 */
export interface TraduccionesPartial {
    /**
     * 
     * @type {number}
     * @memberof TraduccionesPartial
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TraduccionesPartial
     */
    'idiomaId'?: string;
    /**
     * 
     * @type {string}
     * @memberof TraduccionesPartial
     */
    'clave'?: string;
    /**
     * 
     * @type {string}
     * @memberof TraduccionesPartial
     */
    'valor'?: string;
}
/**
 * (tsType: TraduccionesWithRelations, schemaOptions: { includeRelations: true })
 * @export
 * @interface TraduccionesWithRelations
 */
export interface TraduccionesWithRelations {
    /**
     * 
     * @type {number}
     * @memberof TraduccionesWithRelations
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TraduccionesWithRelations
     */
    'idiomaId'?: string;
    /**
     * 
     * @type {string}
     * @memberof TraduccionesWithRelations
     */
    'clave'?: string;
    /**
     * 
     * @type {string}
     * @memberof TraduccionesWithRelations
     */
    'valor'?: string;
}
/**
 * 
 * @export
 * @interface Usuarios
 */
export interface Usuarios {
    /**
     * 
     * @type {number}
     * @memberof Usuarios
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof Usuarios
     */
    'empresaId': number;
    /**
     * 
     * @type {string}
     * @memberof Usuarios
     */
    'nombre'?: string;
    /**
     * 
     * @type {string}
     * @memberof Usuarios
     */
    'mail'?: string;
    /**
     * 
     * @type {string}
     * @memberof Usuarios
     */
    'activoSn'?: string;
    /**
     * 
     * @type {string}
     * @memberof Usuarios
     */
    'telefono'?: string;
    /**
     * 
     * @type {string}
     * @memberof Usuarios
     */
    'avatar'?: string;
    /**
     * 
     * @type {string}
     * @memberof Usuarios
     */
    'password'?: string;
    /**
     * 
     * @type {string}
     * @memberof Usuarios
     */
    'role'?: string;
    /**
     * 
     * @type {number}
     * @memberof Usuarios
     */
    'rolesId'?: number;
    /**
     * 
     * @type {string}
     * @memberof Usuarios
     */
    'filtroCategorias'?: string;
    /**
     * 
     * @type {string}
     * @memberof Usuarios
     */
    'filtroMarcas'?: string;
}
/**
 * 
 * @export
 * @interface UsuariosFilter
 */
export interface UsuariosFilter {
    /**
     * 
     * @type {number}
     * @memberof UsuariosFilter
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof UsuariosFilter
     */
    'limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof UsuariosFilter
     */
    'skip'?: number;
    /**
     * 
     * @type {string | Array<string>}
     * @memberof UsuariosFilter
     */
    'order'?: string | Array<string>;
    /**
     * 
     * @type {object | Set<string>}
     * @memberof UsuariosFilter
     */
    'fields'?: object | Set<string>;
    /**
     * 
     * @type {Array<UsuariosIncludeFilterItems | string>}
     * @memberof UsuariosFilter
     */
    'include'?: Array<UsuariosIncludeFilterItems | string>;
}
/**
 * 
 * @export
 * @interface UsuariosFilter1
 */
export interface UsuariosFilter1 {
    /**
     * 
     * @type {number}
     * @memberof UsuariosFilter1
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof UsuariosFilter1
     */
    'limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof UsuariosFilter1
     */
    'skip'?: number;
    /**
     * 
     * @type {string | Array<string>}
     * @memberof UsuariosFilter1
     */
    'order'?: string | Array<string>;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof UsuariosFilter1
     */
    'where'?: { [key: string]: object; };
    /**
     * 
     * @type {object | Set<string>}
     * @memberof UsuariosFilter1
     */
    'fields'?: object | Set<string>;
    /**
     * 
     * @type {Array<UsuariosIncludeFilterItems | string>}
     * @memberof UsuariosFilter1
     */
    'include'?: Array<UsuariosIncludeFilterItems | string>;
}
/**
 * 
 * @export
 * @interface UsuariosIncludeFilterItems
 */
export interface UsuariosIncludeFilterItems {
    /**
     * 
     * @type {string}
     * @memberof UsuariosIncludeFilterItems
     */
    'relation'?: string;
    /**
     * 
     * @type {UsuariosScopeFilter}
     * @memberof UsuariosIncludeFilterItems
     */
    'scope'?: UsuariosScopeFilter;
}
/**
 * (tsType: Partial<Usuarios>, schemaOptions: { partial: true })
 * @export
 * @interface UsuariosPartial
 */
export interface UsuariosPartial {
    /**
     * 
     * @type {number}
     * @memberof UsuariosPartial
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof UsuariosPartial
     */
    'empresaId'?: number;
    /**
     * 
     * @type {string}
     * @memberof UsuariosPartial
     */
    'nombre'?: string;
    /**
     * 
     * @type {string}
     * @memberof UsuariosPartial
     */
    'mail'?: string;
    /**
     * 
     * @type {string}
     * @memberof UsuariosPartial
     */
    'activoSn'?: string;
    /**
     * 
     * @type {string}
     * @memberof UsuariosPartial
     */
    'telefono'?: string;
    /**
     * 
     * @type {string}
     * @memberof UsuariosPartial
     */
    'avatar'?: string;
    /**
     * 
     * @type {string}
     * @memberof UsuariosPartial
     */
    'password'?: string;
    /**
     * 
     * @type {string}
     * @memberof UsuariosPartial
     */
    'role'?: string;
    /**
     * 
     * @type {number}
     * @memberof UsuariosPartial
     */
    'rolesId'?: number;
    /**
     * 
     * @type {string}
     * @memberof UsuariosPartial
     */
    'filtroCategorias'?: string;
    /**
     * 
     * @type {string}
     * @memberof UsuariosPartial
     */
    'filtroMarcas'?: string;
}
/**
 * 
 * @export
 * @interface UsuariosScopeFilter
 */
export interface UsuariosScopeFilter {
    /**
     * 
     * @type {number}
     * @memberof UsuariosScopeFilter
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof UsuariosScopeFilter
     */
    'limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof UsuariosScopeFilter
     */
    'skip'?: number;
    /**
     * 
     * @type {string | Array<string>}
     * @memberof UsuariosScopeFilter
     */
    'order'?: string | Array<string>;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof UsuariosScopeFilter
     */
    'where'?: { [key: string]: object; };
    /**
     * 
     * @type {any | Set<string>}
     * @memberof UsuariosScopeFilter
     */
    'fields'?: any | Set<string>;
    /**
     * 
     * @type {Array<{ [key: string]: object; }>}
     * @memberof UsuariosScopeFilter
     */
    'include'?: Array<{ [key: string]: object; }>;
}
/**
 * (tsType: UsuariosWithRelations, schemaOptions: { includeRelations: true })
 * @export
 * @interface UsuariosWithRelations
 */
export interface UsuariosWithRelations {
    /**
     * 
     * @type {number}
     * @memberof UsuariosWithRelations
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof UsuariosWithRelations
     */
    'empresaId': number;
    /**
     * 
     * @type {string}
     * @memberof UsuariosWithRelations
     */
    'nombre'?: string;
    /**
     * 
     * @type {string}
     * @memberof UsuariosWithRelations
     */
    'mail'?: string;
    /**
     * 
     * @type {string}
     * @memberof UsuariosWithRelations
     */
    'activoSn'?: string;
    /**
     * 
     * @type {string}
     * @memberof UsuariosWithRelations
     */
    'telefono'?: string;
    /**
     * 
     * @type {string}
     * @memberof UsuariosWithRelations
     */
    'avatar'?: string;
    /**
     * 
     * @type {string}
     * @memberof UsuariosWithRelations
     */
    'password'?: string;
    /**
     * 
     * @type {string}
     * @memberof UsuariosWithRelations
     */
    'role'?: string;
    /**
     * 
     * @type {number}
     * @memberof UsuariosWithRelations
     */
    'rolesId'?: number;
    /**
     * 
     * @type {string}
     * @memberof UsuariosWithRelations
     */
    'filtroCategorias'?: string;
    /**
     * 
     * @type {string}
     * @memberof UsuariosWithRelations
     */
    'filtroMarcas'?: string;
    /**
     * 
     * @type {RolesWithRelations}
     * @memberof UsuariosWithRelations
     */
    'roles'?: RolesWithRelations;
}
/**
 * 
 * @export
 * @interface VistaCategoriasConNiveles
 */
export interface VistaCategoriasConNiveles {
    /**
     * 
     * @type {number}
     * @memberof VistaCategoriasConNiveles
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof VistaCategoriasConNiveles
     */
    'empresaId': number;
    /**
     * 
     * @type {string}
     * @memberof VistaCategoriasConNiveles
     */
    'tagAecoc'?: string;
    /**
     * 
     * @type {string}
     * @memberof VistaCategoriasConNiveles
     */
    'codigo'?: string;
    /**
     * 
     * @type {string}
     * @memberof VistaCategoriasConNiveles
     */
    'codigoAECOC'?: string;
    /**
     * 
     * @type {string}
     * @memberof VistaCategoriasConNiveles
     */
    'nombre'?: string;
    /**
     * 
     * @type {string}
     * @memberof VistaCategoriasConNiveles
     */
    'padre'?: string;
    /**
     * 
     * @type {string}
     * @memberof VistaCategoriasConNiveles
     */
    'web'?: string;
    /**
     * 
     * @type {string}
     * @memberof VistaCategoriasConNiveles
     */
    'nivel'?: string;
}
/**
 * 
 * @export
 * @interface VistaCategoriasConNivelesFilter
 */
export interface VistaCategoriasConNivelesFilter {
    /**
     * 
     * @type {number}
     * @memberof VistaCategoriasConNivelesFilter
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof VistaCategoriasConNivelesFilter
     */
    'limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof VistaCategoriasConNivelesFilter
     */
    'skip'?: number;
    /**
     * 
     * @type {string | Array<string>}
     * @memberof VistaCategoriasConNivelesFilter
     */
    'order'?: string | Array<string>;
    /**
     * 
     * @type {object | Set<string>}
     * @memberof VistaCategoriasConNivelesFilter
     */
    'fields'?: object | Set<string>;
}
/**
 * 
 * @export
 * @interface VistaCategoriasConNivelesFilter1
 */
export interface VistaCategoriasConNivelesFilter1 {
    /**
     * 
     * @type {number}
     * @memberof VistaCategoriasConNivelesFilter1
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof VistaCategoriasConNivelesFilter1
     */
    'limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof VistaCategoriasConNivelesFilter1
     */
    'skip'?: number;
    /**
     * 
     * @type {string | Array<string>}
     * @memberof VistaCategoriasConNivelesFilter1
     */
    'order'?: string | Array<string>;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof VistaCategoriasConNivelesFilter1
     */
    'where'?: { [key: string]: object; };
    /**
     * 
     * @type {object | Set<string>}
     * @memberof VistaCategoriasConNivelesFilter1
     */
    'fields'?: object | Set<string>;
}
/**
 * (tsType: Partial<VistaCategoriasConNiveles>, schemaOptions: { partial: true })
 * @export
 * @interface VistaCategoriasConNivelesPartial
 */
export interface VistaCategoriasConNivelesPartial {
    /**
     * 
     * @type {number}
     * @memberof VistaCategoriasConNivelesPartial
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof VistaCategoriasConNivelesPartial
     */
    'empresaId'?: number;
    /**
     * 
     * @type {string}
     * @memberof VistaCategoriasConNivelesPartial
     */
    'tagAecoc'?: string;
    /**
     * 
     * @type {string}
     * @memberof VistaCategoriasConNivelesPartial
     */
    'codigo'?: string;
    /**
     * 
     * @type {string}
     * @memberof VistaCategoriasConNivelesPartial
     */
    'codigoAECOC'?: string;
    /**
     * 
     * @type {string}
     * @memberof VistaCategoriasConNivelesPartial
     */
    'nombre'?: string;
    /**
     * 
     * @type {string}
     * @memberof VistaCategoriasConNivelesPartial
     */
    'padre'?: string;
    /**
     * 
     * @type {string}
     * @memberof VistaCategoriasConNivelesPartial
     */
    'web'?: string;
    /**
     * 
     * @type {string}
     * @memberof VistaCategoriasConNivelesPartial
     */
    'nivel'?: string;
}
/**
 * (tsType: VistaCategoriasConNivelesWithRelations, schemaOptions: { includeRelations: true })
 * @export
 * @interface VistaCategoriasConNivelesWithRelations
 */
export interface VistaCategoriasConNivelesWithRelations {
    /**
     * 
     * @type {number}
     * @memberof VistaCategoriasConNivelesWithRelations
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof VistaCategoriasConNivelesWithRelations
     */
    'empresaId': number;
    /**
     * 
     * @type {string}
     * @memberof VistaCategoriasConNivelesWithRelations
     */
    'tagAecoc'?: string;
    /**
     * 
     * @type {string}
     * @memberof VistaCategoriasConNivelesWithRelations
     */
    'codigo'?: string;
    /**
     * 
     * @type {string}
     * @memberof VistaCategoriasConNivelesWithRelations
     */
    'codigoAECOC'?: string;
    /**
     * 
     * @type {string}
     * @memberof VistaCategoriasConNivelesWithRelations
     */
    'nombre'?: string;
    /**
     * 
     * @type {string}
     * @memberof VistaCategoriasConNivelesWithRelations
     */
    'padre'?: string;
    /**
     * 
     * @type {string}
     * @memberof VistaCategoriasConNivelesWithRelations
     */
    'web'?: string;
    /**
     * 
     * @type {string}
     * @memberof VistaCategoriasConNivelesWithRelations
     */
    'nivel'?: string;
}
/**
 * 
 * @export
 * @interface VistaMarketPlacePrompts
 */
export interface VistaMarketPlacePrompts {
    /**
     * 
     * @type {number}
     * @memberof VistaMarketPlacePrompts
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof VistaMarketPlacePrompts
     */
    'marketplaceId': number;
    /**
     * 
     * @type {string}
     * @memberof VistaMarketPlacePrompts
     */
    'marketplaceCodigo'?: string;
    /**
     * 
     * @type {number}
     * @memberof VistaMarketPlacePrompts
     */
    'categoriaId': number;
    /**
     * 
     * @type {string}
     * @memberof VistaMarketPlacePrompts
     */
    'categoriaCodigo'?: string;
    /**
     * 
     * @type {string}
     * @memberof VistaMarketPlacePrompts
     */
    'categoriaPadre'?: string;
    /**
     * 
     * @type {string}
     * @memberof VistaMarketPlacePrompts
     */
    'categoriaNivel'?: string;
    /**
     * 
     * @type {string}
     * @memberof VistaMarketPlacePrompts
     */
    'campoUno'?: string;
    /**
     * 
     * @type {string}
     * @memberof VistaMarketPlacePrompts
     */
    'promptUno'?: string;
    /**
     * 
     * @type {string}
     * @memberof VistaMarketPlacePrompts
     */
    'campoDos'?: string;
    /**
     * 
     * @type {string}
     * @memberof VistaMarketPlacePrompts
     */
    'promptDos'?: string;
    /**
     * 
     * @type {string}
     * @memberof VistaMarketPlacePrompts
     */
    'campoTres'?: string;
    /**
     * 
     * @type {string}
     * @memberof VistaMarketPlacePrompts
     */
    'promptTres'?: string;
    /**
     * 
     * @type {string}
     * @memberof VistaMarketPlacePrompts
     */
    'campoCuatro'?: string;
    /**
     * 
     * @type {string}
     * @memberof VistaMarketPlacePrompts
     */
    'promptCuatro'?: string;
    /**
     * 
     * @type {string}
     * @memberof VistaMarketPlacePrompts
     */
    'campoCinco'?: string;
    /**
     * 
     * @type {string}
     * @memberof VistaMarketPlacePrompts
     */
    'promptCinco'?: string;
}
/**
 * 
 * @export
 * @interface VistaMarketPlacePromptsFilter
 */
export interface VistaMarketPlacePromptsFilter {
    /**
     * 
     * @type {number}
     * @memberof VistaMarketPlacePromptsFilter
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof VistaMarketPlacePromptsFilter
     */
    'limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof VistaMarketPlacePromptsFilter
     */
    'skip'?: number;
    /**
     * 
     * @type {string | Array<string>}
     * @memberof VistaMarketPlacePromptsFilter
     */
    'order'?: string | Array<string>;
    /**
     * 
     * @type {object | Set<string>}
     * @memberof VistaMarketPlacePromptsFilter
     */
    'fields'?: object | Set<string>;
}
/**
 * 
 * @export
 * @interface VistaMarketPlacePromptsFilter1
 */
export interface VistaMarketPlacePromptsFilter1 {
    /**
     * 
     * @type {number}
     * @memberof VistaMarketPlacePromptsFilter1
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof VistaMarketPlacePromptsFilter1
     */
    'limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof VistaMarketPlacePromptsFilter1
     */
    'skip'?: number;
    /**
     * 
     * @type {string | Array<string>}
     * @memberof VistaMarketPlacePromptsFilter1
     */
    'order'?: string | Array<string>;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof VistaMarketPlacePromptsFilter1
     */
    'where'?: { [key: string]: object; };
    /**
     * 
     * @type {object | Set<string>}
     * @memberof VistaMarketPlacePromptsFilter1
     */
    'fields'?: object | Set<string>;
}
/**
 * (tsType: Partial<VistaMarketPlacePrompts>, schemaOptions: { partial: true })
 * @export
 * @interface VistaMarketPlacePromptsPartial
 */
export interface VistaMarketPlacePromptsPartial {
    /**
     * 
     * @type {number}
     * @memberof VistaMarketPlacePromptsPartial
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof VistaMarketPlacePromptsPartial
     */
    'marketplaceId'?: number;
    /**
     * 
     * @type {string}
     * @memberof VistaMarketPlacePromptsPartial
     */
    'marketplaceCodigo'?: string;
    /**
     * 
     * @type {number}
     * @memberof VistaMarketPlacePromptsPartial
     */
    'categoriaId'?: number;
    /**
     * 
     * @type {string}
     * @memberof VistaMarketPlacePromptsPartial
     */
    'categoriaCodigo'?: string;
    /**
     * 
     * @type {string}
     * @memberof VistaMarketPlacePromptsPartial
     */
    'categoriaPadre'?: string;
    /**
     * 
     * @type {string}
     * @memberof VistaMarketPlacePromptsPartial
     */
    'categoriaNivel'?: string;
    /**
     * 
     * @type {string}
     * @memberof VistaMarketPlacePromptsPartial
     */
    'campoUno'?: string;
    /**
     * 
     * @type {string}
     * @memberof VistaMarketPlacePromptsPartial
     */
    'promptUno'?: string;
    /**
     * 
     * @type {string}
     * @memberof VistaMarketPlacePromptsPartial
     */
    'campoDos'?: string;
    /**
     * 
     * @type {string}
     * @memberof VistaMarketPlacePromptsPartial
     */
    'promptDos'?: string;
    /**
     * 
     * @type {string}
     * @memberof VistaMarketPlacePromptsPartial
     */
    'campoTres'?: string;
    /**
     * 
     * @type {string}
     * @memberof VistaMarketPlacePromptsPartial
     */
    'promptTres'?: string;
    /**
     * 
     * @type {string}
     * @memberof VistaMarketPlacePromptsPartial
     */
    'campoCuatro'?: string;
    /**
     * 
     * @type {string}
     * @memberof VistaMarketPlacePromptsPartial
     */
    'promptCuatro'?: string;
    /**
     * 
     * @type {string}
     * @memberof VistaMarketPlacePromptsPartial
     */
    'campoCinco'?: string;
    /**
     * 
     * @type {string}
     * @memberof VistaMarketPlacePromptsPartial
     */
    'promptCinco'?: string;
}
/**
 * (tsType: VistaMarketPlacePromptsWithRelations, schemaOptions: { includeRelations: true })
 * @export
 * @interface VistaMarketPlacePromptsWithRelations
 */
export interface VistaMarketPlacePromptsWithRelations {
    /**
     * 
     * @type {number}
     * @memberof VistaMarketPlacePromptsWithRelations
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof VistaMarketPlacePromptsWithRelations
     */
    'marketplaceId': number;
    /**
     * 
     * @type {string}
     * @memberof VistaMarketPlacePromptsWithRelations
     */
    'marketplaceCodigo'?: string;
    /**
     * 
     * @type {number}
     * @memberof VistaMarketPlacePromptsWithRelations
     */
    'categoriaId': number;
    /**
     * 
     * @type {string}
     * @memberof VistaMarketPlacePromptsWithRelations
     */
    'categoriaCodigo'?: string;
    /**
     * 
     * @type {string}
     * @memberof VistaMarketPlacePromptsWithRelations
     */
    'categoriaPadre'?: string;
    /**
     * 
     * @type {string}
     * @memberof VistaMarketPlacePromptsWithRelations
     */
    'categoriaNivel'?: string;
    /**
     * 
     * @type {string}
     * @memberof VistaMarketPlacePromptsWithRelations
     */
    'campoUno'?: string;
    /**
     * 
     * @type {string}
     * @memberof VistaMarketPlacePromptsWithRelations
     */
    'promptUno'?: string;
    /**
     * 
     * @type {string}
     * @memberof VistaMarketPlacePromptsWithRelations
     */
    'campoDos'?: string;
    /**
     * 
     * @type {string}
     * @memberof VistaMarketPlacePromptsWithRelations
     */
    'promptDos'?: string;
    /**
     * 
     * @type {string}
     * @memberof VistaMarketPlacePromptsWithRelations
     */
    'campoTres'?: string;
    /**
     * 
     * @type {string}
     * @memberof VistaMarketPlacePromptsWithRelations
     */
    'promptTres'?: string;
    /**
     * 
     * @type {string}
     * @memberof VistaMarketPlacePromptsWithRelations
     */
    'campoCuatro'?: string;
    /**
     * 
     * @type {string}
     * @memberof VistaMarketPlacePromptsWithRelations
     */
    'promptCuatro'?: string;
    /**
     * 
     * @type {string}
     * @memberof VistaMarketPlacePromptsWithRelations
     */
    'campoCinco'?: string;
    /**
     * 
     * @type {string}
     * @memberof VistaMarketPlacePromptsWithRelations
     */
    'promptCinco'?: string;
}
/**
 * 
 * @export
 * @interface VistaMuestraFotosOptimizadasFilter
 */
export interface VistaMuestraFotosOptimizadasFilter {
    /**
     * 
     * @type {number}
     * @memberof VistaMuestraFotosOptimizadasFilter
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof VistaMuestraFotosOptimizadasFilter
     */
    'limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof VistaMuestraFotosOptimizadasFilter
     */
    'skip'?: number;
    /**
     * 
     * @type {string | Array<string>}
     * @memberof VistaMuestraFotosOptimizadasFilter
     */
    'order'?: string | Array<string>;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof VistaMuestraFotosOptimizadasFilter
     */
    'where'?: { [key: string]: object; };
    /**
     * 
     * @type {object | Set<string>}
     * @memberof VistaMuestraFotosOptimizadasFilter
     */
    'fields'?: object | Set<string>;
}

/**
 * AecocControllerApi - axios parameter creator
 * @export
 */
export const AecocControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aecocControllerEnviarAECOC: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/enviarAECOC`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} email 
         * @param {string} password 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aecocControllerEnviarAECOCmanual: async (email: string, password: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'email' is not null or undefined
            assertParamExists('aecocControllerEnviarAECOCmanual', 'email', email)
            // verify required parameter 'password' is not null or undefined
            assertParamExists('aecocControllerEnviarAECOCmanual', 'password', password)
            const localVarPath = `/enviarAECOCmanual/{email}/{password}`
                .replace(`{${"email"}}`, encodeURIComponent(String(email)))
                .replace(`{${"password"}}`, encodeURIComponent(String(password)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AecocControllerApi - functional programming interface
 * @export
 */
export const AecocControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AecocControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async aecocControllerEnviarAECOC(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Aecoc>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.aecocControllerEnviarAECOC(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} email 
         * @param {string} password 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async aecocControllerEnviarAECOCmanual(email: string, password: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Aecoc>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.aecocControllerEnviarAECOCmanual(email, password, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AecocControllerApi - factory interface
 * @export
 */
export const AecocControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AecocControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aecocControllerEnviarAECOC(options?: any): AxiosPromise<Aecoc> {
            return localVarFp.aecocControllerEnviarAECOC(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} email 
         * @param {string} password 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aecocControllerEnviarAECOCmanual(email: string, password: string, options?: any): AxiosPromise<Aecoc> {
            return localVarFp.aecocControllerEnviarAECOCmanual(email, password, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AecocControllerApi - object-oriented interface
 * @export
 * @class AecocControllerApi
 * @extends {BaseAPI}
 */
export class AecocControllerApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AecocControllerApi
     */
    public aecocControllerEnviarAECOC(options?: AxiosRequestConfig) {
        return AecocControllerApiFp(this.configuration).aecocControllerEnviarAECOC(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} email 
     * @param {string} password 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AecocControllerApi
     */
    public aecocControllerEnviarAECOCmanual(email: string, password: string, options?: AxiosRequestConfig) {
        return AecocControllerApiFp(this.configuration).aecocControllerEnviarAECOCmanual(email, password, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AtributosControllerApi - axios parameter creator
 * @export
 */
export const AtributosControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        atributosControllerCount: async (where?: { [key: string]: object; }, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/atributos/count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (where !== undefined) {
                localVarQueryParameter['where'] = where;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {NewAtributos} [newAtributos] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        atributosControllerCreate: async (newAtributos?: NewAtributos, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/atributos`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(newAtributos, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        atributosControllerDeleteById: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('atributosControllerDeleteById', 'id', id)
            const localVarPath = `/atributos/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AtributosFilter1} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        atributosControllerFind: async (filter?: AtributosFilter1, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/atributos`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {AtributosFilter} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        atributosControllerFindById: async (id: number, filter?: AtributosFilter, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('atributosControllerFindById', 'id', id)
            const localVarPath = `/atributos/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {Atributos} [atributos] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        atributosControllerReplaceById: async (id: number, atributos?: Atributos, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('atributosControllerReplaceById', 'id', id)
            const localVarPath = `/atributos/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(atributos, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {AtributosPartial} [atributosPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        atributosControllerUpdateAll: async (where?: { [key: string]: object; }, atributosPartial?: AtributosPartial, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/atributos`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (where !== undefined) {
                localVarQueryParameter['where'] = where;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(atributosPartial, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {AtributosPartial} [atributosPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        atributosControllerUpdateById: async (id: number, atributosPartial?: AtributosPartial, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('atributosControllerUpdateById', 'id', id)
            const localVarPath = `/atributos/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(atributosPartial, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AtributosControllerApi - functional programming interface
 * @export
 */
export const AtributosControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AtributosControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async atributosControllerCount(where?: { [key: string]: object; }, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoopbackCount>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.atributosControllerCount(where, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {NewAtributos} [newAtributos] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async atributosControllerCreate(newAtributos?: NewAtributos, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Atributos>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.atributosControllerCreate(newAtributos, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async atributosControllerDeleteById(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.atributosControllerDeleteById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {AtributosFilter1} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async atributosControllerFind(filter?: AtributosFilter1, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AtributosWithRelations>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.atributosControllerFind(filter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {AtributosFilter} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async atributosControllerFindById(id: number, filter?: AtributosFilter, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AtributosWithRelations>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.atributosControllerFindById(id, filter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {Atributos} [atributos] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async atributosControllerReplaceById(id: number, atributos?: Atributos, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.atributosControllerReplaceById(id, atributos, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {AtributosPartial} [atributosPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async atributosControllerUpdateAll(where?: { [key: string]: object; }, atributosPartial?: AtributosPartial, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoopbackCount>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.atributosControllerUpdateAll(where, atributosPartial, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {AtributosPartial} [atributosPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async atributosControllerUpdateById(id: number, atributosPartial?: AtributosPartial, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.atributosControllerUpdateById(id, atributosPartial, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AtributosControllerApi - factory interface
 * @export
 */
export const AtributosControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AtributosControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        atributosControllerCount(where?: { [key: string]: object; }, options?: any): AxiosPromise<LoopbackCount> {
            return localVarFp.atributosControllerCount(where, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {NewAtributos} [newAtributos] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        atributosControllerCreate(newAtributos?: NewAtributos, options?: any): AxiosPromise<Atributos> {
            return localVarFp.atributosControllerCreate(newAtributos, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        atributosControllerDeleteById(id: number, options?: any): AxiosPromise<any> {
            return localVarFp.atributosControllerDeleteById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AtributosFilter1} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        atributosControllerFind(filter?: AtributosFilter1, options?: any): AxiosPromise<Array<AtributosWithRelations>> {
            return localVarFp.atributosControllerFind(filter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {AtributosFilter} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        atributosControllerFindById(id: number, filter?: AtributosFilter, options?: any): AxiosPromise<AtributosWithRelations> {
            return localVarFp.atributosControllerFindById(id, filter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {Atributos} [atributos] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        atributosControllerReplaceById(id: number, atributos?: Atributos, options?: any): AxiosPromise<any> {
            return localVarFp.atributosControllerReplaceById(id, atributos, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {AtributosPartial} [atributosPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        atributosControllerUpdateAll(where?: { [key: string]: object; }, atributosPartial?: AtributosPartial, options?: any): AxiosPromise<LoopbackCount> {
            return localVarFp.atributosControllerUpdateAll(where, atributosPartial, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {AtributosPartial} [atributosPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        atributosControllerUpdateById(id: number, atributosPartial?: AtributosPartial, options?: any): AxiosPromise<any> {
            return localVarFp.atributosControllerUpdateById(id, atributosPartial, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AtributosControllerApi - object-oriented interface
 * @export
 * @class AtributosControllerApi
 * @extends {BaseAPI}
 */
export class AtributosControllerApi extends BaseAPI {
    /**
     * 
     * @param {{ [key: string]: object; }} [where] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AtributosControllerApi
     */
    public atributosControllerCount(where?: { [key: string]: object; }, options?: AxiosRequestConfig) {
        return AtributosControllerApiFp(this.configuration).atributosControllerCount(where, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {NewAtributos} [newAtributos] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AtributosControllerApi
     */
    public atributosControllerCreate(newAtributos?: NewAtributos, options?: AxiosRequestConfig) {
        return AtributosControllerApiFp(this.configuration).atributosControllerCreate(newAtributos, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AtributosControllerApi
     */
    public atributosControllerDeleteById(id: number, options?: AxiosRequestConfig) {
        return AtributosControllerApiFp(this.configuration).atributosControllerDeleteById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AtributosFilter1} [filter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AtributosControllerApi
     */
    public atributosControllerFind(filter?: AtributosFilter1, options?: AxiosRequestConfig) {
        return AtributosControllerApiFp(this.configuration).atributosControllerFind(filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {AtributosFilter} [filter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AtributosControllerApi
     */
    public atributosControllerFindById(id: number, filter?: AtributosFilter, options?: AxiosRequestConfig) {
        return AtributosControllerApiFp(this.configuration).atributosControllerFindById(id, filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {Atributos} [atributos] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AtributosControllerApi
     */
    public atributosControllerReplaceById(id: number, atributos?: Atributos, options?: AxiosRequestConfig) {
        return AtributosControllerApiFp(this.configuration).atributosControllerReplaceById(id, atributos, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} [where] 
     * @param {AtributosPartial} [atributosPartial] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AtributosControllerApi
     */
    public atributosControllerUpdateAll(where?: { [key: string]: object; }, atributosPartial?: AtributosPartial, options?: AxiosRequestConfig) {
        return AtributosControllerApiFp(this.configuration).atributosControllerUpdateAll(where, atributosPartial, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {AtributosPartial} [atributosPartial] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AtributosControllerApi
     */
    public atributosControllerUpdateById(id: number, atributosPartial?: AtributosPartial, options?: AxiosRequestConfig) {
        return AtributosControllerApiFp(this.configuration).atributosControllerUpdateById(id, atributosPartial, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AuthControllerApi - axios parameter creator
 * @export
 */
export const AuthControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {InlineObject} inlineObject 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerLogin: async (inlineObject: InlineObject, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'inlineObject' is not null or undefined
            assertParamExists('authControllerLogin', 'inlineObject', inlineObject)
            const localVarPath = `/auth/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inlineObject, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerMe: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/usuarios/me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {InlineObject1} inlineObject1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerRefresh: async (inlineObject1: InlineObject1, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'inlineObject1' is not null or undefined
            assertParamExists('authControllerRefresh', 'inlineObject1', inlineObject1)
            const localVarPath = `/auth/refresh-token`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inlineObject1, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {InlineObject6} inlineObject6 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerRefreshLogin: async (inlineObject6: InlineObject6, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'inlineObject6' is not null or undefined
            assertParamExists('authControllerRefreshLogin', 'inlineObject6', inlineObject6)
            const localVarPath = `/usuarios/refresh-login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inlineObject6, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Usuarios} [usuarios] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerSignup: async (usuarios?: Usuarios, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/auth/signup`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(usuarios, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuthControllerApi - functional programming interface
 * @export
 */
export const AuthControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AuthControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {InlineObject} inlineObject 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authControllerLogin(inlineObject: InlineObject, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse200>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authControllerLogin(inlineObject, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authControllerMe(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UsuariosWithRelations>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authControllerMe(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {InlineObject1} inlineObject1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authControllerRefresh(inlineObject1: InlineObject1, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2001>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authControllerRefresh(inlineObject1, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {InlineObject6} inlineObject6 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authControllerRefreshLogin(inlineObject6: InlineObject6, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2003>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authControllerRefreshLogin(inlineObject6, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Usuarios} [usuarios] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authControllerSignup(usuarios?: Usuarios, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Usuarios>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authControllerSignup(usuarios, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AuthControllerApi - factory interface
 * @export
 */
export const AuthControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AuthControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {InlineObject} inlineObject 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerLogin(inlineObject: InlineObject, options?: any): AxiosPromise<InlineResponse200> {
            return localVarFp.authControllerLogin(inlineObject, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerMe(options?: any): AxiosPromise<UsuariosWithRelations> {
            return localVarFp.authControllerMe(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {InlineObject1} inlineObject1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerRefresh(inlineObject1: InlineObject1, options?: any): AxiosPromise<InlineResponse2001> {
            return localVarFp.authControllerRefresh(inlineObject1, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {InlineObject6} inlineObject6 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerRefreshLogin(inlineObject6: InlineObject6, options?: any): AxiosPromise<InlineResponse2003> {
            return localVarFp.authControllerRefreshLogin(inlineObject6, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Usuarios} [usuarios] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerSignup(usuarios?: Usuarios, options?: any): AxiosPromise<Usuarios> {
            return localVarFp.authControllerSignup(usuarios, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AuthControllerApi - object-oriented interface
 * @export
 * @class AuthControllerApi
 * @extends {BaseAPI}
 */
export class AuthControllerApi extends BaseAPI {
    /**
     * 
     * @param {InlineObject} inlineObject 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthControllerApi
     */
    public authControllerLogin(inlineObject: InlineObject, options?: AxiosRequestConfig) {
        return AuthControllerApiFp(this.configuration).authControllerLogin(inlineObject, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthControllerApi
     */
    public authControllerMe(options?: AxiosRequestConfig) {
        return AuthControllerApiFp(this.configuration).authControllerMe(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InlineObject1} inlineObject1 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthControllerApi
     */
    public authControllerRefresh(inlineObject1: InlineObject1, options?: AxiosRequestConfig) {
        return AuthControllerApiFp(this.configuration).authControllerRefresh(inlineObject1, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InlineObject6} inlineObject6 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthControllerApi
     */
    public authControllerRefreshLogin(inlineObject6: InlineObject6, options?: AxiosRequestConfig) {
        return AuthControllerApiFp(this.configuration).authControllerRefreshLogin(inlineObject6, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Usuarios} [usuarios] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthControllerApi
     */
    public authControllerSignup(usuarios?: Usuarios, options?: AxiosRequestConfig) {
        return AuthControllerApiFp(this.configuration).authControllerSignup(usuarios, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * B2bProductosControllerApi - axios parameter creator
 * @export
 */
export const B2bProductosControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        b2bProductosControllerCount: async (where?: { [key: string]: object; }, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/B2bProductos/count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (where !== undefined) {
                localVarQueryParameter['where'] = where;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {B2bProductosFilter} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        b2bProductosControllerFind: async (filter?: B2bProductosFilter, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/B2bProductos`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        b2bProductosControllerLimpiaatributos: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/limpiaatributos`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [filtro1] 
         * @param {string} [filtro2] 
         * @param {string} [filtro3] 
         * @param {boolean} [multiempresas] 
         * @param {string} [cliente] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        b2bProductosControllerObtenerProductosFiltrados: async (filtro1?: string, filtro2?: string, filtro3?: string, multiempresas?: boolean, cliente?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/B2bProductos/obtenerProductosFiltrados`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filtro1 !== undefined) {
                localVarQueryParameter['filtro1'] = filtro1;
            }

            if (filtro2 !== undefined) {
                localVarQueryParameter['filtro2'] = filtro2;
            }

            if (filtro3 !== undefined) {
                localVarQueryParameter['filtro3'] = filtro3;
            }

            if (multiempresas !== undefined) {
                localVarQueryParameter['multiempresas'] = multiempresas;
            }

            if (cliente !== undefined) {
                localVarQueryParameter['cliente'] = cliente;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nombre 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        b2bProductosControllerQueryFullProducts: async (nombre: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nombre' is not null or undefined
            assertParamExists('b2bProductosControllerQueryFullProducts', 'nombre', nombre)
            const localVarPath = `/B2bProductos/{nombre}`
                .replace(`{${"nombre"}}`, encodeURIComponent(String(nombre)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * B2bProductosControllerApi - functional programming interface
 * @export
 */
export const B2bProductosControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = B2bProductosControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async b2bProductosControllerCount(where?: { [key: string]: object; }, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoopbackCount>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.b2bProductosControllerCount(where, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {B2bProductosFilter} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async b2bProductosControllerFind(filter?: B2bProductosFilter, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<B2bProductosWithRelations>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.b2bProductosControllerFind(filter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async b2bProductosControllerLimpiaatributos(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<B2bProductosWithRelations>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.b2bProductosControllerLimpiaatributos(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [filtro1] 
         * @param {string} [filtro2] 
         * @param {string} [filtro3] 
         * @param {boolean} [multiempresas] 
         * @param {string} [cliente] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async b2bProductosControllerObtenerProductosFiltrados(filtro1?: string, filtro2?: string, filtro3?: string, multiempresas?: boolean, cliente?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<B2bProductosWithRelations>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.b2bProductosControllerObtenerProductosFiltrados(filtro1, filtro2, filtro3, multiempresas, cliente, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} nombre 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async b2bProductosControllerQueryFullProducts(nombre: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<B2bProductosWithRelations>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.b2bProductosControllerQueryFullProducts(nombre, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * B2bProductosControllerApi - factory interface
 * @export
 */
export const B2bProductosControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = B2bProductosControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        b2bProductosControllerCount(where?: { [key: string]: object; }, options?: any): AxiosPromise<LoopbackCount> {
            return localVarFp.b2bProductosControllerCount(where, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {B2bProductosFilter} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        b2bProductosControllerFind(filter?: B2bProductosFilter, options?: any): AxiosPromise<Array<B2bProductosWithRelations>> {
            return localVarFp.b2bProductosControllerFind(filter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        b2bProductosControllerLimpiaatributos(options?: any): AxiosPromise<Array<B2bProductosWithRelations>> {
            return localVarFp.b2bProductosControllerLimpiaatributos(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [filtro1] 
         * @param {string} [filtro2] 
         * @param {string} [filtro3] 
         * @param {boolean} [multiempresas] 
         * @param {string} [cliente] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        b2bProductosControllerObtenerProductosFiltrados(filtro1?: string, filtro2?: string, filtro3?: string, multiempresas?: boolean, cliente?: string, options?: any): AxiosPromise<Array<B2bProductosWithRelations>> {
            return localVarFp.b2bProductosControllerObtenerProductosFiltrados(filtro1, filtro2, filtro3, multiempresas, cliente, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} nombre 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        b2bProductosControllerQueryFullProducts(nombre: string, options?: any): AxiosPromise<Array<B2bProductosWithRelations>> {
            return localVarFp.b2bProductosControllerQueryFullProducts(nombre, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * B2bProductosControllerApi - object-oriented interface
 * @export
 * @class B2bProductosControllerApi
 * @extends {BaseAPI}
 */
export class B2bProductosControllerApi extends BaseAPI {
    /**
     * 
     * @param {{ [key: string]: object; }} [where] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof B2bProductosControllerApi
     */
    public b2bProductosControllerCount(where?: { [key: string]: object; }, options?: AxiosRequestConfig) {
        return B2bProductosControllerApiFp(this.configuration).b2bProductosControllerCount(where, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {B2bProductosFilter} [filter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof B2bProductosControllerApi
     */
    public b2bProductosControllerFind(filter?: B2bProductosFilter, options?: AxiosRequestConfig) {
        return B2bProductosControllerApiFp(this.configuration).b2bProductosControllerFind(filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof B2bProductosControllerApi
     */
    public b2bProductosControllerLimpiaatributos(options?: AxiosRequestConfig) {
        return B2bProductosControllerApiFp(this.configuration).b2bProductosControllerLimpiaatributos(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [filtro1] 
     * @param {string} [filtro2] 
     * @param {string} [filtro3] 
     * @param {boolean} [multiempresas] 
     * @param {string} [cliente] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof B2bProductosControllerApi
     */
    public b2bProductosControllerObtenerProductosFiltrados(filtro1?: string, filtro2?: string, filtro3?: string, multiempresas?: boolean, cliente?: string, options?: AxiosRequestConfig) {
        return B2bProductosControllerApiFp(this.configuration).b2bProductosControllerObtenerProductosFiltrados(filtro1, filtro2, filtro3, multiempresas, cliente, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} nombre 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof B2bProductosControllerApi
     */
    public b2bProductosControllerQueryFullProducts(nombre: string, options?: AxiosRequestConfig) {
        return B2bProductosControllerApiFp(this.configuration).b2bProductosControllerQueryFullProducts(nombre, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CatalogosControllerApi - axios parameter creator
 * @export
 */
export const CatalogosControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        catalogosControllerCount: async (where?: { [key: string]: object; }, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/catalogos/count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (where !== undefined) {
                localVarQueryParameter['where'] = where;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {NewCatalogos} [newCatalogos] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        catalogosControllerCreate: async (newCatalogos?: NewCatalogos, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/catalogos`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(newCatalogos, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        catalogosControllerDeleteById: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('catalogosControllerDeleteById', 'id', id)
            const localVarPath = `/catalogos/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CatalogosFilter1} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        catalogosControllerFind: async (filter?: CatalogosFilter1, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/catalogos`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {CatalogosFilter} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        catalogosControllerFindById: async (id: number, filter?: CatalogosFilter, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('catalogosControllerFindById', 'id', id)
            const localVarPath = `/catalogos/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {Catalogos} [catalogos] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        catalogosControllerReplaceById: async (id: number, catalogos?: Catalogos, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('catalogosControllerReplaceById', 'id', id)
            const localVarPath = `/catalogos/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(catalogos, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {CatalogosPartial} [catalogosPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        catalogosControllerUpdateAll: async (where?: { [key: string]: object; }, catalogosPartial?: CatalogosPartial, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/catalogos`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (where !== undefined) {
                localVarQueryParameter['where'] = where;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(catalogosPartial, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {CatalogosPartial} [catalogosPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        catalogosControllerUpdateById: async (id: number, catalogosPartial?: CatalogosPartial, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('catalogosControllerUpdateById', 'id', id)
            const localVarPath = `/catalogos/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(catalogosPartial, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CatalogosControllerApi - functional programming interface
 * @export
 */
export const CatalogosControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CatalogosControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async catalogosControllerCount(where?: { [key: string]: object; }, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoopbackCount>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.catalogosControllerCount(where, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {NewCatalogos} [newCatalogos] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async catalogosControllerCreate(newCatalogos?: NewCatalogos, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Catalogos>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.catalogosControllerCreate(newCatalogos, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async catalogosControllerDeleteById(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.catalogosControllerDeleteById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CatalogosFilter1} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async catalogosControllerFind(filter?: CatalogosFilter1, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CatalogosWithRelations>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.catalogosControllerFind(filter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {CatalogosFilter} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async catalogosControllerFindById(id: number, filter?: CatalogosFilter, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CatalogosWithRelations>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.catalogosControllerFindById(id, filter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {Catalogos} [catalogos] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async catalogosControllerReplaceById(id: number, catalogos?: Catalogos, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.catalogosControllerReplaceById(id, catalogos, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {CatalogosPartial} [catalogosPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async catalogosControllerUpdateAll(where?: { [key: string]: object; }, catalogosPartial?: CatalogosPartial, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoopbackCount>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.catalogosControllerUpdateAll(where, catalogosPartial, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {CatalogosPartial} [catalogosPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async catalogosControllerUpdateById(id: number, catalogosPartial?: CatalogosPartial, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.catalogosControllerUpdateById(id, catalogosPartial, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CatalogosControllerApi - factory interface
 * @export
 */
export const CatalogosControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CatalogosControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        catalogosControllerCount(where?: { [key: string]: object; }, options?: any): AxiosPromise<LoopbackCount> {
            return localVarFp.catalogosControllerCount(where, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {NewCatalogos} [newCatalogos] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        catalogosControllerCreate(newCatalogos?: NewCatalogos, options?: any): AxiosPromise<Catalogos> {
            return localVarFp.catalogosControllerCreate(newCatalogos, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        catalogosControllerDeleteById(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.catalogosControllerDeleteById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CatalogosFilter1} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        catalogosControllerFind(filter?: CatalogosFilter1, options?: any): AxiosPromise<Array<CatalogosWithRelations>> {
            return localVarFp.catalogosControllerFind(filter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {CatalogosFilter} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        catalogosControllerFindById(id: number, filter?: CatalogosFilter, options?: any): AxiosPromise<CatalogosWithRelations> {
            return localVarFp.catalogosControllerFindById(id, filter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {Catalogos} [catalogos] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        catalogosControllerReplaceById(id: number, catalogos?: Catalogos, options?: any): AxiosPromise<void> {
            return localVarFp.catalogosControllerReplaceById(id, catalogos, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {CatalogosPartial} [catalogosPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        catalogosControllerUpdateAll(where?: { [key: string]: object; }, catalogosPartial?: CatalogosPartial, options?: any): AxiosPromise<LoopbackCount> {
            return localVarFp.catalogosControllerUpdateAll(where, catalogosPartial, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {CatalogosPartial} [catalogosPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        catalogosControllerUpdateById(id: number, catalogosPartial?: CatalogosPartial, options?: any): AxiosPromise<void> {
            return localVarFp.catalogosControllerUpdateById(id, catalogosPartial, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CatalogosControllerApi - object-oriented interface
 * @export
 * @class CatalogosControllerApi
 * @extends {BaseAPI}
 */
export class CatalogosControllerApi extends BaseAPI {
    /**
     * 
     * @param {{ [key: string]: object; }} [where] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CatalogosControllerApi
     */
    public catalogosControllerCount(where?: { [key: string]: object; }, options?: AxiosRequestConfig) {
        return CatalogosControllerApiFp(this.configuration).catalogosControllerCount(where, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {NewCatalogos} [newCatalogos] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CatalogosControllerApi
     */
    public catalogosControllerCreate(newCatalogos?: NewCatalogos, options?: AxiosRequestConfig) {
        return CatalogosControllerApiFp(this.configuration).catalogosControllerCreate(newCatalogos, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CatalogosControllerApi
     */
    public catalogosControllerDeleteById(id: number, options?: AxiosRequestConfig) {
        return CatalogosControllerApiFp(this.configuration).catalogosControllerDeleteById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CatalogosFilter1} [filter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CatalogosControllerApi
     */
    public catalogosControllerFind(filter?: CatalogosFilter1, options?: AxiosRequestConfig) {
        return CatalogosControllerApiFp(this.configuration).catalogosControllerFind(filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {CatalogosFilter} [filter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CatalogosControllerApi
     */
    public catalogosControllerFindById(id: number, filter?: CatalogosFilter, options?: AxiosRequestConfig) {
        return CatalogosControllerApiFp(this.configuration).catalogosControllerFindById(id, filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {Catalogos} [catalogos] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CatalogosControllerApi
     */
    public catalogosControllerReplaceById(id: number, catalogos?: Catalogos, options?: AxiosRequestConfig) {
        return CatalogosControllerApiFp(this.configuration).catalogosControllerReplaceById(id, catalogos, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} [where] 
     * @param {CatalogosPartial} [catalogosPartial] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CatalogosControllerApi
     */
    public catalogosControllerUpdateAll(where?: { [key: string]: object; }, catalogosPartial?: CatalogosPartial, options?: AxiosRequestConfig) {
        return CatalogosControllerApiFp(this.configuration).catalogosControllerUpdateAll(where, catalogosPartial, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {CatalogosPartial} [catalogosPartial] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CatalogosControllerApi
     */
    public catalogosControllerUpdateById(id: number, catalogosPartial?: CatalogosPartial, options?: AxiosRequestConfig) {
        return CatalogosControllerApiFp(this.configuration).catalogosControllerUpdateById(id, catalogosPartial, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CatalogosProductosControllerApi - axios parameter creator
 * @export
 */
export const CatalogosProductosControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {Array<InlineObject>} [inlineObject] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        catalogosProductosControllerAgregarMultiplesProductosAlCatalogo: async (inlineObject?: Array<InlineObject>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/agregar-multiples-productos-al-catalogo`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inlineObject, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        catalogosProductosControllerCount: async (where?: { [key: string]: object; }, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/catalogos-productos/count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (where !== undefined) {
                localVarQueryParameter['where'] = where;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {NewCatalogosProductos} [newCatalogosProductos] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        catalogosProductosControllerCreate: async (newCatalogosProductos?: NewCatalogosProductos, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/catalogos-productos`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(newCatalogosProductos, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        catalogosProductosControllerDeleteById: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('catalogosProductosControllerDeleteById', 'id', id)
            const localVarPath = `/catalogos-productos/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {InlineObject2} [inlineObject2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        catalogosProductosControllerEliminarMultiplesProductosDelCatalogo: async (inlineObject2?: InlineObject2, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/eliminar-multiples-productos-del-catalogo`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inlineObject2, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} catalogoId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        catalogosProductosControllerEliminarProductosPorCatalogo: async (catalogoId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'catalogoId' is not null or undefined
            assertParamExists('catalogosProductosControllerEliminarProductosPorCatalogo', 'catalogoId', catalogoId)
            const localVarPath = `/catalogos-productos/eliminar-por-catalogo/{catalogoId}`
                .replace(`{${"catalogoId"}}`, encodeURIComponent(String(catalogoId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CatalogosProductosFilter1} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        catalogosProductosControllerFind: async (filter?: CatalogosProductosFilter1, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/catalogos-productos`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {CatalogosProductosFilter} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        catalogosProductosControllerFindById: async (id: number, filter?: CatalogosProductosFilter, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('catalogosProductosControllerFindById', 'id', id)
            const localVarPath = `/catalogos-productos/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        catalogosProductosControllerObtenerReferenciasDeAgrupacion: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/obtenerReferenciasDeAgrupacion`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {CatalogosProductos} [catalogosProductos] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        catalogosProductosControllerReplaceById: async (id: number, catalogosProductos?: CatalogosProductos, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('catalogosProductosControllerReplaceById', 'id', id)
            const localVarPath = `/catalogos-productos/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(catalogosProductos, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {CatalogosProductosPartial} [catalogosProductosPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        catalogosProductosControllerUpdateAll: async (where?: { [key: string]: object; }, catalogosProductosPartial?: CatalogosProductosPartial, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/catalogos-productos`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (where !== undefined) {
                localVarQueryParameter['where'] = where;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(catalogosProductosPartial, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {CatalogosProductosPartial} [catalogosProductosPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        catalogosProductosControllerUpdateById: async (id: number, catalogosProductosPartial?: CatalogosProductosPartial, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('catalogosProductosControllerUpdateById', 'id', id)
            const localVarPath = `/catalogos-productos/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(catalogosProductosPartial, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CatalogosProductosControllerApi - functional programming interface
 * @export
 */
export const CatalogosProductosControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CatalogosProductosControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {Array<InlineObject>} [inlineObject] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async catalogosProductosControllerAgregarMultiplesProductosAlCatalogo(inlineObject?: Array<InlineObject>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CatalogosProductos>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.catalogosProductosControllerAgregarMultiplesProductosAlCatalogo(inlineObject, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async catalogosProductosControllerCount(where?: { [key: string]: object; }, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoopbackCount>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.catalogosProductosControllerCount(where, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {NewCatalogosProductos} [newCatalogosProductos] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async catalogosProductosControllerCreate(newCatalogosProductos?: NewCatalogosProductos, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CatalogosProductos>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.catalogosProductosControllerCreate(newCatalogosProductos, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async catalogosProductosControllerDeleteById(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.catalogosProductosControllerDeleteById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {InlineObject2} [inlineObject2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async catalogosProductosControllerEliminarMultiplesProductosDelCatalogo(inlineObject2?: InlineObject2, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2002>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.catalogosProductosControllerEliminarMultiplesProductosDelCatalogo(inlineObject2, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} catalogoId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async catalogosProductosControllerEliminarProductosPorCatalogo(catalogoId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.catalogosProductosControllerEliminarProductosPorCatalogo(catalogoId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CatalogosProductosFilter1} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async catalogosProductosControllerFind(filter?: CatalogosProductosFilter1, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CatalogosProductosWithRelations>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.catalogosProductosControllerFind(filter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {CatalogosProductosFilter} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async catalogosProductosControllerFindById(id: number, filter?: CatalogosProductosFilter, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CatalogosProductosWithRelations>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.catalogosProductosControllerFindById(id, filter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async catalogosProductosControllerObtenerReferenciasDeAgrupacion(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.catalogosProductosControllerObtenerReferenciasDeAgrupacion(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {CatalogosProductos} [catalogosProductos] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async catalogosProductosControllerReplaceById(id: number, catalogosProductos?: CatalogosProductos, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.catalogosProductosControllerReplaceById(id, catalogosProductos, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {CatalogosProductosPartial} [catalogosProductosPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async catalogosProductosControllerUpdateAll(where?: { [key: string]: object; }, catalogosProductosPartial?: CatalogosProductosPartial, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoopbackCount>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.catalogosProductosControllerUpdateAll(where, catalogosProductosPartial, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {CatalogosProductosPartial} [catalogosProductosPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async catalogosProductosControllerUpdateById(id: number, catalogosProductosPartial?: CatalogosProductosPartial, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.catalogosProductosControllerUpdateById(id, catalogosProductosPartial, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CatalogosProductosControllerApi - factory interface
 * @export
 */
export const CatalogosProductosControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CatalogosProductosControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {Array<InlineObject>} [inlineObject] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        catalogosProductosControllerAgregarMultiplesProductosAlCatalogo(inlineObject?: Array<InlineObject>, options?: any): AxiosPromise<Array<CatalogosProductos>> {
            return localVarFp.catalogosProductosControllerAgregarMultiplesProductosAlCatalogo(inlineObject, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        catalogosProductosControllerCount(where?: { [key: string]: object; }, options?: any): AxiosPromise<LoopbackCount> {
            return localVarFp.catalogosProductosControllerCount(where, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {NewCatalogosProductos} [newCatalogosProductos] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        catalogosProductosControllerCreate(newCatalogosProductos?: NewCatalogosProductos, options?: any): AxiosPromise<CatalogosProductos> {
            return localVarFp.catalogosProductosControllerCreate(newCatalogosProductos, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        catalogosProductosControllerDeleteById(id: number, options?: any): AxiosPromise<any> {
            return localVarFp.catalogosProductosControllerDeleteById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {InlineObject2} [inlineObject2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        catalogosProductosControllerEliminarMultiplesProductosDelCatalogo(inlineObject2?: InlineObject2, options?: any): AxiosPromise<InlineResponse2002> {
            return localVarFp.catalogosProductosControllerEliminarMultiplesProductosDelCatalogo(inlineObject2, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} catalogoId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        catalogosProductosControllerEliminarProductosPorCatalogo(catalogoId: number, options?: any): AxiosPromise<any> {
            return localVarFp.catalogosProductosControllerEliminarProductosPorCatalogo(catalogoId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CatalogosProductosFilter1} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        catalogosProductosControllerFind(filter?: CatalogosProductosFilter1, options?: any): AxiosPromise<Array<CatalogosProductosWithRelations>> {
            return localVarFp.catalogosProductosControllerFind(filter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {CatalogosProductosFilter} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        catalogosProductosControllerFindById(id: number, filter?: CatalogosProductosFilter, options?: any): AxiosPromise<CatalogosProductosWithRelations> {
            return localVarFp.catalogosProductosControllerFindById(id, filter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        catalogosProductosControllerObtenerReferenciasDeAgrupacion(options?: any): AxiosPromise<object> {
            return localVarFp.catalogosProductosControllerObtenerReferenciasDeAgrupacion(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {CatalogosProductos} [catalogosProductos] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        catalogosProductosControllerReplaceById(id: number, catalogosProductos?: CatalogosProductos, options?: any): AxiosPromise<any> {
            return localVarFp.catalogosProductosControllerReplaceById(id, catalogosProductos, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {CatalogosProductosPartial} [catalogosProductosPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        catalogosProductosControllerUpdateAll(where?: { [key: string]: object; }, catalogosProductosPartial?: CatalogosProductosPartial, options?: any): AxiosPromise<LoopbackCount> {
            return localVarFp.catalogosProductosControllerUpdateAll(where, catalogosProductosPartial, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {CatalogosProductosPartial} [catalogosProductosPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        catalogosProductosControllerUpdateById(id: number, catalogosProductosPartial?: CatalogosProductosPartial, options?: any): AxiosPromise<any> {
            return localVarFp.catalogosProductosControllerUpdateById(id, catalogosProductosPartial, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CatalogosProductosControllerApi - object-oriented interface
 * @export
 * @class CatalogosProductosControllerApi
 * @extends {BaseAPI}
 */
export class CatalogosProductosControllerApi extends BaseAPI {
    /**
     * 
     * @param {Array<InlineObject>} [inlineObject] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CatalogosProductosControllerApi
     */
    public catalogosProductosControllerAgregarMultiplesProductosAlCatalogo(inlineObject?: Array<InlineObject>, options?: AxiosRequestConfig) {
        return CatalogosProductosControllerApiFp(this.configuration).catalogosProductosControllerAgregarMultiplesProductosAlCatalogo(inlineObject, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} [where] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CatalogosProductosControllerApi
     */
    public catalogosProductosControllerCount(where?: { [key: string]: object; }, options?: AxiosRequestConfig) {
        return CatalogosProductosControllerApiFp(this.configuration).catalogosProductosControllerCount(where, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {NewCatalogosProductos} [newCatalogosProductos] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CatalogosProductosControllerApi
     */
    public catalogosProductosControllerCreate(newCatalogosProductos?: NewCatalogosProductos, options?: AxiosRequestConfig) {
        return CatalogosProductosControllerApiFp(this.configuration).catalogosProductosControllerCreate(newCatalogosProductos, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CatalogosProductosControllerApi
     */
    public catalogosProductosControllerDeleteById(id: number, options?: AxiosRequestConfig) {
        return CatalogosProductosControllerApiFp(this.configuration).catalogosProductosControllerDeleteById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InlineObject2} [inlineObject2] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CatalogosProductosControllerApi
     */
    public catalogosProductosControllerEliminarMultiplesProductosDelCatalogo(inlineObject2?: InlineObject2, options?: AxiosRequestConfig) {
        return CatalogosProductosControllerApiFp(this.configuration).catalogosProductosControllerEliminarMultiplesProductosDelCatalogo(inlineObject2, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} catalogoId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CatalogosProductosControllerApi
     */
    public catalogosProductosControllerEliminarProductosPorCatalogo(catalogoId: number, options?: AxiosRequestConfig) {
        return CatalogosProductosControllerApiFp(this.configuration).catalogosProductosControllerEliminarProductosPorCatalogo(catalogoId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CatalogosProductosFilter1} [filter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CatalogosProductosControllerApi
     */
    public catalogosProductosControllerFind(filter?: CatalogosProductosFilter1, options?: AxiosRequestConfig) {
        return CatalogosProductosControllerApiFp(this.configuration).catalogosProductosControllerFind(filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {CatalogosProductosFilter} [filter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CatalogosProductosControllerApi
     */
    public catalogosProductosControllerFindById(id: number, filter?: CatalogosProductosFilter, options?: AxiosRequestConfig) {
        return CatalogosProductosControllerApiFp(this.configuration).catalogosProductosControllerFindById(id, filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CatalogosProductosControllerApi
     */
    public catalogosProductosControllerObtenerReferenciasDeAgrupacion(options?: AxiosRequestConfig) {
        return CatalogosProductosControllerApiFp(this.configuration).catalogosProductosControllerObtenerReferenciasDeAgrupacion(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {CatalogosProductos} [catalogosProductos] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CatalogosProductosControllerApi
     */
    public catalogosProductosControllerReplaceById(id: number, catalogosProductos?: CatalogosProductos, options?: AxiosRequestConfig) {
        return CatalogosProductosControllerApiFp(this.configuration).catalogosProductosControllerReplaceById(id, catalogosProductos, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} [where] 
     * @param {CatalogosProductosPartial} [catalogosProductosPartial] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CatalogosProductosControllerApi
     */
    public catalogosProductosControllerUpdateAll(where?: { [key: string]: object; }, catalogosProductosPartial?: CatalogosProductosPartial, options?: AxiosRequestConfig) {
        return CatalogosProductosControllerApiFp(this.configuration).catalogosProductosControllerUpdateAll(where, catalogosProductosPartial, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {CatalogosProductosPartial} [catalogosProductosPartial] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CatalogosProductosControllerApi
     */
    public catalogosProductosControllerUpdateById(id: number, catalogosProductosPartial?: CatalogosProductosPartial, options?: AxiosRequestConfig) {
        return CatalogosProductosControllerApiFp(this.configuration).catalogosProductosControllerUpdateById(id, catalogosProductosPartial, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CategoriasControllerApi - axios parameter creator
 * @export
 */
export const CategoriasControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        categoriasControllerCount: async (where?: { [key: string]: object; }, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/categorias/count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (where !== undefined) {
                localVarQueryParameter['where'] = where;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {NewCategorias} [newCategorias] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        categoriasControllerCreate: async (newCategorias?: NewCategorias, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/categorias`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(newCategorias, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        categoriasControllerDeleteById: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('categoriasControllerDeleteById', 'id', id)
            const localVarPath = `/categorias/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CategoriasFilter1} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        categoriasControllerFind: async (filter?: CategoriasFilter1, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/categorias`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {CategoriasFilter} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        categoriasControllerFindById: async (id: number, filter?: CategoriasFilter, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('categoriasControllerFindById', 'id', id)
            const localVarPath = `/categorias/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {Categorias} [categorias] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        categoriasControllerReplaceById: async (id: number, categorias?: Categorias, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('categoriasControllerReplaceById', 'id', id)
            const localVarPath = `/categorias/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(categorias, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {CategoriasPartial} [categoriasPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        categoriasControllerUpdateAll: async (where?: { [key: string]: object; }, categoriasPartial?: CategoriasPartial, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/categorias`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (where !== undefined) {
                localVarQueryParameter['where'] = where;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(categoriasPartial, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {CategoriasPartial} [categoriasPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        categoriasControllerUpdateById: async (id: number, categoriasPartial?: CategoriasPartial, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('categoriasControllerUpdateById', 'id', id)
            const localVarPath = `/categorias/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(categoriasPartial, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CategoriasControllerApi - functional programming interface
 * @export
 */
export const CategoriasControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CategoriasControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async categoriasControllerCount(where?: { [key: string]: object; }, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoopbackCount>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.categoriasControllerCount(where, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {NewCategorias} [newCategorias] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async categoriasControllerCreate(newCategorias?: NewCategorias, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Categorias>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.categoriasControllerCreate(newCategorias, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async categoriasControllerDeleteById(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.categoriasControllerDeleteById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CategoriasFilter1} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async categoriasControllerFind(filter?: CategoriasFilter1, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CategoriasWithRelations>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.categoriasControllerFind(filter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {CategoriasFilter} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async categoriasControllerFindById(id: number, filter?: CategoriasFilter, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CategoriasWithRelations>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.categoriasControllerFindById(id, filter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {Categorias} [categorias] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async categoriasControllerReplaceById(id: number, categorias?: Categorias, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.categoriasControllerReplaceById(id, categorias, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {CategoriasPartial} [categoriasPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async categoriasControllerUpdateAll(where?: { [key: string]: object; }, categoriasPartial?: CategoriasPartial, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoopbackCount>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.categoriasControllerUpdateAll(where, categoriasPartial, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {CategoriasPartial} [categoriasPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async categoriasControllerUpdateById(id: number, categoriasPartial?: CategoriasPartial, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.categoriasControllerUpdateById(id, categoriasPartial, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CategoriasControllerApi - factory interface
 * @export
 */
export const CategoriasControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CategoriasControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        categoriasControllerCount(where?: { [key: string]: object; }, options?: any): AxiosPromise<LoopbackCount> {
            return localVarFp.categoriasControllerCount(where, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {NewCategorias} [newCategorias] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        categoriasControllerCreate(newCategorias?: NewCategorias, options?: any): AxiosPromise<Categorias> {
            return localVarFp.categoriasControllerCreate(newCategorias, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        categoriasControllerDeleteById(id: number, options?: any): AxiosPromise<any> {
            return localVarFp.categoriasControllerDeleteById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CategoriasFilter1} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        categoriasControllerFind(filter?: CategoriasFilter1, options?: any): AxiosPromise<Array<CategoriasWithRelations>> {
            return localVarFp.categoriasControllerFind(filter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {CategoriasFilter} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        categoriasControllerFindById(id: number, filter?: CategoriasFilter, options?: any): AxiosPromise<CategoriasWithRelations> {
            return localVarFp.categoriasControllerFindById(id, filter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {Categorias} [categorias] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        categoriasControllerReplaceById(id: number, categorias?: Categorias, options?: any): AxiosPromise<any> {
            return localVarFp.categoriasControllerReplaceById(id, categorias, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {CategoriasPartial} [categoriasPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        categoriasControllerUpdateAll(where?: { [key: string]: object; }, categoriasPartial?: CategoriasPartial, options?: any): AxiosPromise<LoopbackCount> {
            return localVarFp.categoriasControllerUpdateAll(where, categoriasPartial, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {CategoriasPartial} [categoriasPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        categoriasControllerUpdateById(id: number, categoriasPartial?: CategoriasPartial, options?: any): AxiosPromise<any> {
            return localVarFp.categoriasControllerUpdateById(id, categoriasPartial, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CategoriasControllerApi - object-oriented interface
 * @export
 * @class CategoriasControllerApi
 * @extends {BaseAPI}
 */
export class CategoriasControllerApi extends BaseAPI {
    /**
     * 
     * @param {{ [key: string]: object; }} [where] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoriasControllerApi
     */
    public categoriasControllerCount(where?: { [key: string]: object; }, options?: AxiosRequestConfig) {
        return CategoriasControllerApiFp(this.configuration).categoriasControllerCount(where, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {NewCategorias} [newCategorias] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoriasControllerApi
     */
    public categoriasControllerCreate(newCategorias?: NewCategorias, options?: AxiosRequestConfig) {
        return CategoriasControllerApiFp(this.configuration).categoriasControllerCreate(newCategorias, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoriasControllerApi
     */
    public categoriasControllerDeleteById(id: number, options?: AxiosRequestConfig) {
        return CategoriasControllerApiFp(this.configuration).categoriasControllerDeleteById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CategoriasFilter1} [filter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoriasControllerApi
     */
    public categoriasControllerFind(filter?: CategoriasFilter1, options?: AxiosRequestConfig) {
        return CategoriasControllerApiFp(this.configuration).categoriasControllerFind(filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {CategoriasFilter} [filter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoriasControllerApi
     */
    public categoriasControllerFindById(id: number, filter?: CategoriasFilter, options?: AxiosRequestConfig) {
        return CategoriasControllerApiFp(this.configuration).categoriasControllerFindById(id, filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {Categorias} [categorias] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoriasControllerApi
     */
    public categoriasControllerReplaceById(id: number, categorias?: Categorias, options?: AxiosRequestConfig) {
        return CategoriasControllerApiFp(this.configuration).categoriasControllerReplaceById(id, categorias, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} [where] 
     * @param {CategoriasPartial} [categoriasPartial] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoriasControllerApi
     */
    public categoriasControllerUpdateAll(where?: { [key: string]: object; }, categoriasPartial?: CategoriasPartial, options?: AxiosRequestConfig) {
        return CategoriasControllerApiFp(this.configuration).categoriasControllerUpdateAll(where, categoriasPartial, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {CategoriasPartial} [categoriasPartial] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoriasControllerApi
     */
    public categoriasControllerUpdateById(id: number, categoriasPartial?: CategoriasPartial, options?: AxiosRequestConfig) {
        return CategoriasControllerApiFp(this.configuration).categoriasControllerUpdateById(id, categoriasPartial, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ClubsControllerApi - axios parameter creator
 * @export
 */
export const ClubsControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clubsControllerCount: async (where?: { [key: string]: object; }, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Clubs/count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (where !== undefined) {
                localVarQueryParameter['where'] = where;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {NewClubs} [newClubs] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clubsControllerCreate: async (newClubs?: NewClubs, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Clubs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(newClubs, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clubsControllerDeleteById: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('clubsControllerDeleteById', 'id', id)
            const localVarPath = `/Clubs/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ClubsFilter1} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clubsControllerFind: async (filter?: ClubsFilter1, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Clubs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {ClubsFilter} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clubsControllerFindById: async (id: number, filter?: ClubsFilter, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('clubsControllerFindById', 'id', id)
            const localVarPath = `/Clubs/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {Clubs} [clubs] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clubsControllerReplaceById: async (id: number, clubs?: Clubs, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('clubsControllerReplaceById', 'id', id)
            const localVarPath = `/Clubs/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(clubs, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {ClubsPartial} [clubsPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clubsControllerUpdateAll: async (where?: { [key: string]: object; }, clubsPartial?: ClubsPartial, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Clubs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (where !== undefined) {
                localVarQueryParameter['where'] = where;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(clubsPartial, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {ClubsPartial} [clubsPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clubsControllerUpdateById: async (id: number, clubsPartial?: ClubsPartial, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('clubsControllerUpdateById', 'id', id)
            const localVarPath = `/Clubs/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(clubsPartial, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ClubsControllerApi - functional programming interface
 * @export
 */
export const ClubsControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ClubsControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clubsControllerCount(where?: { [key: string]: object; }, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoopbackCount>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clubsControllerCount(where, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {NewClubs} [newClubs] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clubsControllerCreate(newClubs?: NewClubs, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Clubs>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clubsControllerCreate(newClubs, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clubsControllerDeleteById(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clubsControllerDeleteById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ClubsFilter1} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clubsControllerFind(filter?: ClubsFilter1, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ClubsWithRelations>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clubsControllerFind(filter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {ClubsFilter} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clubsControllerFindById(id: number, filter?: ClubsFilter, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClubsWithRelations>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clubsControllerFindById(id, filter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {Clubs} [clubs] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clubsControllerReplaceById(id: number, clubs?: Clubs, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clubsControllerReplaceById(id, clubs, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {ClubsPartial} [clubsPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clubsControllerUpdateAll(where?: { [key: string]: object; }, clubsPartial?: ClubsPartial, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoopbackCount>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clubsControllerUpdateAll(where, clubsPartial, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {ClubsPartial} [clubsPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clubsControllerUpdateById(id: number, clubsPartial?: ClubsPartial, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clubsControllerUpdateById(id, clubsPartial, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ClubsControllerApi - factory interface
 * @export
 */
export const ClubsControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ClubsControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clubsControllerCount(where?: { [key: string]: object; }, options?: any): AxiosPromise<LoopbackCount> {
            return localVarFp.clubsControllerCount(where, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {NewClubs} [newClubs] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clubsControllerCreate(newClubs?: NewClubs, options?: any): AxiosPromise<Clubs> {
            return localVarFp.clubsControllerCreate(newClubs, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clubsControllerDeleteById(id: number, options?: any): AxiosPromise<any> {
            return localVarFp.clubsControllerDeleteById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ClubsFilter1} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clubsControllerFind(filter?: ClubsFilter1, options?: any): AxiosPromise<Array<ClubsWithRelations>> {
            return localVarFp.clubsControllerFind(filter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {ClubsFilter} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clubsControllerFindById(id: number, filter?: ClubsFilter, options?: any): AxiosPromise<ClubsWithRelations> {
            return localVarFp.clubsControllerFindById(id, filter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {Clubs} [clubs] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clubsControllerReplaceById(id: number, clubs?: Clubs, options?: any): AxiosPromise<any> {
            return localVarFp.clubsControllerReplaceById(id, clubs, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {ClubsPartial} [clubsPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clubsControllerUpdateAll(where?: { [key: string]: object; }, clubsPartial?: ClubsPartial, options?: any): AxiosPromise<LoopbackCount> {
            return localVarFp.clubsControllerUpdateAll(where, clubsPartial, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {ClubsPartial} [clubsPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clubsControllerUpdateById(id: number, clubsPartial?: ClubsPartial, options?: any): AxiosPromise<any> {
            return localVarFp.clubsControllerUpdateById(id, clubsPartial, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ClubsControllerApi - object-oriented interface
 * @export
 * @class ClubsControllerApi
 * @extends {BaseAPI}
 */
export class ClubsControllerApi extends BaseAPI {
    /**
     * 
     * @param {{ [key: string]: object; }} [where] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClubsControllerApi
     */
    public clubsControllerCount(where?: { [key: string]: object; }, options?: AxiosRequestConfig) {
        return ClubsControllerApiFp(this.configuration).clubsControllerCount(where, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {NewClubs} [newClubs] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClubsControllerApi
     */
    public clubsControllerCreate(newClubs?: NewClubs, options?: AxiosRequestConfig) {
        return ClubsControllerApiFp(this.configuration).clubsControllerCreate(newClubs, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClubsControllerApi
     */
    public clubsControllerDeleteById(id: number, options?: AxiosRequestConfig) {
        return ClubsControllerApiFp(this.configuration).clubsControllerDeleteById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ClubsFilter1} [filter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClubsControllerApi
     */
    public clubsControllerFind(filter?: ClubsFilter1, options?: AxiosRequestConfig) {
        return ClubsControllerApiFp(this.configuration).clubsControllerFind(filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {ClubsFilter} [filter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClubsControllerApi
     */
    public clubsControllerFindById(id: number, filter?: ClubsFilter, options?: AxiosRequestConfig) {
        return ClubsControllerApiFp(this.configuration).clubsControllerFindById(id, filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {Clubs} [clubs] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClubsControllerApi
     */
    public clubsControllerReplaceById(id: number, clubs?: Clubs, options?: AxiosRequestConfig) {
        return ClubsControllerApiFp(this.configuration).clubsControllerReplaceById(id, clubs, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} [where] 
     * @param {ClubsPartial} [clubsPartial] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClubsControllerApi
     */
    public clubsControllerUpdateAll(where?: { [key: string]: object; }, clubsPartial?: ClubsPartial, options?: AxiosRequestConfig) {
        return ClubsControllerApiFp(this.configuration).clubsControllerUpdateAll(where, clubsPartial, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {ClubsPartial} [clubsPartial] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClubsControllerApi
     */
    public clubsControllerUpdateById(id: number, clubsPartial?: ClubsPartial, options?: AxiosRequestConfig) {
        return ClubsControllerApiFp(this.configuration).clubsControllerUpdateById(id, clubsPartial, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ConexionNavisionControllerApi - axios parameter creator
 * @export
 */
export const ConexionNavisionControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        conexionNavisionControllerGetToken: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/token`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [$endpoint] 
         * @param {string} [$filter] 
         * @param {number} [$top] 
         * @param {number} [$skip] 
         * @param {string} [$orderby] 
         * @param {string} [$expand] 
         * @param {boolean} [$count] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        conexionNavisionControllerGetendPoint: async ($endpoint?: string, $filter?: string, $top?: number, $skip?: number, $orderby?: string, $expand?: string, $count?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/endpoint`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if ($endpoint !== undefined) {
                localVarQueryParameter['$endpoint'] = $endpoint;
            }

            if ($filter !== undefined) {
                localVarQueryParameter['$filter'] = $filter;
            }

            if ($top !== undefined) {
                localVarQueryParameter['$top'] = $top;
            }

            if ($skip !== undefined) {
                localVarQueryParameter['$skip'] = $skip;
            }

            if ($orderby !== undefined) {
                localVarQueryParameter['$orderby'] = $orderby;
            }

            if ($expand !== undefined) {
                localVarQueryParameter['$expand'] = $expand;
            }

            if ($count !== undefined) {
                localVarQueryParameter['$count'] = $count;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [$endpoint] 
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        conexionNavisionControllerPostEliminarProductoDeBC: async ($endpoint?: string, body?: object, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/postEliminarProductoDeBC`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if ($endpoint !== undefined) {
                localVarQueryParameter['$endpoint'] = $endpoint;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [$endpoint] 
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        conexionNavisionControllerPostEndpointBC: async ($endpoint?: string, body?: object, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/postEndpointBC`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if ($endpoint !== undefined) {
                localVarQueryParameter['$endpoint'] = $endpoint;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [$endpoint] 
         * @param {string} [$desdeWEB] 
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        conexionNavisionControllerSincronizarProductosEnBC: async ($endpoint?: string, $desdeWEB?: string, body?: object, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/sincronizarProductosEnBC`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if ($endpoint !== undefined) {
                localVarQueryParameter['$endpoint'] = $endpoint;
            }

            if ($desdeWEB !== undefined) {
                localVarQueryParameter['$desdeWEB'] = $desdeWEB;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ConexionNavisionControllerApi - functional programming interface
 * @export
 */
export const ConexionNavisionControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ConexionNavisionControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async conexionNavisionControllerGetToken(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.conexionNavisionControllerGetToken(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [$endpoint] 
         * @param {string} [$filter] 
         * @param {number} [$top] 
         * @param {number} [$skip] 
         * @param {string} [$orderby] 
         * @param {string} [$expand] 
         * @param {boolean} [$count] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async conexionNavisionControllerGetendPoint($endpoint?: string, $filter?: string, $top?: number, $skip?: number, $orderby?: string, $expand?: string, $count?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.conexionNavisionControllerGetendPoint($endpoint, $filter, $top, $skip, $orderby, $expand, $count, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [$endpoint] 
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async conexionNavisionControllerPostEliminarProductoDeBC($endpoint?: string, body?: object, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.conexionNavisionControllerPostEliminarProductoDeBC($endpoint, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [$endpoint] 
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async conexionNavisionControllerPostEndpointBC($endpoint?: string, body?: object, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.conexionNavisionControllerPostEndpointBC($endpoint, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [$endpoint] 
         * @param {string} [$desdeWEB] 
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async conexionNavisionControllerSincronizarProductosEnBC($endpoint?: string, $desdeWEB?: string, body?: object, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.conexionNavisionControllerSincronizarProductosEnBC($endpoint, $desdeWEB, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ConexionNavisionControllerApi - factory interface
 * @export
 */
export const ConexionNavisionControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ConexionNavisionControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        conexionNavisionControllerGetToken(options?: any): AxiosPromise<object> {
            return localVarFp.conexionNavisionControllerGetToken(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [$endpoint] 
         * @param {string} [$filter] 
         * @param {number} [$top] 
         * @param {number} [$skip] 
         * @param {string} [$orderby] 
         * @param {string} [$expand] 
         * @param {boolean} [$count] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        conexionNavisionControllerGetendPoint($endpoint?: string, $filter?: string, $top?: number, $skip?: number, $orderby?: string, $expand?: string, $count?: boolean, options?: any): AxiosPromise<object> {
            return localVarFp.conexionNavisionControllerGetendPoint($endpoint, $filter, $top, $skip, $orderby, $expand, $count, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [$endpoint] 
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        conexionNavisionControllerPostEliminarProductoDeBC($endpoint?: string, body?: object, options?: any): AxiosPromise<object> {
            return localVarFp.conexionNavisionControllerPostEliminarProductoDeBC($endpoint, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [$endpoint] 
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        conexionNavisionControllerPostEndpointBC($endpoint?: string, body?: object, options?: any): AxiosPromise<object> {
            return localVarFp.conexionNavisionControllerPostEndpointBC($endpoint, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [$endpoint] 
         * @param {string} [$desdeWEB] 
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        conexionNavisionControllerSincronizarProductosEnBC($endpoint?: string, $desdeWEB?: string, body?: object, options?: any): AxiosPromise<object> {
            return localVarFp.conexionNavisionControllerSincronizarProductosEnBC($endpoint, $desdeWEB, body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ConexionNavisionControllerApi - object-oriented interface
 * @export
 * @class ConexionNavisionControllerApi
 * @extends {BaseAPI}
 */
export class ConexionNavisionControllerApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConexionNavisionControllerApi
     */
    public conexionNavisionControllerGetToken(options?: AxiosRequestConfig) {
        return ConexionNavisionControllerApiFp(this.configuration).conexionNavisionControllerGetToken(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [$endpoint] 
     * @param {string} [$filter] 
     * @param {number} [$top] 
     * @param {number} [$skip] 
     * @param {string} [$orderby] 
     * @param {string} [$expand] 
     * @param {boolean} [$count] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConexionNavisionControllerApi
     */
    public conexionNavisionControllerGetendPoint($endpoint?: string, $filter?: string, $top?: number, $skip?: number, $orderby?: string, $expand?: string, $count?: boolean, options?: AxiosRequestConfig) {
        return ConexionNavisionControllerApiFp(this.configuration).conexionNavisionControllerGetendPoint($endpoint, $filter, $top, $skip, $orderby, $expand, $count, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [$endpoint] 
     * @param {object} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConexionNavisionControllerApi
     */
    public conexionNavisionControllerPostEliminarProductoDeBC($endpoint?: string, body?: object, options?: AxiosRequestConfig) {
        return ConexionNavisionControllerApiFp(this.configuration).conexionNavisionControllerPostEliminarProductoDeBC($endpoint, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [$endpoint] 
     * @param {object} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConexionNavisionControllerApi
     */
    public conexionNavisionControllerPostEndpointBC($endpoint?: string, body?: object, options?: AxiosRequestConfig) {
        return ConexionNavisionControllerApiFp(this.configuration).conexionNavisionControllerPostEndpointBC($endpoint, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [$endpoint] 
     * @param {string} [$desdeWEB] 
     * @param {object} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConexionNavisionControllerApi
     */
    public conexionNavisionControllerSincronizarProductosEnBC($endpoint?: string, $desdeWEB?: string, body?: object, options?: AxiosRequestConfig) {
        return ConexionNavisionControllerApiFp(this.configuration).conexionNavisionControllerSincronizarProductosEnBC($endpoint, $desdeWEB, body, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * EmpresasBCControllerApi - axios parameter creator
 * @export
 */
export const EmpresasBCControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        empresasBCControllerCount: async (where?: { [key: string]: object; }, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/EmpresasBC/count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (where !== undefined) {
                localVarQueryParameter['where'] = where;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {NewEmpresasBC} [newEmpresasBC] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        empresasBCControllerCreate: async (newEmpresasBC?: NewEmpresasBC, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/EmpresasBC`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(newEmpresasBC, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        empresasBCControllerDeleteById: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('empresasBCControllerDeleteById', 'id', id)
            const localVarPath = `/EmpresasBC/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {EmpresasBCFilter1} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        empresasBCControllerFind: async (filter?: EmpresasBCFilter1, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/EmpresasBC`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {EmpresasBCFilter} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        empresasBCControllerFindById: async (id: number, filter?: EmpresasBCFilter, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('empresasBCControllerFindById', 'id', id)
            const localVarPath = `/EmpresasBC/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {EmpresasBC} [empresasBC] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        empresasBCControllerReplaceById: async (id: number, empresasBC?: EmpresasBC, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('empresasBCControllerReplaceById', 'id', id)
            const localVarPath = `/EmpresasBC/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(empresasBC, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {EmpresasBCPartial} [empresasBCPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        empresasBCControllerUpdateAll: async (where?: { [key: string]: object; }, empresasBCPartial?: EmpresasBCPartial, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/EmpresasBC`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (where !== undefined) {
                localVarQueryParameter['where'] = where;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(empresasBCPartial, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {EmpresasBCPartial} [empresasBCPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        empresasBCControllerUpdateById: async (id: number, empresasBCPartial?: EmpresasBCPartial, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('empresasBCControllerUpdateById', 'id', id)
            const localVarPath = `/EmpresasBC/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(empresasBCPartial, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EmpresasBCControllerApi - functional programming interface
 * @export
 */
export const EmpresasBCControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = EmpresasBCControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async empresasBCControllerCount(where?: { [key: string]: object; }, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoopbackCount>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.empresasBCControllerCount(where, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {NewEmpresasBC} [newEmpresasBC] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async empresasBCControllerCreate(newEmpresasBC?: NewEmpresasBC, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmpresasBC>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.empresasBCControllerCreate(newEmpresasBC, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async empresasBCControllerDeleteById(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.empresasBCControllerDeleteById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {EmpresasBCFilter1} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async empresasBCControllerFind(filter?: EmpresasBCFilter1, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EmpresasBCWithRelations>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.empresasBCControllerFind(filter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {EmpresasBCFilter} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async empresasBCControllerFindById(id: number, filter?: EmpresasBCFilter, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmpresasBCWithRelations>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.empresasBCControllerFindById(id, filter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {EmpresasBC} [empresasBC] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async empresasBCControllerReplaceById(id: number, empresasBC?: EmpresasBC, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.empresasBCControllerReplaceById(id, empresasBC, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {EmpresasBCPartial} [empresasBCPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async empresasBCControllerUpdateAll(where?: { [key: string]: object; }, empresasBCPartial?: EmpresasBCPartial, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoopbackCount>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.empresasBCControllerUpdateAll(where, empresasBCPartial, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {EmpresasBCPartial} [empresasBCPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async empresasBCControllerUpdateById(id: number, empresasBCPartial?: EmpresasBCPartial, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.empresasBCControllerUpdateById(id, empresasBCPartial, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * EmpresasBCControllerApi - factory interface
 * @export
 */
export const EmpresasBCControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EmpresasBCControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        empresasBCControllerCount(where?: { [key: string]: object; }, options?: any): AxiosPromise<LoopbackCount> {
            return localVarFp.empresasBCControllerCount(where, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {NewEmpresasBC} [newEmpresasBC] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        empresasBCControllerCreate(newEmpresasBC?: NewEmpresasBC, options?: any): AxiosPromise<EmpresasBC> {
            return localVarFp.empresasBCControllerCreate(newEmpresasBC, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        empresasBCControllerDeleteById(id: number, options?: any): AxiosPromise<any> {
            return localVarFp.empresasBCControllerDeleteById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {EmpresasBCFilter1} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        empresasBCControllerFind(filter?: EmpresasBCFilter1, options?: any): AxiosPromise<Array<EmpresasBCWithRelations>> {
            return localVarFp.empresasBCControllerFind(filter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {EmpresasBCFilter} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        empresasBCControllerFindById(id: number, filter?: EmpresasBCFilter, options?: any): AxiosPromise<EmpresasBCWithRelations> {
            return localVarFp.empresasBCControllerFindById(id, filter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {EmpresasBC} [empresasBC] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        empresasBCControllerReplaceById(id: number, empresasBC?: EmpresasBC, options?: any): AxiosPromise<any> {
            return localVarFp.empresasBCControllerReplaceById(id, empresasBC, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {EmpresasBCPartial} [empresasBCPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        empresasBCControllerUpdateAll(where?: { [key: string]: object; }, empresasBCPartial?: EmpresasBCPartial, options?: any): AxiosPromise<LoopbackCount> {
            return localVarFp.empresasBCControllerUpdateAll(where, empresasBCPartial, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {EmpresasBCPartial} [empresasBCPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        empresasBCControllerUpdateById(id: number, empresasBCPartial?: EmpresasBCPartial, options?: any): AxiosPromise<any> {
            return localVarFp.empresasBCControllerUpdateById(id, empresasBCPartial, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * EmpresasBCControllerApi - object-oriented interface
 * @export
 * @class EmpresasBCControllerApi
 * @extends {BaseAPI}
 */
export class EmpresasBCControllerApi extends BaseAPI {
    /**
     * 
     * @param {{ [key: string]: object; }} [where] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmpresasBCControllerApi
     */
    public empresasBCControllerCount(where?: { [key: string]: object; }, options?: AxiosRequestConfig) {
        return EmpresasBCControllerApiFp(this.configuration).empresasBCControllerCount(where, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {NewEmpresasBC} [newEmpresasBC] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmpresasBCControllerApi
     */
    public empresasBCControllerCreate(newEmpresasBC?: NewEmpresasBC, options?: AxiosRequestConfig) {
        return EmpresasBCControllerApiFp(this.configuration).empresasBCControllerCreate(newEmpresasBC, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmpresasBCControllerApi
     */
    public empresasBCControllerDeleteById(id: number, options?: AxiosRequestConfig) {
        return EmpresasBCControllerApiFp(this.configuration).empresasBCControllerDeleteById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {EmpresasBCFilter1} [filter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmpresasBCControllerApi
     */
    public empresasBCControllerFind(filter?: EmpresasBCFilter1, options?: AxiosRequestConfig) {
        return EmpresasBCControllerApiFp(this.configuration).empresasBCControllerFind(filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {EmpresasBCFilter} [filter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmpresasBCControllerApi
     */
    public empresasBCControllerFindById(id: number, filter?: EmpresasBCFilter, options?: AxiosRequestConfig) {
        return EmpresasBCControllerApiFp(this.configuration).empresasBCControllerFindById(id, filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {EmpresasBC} [empresasBC] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmpresasBCControllerApi
     */
    public empresasBCControllerReplaceById(id: number, empresasBC?: EmpresasBC, options?: AxiosRequestConfig) {
        return EmpresasBCControllerApiFp(this.configuration).empresasBCControllerReplaceById(id, empresasBC, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} [where] 
     * @param {EmpresasBCPartial} [empresasBCPartial] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmpresasBCControllerApi
     */
    public empresasBCControllerUpdateAll(where?: { [key: string]: object; }, empresasBCPartial?: EmpresasBCPartial, options?: AxiosRequestConfig) {
        return EmpresasBCControllerApiFp(this.configuration).empresasBCControllerUpdateAll(where, empresasBCPartial, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {EmpresasBCPartial} [empresasBCPartial] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmpresasBCControllerApi
     */
    public empresasBCControllerUpdateById(id: number, empresasBCPartial?: EmpresasBCPartial, options?: AxiosRequestConfig) {
        return EmpresasBCControllerApiFp(this.configuration).empresasBCControllerUpdateById(id, empresasBCPartial, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * EmpresasControllerApi - axios parameter creator
 * @export
 */
export const EmpresasControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        empresasControllerCount: async (where?: { [key: string]: object; }, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/empresas/count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (where !== undefined) {
                localVarQueryParameter['where'] = where;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {NewEmpresas} [newEmpresas] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        empresasControllerCreate: async (newEmpresas?: NewEmpresas, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/empresas`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(newEmpresas, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        empresasControllerDeleteById: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('empresasControllerDeleteById', 'id', id)
            const localVarPath = `/empresas/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {EmpresasFilter1} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        empresasControllerFind: async (filter?: EmpresasFilter1, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/empresas`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {EmpresasFilter} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        empresasControllerFindById: async (id: number, filter?: EmpresasFilter, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('empresasControllerFindById', 'id', id)
            const localVarPath = `/empresas/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {Empresas} [empresas] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        empresasControllerReplaceById: async (id: number, empresas?: Empresas, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('empresasControllerReplaceById', 'id', id)
            const localVarPath = `/empresas/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(empresas, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {EmpresasPartial} [empresasPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        empresasControllerUpdateAll: async (where?: { [key: string]: object; }, empresasPartial?: EmpresasPartial, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/empresas`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (where !== undefined) {
                localVarQueryParameter['where'] = where;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(empresasPartial, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {EmpresasPartial} [empresasPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        empresasControllerUpdateById: async (id: number, empresasPartial?: EmpresasPartial, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('empresasControllerUpdateById', 'id', id)
            const localVarPath = `/empresas/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(empresasPartial, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EmpresasControllerApi - functional programming interface
 * @export
 */
export const EmpresasControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = EmpresasControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async empresasControllerCount(where?: { [key: string]: object; }, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoopbackCount>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.empresasControllerCount(where, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {NewEmpresas} [newEmpresas] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async empresasControllerCreate(newEmpresas?: NewEmpresas, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Empresas>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.empresasControllerCreate(newEmpresas, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async empresasControllerDeleteById(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.empresasControllerDeleteById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {EmpresasFilter1} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async empresasControllerFind(filter?: EmpresasFilter1, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EmpresasWithRelations>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.empresasControllerFind(filter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {EmpresasFilter} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async empresasControllerFindById(id: number, filter?: EmpresasFilter, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmpresasWithRelations>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.empresasControllerFindById(id, filter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {Empresas} [empresas] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async empresasControllerReplaceById(id: number, empresas?: Empresas, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.empresasControllerReplaceById(id, empresas, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {EmpresasPartial} [empresasPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async empresasControllerUpdateAll(where?: { [key: string]: object; }, empresasPartial?: EmpresasPartial, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoopbackCount>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.empresasControllerUpdateAll(where, empresasPartial, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {EmpresasPartial} [empresasPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async empresasControllerUpdateById(id: number, empresasPartial?: EmpresasPartial, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.empresasControllerUpdateById(id, empresasPartial, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * EmpresasControllerApi - factory interface
 * @export
 */
export const EmpresasControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EmpresasControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        empresasControllerCount(where?: { [key: string]: object; }, options?: any): AxiosPromise<LoopbackCount> {
            return localVarFp.empresasControllerCount(where, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {NewEmpresas} [newEmpresas] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        empresasControllerCreate(newEmpresas?: NewEmpresas, options?: any): AxiosPromise<Empresas> {
            return localVarFp.empresasControllerCreate(newEmpresas, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        empresasControllerDeleteById(id: number, options?: any): AxiosPromise<any> {
            return localVarFp.empresasControllerDeleteById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {EmpresasFilter1} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        empresasControllerFind(filter?: EmpresasFilter1, options?: any): AxiosPromise<Array<EmpresasWithRelations>> {
            return localVarFp.empresasControllerFind(filter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {EmpresasFilter} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        empresasControllerFindById(id: number, filter?: EmpresasFilter, options?: any): AxiosPromise<EmpresasWithRelations> {
            return localVarFp.empresasControllerFindById(id, filter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {Empresas} [empresas] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        empresasControllerReplaceById(id: number, empresas?: Empresas, options?: any): AxiosPromise<any> {
            return localVarFp.empresasControllerReplaceById(id, empresas, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {EmpresasPartial} [empresasPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        empresasControllerUpdateAll(where?: { [key: string]: object; }, empresasPartial?: EmpresasPartial, options?: any): AxiosPromise<LoopbackCount> {
            return localVarFp.empresasControllerUpdateAll(where, empresasPartial, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {EmpresasPartial} [empresasPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        empresasControllerUpdateById(id: number, empresasPartial?: EmpresasPartial, options?: any): AxiosPromise<any> {
            return localVarFp.empresasControllerUpdateById(id, empresasPartial, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * EmpresasControllerApi - object-oriented interface
 * @export
 * @class EmpresasControllerApi
 * @extends {BaseAPI}
 */
export class EmpresasControllerApi extends BaseAPI {
    /**
     * 
     * @param {{ [key: string]: object; }} [where] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmpresasControllerApi
     */
    public empresasControllerCount(where?: { [key: string]: object; }, options?: AxiosRequestConfig) {
        return EmpresasControllerApiFp(this.configuration).empresasControllerCount(where, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {NewEmpresas} [newEmpresas] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmpresasControllerApi
     */
    public empresasControllerCreate(newEmpresas?: NewEmpresas, options?: AxiosRequestConfig) {
        return EmpresasControllerApiFp(this.configuration).empresasControllerCreate(newEmpresas, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmpresasControllerApi
     */
    public empresasControllerDeleteById(id: number, options?: AxiosRequestConfig) {
        return EmpresasControllerApiFp(this.configuration).empresasControllerDeleteById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {EmpresasFilter1} [filter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmpresasControllerApi
     */
    public empresasControllerFind(filter?: EmpresasFilter1, options?: AxiosRequestConfig) {
        return EmpresasControllerApiFp(this.configuration).empresasControllerFind(filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {EmpresasFilter} [filter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmpresasControllerApi
     */
    public empresasControllerFindById(id: number, filter?: EmpresasFilter, options?: AxiosRequestConfig) {
        return EmpresasControllerApiFp(this.configuration).empresasControllerFindById(id, filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {Empresas} [empresas] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmpresasControllerApi
     */
    public empresasControllerReplaceById(id: number, empresas?: Empresas, options?: AxiosRequestConfig) {
        return EmpresasControllerApiFp(this.configuration).empresasControllerReplaceById(id, empresas, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} [where] 
     * @param {EmpresasPartial} [empresasPartial] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmpresasControllerApi
     */
    public empresasControllerUpdateAll(where?: { [key: string]: object; }, empresasPartial?: EmpresasPartial, options?: AxiosRequestConfig) {
        return EmpresasControllerApiFp(this.configuration).empresasControllerUpdateAll(where, empresasPartial, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {EmpresasPartial} [empresasPartial] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmpresasControllerApi
     */
    public empresasControllerUpdateById(id: number, empresasPartial?: EmpresasPartial, options?: AxiosRequestConfig) {
        return EmpresasControllerApiFp(this.configuration).empresasControllerUpdateById(id, empresasPartial, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * EnsambladosControllerApi - axios parameter creator
 * @export
 */
export const EnsambladosControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ensambladosControllerCount: async (where?: { [key: string]: object; }, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Ensamblados/count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (where !== undefined) {
                localVarQueryParameter['where'] = where;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {NewEnsamblados} [newEnsamblados] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ensambladosControllerCreate: async (newEnsamblados?: NewEnsamblados, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Ensamblados`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(newEnsamblados, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {string} skuPrincipal 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ensambladosControllerDeleteById: async (id: number, skuPrincipal: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('ensambladosControllerDeleteById', 'id', id)
            // verify required parameter 'skuPrincipal' is not null or undefined
            assertParamExists('ensambladosControllerDeleteById', 'skuPrincipal', skuPrincipal)
            const localVarPath = `/Ensamblados/{id}/{skuPrincipal}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"skuPrincipal"}}`, encodeURIComponent(String(skuPrincipal)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {EnsambladosFilter1} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ensambladosControllerFind: async (filter?: EnsambladosFilter1, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Ensamblados`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {EnsambladosFilter} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ensambladosControllerFindById: async (id: number, filter?: EnsambladosFilter, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('ensambladosControllerFindById', 'id', id)
            const localVarPath = `/Ensamblados/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {Ensamblados} [ensamblados] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ensambladosControllerReplaceById: async (id: number, ensamblados?: Ensamblados, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('ensambladosControllerReplaceById', 'id', id)
            const localVarPath = `/Ensamblados/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(ensamblados, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {EnsambladosPartial} [ensambladosPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ensambladosControllerUpdateAll: async (where?: { [key: string]: object; }, ensambladosPartial?: EnsambladosPartial, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Ensamblados`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (where !== undefined) {
                localVarQueryParameter['where'] = where;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(ensambladosPartial, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {EnsambladosPartial} [ensambladosPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ensambladosControllerUpdateById: async (id: number, ensambladosPartial?: EnsambladosPartial, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('ensambladosControllerUpdateById', 'id', id)
            const localVarPath = `/Ensamblados/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(ensambladosPartial, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EnsambladosControllerApi - functional programming interface
 * @export
 */
export const EnsambladosControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = EnsambladosControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ensambladosControllerCount(where?: { [key: string]: object; }, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoopbackCount>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ensambladosControllerCount(where, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {NewEnsamblados} [newEnsamblados] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ensambladosControllerCreate(newEnsamblados?: NewEnsamblados, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Ensamblados>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ensambladosControllerCreate(newEnsamblados, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {string} skuPrincipal 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ensambladosControllerDeleteById(id: number, skuPrincipal: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ensambladosControllerDeleteById(id, skuPrincipal, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {EnsambladosFilter1} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ensambladosControllerFind(filter?: EnsambladosFilter1, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EnsambladosWithRelations>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ensambladosControllerFind(filter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {EnsambladosFilter} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ensambladosControllerFindById(id: number, filter?: EnsambladosFilter, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EnsambladosWithRelations>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ensambladosControllerFindById(id, filter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {Ensamblados} [ensamblados] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ensambladosControllerReplaceById(id: number, ensamblados?: Ensamblados, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ensambladosControllerReplaceById(id, ensamblados, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {EnsambladosPartial} [ensambladosPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ensambladosControllerUpdateAll(where?: { [key: string]: object; }, ensambladosPartial?: EnsambladosPartial, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoopbackCount>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ensambladosControllerUpdateAll(where, ensambladosPartial, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {EnsambladosPartial} [ensambladosPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ensambladosControllerUpdateById(id: number, ensambladosPartial?: EnsambladosPartial, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ensambladosControllerUpdateById(id, ensambladosPartial, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * EnsambladosControllerApi - factory interface
 * @export
 */
export const EnsambladosControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EnsambladosControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ensambladosControllerCount(where?: { [key: string]: object; }, options?: any): AxiosPromise<LoopbackCount> {
            return localVarFp.ensambladosControllerCount(where, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {NewEnsamblados} [newEnsamblados] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ensambladosControllerCreate(newEnsamblados?: NewEnsamblados, options?: any): AxiosPromise<Ensamblados> {
            return localVarFp.ensambladosControllerCreate(newEnsamblados, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {string} skuPrincipal 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ensambladosControllerDeleteById(id: number, skuPrincipal: string, options?: any): AxiosPromise<any> {
            return localVarFp.ensambladosControllerDeleteById(id, skuPrincipal, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {EnsambladosFilter1} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ensambladosControllerFind(filter?: EnsambladosFilter1, options?: any): AxiosPromise<Array<EnsambladosWithRelations>> {
            return localVarFp.ensambladosControllerFind(filter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {EnsambladosFilter} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ensambladosControllerFindById(id: number, filter?: EnsambladosFilter, options?: any): AxiosPromise<EnsambladosWithRelations> {
            return localVarFp.ensambladosControllerFindById(id, filter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {Ensamblados} [ensamblados] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ensambladosControllerReplaceById(id: number, ensamblados?: Ensamblados, options?: any): AxiosPromise<any> {
            return localVarFp.ensambladosControllerReplaceById(id, ensamblados, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {EnsambladosPartial} [ensambladosPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ensambladosControllerUpdateAll(where?: { [key: string]: object; }, ensambladosPartial?: EnsambladosPartial, options?: any): AxiosPromise<LoopbackCount> {
            return localVarFp.ensambladosControllerUpdateAll(where, ensambladosPartial, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {EnsambladosPartial} [ensambladosPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ensambladosControllerUpdateById(id: number, ensambladosPartial?: EnsambladosPartial, options?: any): AxiosPromise<any> {
            return localVarFp.ensambladosControllerUpdateById(id, ensambladosPartial, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * EnsambladosControllerApi - object-oriented interface
 * @export
 * @class EnsambladosControllerApi
 * @extends {BaseAPI}
 */
export class EnsambladosControllerApi extends BaseAPI {
    /**
     * 
     * @param {{ [key: string]: object; }} [where] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EnsambladosControllerApi
     */
    public ensambladosControllerCount(where?: { [key: string]: object; }, options?: AxiosRequestConfig) {
        return EnsambladosControllerApiFp(this.configuration).ensambladosControllerCount(where, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {NewEnsamblados} [newEnsamblados] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EnsambladosControllerApi
     */
    public ensambladosControllerCreate(newEnsamblados?: NewEnsamblados, options?: AxiosRequestConfig) {
        return EnsambladosControllerApiFp(this.configuration).ensambladosControllerCreate(newEnsamblados, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {string} skuPrincipal 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EnsambladosControllerApi
     */
    public ensambladosControllerDeleteById(id: number, skuPrincipal: string, options?: AxiosRequestConfig) {
        return EnsambladosControllerApiFp(this.configuration).ensambladosControllerDeleteById(id, skuPrincipal, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {EnsambladosFilter1} [filter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EnsambladosControllerApi
     */
    public ensambladosControllerFind(filter?: EnsambladosFilter1, options?: AxiosRequestConfig) {
        return EnsambladosControllerApiFp(this.configuration).ensambladosControllerFind(filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {EnsambladosFilter} [filter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EnsambladosControllerApi
     */
    public ensambladosControllerFindById(id: number, filter?: EnsambladosFilter, options?: AxiosRequestConfig) {
        return EnsambladosControllerApiFp(this.configuration).ensambladosControllerFindById(id, filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {Ensamblados} [ensamblados] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EnsambladosControllerApi
     */
    public ensambladosControllerReplaceById(id: number, ensamblados?: Ensamblados, options?: AxiosRequestConfig) {
        return EnsambladosControllerApiFp(this.configuration).ensambladosControllerReplaceById(id, ensamblados, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} [where] 
     * @param {EnsambladosPartial} [ensambladosPartial] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EnsambladosControllerApi
     */
    public ensambladosControllerUpdateAll(where?: { [key: string]: object; }, ensambladosPartial?: EnsambladosPartial, options?: AxiosRequestConfig) {
        return EnsambladosControllerApiFp(this.configuration).ensambladosControllerUpdateAll(where, ensambladosPartial, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {EnsambladosPartial} [ensambladosPartial] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EnsambladosControllerApi
     */
    public ensambladosControllerUpdateById(id: number, ensambladosPartial?: EnsambladosPartial, options?: AxiosRequestConfig) {
        return EnsambladosControllerApiFp(this.configuration).ensambladosControllerUpdateById(id, ensambladosPartial, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * FamiliasControllerApi - axios parameter creator
 * @export
 */
export const FamiliasControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        familiasControllerCount: async (where?: { [key: string]: object; }, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/familias/count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (where !== undefined) {
                localVarQueryParameter['where'] = where;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {NewFamilias} [newFamilias] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        familiasControllerCreate: async (newFamilias?: NewFamilias, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/familias`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(newFamilias, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        familiasControllerDeleteById: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('familiasControllerDeleteById', 'id', id)
            const localVarPath = `/familias/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {FamiliasFilter1} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        familiasControllerFind: async (filter?: FamiliasFilter1, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/familias`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {FamiliasFilter} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        familiasControllerFindById: async (id: number, filter?: FamiliasFilter, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('familiasControllerFindById', 'id', id)
            const localVarPath = `/familias/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {Familias} [familias] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        familiasControllerReplaceById: async (id: number, familias?: Familias, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('familiasControllerReplaceById', 'id', id)
            const localVarPath = `/familias/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(familias, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {FamiliasPartial} [familiasPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        familiasControllerUpdateAll: async (where?: { [key: string]: object; }, familiasPartial?: FamiliasPartial, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/familias`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (where !== undefined) {
                localVarQueryParameter['where'] = where;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(familiasPartial, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {FamiliasPartial} [familiasPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        familiasControllerUpdateById: async (id: number, familiasPartial?: FamiliasPartial, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('familiasControllerUpdateById', 'id', id)
            const localVarPath = `/familias/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(familiasPartial, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FamiliasControllerApi - functional programming interface
 * @export
 */
export const FamiliasControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FamiliasControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async familiasControllerCount(where?: { [key: string]: object; }, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoopbackCount>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.familiasControllerCount(where, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {NewFamilias} [newFamilias] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async familiasControllerCreate(newFamilias?: NewFamilias, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Familias>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.familiasControllerCreate(newFamilias, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async familiasControllerDeleteById(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.familiasControllerDeleteById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {FamiliasFilter1} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async familiasControllerFind(filter?: FamiliasFilter1, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<FamiliasWithRelations>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.familiasControllerFind(filter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {FamiliasFilter} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async familiasControllerFindById(id: number, filter?: FamiliasFilter, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FamiliasWithRelations>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.familiasControllerFindById(id, filter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {Familias} [familias] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async familiasControllerReplaceById(id: number, familias?: Familias, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.familiasControllerReplaceById(id, familias, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {FamiliasPartial} [familiasPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async familiasControllerUpdateAll(where?: { [key: string]: object; }, familiasPartial?: FamiliasPartial, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoopbackCount>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.familiasControllerUpdateAll(where, familiasPartial, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {FamiliasPartial} [familiasPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async familiasControllerUpdateById(id: number, familiasPartial?: FamiliasPartial, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.familiasControllerUpdateById(id, familiasPartial, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FamiliasControllerApi - factory interface
 * @export
 */
export const FamiliasControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FamiliasControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        familiasControllerCount(where?: { [key: string]: object; }, options?: any): AxiosPromise<LoopbackCount> {
            return localVarFp.familiasControllerCount(where, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {NewFamilias} [newFamilias] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        familiasControllerCreate(newFamilias?: NewFamilias, options?: any): AxiosPromise<Familias> {
            return localVarFp.familiasControllerCreate(newFamilias, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        familiasControllerDeleteById(id: number, options?: any): AxiosPromise<any> {
            return localVarFp.familiasControllerDeleteById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {FamiliasFilter1} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        familiasControllerFind(filter?: FamiliasFilter1, options?: any): AxiosPromise<Array<FamiliasWithRelations>> {
            return localVarFp.familiasControllerFind(filter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {FamiliasFilter} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        familiasControllerFindById(id: number, filter?: FamiliasFilter, options?: any): AxiosPromise<FamiliasWithRelations> {
            return localVarFp.familiasControllerFindById(id, filter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {Familias} [familias] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        familiasControllerReplaceById(id: number, familias?: Familias, options?: any): AxiosPromise<any> {
            return localVarFp.familiasControllerReplaceById(id, familias, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {FamiliasPartial} [familiasPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        familiasControllerUpdateAll(where?: { [key: string]: object; }, familiasPartial?: FamiliasPartial, options?: any): AxiosPromise<LoopbackCount> {
            return localVarFp.familiasControllerUpdateAll(where, familiasPartial, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {FamiliasPartial} [familiasPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        familiasControllerUpdateById(id: number, familiasPartial?: FamiliasPartial, options?: any): AxiosPromise<any> {
            return localVarFp.familiasControllerUpdateById(id, familiasPartial, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FamiliasControllerApi - object-oriented interface
 * @export
 * @class FamiliasControllerApi
 * @extends {BaseAPI}
 */
export class FamiliasControllerApi extends BaseAPI {
    /**
     * 
     * @param {{ [key: string]: object; }} [where] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FamiliasControllerApi
     */
    public familiasControllerCount(where?: { [key: string]: object; }, options?: AxiosRequestConfig) {
        return FamiliasControllerApiFp(this.configuration).familiasControllerCount(where, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {NewFamilias} [newFamilias] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FamiliasControllerApi
     */
    public familiasControllerCreate(newFamilias?: NewFamilias, options?: AxiosRequestConfig) {
        return FamiliasControllerApiFp(this.configuration).familiasControllerCreate(newFamilias, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FamiliasControllerApi
     */
    public familiasControllerDeleteById(id: number, options?: AxiosRequestConfig) {
        return FamiliasControllerApiFp(this.configuration).familiasControllerDeleteById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FamiliasFilter1} [filter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FamiliasControllerApi
     */
    public familiasControllerFind(filter?: FamiliasFilter1, options?: AxiosRequestConfig) {
        return FamiliasControllerApiFp(this.configuration).familiasControllerFind(filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {FamiliasFilter} [filter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FamiliasControllerApi
     */
    public familiasControllerFindById(id: number, filter?: FamiliasFilter, options?: AxiosRequestConfig) {
        return FamiliasControllerApiFp(this.configuration).familiasControllerFindById(id, filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {Familias} [familias] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FamiliasControllerApi
     */
    public familiasControllerReplaceById(id: number, familias?: Familias, options?: AxiosRequestConfig) {
        return FamiliasControllerApiFp(this.configuration).familiasControllerReplaceById(id, familias, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} [where] 
     * @param {FamiliasPartial} [familiasPartial] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FamiliasControllerApi
     */
    public familiasControllerUpdateAll(where?: { [key: string]: object; }, familiasPartial?: FamiliasPartial, options?: AxiosRequestConfig) {
        return FamiliasControllerApiFp(this.configuration).familiasControllerUpdateAll(where, familiasPartial, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {FamiliasPartial} [familiasPartial] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FamiliasControllerApi
     */
    public familiasControllerUpdateById(id: number, familiasPartial?: FamiliasPartial, options?: AxiosRequestConfig) {
        return FamiliasControllerApiFp(this.configuration).familiasControllerUpdateById(id, familiasPartial, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * FileDownloadControllerApi - axios parameter creator
 * @export
 */
export const FileDownloadControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} filename 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileDownloadControllerDownloadFile: async (filename: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'filename' is not null or undefined
            assertParamExists('fileDownloadControllerDownloadFile', 'filename', filename)
            const localVarPath = `/files-download/{filename}`
                .replace(`{${"filename"}}`, encodeURIComponent(String(filename)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileDownloadControllerListFiles: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/files-download`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FileDownloadControllerApi - functional programming interface
 * @export
 */
export const FileDownloadControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FileDownloadControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} filename 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fileDownloadControllerDownloadFile(filename: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fileDownloadControllerDownloadFile(filename, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fileDownloadControllerListFiles(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fileDownloadControllerListFiles(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FileDownloadControllerApi - factory interface
 * @export
 */
export const FileDownloadControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FileDownloadControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {string} filename 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileDownloadControllerDownloadFile(filename: string, options?: any): AxiosPromise<any> {
            return localVarFp.fileDownloadControllerDownloadFile(filename, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileDownloadControllerListFiles(options?: any): AxiosPromise<Array<string>> {
            return localVarFp.fileDownloadControllerListFiles(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FileDownloadControllerApi - object-oriented interface
 * @export
 * @class FileDownloadControllerApi
 * @extends {BaseAPI}
 */
export class FileDownloadControllerApi extends BaseAPI {
    /**
     * 
     * @param {string} filename 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileDownloadControllerApi
     */
    public fileDownloadControllerDownloadFile(filename: string, options?: AxiosRequestConfig) {
        return FileDownloadControllerApiFp(this.configuration).fileDownloadControllerDownloadFile(filename, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileDownloadControllerApi
     */
    public fileDownloadControllerListFiles(options?: AxiosRequestConfig) {
        return FileDownloadControllerApiFp(this.configuration).fileDownloadControllerListFiles(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * FileUploadControllerApi - axios parameter creator
 * @export
 */
export const FileUploadControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} folderPathDir 
         * @param {string} fileName 
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileUploadControllerFileUpload: async (folderPathDir: string, fileName: string, file?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'folderPathDir' is not null or undefined
            assertParamExists('fileUploadControllerFileUpload', 'folderPathDir', folderPathDir)
            // verify required parameter 'fileName' is not null or undefined
            assertParamExists('fileUploadControllerFileUpload', 'fileName', fileName)
            const localVarPath = `/files-upload/{folderPathDir}/{fileName}`
                .replace(`{${"folderPathDir"}}`, encodeURIComponent(String(folderPathDir)))
                .replace(`{${"fileName"}}`, encodeURIComponent(String(fileName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} folderPathDir 
         * @param {string} fileName 
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileUploadControllerImageUpload: async (folderPathDir: string, fileName: string, file?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'folderPathDir' is not null or undefined
            assertParamExists('fileUploadControllerImageUpload', 'folderPathDir', folderPathDir)
            // verify required parameter 'fileName' is not null or undefined
            assertParamExists('fileUploadControllerImageUpload', 'fileName', fileName)
            const localVarPath = `/images-upload/{folderPathDir}/{fileName}`
                .replace(`{${"folderPathDir"}}`, encodeURIComponent(String(folderPathDir)))
                .replace(`{${"fileName"}}`, encodeURIComponent(String(fileName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [productos] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileUploadControllerProcesarImagenes: async (productos?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/procesar-imagenes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (productos !== undefined) {
                localVarQueryParameter['productos'] = productos;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FileUploadControllerApi - functional programming interface
 * @export
 */
export const FileUploadControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FileUploadControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} folderPathDir 
         * @param {string} fileName 
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fileUploadControllerFileUpload(folderPathDir: string, fileName: string, file?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fileUploadControllerFileUpload(folderPathDir, fileName, file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} folderPathDir 
         * @param {string} fileName 
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fileUploadControllerImageUpload(folderPathDir: string, fileName: string, file?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fileUploadControllerImageUpload(folderPathDir, fileName, file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [productos] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fileUploadControllerProcesarImagenes(productos?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fileUploadControllerProcesarImagenes(productos, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FileUploadControllerApi - factory interface
 * @export
 */
export const FileUploadControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FileUploadControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {string} folderPathDir 
         * @param {string} fileName 
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileUploadControllerFileUpload(folderPathDir: string, fileName: string, file?: any, options?: any): AxiosPromise<object> {
            return localVarFp.fileUploadControllerFileUpload(folderPathDir, fileName, file, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} folderPathDir 
         * @param {string} fileName 
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileUploadControllerImageUpload(folderPathDir: string, fileName: string, file?: any, options?: any): AxiosPromise<object> {
            return localVarFp.fileUploadControllerImageUpload(folderPathDir, fileName, file, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [productos] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileUploadControllerProcesarImagenes(productos?: string, options?: any): AxiosPromise<void> {
            return localVarFp.fileUploadControllerProcesarImagenes(productos, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FileUploadControllerApi - object-oriented interface
 * @export
 * @class FileUploadControllerApi
 * @extends {BaseAPI}
 */
export class FileUploadControllerApi extends BaseAPI {
    /**
     * 
     * @param {string} folderPathDir 
     * @param {string} fileName 
     * @param {any} [file] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileUploadControllerApi
     */
    public fileUploadControllerFileUpload(folderPathDir: string, fileName: string, file?: any, options?: AxiosRequestConfig) {
        return FileUploadControllerApiFp(this.configuration).fileUploadControllerFileUpload(folderPathDir, fileName, file, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} folderPathDir 
     * @param {string} fileName 
     * @param {any} [file] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileUploadControllerApi
     */
    public fileUploadControllerImageUpload(folderPathDir: string, fileName: string, file?: any, options?: AxiosRequestConfig) {
        return FileUploadControllerApiFp(this.configuration).fileUploadControllerImageUpload(folderPathDir, fileName, file, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [productos] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileUploadControllerApi
     */
    public fileUploadControllerProcesarImagenes(productos?: string, options?: AxiosRequestConfig) {
        return FileUploadControllerApiFp(this.configuration).fileUploadControllerProcesarImagenes(productos, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * GruposAtributosControllerApi - axios parameter creator
 * @export
 */
export const GruposAtributosControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gruposAtributosControllerCount: async (where?: { [key: string]: object; }, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/grupos-atributos/count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (where !== undefined) {
                localVarQueryParameter['where'] = where;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {NewGruposAtributos} [newGruposAtributos] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gruposAtributosControllerCreate: async (newGruposAtributos?: NewGruposAtributos, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/grupos-atributos`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(newGruposAtributos, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gruposAtributosControllerDeleteById: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('gruposAtributosControllerDeleteById', 'id', id)
            const localVarPath = `/grupos-atributos/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {GruposAtributosFilter1} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gruposAtributosControllerFind: async (filter?: GruposAtributosFilter1, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/grupos-atributos`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {GruposAtributosFilter} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gruposAtributosControllerFindById: async (id: number, filter?: GruposAtributosFilter, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('gruposAtributosControllerFindById', 'id', id)
            const localVarPath = `/grupos-atributos/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {GruposAtributos} [gruposAtributos] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gruposAtributosControllerReplaceById: async (id: number, gruposAtributos?: GruposAtributos, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('gruposAtributosControllerReplaceById', 'id', id)
            const localVarPath = `/grupos-atributos/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(gruposAtributos, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {GruposAtributosPartial} [gruposAtributosPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gruposAtributosControllerUpdateAll: async (where?: { [key: string]: object; }, gruposAtributosPartial?: GruposAtributosPartial, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/grupos-atributos`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (where !== undefined) {
                localVarQueryParameter['where'] = where;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(gruposAtributosPartial, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {GruposAtributosPartial} [gruposAtributosPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gruposAtributosControllerUpdateById: async (id: number, gruposAtributosPartial?: GruposAtributosPartial, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('gruposAtributosControllerUpdateById', 'id', id)
            const localVarPath = `/grupos-atributos/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(gruposAtributosPartial, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GruposAtributosControllerApi - functional programming interface
 * @export
 */
export const GruposAtributosControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = GruposAtributosControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async gruposAtributosControllerCount(where?: { [key: string]: object; }, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoopbackCount>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.gruposAtributosControllerCount(where, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {NewGruposAtributos} [newGruposAtributos] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async gruposAtributosControllerCreate(newGruposAtributos?: NewGruposAtributos, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GruposAtributos>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.gruposAtributosControllerCreate(newGruposAtributos, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async gruposAtributosControllerDeleteById(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.gruposAtributosControllerDeleteById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {GruposAtributosFilter1} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async gruposAtributosControllerFind(filter?: GruposAtributosFilter1, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GruposAtributosWithRelations>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.gruposAtributosControllerFind(filter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {GruposAtributosFilter} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async gruposAtributosControllerFindById(id: number, filter?: GruposAtributosFilter, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GruposAtributosWithRelations>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.gruposAtributosControllerFindById(id, filter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {GruposAtributos} [gruposAtributos] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async gruposAtributosControllerReplaceById(id: number, gruposAtributos?: GruposAtributos, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.gruposAtributosControllerReplaceById(id, gruposAtributos, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {GruposAtributosPartial} [gruposAtributosPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async gruposAtributosControllerUpdateAll(where?: { [key: string]: object; }, gruposAtributosPartial?: GruposAtributosPartial, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoopbackCount>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.gruposAtributosControllerUpdateAll(where, gruposAtributosPartial, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {GruposAtributosPartial} [gruposAtributosPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async gruposAtributosControllerUpdateById(id: number, gruposAtributosPartial?: GruposAtributosPartial, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.gruposAtributosControllerUpdateById(id, gruposAtributosPartial, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * GruposAtributosControllerApi - factory interface
 * @export
 */
export const GruposAtributosControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = GruposAtributosControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gruposAtributosControllerCount(where?: { [key: string]: object; }, options?: any): AxiosPromise<LoopbackCount> {
            return localVarFp.gruposAtributosControllerCount(where, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {NewGruposAtributos} [newGruposAtributos] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gruposAtributosControllerCreate(newGruposAtributos?: NewGruposAtributos, options?: any): AxiosPromise<GruposAtributos> {
            return localVarFp.gruposAtributosControllerCreate(newGruposAtributos, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gruposAtributosControllerDeleteById(id: number, options?: any): AxiosPromise<any> {
            return localVarFp.gruposAtributosControllerDeleteById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {GruposAtributosFilter1} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gruposAtributosControllerFind(filter?: GruposAtributosFilter1, options?: any): AxiosPromise<Array<GruposAtributosWithRelations>> {
            return localVarFp.gruposAtributosControllerFind(filter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {GruposAtributosFilter} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gruposAtributosControllerFindById(id: number, filter?: GruposAtributosFilter, options?: any): AxiosPromise<GruposAtributosWithRelations> {
            return localVarFp.gruposAtributosControllerFindById(id, filter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {GruposAtributos} [gruposAtributos] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gruposAtributosControllerReplaceById(id: number, gruposAtributos?: GruposAtributos, options?: any): AxiosPromise<any> {
            return localVarFp.gruposAtributosControllerReplaceById(id, gruposAtributos, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {GruposAtributosPartial} [gruposAtributosPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gruposAtributosControllerUpdateAll(where?: { [key: string]: object; }, gruposAtributosPartial?: GruposAtributosPartial, options?: any): AxiosPromise<LoopbackCount> {
            return localVarFp.gruposAtributosControllerUpdateAll(where, gruposAtributosPartial, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {GruposAtributosPartial} [gruposAtributosPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gruposAtributosControllerUpdateById(id: number, gruposAtributosPartial?: GruposAtributosPartial, options?: any): AxiosPromise<any> {
            return localVarFp.gruposAtributosControllerUpdateById(id, gruposAtributosPartial, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * GruposAtributosControllerApi - object-oriented interface
 * @export
 * @class GruposAtributosControllerApi
 * @extends {BaseAPI}
 */
export class GruposAtributosControllerApi extends BaseAPI {
    /**
     * 
     * @param {{ [key: string]: object; }} [where] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GruposAtributosControllerApi
     */
    public gruposAtributosControllerCount(where?: { [key: string]: object; }, options?: AxiosRequestConfig) {
        return GruposAtributosControllerApiFp(this.configuration).gruposAtributosControllerCount(where, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {NewGruposAtributos} [newGruposAtributos] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GruposAtributosControllerApi
     */
    public gruposAtributosControllerCreate(newGruposAtributos?: NewGruposAtributos, options?: AxiosRequestConfig) {
        return GruposAtributosControllerApiFp(this.configuration).gruposAtributosControllerCreate(newGruposAtributos, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GruposAtributosControllerApi
     */
    public gruposAtributosControllerDeleteById(id: number, options?: AxiosRequestConfig) {
        return GruposAtributosControllerApiFp(this.configuration).gruposAtributosControllerDeleteById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {GruposAtributosFilter1} [filter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GruposAtributosControllerApi
     */
    public gruposAtributosControllerFind(filter?: GruposAtributosFilter1, options?: AxiosRequestConfig) {
        return GruposAtributosControllerApiFp(this.configuration).gruposAtributosControllerFind(filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {GruposAtributosFilter} [filter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GruposAtributosControllerApi
     */
    public gruposAtributosControllerFindById(id: number, filter?: GruposAtributosFilter, options?: AxiosRequestConfig) {
        return GruposAtributosControllerApiFp(this.configuration).gruposAtributosControllerFindById(id, filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {GruposAtributos} [gruposAtributos] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GruposAtributosControllerApi
     */
    public gruposAtributosControllerReplaceById(id: number, gruposAtributos?: GruposAtributos, options?: AxiosRequestConfig) {
        return GruposAtributosControllerApiFp(this.configuration).gruposAtributosControllerReplaceById(id, gruposAtributos, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} [where] 
     * @param {GruposAtributosPartial} [gruposAtributosPartial] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GruposAtributosControllerApi
     */
    public gruposAtributosControllerUpdateAll(where?: { [key: string]: object; }, gruposAtributosPartial?: GruposAtributosPartial, options?: AxiosRequestConfig) {
        return GruposAtributosControllerApiFp(this.configuration).gruposAtributosControllerUpdateAll(where, gruposAtributosPartial, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {GruposAtributosPartial} [gruposAtributosPartial] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GruposAtributosControllerApi
     */
    public gruposAtributosControllerUpdateById(id: number, gruposAtributosPartial?: GruposAtributosPartial, options?: AxiosRequestConfig) {
        return GruposAtributosControllerApiFp(this.configuration).gruposAtributosControllerUpdateById(id, gruposAtributosPartial, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * IconosControllerApi - axios parameter creator
 * @export
 */
export const IconosControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        iconosControllerCount: async (where?: { [key: string]: object; }, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Iconos/count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (where !== undefined) {
                localVarQueryParameter['where'] = where;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {NewIconos} [newIconos] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        iconosControllerCreate: async (newIconos?: NewIconos, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Iconos`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(newIconos, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        iconosControllerDeleteById: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('iconosControllerDeleteById', 'id', id)
            const localVarPath = `/Iconos/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {IconosFilter1} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        iconosControllerFind: async (filter?: IconosFilter1, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Iconos`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {IconosFilter} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        iconosControllerFindById: async (id: number, filter?: IconosFilter, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('iconosControllerFindById', 'id', id)
            const localVarPath = `/Iconos/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {Iconos} [iconos] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        iconosControllerReplaceById: async (id: number, iconos?: Iconos, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('iconosControllerReplaceById', 'id', id)
            const localVarPath = `/Iconos/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(iconos, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {IconosPartial} [iconosPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        iconosControllerUpdateAll: async (where?: { [key: string]: object; }, iconosPartial?: IconosPartial, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Iconos`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (where !== undefined) {
                localVarQueryParameter['where'] = where;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(iconosPartial, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {IconosPartial} [iconosPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        iconosControllerUpdateById: async (id: number, iconosPartial?: IconosPartial, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('iconosControllerUpdateById', 'id', id)
            const localVarPath = `/Iconos/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(iconosPartial, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * IconosControllerApi - functional programming interface
 * @export
 */
export const IconosControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = IconosControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async iconosControllerCount(where?: { [key: string]: object; }, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoopbackCount>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.iconosControllerCount(where, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {NewIconos} [newIconos] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async iconosControllerCreate(newIconos?: NewIconos, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Iconos>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.iconosControllerCreate(newIconos, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async iconosControllerDeleteById(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.iconosControllerDeleteById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {IconosFilter1} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async iconosControllerFind(filter?: IconosFilter1, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<IconosWithRelations>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.iconosControllerFind(filter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {IconosFilter} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async iconosControllerFindById(id: number, filter?: IconosFilter, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IconosWithRelations>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.iconosControllerFindById(id, filter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {Iconos} [iconos] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async iconosControllerReplaceById(id: number, iconos?: Iconos, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.iconosControllerReplaceById(id, iconos, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {IconosPartial} [iconosPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async iconosControllerUpdateAll(where?: { [key: string]: object; }, iconosPartial?: IconosPartial, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoopbackCount>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.iconosControllerUpdateAll(where, iconosPartial, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {IconosPartial} [iconosPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async iconosControllerUpdateById(id: number, iconosPartial?: IconosPartial, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.iconosControllerUpdateById(id, iconosPartial, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * IconosControllerApi - factory interface
 * @export
 */
export const IconosControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = IconosControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        iconosControllerCount(where?: { [key: string]: object; }, options?: any): AxiosPromise<LoopbackCount> {
            return localVarFp.iconosControllerCount(where, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {NewIconos} [newIconos] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        iconosControllerCreate(newIconos?: NewIconos, options?: any): AxiosPromise<Iconos> {
            return localVarFp.iconosControllerCreate(newIconos, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        iconosControllerDeleteById(id: number, options?: any): AxiosPromise<any> {
            return localVarFp.iconosControllerDeleteById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {IconosFilter1} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        iconosControllerFind(filter?: IconosFilter1, options?: any): AxiosPromise<Array<IconosWithRelations>> {
            return localVarFp.iconosControllerFind(filter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {IconosFilter} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        iconosControllerFindById(id: number, filter?: IconosFilter, options?: any): AxiosPromise<IconosWithRelations> {
            return localVarFp.iconosControllerFindById(id, filter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {Iconos} [iconos] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        iconosControllerReplaceById(id: number, iconos?: Iconos, options?: any): AxiosPromise<any> {
            return localVarFp.iconosControllerReplaceById(id, iconos, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {IconosPartial} [iconosPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        iconosControllerUpdateAll(where?: { [key: string]: object; }, iconosPartial?: IconosPartial, options?: any): AxiosPromise<LoopbackCount> {
            return localVarFp.iconosControllerUpdateAll(where, iconosPartial, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {IconosPartial} [iconosPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        iconosControllerUpdateById(id: number, iconosPartial?: IconosPartial, options?: any): AxiosPromise<any> {
            return localVarFp.iconosControllerUpdateById(id, iconosPartial, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * IconosControllerApi - object-oriented interface
 * @export
 * @class IconosControllerApi
 * @extends {BaseAPI}
 */
export class IconosControllerApi extends BaseAPI {
    /**
     * 
     * @param {{ [key: string]: object; }} [where] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IconosControllerApi
     */
    public iconosControllerCount(where?: { [key: string]: object; }, options?: AxiosRequestConfig) {
        return IconosControllerApiFp(this.configuration).iconosControllerCount(where, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {NewIconos} [newIconos] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IconosControllerApi
     */
    public iconosControllerCreate(newIconos?: NewIconos, options?: AxiosRequestConfig) {
        return IconosControllerApiFp(this.configuration).iconosControllerCreate(newIconos, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IconosControllerApi
     */
    public iconosControllerDeleteById(id: number, options?: AxiosRequestConfig) {
        return IconosControllerApiFp(this.configuration).iconosControllerDeleteById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {IconosFilter1} [filter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IconosControllerApi
     */
    public iconosControllerFind(filter?: IconosFilter1, options?: AxiosRequestConfig) {
        return IconosControllerApiFp(this.configuration).iconosControllerFind(filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {IconosFilter} [filter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IconosControllerApi
     */
    public iconosControllerFindById(id: number, filter?: IconosFilter, options?: AxiosRequestConfig) {
        return IconosControllerApiFp(this.configuration).iconosControllerFindById(id, filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {Iconos} [iconos] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IconosControllerApi
     */
    public iconosControllerReplaceById(id: number, iconos?: Iconos, options?: AxiosRequestConfig) {
        return IconosControllerApiFp(this.configuration).iconosControllerReplaceById(id, iconos, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} [where] 
     * @param {IconosPartial} [iconosPartial] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IconosControllerApi
     */
    public iconosControllerUpdateAll(where?: { [key: string]: object; }, iconosPartial?: IconosPartial, options?: AxiosRequestConfig) {
        return IconosControllerApiFp(this.configuration).iconosControllerUpdateAll(where, iconosPartial, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {IconosPartial} [iconosPartial] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IconosControllerApi
     */
    public iconosControllerUpdateById(id: number, iconosPartial?: IconosPartial, options?: AxiosRequestConfig) {
        return IconosControllerApiFp(this.configuration).iconosControllerUpdateById(id, iconosPartial, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * IdiomasControllerApi - axios parameter creator
 * @export
 */
export const IdiomasControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        idiomasControllerCount: async (where?: { [key: string]: object; }, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/idiomas/count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (where !== undefined) {
                localVarQueryParameter['where'] = where;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {NewIdiomas} [newIdiomas] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        idiomasControllerCreate: async (newIdiomas?: NewIdiomas, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/idiomas`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(newIdiomas, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        idiomasControllerDeleteById: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('idiomasControllerDeleteById', 'id', id)
            const localVarPath = `/idiomas/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {IdiomasFilter1} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        idiomasControllerFind: async (filter?: IdiomasFilter1, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/idiomas`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {IdiomasFilter} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        idiomasControllerFindById: async (id: number, filter?: IdiomasFilter, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('idiomasControllerFindById', 'id', id)
            const localVarPath = `/idiomas/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {Idiomas} [idiomas] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        idiomasControllerReplaceById: async (id: number, idiomas?: Idiomas, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('idiomasControllerReplaceById', 'id', id)
            const localVarPath = `/idiomas/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(idiomas, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {IdiomasPartial} [idiomasPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        idiomasControllerUpdateAll: async (where?: { [key: string]: object; }, idiomasPartial?: IdiomasPartial, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/idiomas`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (where !== undefined) {
                localVarQueryParameter['where'] = where;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(idiomasPartial, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {IdiomasPartial} [idiomasPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        idiomasControllerUpdateById: async (id: number, idiomasPartial?: IdiomasPartial, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('idiomasControllerUpdateById', 'id', id)
            const localVarPath = `/idiomas/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(idiomasPartial, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * IdiomasControllerApi - functional programming interface
 * @export
 */
export const IdiomasControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = IdiomasControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async idiomasControllerCount(where?: { [key: string]: object; }, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoopbackCount>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.idiomasControllerCount(where, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {NewIdiomas} [newIdiomas] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async idiomasControllerCreate(newIdiomas?: NewIdiomas, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Idiomas>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.idiomasControllerCreate(newIdiomas, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async idiomasControllerDeleteById(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.idiomasControllerDeleteById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {IdiomasFilter1} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async idiomasControllerFind(filter?: IdiomasFilter1, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<IdiomasWithRelations>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.idiomasControllerFind(filter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {IdiomasFilter} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async idiomasControllerFindById(id: number, filter?: IdiomasFilter, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IdiomasWithRelations>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.idiomasControllerFindById(id, filter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {Idiomas} [idiomas] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async idiomasControllerReplaceById(id: number, idiomas?: Idiomas, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.idiomasControllerReplaceById(id, idiomas, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {IdiomasPartial} [idiomasPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async idiomasControllerUpdateAll(where?: { [key: string]: object; }, idiomasPartial?: IdiomasPartial, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoopbackCount>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.idiomasControllerUpdateAll(where, idiomasPartial, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {IdiomasPartial} [idiomasPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async idiomasControllerUpdateById(id: number, idiomasPartial?: IdiomasPartial, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.idiomasControllerUpdateById(id, idiomasPartial, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * IdiomasControllerApi - factory interface
 * @export
 */
export const IdiomasControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = IdiomasControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        idiomasControllerCount(where?: { [key: string]: object; }, options?: any): AxiosPromise<LoopbackCount> {
            return localVarFp.idiomasControllerCount(where, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {NewIdiomas} [newIdiomas] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        idiomasControllerCreate(newIdiomas?: NewIdiomas, options?: any): AxiosPromise<Idiomas> {
            return localVarFp.idiomasControllerCreate(newIdiomas, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        idiomasControllerDeleteById(id: number, options?: any): AxiosPromise<any> {
            return localVarFp.idiomasControllerDeleteById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {IdiomasFilter1} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        idiomasControllerFind(filter?: IdiomasFilter1, options?: any): AxiosPromise<Array<IdiomasWithRelations>> {
            return localVarFp.idiomasControllerFind(filter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {IdiomasFilter} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        idiomasControllerFindById(id: number, filter?: IdiomasFilter, options?: any): AxiosPromise<IdiomasWithRelations> {
            return localVarFp.idiomasControllerFindById(id, filter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {Idiomas} [idiomas] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        idiomasControllerReplaceById(id: number, idiomas?: Idiomas, options?: any): AxiosPromise<any> {
            return localVarFp.idiomasControllerReplaceById(id, idiomas, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {IdiomasPartial} [idiomasPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        idiomasControllerUpdateAll(where?: { [key: string]: object; }, idiomasPartial?: IdiomasPartial, options?: any): AxiosPromise<LoopbackCount> {
            return localVarFp.idiomasControllerUpdateAll(where, idiomasPartial, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {IdiomasPartial} [idiomasPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        idiomasControllerUpdateById(id: number, idiomasPartial?: IdiomasPartial, options?: any): AxiosPromise<any> {
            return localVarFp.idiomasControllerUpdateById(id, idiomasPartial, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * IdiomasControllerApi - object-oriented interface
 * @export
 * @class IdiomasControllerApi
 * @extends {BaseAPI}
 */
export class IdiomasControllerApi extends BaseAPI {
    /**
     * 
     * @param {{ [key: string]: object; }} [where] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IdiomasControllerApi
     */
    public idiomasControllerCount(where?: { [key: string]: object; }, options?: AxiosRequestConfig) {
        return IdiomasControllerApiFp(this.configuration).idiomasControllerCount(where, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {NewIdiomas} [newIdiomas] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IdiomasControllerApi
     */
    public idiomasControllerCreate(newIdiomas?: NewIdiomas, options?: AxiosRequestConfig) {
        return IdiomasControllerApiFp(this.configuration).idiomasControllerCreate(newIdiomas, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IdiomasControllerApi
     */
    public idiomasControllerDeleteById(id: number, options?: AxiosRequestConfig) {
        return IdiomasControllerApiFp(this.configuration).idiomasControllerDeleteById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {IdiomasFilter1} [filter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IdiomasControllerApi
     */
    public idiomasControllerFind(filter?: IdiomasFilter1, options?: AxiosRequestConfig) {
        return IdiomasControllerApiFp(this.configuration).idiomasControllerFind(filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {IdiomasFilter} [filter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IdiomasControllerApi
     */
    public idiomasControllerFindById(id: number, filter?: IdiomasFilter, options?: AxiosRequestConfig) {
        return IdiomasControllerApiFp(this.configuration).idiomasControllerFindById(id, filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {Idiomas} [idiomas] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IdiomasControllerApi
     */
    public idiomasControllerReplaceById(id: number, idiomas?: Idiomas, options?: AxiosRequestConfig) {
        return IdiomasControllerApiFp(this.configuration).idiomasControllerReplaceById(id, idiomas, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} [where] 
     * @param {IdiomasPartial} [idiomasPartial] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IdiomasControllerApi
     */
    public idiomasControllerUpdateAll(where?: { [key: string]: object; }, idiomasPartial?: IdiomasPartial, options?: AxiosRequestConfig) {
        return IdiomasControllerApiFp(this.configuration).idiomasControllerUpdateAll(where, idiomasPartial, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {IdiomasPartial} [idiomasPartial] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IdiomasControllerApi
     */
    public idiomasControllerUpdateById(id: number, idiomasPartial?: IdiomasPartial, options?: AxiosRequestConfig) {
        return IdiomasControllerApiFp(this.configuration).idiomasControllerUpdateById(id, idiomasPartial, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * InsertItemControllerApi - axios parameter creator
 * @export
 */
export const InsertItemControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} idProducto 
         * @param {string} atributos 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insertItemControllerInsertItem: async (idProducto: string, atributos: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'idProducto' is not null or undefined
            assertParamExists('insertItemControllerInsertItem', 'idProducto', idProducto)
            // verify required parameter 'atributos' is not null or undefined
            assertParamExists('insertItemControllerInsertItem', 'atributos', atributos)
            const localVarPath = `/insertItem/{idProducto}/{atributos}`
                .replace(`{${"idProducto"}}`, encodeURIComponent(String(idProducto)))
                .replace(`{${"atributos"}}`, encodeURIComponent(String(atributos)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InsertItemControllerApi - functional programming interface
 * @export
 */
export const InsertItemControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = InsertItemControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} idProducto 
         * @param {string} atributos 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async insertItemControllerInsertItem(idProducto: string, atributos: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.insertItemControllerInsertItem(idProducto, atributos, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * InsertItemControllerApi - factory interface
 * @export
 */
export const InsertItemControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = InsertItemControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {string} idProducto 
         * @param {string} atributos 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insertItemControllerInsertItem(idProducto: string, atributos: string, options?: any): AxiosPromise<object> {
            return localVarFp.insertItemControllerInsertItem(idProducto, atributos, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * InsertItemControllerApi - object-oriented interface
 * @export
 * @class InsertItemControllerApi
 * @extends {BaseAPI}
 */
export class InsertItemControllerApi extends BaseAPI {
    /**
     * 
     * @param {string} idProducto 
     * @param {string} atributos 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InsertItemControllerApi
     */
    public insertItemControllerInsertItem(idProducto: string, atributos: string, options?: AxiosRequestConfig) {
        return InsertItemControllerApiFp(this.configuration).insertItemControllerInsertItem(idProducto, atributos, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * LogProductosControllerApi - axios parameter creator
 * @export
 */
export const LogProductosControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logProductosControllerGreet: async (name: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            assertParamExists('logProductosControllerGreet', 'name', name)
            const localVarPath = `/greet/{name}`
                .replace(`{${"name"}}`, encodeURIComponent(String(name)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logProductosControllerLog: async (body?: object, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/log-productos`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LogProductosControllerApi - functional programming interface
 * @export
 */
export const LogProductosControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LogProductosControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async logProductosControllerGreet(name: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.logProductosControllerGreet(name, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async logProductosControllerLog(body?: object, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.logProductosControllerLog(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * LogProductosControllerApi - factory interface
 * @export
 */
export const LogProductosControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LogProductosControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logProductosControllerGreet(name: string, options?: any): AxiosPromise<void> {
            return localVarFp.logProductosControllerGreet(name, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logProductosControllerLog(body?: object, options?: any): AxiosPromise<object> {
            return localVarFp.logProductosControllerLog(body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * LogProductosControllerApi - object-oriented interface
 * @export
 * @class LogProductosControllerApi
 * @extends {BaseAPI}
 */
export class LogProductosControllerApi extends BaseAPI {
    /**
     * 
     * @param {string} name 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LogProductosControllerApi
     */
    public logProductosControllerGreet(name: string, options?: AxiosRequestConfig) {
        return LogProductosControllerApiFp(this.configuration).logProductosControllerGreet(name, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {object} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LogProductosControllerApi
     */
    public logProductosControllerLog(body?: object, options?: AxiosRequestConfig) {
        return LogProductosControllerApiFp(this.configuration).logProductosControllerLog(body, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * LogsnavControllerApi - axios parameter creator
 * @export
 */
export const LogsnavControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logsnavControllerCount: async (where?: { [key: string]: object; }, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/logsnavs/count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (where !== undefined) {
                localVarQueryParameter['where'] = where;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {NewLogsnav} [newLogsnav] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logsnavControllerCreate: async (newLogsnav?: NewLogsnav, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/logsnavs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(newLogsnav, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logsnavControllerDeleteById: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('logsnavControllerDeleteById', 'id', id)
            const localVarPath = `/logsnavs/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {LogsnavFilter1} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logsnavControllerFind: async (filter?: LogsnavFilter1, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/logsnavs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {LogsnavFilter} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logsnavControllerFindById: async (id: number, filter?: LogsnavFilter, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('logsnavControllerFindById', 'id', id)
            const localVarPath = `/logsnavs/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {Logsnav} [logsnav] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logsnavControllerReplaceById: async (id: number, logsnav?: Logsnav, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('logsnavControllerReplaceById', 'id', id)
            const localVarPath = `/logsnavs/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(logsnav, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {LogsnavPartial} [logsnavPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logsnavControllerUpdateAll: async (where?: { [key: string]: object; }, logsnavPartial?: LogsnavPartial, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/logsnavs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (where !== undefined) {
                localVarQueryParameter['where'] = where;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(logsnavPartial, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {LogsnavPartial} [logsnavPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logsnavControllerUpdateById: async (id: number, logsnavPartial?: LogsnavPartial, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('logsnavControllerUpdateById', 'id', id)
            const localVarPath = `/logsnavs/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(logsnavPartial, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LogsnavControllerApi - functional programming interface
 * @export
 */
export const LogsnavControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LogsnavControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async logsnavControllerCount(where?: { [key: string]: object; }, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoopbackCount>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.logsnavControllerCount(where, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {NewLogsnav} [newLogsnav] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async logsnavControllerCreate(newLogsnav?: NewLogsnav, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Logsnav>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.logsnavControllerCreate(newLogsnav, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async logsnavControllerDeleteById(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.logsnavControllerDeleteById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {LogsnavFilter1} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async logsnavControllerFind(filter?: LogsnavFilter1, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<LogsnavWithRelations>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.logsnavControllerFind(filter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {LogsnavFilter} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async logsnavControllerFindById(id: number, filter?: LogsnavFilter, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LogsnavWithRelations>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.logsnavControllerFindById(id, filter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {Logsnav} [logsnav] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async logsnavControllerReplaceById(id: number, logsnav?: Logsnav, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.logsnavControllerReplaceById(id, logsnav, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {LogsnavPartial} [logsnavPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async logsnavControllerUpdateAll(where?: { [key: string]: object; }, logsnavPartial?: LogsnavPartial, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoopbackCount>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.logsnavControllerUpdateAll(where, logsnavPartial, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {LogsnavPartial} [logsnavPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async logsnavControllerUpdateById(id: number, logsnavPartial?: LogsnavPartial, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.logsnavControllerUpdateById(id, logsnavPartial, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * LogsnavControllerApi - factory interface
 * @export
 */
export const LogsnavControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LogsnavControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logsnavControllerCount(where?: { [key: string]: object; }, options?: any): AxiosPromise<LoopbackCount> {
            return localVarFp.logsnavControllerCount(where, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {NewLogsnav} [newLogsnav] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logsnavControllerCreate(newLogsnav?: NewLogsnav, options?: any): AxiosPromise<Logsnav> {
            return localVarFp.logsnavControllerCreate(newLogsnav, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logsnavControllerDeleteById(id: number, options?: any): AxiosPromise<any> {
            return localVarFp.logsnavControllerDeleteById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {LogsnavFilter1} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logsnavControllerFind(filter?: LogsnavFilter1, options?: any): AxiosPromise<Array<LogsnavWithRelations>> {
            return localVarFp.logsnavControllerFind(filter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {LogsnavFilter} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logsnavControllerFindById(id: number, filter?: LogsnavFilter, options?: any): AxiosPromise<LogsnavWithRelations> {
            return localVarFp.logsnavControllerFindById(id, filter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {Logsnav} [logsnav] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logsnavControllerReplaceById(id: number, logsnav?: Logsnav, options?: any): AxiosPromise<any> {
            return localVarFp.logsnavControllerReplaceById(id, logsnav, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {LogsnavPartial} [logsnavPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logsnavControllerUpdateAll(where?: { [key: string]: object; }, logsnavPartial?: LogsnavPartial, options?: any): AxiosPromise<LoopbackCount> {
            return localVarFp.logsnavControllerUpdateAll(where, logsnavPartial, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {LogsnavPartial} [logsnavPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logsnavControllerUpdateById(id: number, logsnavPartial?: LogsnavPartial, options?: any): AxiosPromise<any> {
            return localVarFp.logsnavControllerUpdateById(id, logsnavPartial, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * LogsnavControllerApi - object-oriented interface
 * @export
 * @class LogsnavControllerApi
 * @extends {BaseAPI}
 */
export class LogsnavControllerApi extends BaseAPI {
    /**
     * 
     * @param {{ [key: string]: object; }} [where] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LogsnavControllerApi
     */
    public logsnavControllerCount(where?: { [key: string]: object; }, options?: AxiosRequestConfig) {
        return LogsnavControllerApiFp(this.configuration).logsnavControllerCount(where, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {NewLogsnav} [newLogsnav] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LogsnavControllerApi
     */
    public logsnavControllerCreate(newLogsnav?: NewLogsnav, options?: AxiosRequestConfig) {
        return LogsnavControllerApiFp(this.configuration).logsnavControllerCreate(newLogsnav, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LogsnavControllerApi
     */
    public logsnavControllerDeleteById(id: number, options?: AxiosRequestConfig) {
        return LogsnavControllerApiFp(this.configuration).logsnavControllerDeleteById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LogsnavFilter1} [filter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LogsnavControllerApi
     */
    public logsnavControllerFind(filter?: LogsnavFilter1, options?: AxiosRequestConfig) {
        return LogsnavControllerApiFp(this.configuration).logsnavControllerFind(filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {LogsnavFilter} [filter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LogsnavControllerApi
     */
    public logsnavControllerFindById(id: number, filter?: LogsnavFilter, options?: AxiosRequestConfig) {
        return LogsnavControllerApiFp(this.configuration).logsnavControllerFindById(id, filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {Logsnav} [logsnav] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LogsnavControllerApi
     */
    public logsnavControllerReplaceById(id: number, logsnav?: Logsnav, options?: AxiosRequestConfig) {
        return LogsnavControllerApiFp(this.configuration).logsnavControllerReplaceById(id, logsnav, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} [where] 
     * @param {LogsnavPartial} [logsnavPartial] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LogsnavControllerApi
     */
    public logsnavControllerUpdateAll(where?: { [key: string]: object; }, logsnavPartial?: LogsnavPartial, options?: AxiosRequestConfig) {
        return LogsnavControllerApiFp(this.configuration).logsnavControllerUpdateAll(where, logsnavPartial, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {LogsnavPartial} [logsnavPartial] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LogsnavControllerApi
     */
    public logsnavControllerUpdateById(id: number, logsnavPartial?: LogsnavPartial, options?: AxiosRequestConfig) {
        return LogsnavControllerApiFp(this.configuration).logsnavControllerUpdateById(id, logsnavPartial, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * MarcaControllerApi - axios parameter creator
 * @export
 */
export const MarcaControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        marcaControllerCount: async (where?: { [key: string]: object; }, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Marca/count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (where !== undefined) {
                localVarQueryParameter['where'] = where;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {NewMarca} [newMarca] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        marcaControllerCreate: async (newMarca?: NewMarca, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Marca`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(newMarca, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        marcaControllerDeleteById: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('marcaControllerDeleteById', 'id', id)
            const localVarPath = `/Marca/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {MarcaFilter1} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        marcaControllerFind: async (filter?: MarcaFilter1, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Marca`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {MarcaFilter} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        marcaControllerFindById: async (id: number, filter?: MarcaFilter, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('marcaControllerFindById', 'id', id)
            const localVarPath = `/Marca/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {Marca} [marca] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        marcaControllerReplaceById: async (id: number, marca?: Marca, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('marcaControllerReplaceById', 'id', id)
            const localVarPath = `/Marca/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(marca, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {MarcaPartial} [marcaPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        marcaControllerUpdateAll: async (where?: { [key: string]: object; }, marcaPartial?: MarcaPartial, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Marca`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (where !== undefined) {
                localVarQueryParameter['where'] = where;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(marcaPartial, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {MarcaPartial} [marcaPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        marcaControllerUpdateById: async (id: number, marcaPartial?: MarcaPartial, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('marcaControllerUpdateById', 'id', id)
            const localVarPath = `/Marca/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(marcaPartial, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MarcaControllerApi - functional programming interface
 * @export
 */
export const MarcaControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MarcaControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async marcaControllerCount(where?: { [key: string]: object; }, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoopbackCount>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.marcaControllerCount(where, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {NewMarca} [newMarca] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async marcaControllerCreate(newMarca?: NewMarca, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Marca>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.marcaControllerCreate(newMarca, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async marcaControllerDeleteById(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.marcaControllerDeleteById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {MarcaFilter1} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async marcaControllerFind(filter?: MarcaFilter1, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MarcaWithRelations>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.marcaControllerFind(filter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {MarcaFilter} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async marcaControllerFindById(id: number, filter?: MarcaFilter, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MarcaWithRelations>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.marcaControllerFindById(id, filter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {Marca} [marca] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async marcaControllerReplaceById(id: number, marca?: Marca, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.marcaControllerReplaceById(id, marca, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {MarcaPartial} [marcaPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async marcaControllerUpdateAll(where?: { [key: string]: object; }, marcaPartial?: MarcaPartial, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoopbackCount>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.marcaControllerUpdateAll(where, marcaPartial, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {MarcaPartial} [marcaPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async marcaControllerUpdateById(id: number, marcaPartial?: MarcaPartial, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.marcaControllerUpdateById(id, marcaPartial, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MarcaControllerApi - factory interface
 * @export
 */
export const MarcaControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MarcaControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        marcaControllerCount(where?: { [key: string]: object; }, options?: any): AxiosPromise<LoopbackCount> {
            return localVarFp.marcaControllerCount(where, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {NewMarca} [newMarca] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        marcaControllerCreate(newMarca?: NewMarca, options?: any): AxiosPromise<Marca> {
            return localVarFp.marcaControllerCreate(newMarca, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        marcaControllerDeleteById(id: number, options?: any): AxiosPromise<any> {
            return localVarFp.marcaControllerDeleteById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MarcaFilter1} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        marcaControllerFind(filter?: MarcaFilter1, options?: any): AxiosPromise<Array<MarcaWithRelations>> {
            return localVarFp.marcaControllerFind(filter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {MarcaFilter} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        marcaControllerFindById(id: number, filter?: MarcaFilter, options?: any): AxiosPromise<MarcaWithRelations> {
            return localVarFp.marcaControllerFindById(id, filter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {Marca} [marca] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        marcaControllerReplaceById(id: number, marca?: Marca, options?: any): AxiosPromise<any> {
            return localVarFp.marcaControllerReplaceById(id, marca, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {MarcaPartial} [marcaPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        marcaControllerUpdateAll(where?: { [key: string]: object; }, marcaPartial?: MarcaPartial, options?: any): AxiosPromise<LoopbackCount> {
            return localVarFp.marcaControllerUpdateAll(where, marcaPartial, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {MarcaPartial} [marcaPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        marcaControllerUpdateById(id: number, marcaPartial?: MarcaPartial, options?: any): AxiosPromise<any> {
            return localVarFp.marcaControllerUpdateById(id, marcaPartial, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MarcaControllerApi - object-oriented interface
 * @export
 * @class MarcaControllerApi
 * @extends {BaseAPI}
 */
export class MarcaControllerApi extends BaseAPI {
    /**
     * 
     * @param {{ [key: string]: object; }} [where] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarcaControllerApi
     */
    public marcaControllerCount(where?: { [key: string]: object; }, options?: AxiosRequestConfig) {
        return MarcaControllerApiFp(this.configuration).marcaControllerCount(where, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {NewMarca} [newMarca] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarcaControllerApi
     */
    public marcaControllerCreate(newMarca?: NewMarca, options?: AxiosRequestConfig) {
        return MarcaControllerApiFp(this.configuration).marcaControllerCreate(newMarca, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarcaControllerApi
     */
    public marcaControllerDeleteById(id: number, options?: AxiosRequestConfig) {
        return MarcaControllerApiFp(this.configuration).marcaControllerDeleteById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MarcaFilter1} [filter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarcaControllerApi
     */
    public marcaControllerFind(filter?: MarcaFilter1, options?: AxiosRequestConfig) {
        return MarcaControllerApiFp(this.configuration).marcaControllerFind(filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {MarcaFilter} [filter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarcaControllerApi
     */
    public marcaControllerFindById(id: number, filter?: MarcaFilter, options?: AxiosRequestConfig) {
        return MarcaControllerApiFp(this.configuration).marcaControllerFindById(id, filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {Marca} [marca] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarcaControllerApi
     */
    public marcaControllerReplaceById(id: number, marca?: Marca, options?: AxiosRequestConfig) {
        return MarcaControllerApiFp(this.configuration).marcaControllerReplaceById(id, marca, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} [where] 
     * @param {MarcaPartial} [marcaPartial] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarcaControllerApi
     */
    public marcaControllerUpdateAll(where?: { [key: string]: object; }, marcaPartial?: MarcaPartial, options?: AxiosRequestConfig) {
        return MarcaControllerApiFp(this.configuration).marcaControllerUpdateAll(where, marcaPartial, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {MarcaPartial} [marcaPartial] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarcaControllerApi
     */
    public marcaControllerUpdateById(id: number, marcaPartial?: MarcaPartial, options?: AxiosRequestConfig) {
        return MarcaControllerApiFp(this.configuration).marcaControllerUpdateById(id, marcaPartial, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * MarketPlacePromptsControllerApi - axios parameter creator
 * @export
 */
export const MarketPlacePromptsControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        marketPlacePromptsControllerCount: async (where?: { [key: string]: object; }, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/MarketPlacePrompts/count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (where !== undefined) {
                localVarQueryParameter['where'] = where;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {NewMarketPlacePrompts} [newMarketPlacePrompts] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        marketPlacePromptsControllerCreate: async (newMarketPlacePrompts?: NewMarketPlacePrompts, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/MarketPlacePrompts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(newMarketPlacePrompts, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        marketPlacePromptsControllerDeleteById: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('marketPlacePromptsControllerDeleteById', 'id', id)
            const localVarPath = `/MarketPlacePrompts/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [marketplaceId] 
         * @param {number} [categoriaId] 
         * @param {number} [idExcluir] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        marketPlacePromptsControllerExisteMarketPlaceYCategoria: async (marketplaceId?: number, categoriaId?: number, idExcluir?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/existeMarketPlaceYCategoria`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (marketplaceId !== undefined) {
                localVarQueryParameter['marketplaceId'] = marketplaceId;
            }

            if (categoriaId !== undefined) {
                localVarQueryParameter['categoriaId'] = categoriaId;
            }

            if (idExcluir !== undefined) {
                localVarQueryParameter['idExcluir'] = idExcluir;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {MarketPlacePromptsFilter1} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        marketPlacePromptsControllerFind: async (filter?: MarketPlacePromptsFilter1, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/MarketPlacePrompts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {MarketPlacePromptsFilter} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        marketPlacePromptsControllerFindById: async (id: number, filter?: MarketPlacePromptsFilter, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('marketPlacePromptsControllerFindById', 'id', id)
            const localVarPath = `/MarketPlacePrompts/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [$endpoint] 
         * @param {InlineObject4} [inlineObject4] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        marketPlacePromptsControllerGenerarTextosIAAPISVAN: async ($endpoint?: string, inlineObject4?: InlineObject4, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/generarTextos_IA_API_SVAN`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if ($endpoint !== undefined) {
                localVarQueryParameter['$endpoint'] = $endpoint;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inlineObject4, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {MarketPlacePrompts} [marketPlacePrompts] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        marketPlacePromptsControllerReplaceById: async (id: number, marketPlacePrompts?: MarketPlacePrompts, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('marketPlacePromptsControllerReplaceById', 'id', id)
            const localVarPath = `/MarketPlacePrompts/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(marketPlacePrompts, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {MarketPlacePromptsPartial} [marketPlacePromptsPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        marketPlacePromptsControllerUpdateAll: async (where?: { [key: string]: object; }, marketPlacePromptsPartial?: MarketPlacePromptsPartial, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/MarketPlacePrompts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (where !== undefined) {
                localVarQueryParameter['where'] = where;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(marketPlacePromptsPartial, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {MarketPlacePromptsPartial} [marketPlacePromptsPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        marketPlacePromptsControllerUpdateById: async (id: number, marketPlacePromptsPartial?: MarketPlacePromptsPartial, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('marketPlacePromptsControllerUpdateById', 'id', id)
            const localVarPath = `/MarketPlacePrompts/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(marketPlacePromptsPartial, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MarketPlacePromptsControllerApi - functional programming interface
 * @export
 */
export const MarketPlacePromptsControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MarketPlacePromptsControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async marketPlacePromptsControllerCount(where?: { [key: string]: object; }, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoopbackCount>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.marketPlacePromptsControllerCount(where, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {NewMarketPlacePrompts} [newMarketPlacePrompts] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async marketPlacePromptsControllerCreate(newMarketPlacePrompts?: NewMarketPlacePrompts, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MarketPlacePrompts>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.marketPlacePromptsControllerCreate(newMarketPlacePrompts, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async marketPlacePromptsControllerDeleteById(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.marketPlacePromptsControllerDeleteById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [marketplaceId] 
         * @param {number} [categoriaId] 
         * @param {number} [idExcluir] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async marketPlacePromptsControllerExisteMarketPlaceYCategoria(marketplaceId?: number, categoriaId?: number, idExcluir?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.marketPlacePromptsControllerExisteMarketPlaceYCategoria(marketplaceId, categoriaId, idExcluir, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {MarketPlacePromptsFilter1} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async marketPlacePromptsControllerFind(filter?: MarketPlacePromptsFilter1, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MarketPlacePromptsWithRelations>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.marketPlacePromptsControllerFind(filter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {MarketPlacePromptsFilter} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async marketPlacePromptsControllerFindById(id: number, filter?: MarketPlacePromptsFilter, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MarketPlacePromptsWithRelations>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.marketPlacePromptsControllerFindById(id, filter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [$endpoint] 
         * @param {InlineObject4} [inlineObject4] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async marketPlacePromptsControllerGenerarTextosIAAPISVAN($endpoint?: string, inlineObject4?: InlineObject4, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.marketPlacePromptsControllerGenerarTextosIAAPISVAN($endpoint, inlineObject4, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {MarketPlacePrompts} [marketPlacePrompts] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async marketPlacePromptsControllerReplaceById(id: number, marketPlacePrompts?: MarketPlacePrompts, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.marketPlacePromptsControllerReplaceById(id, marketPlacePrompts, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {MarketPlacePromptsPartial} [marketPlacePromptsPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async marketPlacePromptsControllerUpdateAll(where?: { [key: string]: object; }, marketPlacePromptsPartial?: MarketPlacePromptsPartial, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoopbackCount>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.marketPlacePromptsControllerUpdateAll(where, marketPlacePromptsPartial, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {MarketPlacePromptsPartial} [marketPlacePromptsPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async marketPlacePromptsControllerUpdateById(id: number, marketPlacePromptsPartial?: MarketPlacePromptsPartial, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.marketPlacePromptsControllerUpdateById(id, marketPlacePromptsPartial, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MarketPlacePromptsControllerApi - factory interface
 * @export
 */
export const MarketPlacePromptsControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MarketPlacePromptsControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        marketPlacePromptsControllerCount(where?: { [key: string]: object; }, options?: any): AxiosPromise<LoopbackCount> {
            return localVarFp.marketPlacePromptsControllerCount(where, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {NewMarketPlacePrompts} [newMarketPlacePrompts] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        marketPlacePromptsControllerCreate(newMarketPlacePrompts?: NewMarketPlacePrompts, options?: any): AxiosPromise<MarketPlacePrompts> {
            return localVarFp.marketPlacePromptsControllerCreate(newMarketPlacePrompts, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        marketPlacePromptsControllerDeleteById(id: number, options?: any): AxiosPromise<any> {
            return localVarFp.marketPlacePromptsControllerDeleteById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [marketplaceId] 
         * @param {number} [categoriaId] 
         * @param {number} [idExcluir] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        marketPlacePromptsControllerExisteMarketPlaceYCategoria(marketplaceId?: number, categoriaId?: number, idExcluir?: number, options?: any): AxiosPromise<object> {
            return localVarFp.marketPlacePromptsControllerExisteMarketPlaceYCategoria(marketplaceId, categoriaId, idExcluir, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MarketPlacePromptsFilter1} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        marketPlacePromptsControllerFind(filter?: MarketPlacePromptsFilter1, options?: any): AxiosPromise<Array<MarketPlacePromptsWithRelations>> {
            return localVarFp.marketPlacePromptsControllerFind(filter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {MarketPlacePromptsFilter} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        marketPlacePromptsControllerFindById(id: number, filter?: MarketPlacePromptsFilter, options?: any): AxiosPromise<MarketPlacePromptsWithRelations> {
            return localVarFp.marketPlacePromptsControllerFindById(id, filter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [$endpoint] 
         * @param {InlineObject4} [inlineObject4] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        marketPlacePromptsControllerGenerarTextosIAAPISVAN($endpoint?: string, inlineObject4?: InlineObject4, options?: any): AxiosPromise<object> {
            return localVarFp.marketPlacePromptsControllerGenerarTextosIAAPISVAN($endpoint, inlineObject4, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {MarketPlacePrompts} [marketPlacePrompts] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        marketPlacePromptsControllerReplaceById(id: number, marketPlacePrompts?: MarketPlacePrompts, options?: any): AxiosPromise<any> {
            return localVarFp.marketPlacePromptsControllerReplaceById(id, marketPlacePrompts, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {MarketPlacePromptsPartial} [marketPlacePromptsPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        marketPlacePromptsControllerUpdateAll(where?: { [key: string]: object; }, marketPlacePromptsPartial?: MarketPlacePromptsPartial, options?: any): AxiosPromise<LoopbackCount> {
            return localVarFp.marketPlacePromptsControllerUpdateAll(where, marketPlacePromptsPartial, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {MarketPlacePromptsPartial} [marketPlacePromptsPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        marketPlacePromptsControllerUpdateById(id: number, marketPlacePromptsPartial?: MarketPlacePromptsPartial, options?: any): AxiosPromise<any> {
            return localVarFp.marketPlacePromptsControllerUpdateById(id, marketPlacePromptsPartial, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MarketPlacePromptsControllerApi - object-oriented interface
 * @export
 * @class MarketPlacePromptsControllerApi
 * @extends {BaseAPI}
 */
export class MarketPlacePromptsControllerApi extends BaseAPI {
    /**
     * 
     * @param {{ [key: string]: object; }} [where] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarketPlacePromptsControllerApi
     */
    public marketPlacePromptsControllerCount(where?: { [key: string]: object; }, options?: AxiosRequestConfig) {
        return MarketPlacePromptsControllerApiFp(this.configuration).marketPlacePromptsControllerCount(where, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {NewMarketPlacePrompts} [newMarketPlacePrompts] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarketPlacePromptsControllerApi
     */
    public marketPlacePromptsControllerCreate(newMarketPlacePrompts?: NewMarketPlacePrompts, options?: AxiosRequestConfig) {
        return MarketPlacePromptsControllerApiFp(this.configuration).marketPlacePromptsControllerCreate(newMarketPlacePrompts, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarketPlacePromptsControllerApi
     */
    public marketPlacePromptsControllerDeleteById(id: number, options?: AxiosRequestConfig) {
        return MarketPlacePromptsControllerApiFp(this.configuration).marketPlacePromptsControllerDeleteById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [marketplaceId] 
     * @param {number} [categoriaId] 
     * @param {number} [idExcluir] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarketPlacePromptsControllerApi
     */
    public marketPlacePromptsControllerExisteMarketPlaceYCategoria(marketplaceId?: number, categoriaId?: number, idExcluir?: number, options?: AxiosRequestConfig) {
        return MarketPlacePromptsControllerApiFp(this.configuration).marketPlacePromptsControllerExisteMarketPlaceYCategoria(marketplaceId, categoriaId, idExcluir, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MarketPlacePromptsFilter1} [filter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarketPlacePromptsControllerApi
     */
    public marketPlacePromptsControllerFind(filter?: MarketPlacePromptsFilter1, options?: AxiosRequestConfig) {
        return MarketPlacePromptsControllerApiFp(this.configuration).marketPlacePromptsControllerFind(filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {MarketPlacePromptsFilter} [filter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarketPlacePromptsControllerApi
     */
    public marketPlacePromptsControllerFindById(id: number, filter?: MarketPlacePromptsFilter, options?: AxiosRequestConfig) {
        return MarketPlacePromptsControllerApiFp(this.configuration).marketPlacePromptsControllerFindById(id, filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [$endpoint] 
     * @param {InlineObject4} [inlineObject4] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarketPlacePromptsControllerApi
     */
    public marketPlacePromptsControllerGenerarTextosIAAPISVAN($endpoint?: string, inlineObject4?: InlineObject4, options?: AxiosRequestConfig) {
        return MarketPlacePromptsControllerApiFp(this.configuration).marketPlacePromptsControllerGenerarTextosIAAPISVAN($endpoint, inlineObject4, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {MarketPlacePrompts} [marketPlacePrompts] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarketPlacePromptsControllerApi
     */
    public marketPlacePromptsControllerReplaceById(id: number, marketPlacePrompts?: MarketPlacePrompts, options?: AxiosRequestConfig) {
        return MarketPlacePromptsControllerApiFp(this.configuration).marketPlacePromptsControllerReplaceById(id, marketPlacePrompts, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} [where] 
     * @param {MarketPlacePromptsPartial} [marketPlacePromptsPartial] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarketPlacePromptsControllerApi
     */
    public marketPlacePromptsControllerUpdateAll(where?: { [key: string]: object; }, marketPlacePromptsPartial?: MarketPlacePromptsPartial, options?: AxiosRequestConfig) {
        return MarketPlacePromptsControllerApiFp(this.configuration).marketPlacePromptsControllerUpdateAll(where, marketPlacePromptsPartial, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {MarketPlacePromptsPartial} [marketPlacePromptsPartial] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarketPlacePromptsControllerApi
     */
    public marketPlacePromptsControllerUpdateById(id: number, marketPlacePromptsPartial?: MarketPlacePromptsPartial, options?: AxiosRequestConfig) {
        return MarketPlacePromptsControllerApiFp(this.configuration).marketPlacePromptsControllerUpdateById(id, marketPlacePromptsPartial, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * MarketPlacesControllerApi - axios parameter creator
 * @export
 */
export const MarketPlacesControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        marketPlacesControllerCount: async (where?: { [key: string]: object; }, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/MarketPlaces/count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (where !== undefined) {
                localVarQueryParameter['where'] = where;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {NewMarketPlaces} [newMarketPlaces] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        marketPlacesControllerCreate: async (newMarketPlaces?: NewMarketPlaces, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/MarketPlaces`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(newMarketPlaces, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        marketPlacesControllerDeleteById: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('marketPlacesControllerDeleteById', 'id', id)
            const localVarPath = `/MarketPlaces/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {MarketPlacesFilter1} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        marketPlacesControllerFind: async (filter?: MarketPlacesFilter1, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/MarketPlaces`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {MarketPlacesFilter} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        marketPlacesControllerFindById: async (id: number, filter?: MarketPlacesFilter, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('marketPlacesControllerFindById', 'id', id)
            const localVarPath = `/MarketPlaces/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {MarketPlaces} [marketPlaces] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        marketPlacesControllerReplaceById: async (id: number, marketPlaces?: MarketPlaces, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('marketPlacesControllerReplaceById', 'id', id)
            const localVarPath = `/MarketPlaces/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(marketPlaces, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {MarketPlacesPartial} [marketPlacesPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        marketPlacesControllerUpdateAll: async (where?: { [key: string]: object; }, marketPlacesPartial?: MarketPlacesPartial, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/MarketPlaces`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (where !== undefined) {
                localVarQueryParameter['where'] = where;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(marketPlacesPartial, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {MarketPlacesPartial} [marketPlacesPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        marketPlacesControllerUpdateById: async (id: number, marketPlacesPartial?: MarketPlacesPartial, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('marketPlacesControllerUpdateById', 'id', id)
            const localVarPath = `/MarketPlaces/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(marketPlacesPartial, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MarketPlacesControllerApi - functional programming interface
 * @export
 */
export const MarketPlacesControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MarketPlacesControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async marketPlacesControllerCount(where?: { [key: string]: object; }, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoopbackCount>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.marketPlacesControllerCount(where, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {NewMarketPlaces} [newMarketPlaces] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async marketPlacesControllerCreate(newMarketPlaces?: NewMarketPlaces, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MarketPlaces>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.marketPlacesControllerCreate(newMarketPlaces, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async marketPlacesControllerDeleteById(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.marketPlacesControllerDeleteById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {MarketPlacesFilter1} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async marketPlacesControllerFind(filter?: MarketPlacesFilter1, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MarketPlacesWithRelations>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.marketPlacesControllerFind(filter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {MarketPlacesFilter} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async marketPlacesControllerFindById(id: number, filter?: MarketPlacesFilter, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MarketPlacesWithRelations>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.marketPlacesControllerFindById(id, filter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {MarketPlaces} [marketPlaces] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async marketPlacesControllerReplaceById(id: number, marketPlaces?: MarketPlaces, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.marketPlacesControllerReplaceById(id, marketPlaces, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {MarketPlacesPartial} [marketPlacesPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async marketPlacesControllerUpdateAll(where?: { [key: string]: object; }, marketPlacesPartial?: MarketPlacesPartial, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoopbackCount>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.marketPlacesControllerUpdateAll(where, marketPlacesPartial, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {MarketPlacesPartial} [marketPlacesPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async marketPlacesControllerUpdateById(id: number, marketPlacesPartial?: MarketPlacesPartial, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.marketPlacesControllerUpdateById(id, marketPlacesPartial, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MarketPlacesControllerApi - factory interface
 * @export
 */
export const MarketPlacesControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MarketPlacesControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        marketPlacesControllerCount(where?: { [key: string]: object; }, options?: any): AxiosPromise<LoopbackCount> {
            return localVarFp.marketPlacesControllerCount(where, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {NewMarketPlaces} [newMarketPlaces] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        marketPlacesControllerCreate(newMarketPlaces?: NewMarketPlaces, options?: any): AxiosPromise<MarketPlaces> {
            return localVarFp.marketPlacesControllerCreate(newMarketPlaces, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        marketPlacesControllerDeleteById(id: number, options?: any): AxiosPromise<any> {
            return localVarFp.marketPlacesControllerDeleteById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MarketPlacesFilter1} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        marketPlacesControllerFind(filter?: MarketPlacesFilter1, options?: any): AxiosPromise<Array<MarketPlacesWithRelations>> {
            return localVarFp.marketPlacesControllerFind(filter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {MarketPlacesFilter} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        marketPlacesControllerFindById(id: number, filter?: MarketPlacesFilter, options?: any): AxiosPromise<MarketPlacesWithRelations> {
            return localVarFp.marketPlacesControllerFindById(id, filter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {MarketPlaces} [marketPlaces] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        marketPlacesControllerReplaceById(id: number, marketPlaces?: MarketPlaces, options?: any): AxiosPromise<any> {
            return localVarFp.marketPlacesControllerReplaceById(id, marketPlaces, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {MarketPlacesPartial} [marketPlacesPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        marketPlacesControllerUpdateAll(where?: { [key: string]: object; }, marketPlacesPartial?: MarketPlacesPartial, options?: any): AxiosPromise<LoopbackCount> {
            return localVarFp.marketPlacesControllerUpdateAll(where, marketPlacesPartial, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {MarketPlacesPartial} [marketPlacesPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        marketPlacesControllerUpdateById(id: number, marketPlacesPartial?: MarketPlacesPartial, options?: any): AxiosPromise<any> {
            return localVarFp.marketPlacesControllerUpdateById(id, marketPlacesPartial, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MarketPlacesControllerApi - object-oriented interface
 * @export
 * @class MarketPlacesControllerApi
 * @extends {BaseAPI}
 */
export class MarketPlacesControllerApi extends BaseAPI {
    /**
     * 
     * @param {{ [key: string]: object; }} [where] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarketPlacesControllerApi
     */
    public marketPlacesControllerCount(where?: { [key: string]: object; }, options?: AxiosRequestConfig) {
        return MarketPlacesControllerApiFp(this.configuration).marketPlacesControllerCount(where, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {NewMarketPlaces} [newMarketPlaces] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarketPlacesControllerApi
     */
    public marketPlacesControllerCreate(newMarketPlaces?: NewMarketPlaces, options?: AxiosRequestConfig) {
        return MarketPlacesControllerApiFp(this.configuration).marketPlacesControllerCreate(newMarketPlaces, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarketPlacesControllerApi
     */
    public marketPlacesControllerDeleteById(id: number, options?: AxiosRequestConfig) {
        return MarketPlacesControllerApiFp(this.configuration).marketPlacesControllerDeleteById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MarketPlacesFilter1} [filter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarketPlacesControllerApi
     */
    public marketPlacesControllerFind(filter?: MarketPlacesFilter1, options?: AxiosRequestConfig) {
        return MarketPlacesControllerApiFp(this.configuration).marketPlacesControllerFind(filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {MarketPlacesFilter} [filter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarketPlacesControllerApi
     */
    public marketPlacesControllerFindById(id: number, filter?: MarketPlacesFilter, options?: AxiosRequestConfig) {
        return MarketPlacesControllerApiFp(this.configuration).marketPlacesControllerFindById(id, filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {MarketPlaces} [marketPlaces] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarketPlacesControllerApi
     */
    public marketPlacesControllerReplaceById(id: number, marketPlaces?: MarketPlaces, options?: AxiosRequestConfig) {
        return MarketPlacesControllerApiFp(this.configuration).marketPlacesControllerReplaceById(id, marketPlaces, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} [where] 
     * @param {MarketPlacesPartial} [marketPlacesPartial] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarketPlacesControllerApi
     */
    public marketPlacesControllerUpdateAll(where?: { [key: string]: object; }, marketPlacesPartial?: MarketPlacesPartial, options?: AxiosRequestConfig) {
        return MarketPlacesControllerApiFp(this.configuration).marketPlacesControllerUpdateAll(where, marketPlacesPartial, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {MarketPlacesPartial} [marketPlacesPartial] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarketPlacesControllerApi
     */
    public marketPlacesControllerUpdateById(id: number, marketPlacesPartial?: MarketPlacesPartial, options?: AxiosRequestConfig) {
        return MarketPlacesControllerApiFp(this.configuration).marketPlacesControllerUpdateById(id, marketPlacesPartial, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * MultimediaControllerApi - axios parameter creator
 * @export
 */
export const MultimediaControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        multimediaControllerCount: async (where?: { [key: string]: object; }, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/multimedias/count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (where !== undefined) {
                localVarQueryParameter['where'] = where;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {NewMultimedia} [newMultimedia] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        multimediaControllerCreate: async (newMultimedia?: NewMultimedia, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/multimedias`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(newMultimedia, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        multimediaControllerDeleteById: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('multimediaControllerDeleteById', 'id', id)
            const localVarPath = `/multimedias/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {MultimediaFilter1} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        multimediaControllerFind: async (filter?: MultimediaFilter1, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/multimedias`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {MultimediaFilter} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        multimediaControllerFindById: async (id: number, filter?: MultimediaFilter, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('multimediaControllerFindById', 'id', id)
            const localVarPath = `/multimedias/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {Multimedia} [multimedia] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        multimediaControllerReplaceById: async (id: number, multimedia?: Multimedia, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('multimediaControllerReplaceById', 'id', id)
            const localVarPath = `/multimedias/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(multimedia, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {MultimediaPartial} [multimediaPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        multimediaControllerUpdateAll: async (where?: { [key: string]: object; }, multimediaPartial?: MultimediaPartial, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/multimedias`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (where !== undefined) {
                localVarQueryParameter['where'] = where;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(multimediaPartial, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {MultimediaPartial} [multimediaPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        multimediaControllerUpdateById: async (id: number, multimediaPartial?: MultimediaPartial, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('multimediaControllerUpdateById', 'id', id)
            const localVarPath = `/multimedias/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(multimediaPartial, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MultimediaControllerApi - functional programming interface
 * @export
 */
export const MultimediaControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MultimediaControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async multimediaControllerCount(where?: { [key: string]: object; }, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoopbackCount>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.multimediaControllerCount(where, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {NewMultimedia} [newMultimedia] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async multimediaControllerCreate(newMultimedia?: NewMultimedia, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Multimedia>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.multimediaControllerCreate(newMultimedia, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async multimediaControllerDeleteById(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.multimediaControllerDeleteById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {MultimediaFilter1} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async multimediaControllerFind(filter?: MultimediaFilter1, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MultimediaWithRelations>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.multimediaControllerFind(filter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {MultimediaFilter} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async multimediaControllerFindById(id: number, filter?: MultimediaFilter, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MultimediaWithRelations>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.multimediaControllerFindById(id, filter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {Multimedia} [multimedia] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async multimediaControllerReplaceById(id: number, multimedia?: Multimedia, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.multimediaControllerReplaceById(id, multimedia, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {MultimediaPartial} [multimediaPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async multimediaControllerUpdateAll(where?: { [key: string]: object; }, multimediaPartial?: MultimediaPartial, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoopbackCount>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.multimediaControllerUpdateAll(where, multimediaPartial, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {MultimediaPartial} [multimediaPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async multimediaControllerUpdateById(id: number, multimediaPartial?: MultimediaPartial, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.multimediaControllerUpdateById(id, multimediaPartial, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MultimediaControllerApi - factory interface
 * @export
 */
export const MultimediaControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MultimediaControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        multimediaControllerCount(where?: { [key: string]: object; }, options?: any): AxiosPromise<LoopbackCount> {
            return localVarFp.multimediaControllerCount(where, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {NewMultimedia} [newMultimedia] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        multimediaControllerCreate(newMultimedia?: NewMultimedia, options?: any): AxiosPromise<Multimedia> {
            return localVarFp.multimediaControllerCreate(newMultimedia, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        multimediaControllerDeleteById(id: number, options?: any): AxiosPromise<any> {
            return localVarFp.multimediaControllerDeleteById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MultimediaFilter1} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        multimediaControllerFind(filter?: MultimediaFilter1, options?: any): AxiosPromise<Array<MultimediaWithRelations>> {
            return localVarFp.multimediaControllerFind(filter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {MultimediaFilter} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        multimediaControllerFindById(id: number, filter?: MultimediaFilter, options?: any): AxiosPromise<MultimediaWithRelations> {
            return localVarFp.multimediaControllerFindById(id, filter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {Multimedia} [multimedia] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        multimediaControllerReplaceById(id: number, multimedia?: Multimedia, options?: any): AxiosPromise<any> {
            return localVarFp.multimediaControllerReplaceById(id, multimedia, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {MultimediaPartial} [multimediaPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        multimediaControllerUpdateAll(where?: { [key: string]: object; }, multimediaPartial?: MultimediaPartial, options?: any): AxiosPromise<LoopbackCount> {
            return localVarFp.multimediaControllerUpdateAll(where, multimediaPartial, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {MultimediaPartial} [multimediaPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        multimediaControllerUpdateById(id: number, multimediaPartial?: MultimediaPartial, options?: any): AxiosPromise<any> {
            return localVarFp.multimediaControllerUpdateById(id, multimediaPartial, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MultimediaControllerApi - object-oriented interface
 * @export
 * @class MultimediaControllerApi
 * @extends {BaseAPI}
 */
export class MultimediaControllerApi extends BaseAPI {
    /**
     * 
     * @param {{ [key: string]: object; }} [where] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MultimediaControllerApi
     */
    public multimediaControllerCount(where?: { [key: string]: object; }, options?: AxiosRequestConfig) {
        return MultimediaControllerApiFp(this.configuration).multimediaControllerCount(where, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {NewMultimedia} [newMultimedia] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MultimediaControllerApi
     */
    public multimediaControllerCreate(newMultimedia?: NewMultimedia, options?: AxiosRequestConfig) {
        return MultimediaControllerApiFp(this.configuration).multimediaControllerCreate(newMultimedia, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MultimediaControllerApi
     */
    public multimediaControllerDeleteById(id: number, options?: AxiosRequestConfig) {
        return MultimediaControllerApiFp(this.configuration).multimediaControllerDeleteById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MultimediaFilter1} [filter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MultimediaControllerApi
     */
    public multimediaControllerFind(filter?: MultimediaFilter1, options?: AxiosRequestConfig) {
        return MultimediaControllerApiFp(this.configuration).multimediaControllerFind(filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {MultimediaFilter} [filter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MultimediaControllerApi
     */
    public multimediaControllerFindById(id: number, filter?: MultimediaFilter, options?: AxiosRequestConfig) {
        return MultimediaControllerApiFp(this.configuration).multimediaControllerFindById(id, filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {Multimedia} [multimedia] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MultimediaControllerApi
     */
    public multimediaControllerReplaceById(id: number, multimedia?: Multimedia, options?: AxiosRequestConfig) {
        return MultimediaControllerApiFp(this.configuration).multimediaControllerReplaceById(id, multimedia, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} [where] 
     * @param {MultimediaPartial} [multimediaPartial] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MultimediaControllerApi
     */
    public multimediaControllerUpdateAll(where?: { [key: string]: object; }, multimediaPartial?: MultimediaPartial, options?: AxiosRequestConfig) {
        return MultimediaControllerApiFp(this.configuration).multimediaControllerUpdateAll(where, multimediaPartial, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {MultimediaPartial} [multimediaPartial] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MultimediaControllerApi
     */
    public multimediaControllerUpdateById(id: number, multimediaPartial?: MultimediaPartial, options?: AxiosRequestConfig) {
        return MultimediaControllerApiFp(this.configuration).multimediaControllerUpdateById(id, multimediaPartial, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ParametrosGlobalesControllerApi - axios parameter creator
 * @export
 */
export const ParametrosGlobalesControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        parametrosGlobalesControllerCount: async (where?: { [key: string]: object; }, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/parametrosGlobales/count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (where !== undefined) {
                localVarQueryParameter['where'] = where;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {NewParametrosGlobales} [newParametrosGlobales] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        parametrosGlobalesControllerCreate: async (newParametrosGlobales?: NewParametrosGlobales, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/parametrosGlobales`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(newParametrosGlobales, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        parametrosGlobalesControllerDeleteById: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('parametrosGlobalesControllerDeleteById', 'id', id)
            const localVarPath = `/parametrosGlobales/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ParametrosGlobalesFilter1} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        parametrosGlobalesControllerFind: async (filter?: ParametrosGlobalesFilter1, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/parametrosGlobales`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {ParametrosGlobalesFilter} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        parametrosGlobalesControllerFindById: async (id: number, filter?: ParametrosGlobalesFilter, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('parametrosGlobalesControllerFindById', 'id', id)
            const localVarPath = `/parametrosGlobales/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {ParametrosGlobales} [parametrosGlobales] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        parametrosGlobalesControllerReplaceById: async (id: number, parametrosGlobales?: ParametrosGlobales, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('parametrosGlobalesControllerReplaceById', 'id', id)
            const localVarPath = `/parametrosGlobales/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(parametrosGlobales, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {ParametrosGlobalesPartial} [parametrosGlobalesPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        parametrosGlobalesControllerUpdateAll: async (where?: { [key: string]: object; }, parametrosGlobalesPartial?: ParametrosGlobalesPartial, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/parametrosGlobales`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (where !== undefined) {
                localVarQueryParameter['where'] = where;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(parametrosGlobalesPartial, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {ParametrosGlobalesPartial} [parametrosGlobalesPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        parametrosGlobalesControllerUpdateById: async (id: number, parametrosGlobalesPartial?: ParametrosGlobalesPartial, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('parametrosGlobalesControllerUpdateById', 'id', id)
            const localVarPath = `/parametrosGlobales/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(parametrosGlobalesPartial, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ParametrosGlobalesControllerApi - functional programming interface
 * @export
 */
export const ParametrosGlobalesControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ParametrosGlobalesControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async parametrosGlobalesControllerCount(where?: { [key: string]: object; }, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoopbackCount>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.parametrosGlobalesControllerCount(where, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {NewParametrosGlobales} [newParametrosGlobales] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async parametrosGlobalesControllerCreate(newParametrosGlobales?: NewParametrosGlobales, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ParametrosGlobales>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.parametrosGlobalesControllerCreate(newParametrosGlobales, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async parametrosGlobalesControllerDeleteById(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.parametrosGlobalesControllerDeleteById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ParametrosGlobalesFilter1} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async parametrosGlobalesControllerFind(filter?: ParametrosGlobalesFilter1, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ParametrosGlobalesWithRelations>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.parametrosGlobalesControllerFind(filter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {ParametrosGlobalesFilter} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async parametrosGlobalesControllerFindById(id: number, filter?: ParametrosGlobalesFilter, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ParametrosGlobalesWithRelations>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.parametrosGlobalesControllerFindById(id, filter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {ParametrosGlobales} [parametrosGlobales] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async parametrosGlobalesControllerReplaceById(id: number, parametrosGlobales?: ParametrosGlobales, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.parametrosGlobalesControllerReplaceById(id, parametrosGlobales, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {ParametrosGlobalesPartial} [parametrosGlobalesPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async parametrosGlobalesControllerUpdateAll(where?: { [key: string]: object; }, parametrosGlobalesPartial?: ParametrosGlobalesPartial, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoopbackCount>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.parametrosGlobalesControllerUpdateAll(where, parametrosGlobalesPartial, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {ParametrosGlobalesPartial} [parametrosGlobalesPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async parametrosGlobalesControllerUpdateById(id: number, parametrosGlobalesPartial?: ParametrosGlobalesPartial, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.parametrosGlobalesControllerUpdateById(id, parametrosGlobalesPartial, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ParametrosGlobalesControllerApi - factory interface
 * @export
 */
export const ParametrosGlobalesControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ParametrosGlobalesControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        parametrosGlobalesControllerCount(where?: { [key: string]: object; }, options?: any): AxiosPromise<LoopbackCount> {
            return localVarFp.parametrosGlobalesControllerCount(where, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {NewParametrosGlobales} [newParametrosGlobales] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        parametrosGlobalesControllerCreate(newParametrosGlobales?: NewParametrosGlobales, options?: any): AxiosPromise<ParametrosGlobales> {
            return localVarFp.parametrosGlobalesControllerCreate(newParametrosGlobales, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        parametrosGlobalesControllerDeleteById(id: number, options?: any): AxiosPromise<any> {
            return localVarFp.parametrosGlobalesControllerDeleteById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ParametrosGlobalesFilter1} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        parametrosGlobalesControllerFind(filter?: ParametrosGlobalesFilter1, options?: any): AxiosPromise<Array<ParametrosGlobalesWithRelations>> {
            return localVarFp.parametrosGlobalesControllerFind(filter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {ParametrosGlobalesFilter} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        parametrosGlobalesControllerFindById(id: number, filter?: ParametrosGlobalesFilter, options?: any): AxiosPromise<ParametrosGlobalesWithRelations> {
            return localVarFp.parametrosGlobalesControllerFindById(id, filter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {ParametrosGlobales} [parametrosGlobales] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        parametrosGlobalesControllerReplaceById(id: number, parametrosGlobales?: ParametrosGlobales, options?: any): AxiosPromise<any> {
            return localVarFp.parametrosGlobalesControllerReplaceById(id, parametrosGlobales, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {ParametrosGlobalesPartial} [parametrosGlobalesPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        parametrosGlobalesControllerUpdateAll(where?: { [key: string]: object; }, parametrosGlobalesPartial?: ParametrosGlobalesPartial, options?: any): AxiosPromise<LoopbackCount> {
            return localVarFp.parametrosGlobalesControllerUpdateAll(where, parametrosGlobalesPartial, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {ParametrosGlobalesPartial} [parametrosGlobalesPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        parametrosGlobalesControllerUpdateById(id: number, parametrosGlobalesPartial?: ParametrosGlobalesPartial, options?: any): AxiosPromise<any> {
            return localVarFp.parametrosGlobalesControllerUpdateById(id, parametrosGlobalesPartial, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ParametrosGlobalesControllerApi - object-oriented interface
 * @export
 * @class ParametrosGlobalesControllerApi
 * @extends {BaseAPI}
 */
export class ParametrosGlobalesControllerApi extends BaseAPI {
    /**
     * 
     * @param {{ [key: string]: object; }} [where] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ParametrosGlobalesControllerApi
     */
    public parametrosGlobalesControllerCount(where?: { [key: string]: object; }, options?: AxiosRequestConfig) {
        return ParametrosGlobalesControllerApiFp(this.configuration).parametrosGlobalesControllerCount(where, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {NewParametrosGlobales} [newParametrosGlobales] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ParametrosGlobalesControllerApi
     */
    public parametrosGlobalesControllerCreate(newParametrosGlobales?: NewParametrosGlobales, options?: AxiosRequestConfig) {
        return ParametrosGlobalesControllerApiFp(this.configuration).parametrosGlobalesControllerCreate(newParametrosGlobales, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ParametrosGlobalesControllerApi
     */
    public parametrosGlobalesControllerDeleteById(id: number, options?: AxiosRequestConfig) {
        return ParametrosGlobalesControllerApiFp(this.configuration).parametrosGlobalesControllerDeleteById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ParametrosGlobalesFilter1} [filter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ParametrosGlobalesControllerApi
     */
    public parametrosGlobalesControllerFind(filter?: ParametrosGlobalesFilter1, options?: AxiosRequestConfig) {
        return ParametrosGlobalesControllerApiFp(this.configuration).parametrosGlobalesControllerFind(filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {ParametrosGlobalesFilter} [filter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ParametrosGlobalesControllerApi
     */
    public parametrosGlobalesControllerFindById(id: number, filter?: ParametrosGlobalesFilter, options?: AxiosRequestConfig) {
        return ParametrosGlobalesControllerApiFp(this.configuration).parametrosGlobalesControllerFindById(id, filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {ParametrosGlobales} [parametrosGlobales] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ParametrosGlobalesControllerApi
     */
    public parametrosGlobalesControllerReplaceById(id: number, parametrosGlobales?: ParametrosGlobales, options?: AxiosRequestConfig) {
        return ParametrosGlobalesControllerApiFp(this.configuration).parametrosGlobalesControllerReplaceById(id, parametrosGlobales, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} [where] 
     * @param {ParametrosGlobalesPartial} [parametrosGlobalesPartial] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ParametrosGlobalesControllerApi
     */
    public parametrosGlobalesControllerUpdateAll(where?: { [key: string]: object; }, parametrosGlobalesPartial?: ParametrosGlobalesPartial, options?: AxiosRequestConfig) {
        return ParametrosGlobalesControllerApiFp(this.configuration).parametrosGlobalesControllerUpdateAll(where, parametrosGlobalesPartial, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {ParametrosGlobalesPartial} [parametrosGlobalesPartial] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ParametrosGlobalesControllerApi
     */
    public parametrosGlobalesControllerUpdateById(id: number, parametrosGlobalesPartial?: ParametrosGlobalesPartial, options?: AxiosRequestConfig) {
        return ParametrosGlobalesControllerApiFp(this.configuration).parametrosGlobalesControllerUpdateById(id, parametrosGlobalesPartial, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PermisosControllerApi - axios parameter creator
 * @export
 */
export const PermisosControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        permisosControllerCount: async (where?: { [key: string]: object; }, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/permisos/count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (where !== undefined) {
                localVarQueryParameter['where'] = where;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {NewPermisos} [newPermisos] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        permisosControllerCreate: async (newPermisos?: NewPermisos, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/permisos`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(newPermisos, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        permisosControllerDeleteById: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('permisosControllerDeleteById', 'id', id)
            const localVarPath = `/permisos/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PermisosFilter} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        permisosControllerFind: async (filter?: PermisosFilter, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/permisos`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {PermisosFilter1} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        permisosControllerFindById: async (id: number, filter?: PermisosFilter1, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('permisosControllerFindById', 'id', id)
            const localVarPath = `/permisos/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PermisosFilter} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        permisosControllerGetPermissionsMatrix: async (filter?: PermisosFilter, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/permisos/matrix`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {Permisos} [permisos] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        permisosControllerReplaceById: async (id: number, permisos?: Permisos, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('permisosControllerReplaceById', 'id', id)
            const localVarPath = `/permisos/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(permisos, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {PermisosPartial} [permisosPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        permisosControllerUpdateAll: async (where?: { [key: string]: object; }, permisosPartial?: PermisosPartial, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/permisos`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (where !== undefined) {
                localVarQueryParameter['where'] = where;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(permisosPartial, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {PermisosPartial} [permisosPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        permisosControllerUpdateById: async (id: number, permisosPartial?: PermisosPartial, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('permisosControllerUpdateById', 'id', id)
            const localVarPath = `/permisos/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(permisosPartial, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PermisosControllerApi - functional programming interface
 * @export
 */
export const PermisosControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PermisosControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async permisosControllerCount(where?: { [key: string]: object; }, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoopbackCount>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.permisosControllerCount(where, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {NewPermisos} [newPermisos] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async permisosControllerCreate(newPermisos?: NewPermisos, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Permisos>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.permisosControllerCreate(newPermisos, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async permisosControllerDeleteById(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.permisosControllerDeleteById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {PermisosFilter} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async permisosControllerFind(filter?: PermisosFilter, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PermisosWithRelations>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.permisosControllerFind(filter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {PermisosFilter1} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async permisosControllerFindById(id: number, filter?: PermisosFilter1, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PermisosWithRelations>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.permisosControllerFindById(id, filter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {PermisosFilter} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async permisosControllerGetPermissionsMatrix(filter?: PermisosFilter, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RolesWithRelations>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.permisosControllerGetPermissionsMatrix(filter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {Permisos} [permisos] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async permisosControllerReplaceById(id: number, permisos?: Permisos, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.permisosControllerReplaceById(id, permisos, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {PermisosPartial} [permisosPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async permisosControllerUpdateAll(where?: { [key: string]: object; }, permisosPartial?: PermisosPartial, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoopbackCount>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.permisosControllerUpdateAll(where, permisosPartial, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {PermisosPartial} [permisosPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async permisosControllerUpdateById(id: number, permisosPartial?: PermisosPartial, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.permisosControllerUpdateById(id, permisosPartial, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PermisosControllerApi - factory interface
 * @export
 */
export const PermisosControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PermisosControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        permisosControllerCount(where?: { [key: string]: object; }, options?: any): AxiosPromise<LoopbackCount> {
            return localVarFp.permisosControllerCount(where, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {NewPermisos} [newPermisos] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        permisosControllerCreate(newPermisos?: NewPermisos, options?: any): AxiosPromise<Permisos> {
            return localVarFp.permisosControllerCreate(newPermisos, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        permisosControllerDeleteById(id: number, options?: any): AxiosPromise<any> {
            return localVarFp.permisosControllerDeleteById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PermisosFilter} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        permisosControllerFind(filter?: PermisosFilter, options?: any): AxiosPromise<Array<PermisosWithRelations>> {
            return localVarFp.permisosControllerFind(filter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {PermisosFilter1} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        permisosControllerFindById(id: number, filter?: PermisosFilter1, options?: any): AxiosPromise<PermisosWithRelations> {
            return localVarFp.permisosControllerFindById(id, filter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PermisosFilter} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        permisosControllerGetPermissionsMatrix(filter?: PermisosFilter, options?: any): AxiosPromise<Array<RolesWithRelations>> {
            return localVarFp.permisosControllerGetPermissionsMatrix(filter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {Permisos} [permisos] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        permisosControllerReplaceById(id: number, permisos?: Permisos, options?: any): AxiosPromise<any> {
            return localVarFp.permisosControllerReplaceById(id, permisos, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {PermisosPartial} [permisosPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        permisosControllerUpdateAll(where?: { [key: string]: object; }, permisosPartial?: PermisosPartial, options?: any): AxiosPromise<LoopbackCount> {
            return localVarFp.permisosControllerUpdateAll(where, permisosPartial, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {PermisosPartial} [permisosPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        permisosControllerUpdateById(id: number, permisosPartial?: PermisosPartial, options?: any): AxiosPromise<any> {
            return localVarFp.permisosControllerUpdateById(id, permisosPartial, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PermisosControllerApi - object-oriented interface
 * @export
 * @class PermisosControllerApi
 * @extends {BaseAPI}
 */
export class PermisosControllerApi extends BaseAPI {
    /**
     * 
     * @param {{ [key: string]: object; }} [where] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PermisosControllerApi
     */
    public permisosControllerCount(where?: { [key: string]: object; }, options?: AxiosRequestConfig) {
        return PermisosControllerApiFp(this.configuration).permisosControllerCount(where, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {NewPermisos} [newPermisos] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PermisosControllerApi
     */
    public permisosControllerCreate(newPermisos?: NewPermisos, options?: AxiosRequestConfig) {
        return PermisosControllerApiFp(this.configuration).permisosControllerCreate(newPermisos, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PermisosControllerApi
     */
    public permisosControllerDeleteById(id: number, options?: AxiosRequestConfig) {
        return PermisosControllerApiFp(this.configuration).permisosControllerDeleteById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PermisosFilter} [filter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PermisosControllerApi
     */
    public permisosControllerFind(filter?: PermisosFilter, options?: AxiosRequestConfig) {
        return PermisosControllerApiFp(this.configuration).permisosControllerFind(filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {PermisosFilter1} [filter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PermisosControllerApi
     */
    public permisosControllerFindById(id: number, filter?: PermisosFilter1, options?: AxiosRequestConfig) {
        return PermisosControllerApiFp(this.configuration).permisosControllerFindById(id, filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PermisosFilter} [filter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PermisosControllerApi
     */
    public permisosControllerGetPermissionsMatrix(filter?: PermisosFilter, options?: AxiosRequestConfig) {
        return PermisosControllerApiFp(this.configuration).permisosControllerGetPermissionsMatrix(filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {Permisos} [permisos] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PermisosControllerApi
     */
    public permisosControllerReplaceById(id: number, permisos?: Permisos, options?: AxiosRequestConfig) {
        return PermisosControllerApiFp(this.configuration).permisosControllerReplaceById(id, permisos, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} [where] 
     * @param {PermisosPartial} [permisosPartial] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PermisosControllerApi
     */
    public permisosControllerUpdateAll(where?: { [key: string]: object; }, permisosPartial?: PermisosPartial, options?: AxiosRequestConfig) {
        return PermisosControllerApiFp(this.configuration).permisosControllerUpdateAll(where, permisosPartial, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {PermisosPartial} [permisosPartial] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PermisosControllerApi
     */
    public permisosControllerUpdateById(id: number, permisosPartial?: PermisosPartial, options?: AxiosRequestConfig) {
        return PermisosControllerApiFp(this.configuration).permisosControllerUpdateById(id, permisosPartial, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PingControllerApi - axios parameter creator
 * @export
 */
export const PingControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pingControllerPing: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/ping`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PingControllerApi - functional programming interface
 * @export
 */
export const PingControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PingControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pingControllerPing(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PingResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pingControllerPing(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PingControllerApi - factory interface
 * @export
 */
export const PingControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PingControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pingControllerPing(options?: any): AxiosPromise<PingResponse> {
            return localVarFp.pingControllerPing(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PingControllerApi - object-oriented interface
 * @export
 * @class PingControllerApi
 * @extends {BaseAPI}
 */
export class PingControllerApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PingControllerApi
     */
    public pingControllerPing(options?: AxiosRequestConfig) {
        return PingControllerApiFp(this.configuration).pingControllerPing(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PlantillaControllerApi - axios parameter creator
 * @export
 */
export const PlantillaControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        plantillaControllerCount: async (where?: { [key: string]: object; }, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Plantilla/count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (where !== undefined) {
                localVarQueryParameter['where'] = where;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {NewPlantilla} [newPlantilla] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        plantillaControllerCreate: async (newPlantilla?: NewPlantilla, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Plantilla`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(newPlantilla, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        plantillaControllerDeleteById: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('plantillaControllerDeleteById', 'id', id)
            const localVarPath = `/Plantilla/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PlantillaFilter1} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        plantillaControllerFind: async (filter?: PlantillaFilter1, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Plantilla`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {PlantillaFilter} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        plantillaControllerFindById: async (id: number, filter?: PlantillaFilter, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('plantillaControllerFindById', 'id', id)
            const localVarPath = `/Plantilla/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {Plantilla} [plantilla] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        plantillaControllerReplaceById: async (id: number, plantilla?: Plantilla, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('plantillaControllerReplaceById', 'id', id)
            const localVarPath = `/Plantilla/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(plantilla, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {PlantillaPartial} [plantillaPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        plantillaControllerUpdateAll: async (where?: { [key: string]: object; }, plantillaPartial?: PlantillaPartial, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Plantilla`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (where !== undefined) {
                localVarQueryParameter['where'] = where;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(plantillaPartial, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {PlantillaPartial} [plantillaPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        plantillaControllerUpdateById: async (id: number, plantillaPartial?: PlantillaPartial, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('plantillaControllerUpdateById', 'id', id)
            const localVarPath = `/Plantilla/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(plantillaPartial, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PlantillaControllerApi - functional programming interface
 * @export
 */
export const PlantillaControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PlantillaControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async plantillaControllerCount(where?: { [key: string]: object; }, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoopbackCount>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.plantillaControllerCount(where, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {NewPlantilla} [newPlantilla] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async plantillaControllerCreate(newPlantilla?: NewPlantilla, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Plantilla>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.plantillaControllerCreate(newPlantilla, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async plantillaControllerDeleteById(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.plantillaControllerDeleteById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {PlantillaFilter1} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async plantillaControllerFind(filter?: PlantillaFilter1, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PlantillaWithRelations>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.plantillaControllerFind(filter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {PlantillaFilter} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async plantillaControllerFindById(id: number, filter?: PlantillaFilter, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PlantillaWithRelations>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.plantillaControllerFindById(id, filter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {Plantilla} [plantilla] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async plantillaControllerReplaceById(id: number, plantilla?: Plantilla, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.plantillaControllerReplaceById(id, plantilla, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {PlantillaPartial} [plantillaPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async plantillaControllerUpdateAll(where?: { [key: string]: object; }, plantillaPartial?: PlantillaPartial, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoopbackCount>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.plantillaControllerUpdateAll(where, plantillaPartial, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {PlantillaPartial} [plantillaPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async plantillaControllerUpdateById(id: number, plantillaPartial?: PlantillaPartial, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.plantillaControllerUpdateById(id, plantillaPartial, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PlantillaControllerApi - factory interface
 * @export
 */
export const PlantillaControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PlantillaControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        plantillaControllerCount(where?: { [key: string]: object; }, options?: any): AxiosPromise<LoopbackCount> {
            return localVarFp.plantillaControllerCount(where, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {NewPlantilla} [newPlantilla] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        plantillaControllerCreate(newPlantilla?: NewPlantilla, options?: any): AxiosPromise<Plantilla> {
            return localVarFp.plantillaControllerCreate(newPlantilla, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        plantillaControllerDeleteById(id: number, options?: any): AxiosPromise<any> {
            return localVarFp.plantillaControllerDeleteById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PlantillaFilter1} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        plantillaControllerFind(filter?: PlantillaFilter1, options?: any): AxiosPromise<Array<PlantillaWithRelations>> {
            return localVarFp.plantillaControllerFind(filter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {PlantillaFilter} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        plantillaControllerFindById(id: number, filter?: PlantillaFilter, options?: any): AxiosPromise<PlantillaWithRelations> {
            return localVarFp.plantillaControllerFindById(id, filter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {Plantilla} [plantilla] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        plantillaControllerReplaceById(id: number, plantilla?: Plantilla, options?: any): AxiosPromise<any> {
            return localVarFp.plantillaControllerReplaceById(id, plantilla, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {PlantillaPartial} [plantillaPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        plantillaControllerUpdateAll(where?: { [key: string]: object; }, plantillaPartial?: PlantillaPartial, options?: any): AxiosPromise<LoopbackCount> {
            return localVarFp.plantillaControllerUpdateAll(where, plantillaPartial, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {PlantillaPartial} [plantillaPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        plantillaControllerUpdateById(id: number, plantillaPartial?: PlantillaPartial, options?: any): AxiosPromise<any> {
            return localVarFp.plantillaControllerUpdateById(id, plantillaPartial, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PlantillaControllerApi - object-oriented interface
 * @export
 * @class PlantillaControllerApi
 * @extends {BaseAPI}
 */
export class PlantillaControllerApi extends BaseAPI {
    /**
     * 
     * @param {{ [key: string]: object; }} [where] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlantillaControllerApi
     */
    public plantillaControllerCount(where?: { [key: string]: object; }, options?: AxiosRequestConfig) {
        return PlantillaControllerApiFp(this.configuration).plantillaControllerCount(where, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {NewPlantilla} [newPlantilla] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlantillaControllerApi
     */
    public plantillaControllerCreate(newPlantilla?: NewPlantilla, options?: AxiosRequestConfig) {
        return PlantillaControllerApiFp(this.configuration).plantillaControllerCreate(newPlantilla, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlantillaControllerApi
     */
    public plantillaControllerDeleteById(id: number, options?: AxiosRequestConfig) {
        return PlantillaControllerApiFp(this.configuration).plantillaControllerDeleteById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PlantillaFilter1} [filter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlantillaControllerApi
     */
    public plantillaControllerFind(filter?: PlantillaFilter1, options?: AxiosRequestConfig) {
        return PlantillaControllerApiFp(this.configuration).plantillaControllerFind(filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {PlantillaFilter} [filter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlantillaControllerApi
     */
    public plantillaControllerFindById(id: number, filter?: PlantillaFilter, options?: AxiosRequestConfig) {
        return PlantillaControllerApiFp(this.configuration).plantillaControllerFindById(id, filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {Plantilla} [plantilla] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlantillaControllerApi
     */
    public plantillaControllerReplaceById(id: number, plantilla?: Plantilla, options?: AxiosRequestConfig) {
        return PlantillaControllerApiFp(this.configuration).plantillaControllerReplaceById(id, plantilla, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} [where] 
     * @param {PlantillaPartial} [plantillaPartial] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlantillaControllerApi
     */
    public plantillaControllerUpdateAll(where?: { [key: string]: object; }, plantillaPartial?: PlantillaPartial, options?: AxiosRequestConfig) {
        return PlantillaControllerApiFp(this.configuration).plantillaControllerUpdateAll(where, plantillaPartial, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {PlantillaPartial} [plantillaPartial] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlantillaControllerApi
     */
    public plantillaControllerUpdateById(id: number, plantillaPartial?: PlantillaPartial, options?: AxiosRequestConfig) {
        return PlantillaControllerApiFp(this.configuration).plantillaControllerUpdateById(id, plantillaPartial, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ProductoAtributosControllerApi - axios parameter creator
 * @export
 */
export const ProductoAtributosControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} categoriaNombre 
         * @param {number} atributoId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productoAtributosControllerCompruebaAtributosEnProductosPorCategoria: async (categoriaNombre: string, atributoId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'categoriaNombre' is not null or undefined
            assertParamExists('productoAtributosControllerCompruebaAtributosEnProductosPorCategoria', 'categoriaNombre', categoriaNombre)
            // verify required parameter 'atributoId' is not null or undefined
            assertParamExists('productoAtributosControllerCompruebaAtributosEnProductosPorCategoria', 'atributoId', atributoId)
            const localVarPath = `/producto-atributos/comprueba-atributos-en-productos-por-categoria/{categoriaNombre}/{atributoId}`
                .replace(`{${"categoriaNombre"}}`, encodeURIComponent(String(categoriaNombre)))
                .replace(`{${"atributoId"}}`, encodeURIComponent(String(atributoId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productoAtributosControllerCount: async (where?: { [key: string]: object; }, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/producto-atributos/count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (where !== undefined) {
                localVarQueryParameter['where'] = where;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {NewProductoAtributos} [newProductoAtributos] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productoAtributosControllerCreate: async (newProductoAtributos?: NewProductoAtributos, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/producto-atributos`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(newProductoAtributos, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productoAtributosControllerDeleteById: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('productoAtributosControllerDeleteById', 'id', id)
            const localVarPath = `/producto-atributos/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} categoriaNombre 
         * @param {number} atributoId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productoAtributosControllerEliminaAtributosEnProductosPorCategoria: async (categoriaNombre: string, atributoId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'categoriaNombre' is not null or undefined
            assertParamExists('productoAtributosControllerEliminaAtributosEnProductosPorCategoria', 'categoriaNombre', categoriaNombre)
            // verify required parameter 'atributoId' is not null or undefined
            assertParamExists('productoAtributosControllerEliminaAtributosEnProductosPorCategoria', 'atributoId', atributoId)
            const localVarPath = `/producto-atributos/elimina-atributos-en-productos-por-categoria/{categoriaNombre}/{atributoId}`
                .replace(`{${"categoriaNombre"}}`, encodeURIComponent(String(categoriaNombre)))
                .replace(`{${"atributoId"}}`, encodeURIComponent(String(atributoId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ProductoAtributosFilter1} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productoAtributosControllerFind: async (filter?: ProductoAtributosFilter1, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/producto-atributos`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {ProductoAtributosFilter} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productoAtributosControllerFindById: async (id: number, filter?: ProductoAtributosFilter, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('productoAtributosControllerFindById', 'id', id)
            const localVarPath = `/producto-atributos/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} productoId 
         * @param {ProductoAtributosFilter} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productoAtributosControllerFindByProductoId: async (productoId: number, filter?: ProductoAtributosFilter, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'productoId' is not null or undefined
            assertParamExists('productoAtributosControllerFindByProductoId', 'productoId', productoId)
            const localVarPath = `/producto-atributos/{producto_id}`
                .replace(`{${"producto_id"}}`, encodeURIComponent(String(productoId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productoAtributosControllerQueryFullAtrributesProducts: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('productoAtributosControllerQueryFullAtrributesProducts', 'id', id)
            const localVarPath = `/producto-atributos/por-atributo/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productoAtributosControllerQueryFullProducts: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('productoAtributosControllerQueryFullProducts', 'id', id)
            const localVarPath = `/producto-atributos/por-producto/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {ProductoAtributos} [productoAtributos] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productoAtributosControllerReplaceById: async (id: number, productoAtributos?: ProductoAtributos, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('productoAtributosControllerReplaceById', 'id', id)
            const localVarPath = `/producto-atributos/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(productoAtributos, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {ProductoAtributosPartial} [productoAtributosPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productoAtributosControllerUpdateAll: async (where?: { [key: string]: object; }, productoAtributosPartial?: ProductoAtributosPartial, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/producto-atributos`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (where !== undefined) {
                localVarQueryParameter['where'] = where;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(productoAtributosPartial, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {ProductoAtributosPartial} [productoAtributosPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productoAtributosControllerUpdateById: async (id: number, productoAtributosPartial?: ProductoAtributosPartial, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('productoAtributosControllerUpdateById', 'id', id)
            const localVarPath = `/producto-atributos/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(productoAtributosPartial, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProductoAtributosControllerApi - functional programming interface
 * @export
 */
export const ProductoAtributosControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProductoAtributosControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} categoriaNombre 
         * @param {number} atributoId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productoAtributosControllerCompruebaAtributosEnProductosPorCategoria(categoriaNombre: string, atributoId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductoAtributos>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productoAtributosControllerCompruebaAtributosEnProductosPorCategoria(categoriaNombre, atributoId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productoAtributosControllerCount(where?: { [key: string]: object; }, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoopbackCount>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productoAtributosControllerCount(where, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {NewProductoAtributos} [newProductoAtributos] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productoAtributosControllerCreate(newProductoAtributos?: NewProductoAtributos, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductoAtributos>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productoAtributosControllerCreate(newProductoAtributos, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productoAtributosControllerDeleteById(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productoAtributosControllerDeleteById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} categoriaNombre 
         * @param {number} atributoId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productoAtributosControllerEliminaAtributosEnProductosPorCategoria(categoriaNombre: string, atributoId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductoAtributos>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productoAtributosControllerEliminaAtributosEnProductosPorCategoria(categoriaNombre, atributoId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ProductoAtributosFilter1} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productoAtributosControllerFind(filter?: ProductoAtributosFilter1, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ProductoAtributosWithRelations>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productoAtributosControllerFind(filter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {ProductoAtributosFilter} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productoAtributosControllerFindById(id: number, filter?: ProductoAtributosFilter, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductoAtributosWithRelations>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productoAtributosControllerFindById(id, filter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} productoId 
         * @param {ProductoAtributosFilter} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productoAtributosControllerFindByProductoId(productoId: number, filter?: ProductoAtributosFilter, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductoAtributosWithRelations>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productoAtributosControllerFindByProductoId(productoId, filter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productoAtributosControllerQueryFullAtrributesProducts(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductoAtributos>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productoAtributosControllerQueryFullAtrributesProducts(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productoAtributosControllerQueryFullProducts(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductoAtributos>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productoAtributosControllerQueryFullProducts(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {ProductoAtributos} [productoAtributos] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productoAtributosControllerReplaceById(id: number, productoAtributos?: ProductoAtributos, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productoAtributosControllerReplaceById(id, productoAtributos, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {ProductoAtributosPartial} [productoAtributosPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productoAtributosControllerUpdateAll(where?: { [key: string]: object; }, productoAtributosPartial?: ProductoAtributosPartial, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoopbackCount>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productoAtributosControllerUpdateAll(where, productoAtributosPartial, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {ProductoAtributosPartial} [productoAtributosPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productoAtributosControllerUpdateById(id: number, productoAtributosPartial?: ProductoAtributosPartial, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productoAtributosControllerUpdateById(id, productoAtributosPartial, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ProductoAtributosControllerApi - factory interface
 * @export
 */
export const ProductoAtributosControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProductoAtributosControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {string} categoriaNombre 
         * @param {number} atributoId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productoAtributosControllerCompruebaAtributosEnProductosPorCategoria(categoriaNombre: string, atributoId: number, options?: any): AxiosPromise<ProductoAtributos> {
            return localVarFp.productoAtributosControllerCompruebaAtributosEnProductosPorCategoria(categoriaNombre, atributoId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productoAtributosControllerCount(where?: { [key: string]: object; }, options?: any): AxiosPromise<LoopbackCount> {
            return localVarFp.productoAtributosControllerCount(where, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {NewProductoAtributos} [newProductoAtributos] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productoAtributosControllerCreate(newProductoAtributos?: NewProductoAtributos, options?: any): AxiosPromise<ProductoAtributos> {
            return localVarFp.productoAtributosControllerCreate(newProductoAtributos, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productoAtributosControllerDeleteById(id: number, options?: any): AxiosPromise<any> {
            return localVarFp.productoAtributosControllerDeleteById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} categoriaNombre 
         * @param {number} atributoId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productoAtributosControllerEliminaAtributosEnProductosPorCategoria(categoriaNombre: string, atributoId: number, options?: any): AxiosPromise<ProductoAtributos> {
            return localVarFp.productoAtributosControllerEliminaAtributosEnProductosPorCategoria(categoriaNombre, atributoId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ProductoAtributosFilter1} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productoAtributosControllerFind(filter?: ProductoAtributosFilter1, options?: any): AxiosPromise<Array<ProductoAtributosWithRelations>> {
            return localVarFp.productoAtributosControllerFind(filter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {ProductoAtributosFilter} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productoAtributosControllerFindById(id: number, filter?: ProductoAtributosFilter, options?: any): AxiosPromise<ProductoAtributosWithRelations> {
            return localVarFp.productoAtributosControllerFindById(id, filter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} productoId 
         * @param {ProductoAtributosFilter} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productoAtributosControllerFindByProductoId(productoId: number, filter?: ProductoAtributosFilter, options?: any): AxiosPromise<ProductoAtributosWithRelations> {
            return localVarFp.productoAtributosControllerFindByProductoId(productoId, filter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productoAtributosControllerQueryFullAtrributesProducts(id: number, options?: any): AxiosPromise<ProductoAtributos> {
            return localVarFp.productoAtributosControllerQueryFullAtrributesProducts(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productoAtributosControllerQueryFullProducts(id: number, options?: any): AxiosPromise<ProductoAtributos> {
            return localVarFp.productoAtributosControllerQueryFullProducts(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {ProductoAtributos} [productoAtributos] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productoAtributosControllerReplaceById(id: number, productoAtributos?: ProductoAtributos, options?: any): AxiosPromise<any> {
            return localVarFp.productoAtributosControllerReplaceById(id, productoAtributos, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {ProductoAtributosPartial} [productoAtributosPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productoAtributosControllerUpdateAll(where?: { [key: string]: object; }, productoAtributosPartial?: ProductoAtributosPartial, options?: any): AxiosPromise<LoopbackCount> {
            return localVarFp.productoAtributosControllerUpdateAll(where, productoAtributosPartial, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {ProductoAtributosPartial} [productoAtributosPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productoAtributosControllerUpdateById(id: number, productoAtributosPartial?: ProductoAtributosPartial, options?: any): AxiosPromise<any> {
            return localVarFp.productoAtributosControllerUpdateById(id, productoAtributosPartial, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProductoAtributosControllerApi - object-oriented interface
 * @export
 * @class ProductoAtributosControllerApi
 * @extends {BaseAPI}
 */
export class ProductoAtributosControllerApi extends BaseAPI {
    /**
     * 
     * @param {string} categoriaNombre 
     * @param {number} atributoId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductoAtributosControllerApi
     */
    public productoAtributosControllerCompruebaAtributosEnProductosPorCategoria(categoriaNombre: string, atributoId: number, options?: AxiosRequestConfig) {
        return ProductoAtributosControllerApiFp(this.configuration).productoAtributosControllerCompruebaAtributosEnProductosPorCategoria(categoriaNombre, atributoId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} [where] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductoAtributosControllerApi
     */
    public productoAtributosControllerCount(where?: { [key: string]: object; }, options?: AxiosRequestConfig) {
        return ProductoAtributosControllerApiFp(this.configuration).productoAtributosControllerCount(where, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {NewProductoAtributos} [newProductoAtributos] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductoAtributosControllerApi
     */
    public productoAtributosControllerCreate(newProductoAtributos?: NewProductoAtributos, options?: AxiosRequestConfig) {
        return ProductoAtributosControllerApiFp(this.configuration).productoAtributosControllerCreate(newProductoAtributos, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductoAtributosControllerApi
     */
    public productoAtributosControllerDeleteById(id: number, options?: AxiosRequestConfig) {
        return ProductoAtributosControllerApiFp(this.configuration).productoAtributosControllerDeleteById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} categoriaNombre 
     * @param {number} atributoId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductoAtributosControllerApi
     */
    public productoAtributosControllerEliminaAtributosEnProductosPorCategoria(categoriaNombre: string, atributoId: number, options?: AxiosRequestConfig) {
        return ProductoAtributosControllerApiFp(this.configuration).productoAtributosControllerEliminaAtributosEnProductosPorCategoria(categoriaNombre, atributoId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ProductoAtributosFilter1} [filter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductoAtributosControllerApi
     */
    public productoAtributosControllerFind(filter?: ProductoAtributosFilter1, options?: AxiosRequestConfig) {
        return ProductoAtributosControllerApiFp(this.configuration).productoAtributosControllerFind(filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {ProductoAtributosFilter} [filter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductoAtributosControllerApi
     */
    public productoAtributosControllerFindById(id: number, filter?: ProductoAtributosFilter, options?: AxiosRequestConfig) {
        return ProductoAtributosControllerApiFp(this.configuration).productoAtributosControllerFindById(id, filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} productoId 
     * @param {ProductoAtributosFilter} [filter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductoAtributosControllerApi
     */
    public productoAtributosControllerFindByProductoId(productoId: number, filter?: ProductoAtributosFilter, options?: AxiosRequestConfig) {
        return ProductoAtributosControllerApiFp(this.configuration).productoAtributosControllerFindByProductoId(productoId, filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductoAtributosControllerApi
     */
    public productoAtributosControllerQueryFullAtrributesProducts(id: number, options?: AxiosRequestConfig) {
        return ProductoAtributosControllerApiFp(this.configuration).productoAtributosControllerQueryFullAtrributesProducts(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductoAtributosControllerApi
     */
    public productoAtributosControllerQueryFullProducts(id: number, options?: AxiosRequestConfig) {
        return ProductoAtributosControllerApiFp(this.configuration).productoAtributosControllerQueryFullProducts(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {ProductoAtributos} [productoAtributos] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductoAtributosControllerApi
     */
    public productoAtributosControllerReplaceById(id: number, productoAtributos?: ProductoAtributos, options?: AxiosRequestConfig) {
        return ProductoAtributosControllerApiFp(this.configuration).productoAtributosControllerReplaceById(id, productoAtributos, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} [where] 
     * @param {ProductoAtributosPartial} [productoAtributosPartial] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductoAtributosControllerApi
     */
    public productoAtributosControllerUpdateAll(where?: { [key: string]: object; }, productoAtributosPartial?: ProductoAtributosPartial, options?: AxiosRequestConfig) {
        return ProductoAtributosControllerApiFp(this.configuration).productoAtributosControllerUpdateAll(where, productoAtributosPartial, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {ProductoAtributosPartial} [productoAtributosPartial] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductoAtributosControllerApi
     */
    public productoAtributosControllerUpdateById(id: number, productoAtributosPartial?: ProductoAtributosPartial, options?: AxiosRequestConfig) {
        return ProductoAtributosControllerApiFp(this.configuration).productoAtributosControllerUpdateById(id, productoAtributosPartial, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ProductoCaracteristicasControllerApi - axios parameter creator
 * @export
 */
export const ProductoCaracteristicasControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productoCaracteristicasControllerCount: async (where?: { [key: string]: object; }, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/producto-caracteristicas/count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (where !== undefined) {
                localVarQueryParameter['where'] = where;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {NewProductoCaracteristicas} [newProductoCaracteristicas] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productoCaracteristicasControllerCreate: async (newProductoCaracteristicas?: NewProductoCaracteristicas, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/producto-caracteristicas`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(newProductoCaracteristicas, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productoCaracteristicasControllerDeleteById: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('productoCaracteristicasControllerDeleteById', 'id', id)
            const localVarPath = `/producto-caracteristicas/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ProductoCaracteristicasFilter1} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productoCaracteristicasControllerFind: async (filter?: ProductoCaracteristicasFilter1, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/producto-caracteristicas`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {ProductoCaracteristicasFilter} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productoCaracteristicasControllerFindById: async (id: number, filter?: ProductoCaracteristicasFilter, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('productoCaracteristicasControllerFindById', 'id', id)
            const localVarPath = `/producto-caracteristicas/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {ProductoCaracteristicas} [productoCaracteristicas] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productoCaracteristicasControllerReplaceById: async (id: number, productoCaracteristicas?: ProductoCaracteristicas, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('productoCaracteristicasControllerReplaceById', 'id', id)
            const localVarPath = `/producto-caracteristicas/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(productoCaracteristicas, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {ProductoCaracteristicasPartial} [productoCaracteristicasPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productoCaracteristicasControllerUpdateAll: async (where?: { [key: string]: object; }, productoCaracteristicasPartial?: ProductoCaracteristicasPartial, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/producto-caracteristicas`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (where !== undefined) {
                localVarQueryParameter['where'] = where;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(productoCaracteristicasPartial, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {ProductoCaracteristicasPartial} [productoCaracteristicasPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productoCaracteristicasControllerUpdateById: async (id: number, productoCaracteristicasPartial?: ProductoCaracteristicasPartial, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('productoCaracteristicasControllerUpdateById', 'id', id)
            const localVarPath = `/producto-caracteristicas/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(productoCaracteristicasPartial, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProductoCaracteristicasControllerApi - functional programming interface
 * @export
 */
export const ProductoCaracteristicasControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProductoCaracteristicasControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productoCaracteristicasControllerCount(where?: { [key: string]: object; }, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoopbackCount>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productoCaracteristicasControllerCount(where, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {NewProductoCaracteristicas} [newProductoCaracteristicas] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productoCaracteristicasControllerCreate(newProductoCaracteristicas?: NewProductoCaracteristicas, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductoCaracteristicas>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productoCaracteristicasControllerCreate(newProductoCaracteristicas, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productoCaracteristicasControllerDeleteById(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productoCaracteristicasControllerDeleteById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ProductoCaracteristicasFilter1} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productoCaracteristicasControllerFind(filter?: ProductoCaracteristicasFilter1, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ProductoCaracteristicasWithRelations>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productoCaracteristicasControllerFind(filter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {ProductoCaracteristicasFilter} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productoCaracteristicasControllerFindById(id: number, filter?: ProductoCaracteristicasFilter, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductoCaracteristicasWithRelations>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productoCaracteristicasControllerFindById(id, filter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {ProductoCaracteristicas} [productoCaracteristicas] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productoCaracteristicasControllerReplaceById(id: number, productoCaracteristicas?: ProductoCaracteristicas, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productoCaracteristicasControllerReplaceById(id, productoCaracteristicas, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {ProductoCaracteristicasPartial} [productoCaracteristicasPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productoCaracteristicasControllerUpdateAll(where?: { [key: string]: object; }, productoCaracteristicasPartial?: ProductoCaracteristicasPartial, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoopbackCount>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productoCaracteristicasControllerUpdateAll(where, productoCaracteristicasPartial, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {ProductoCaracteristicasPartial} [productoCaracteristicasPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productoCaracteristicasControllerUpdateById(id: number, productoCaracteristicasPartial?: ProductoCaracteristicasPartial, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productoCaracteristicasControllerUpdateById(id, productoCaracteristicasPartial, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ProductoCaracteristicasControllerApi - factory interface
 * @export
 */
export const ProductoCaracteristicasControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProductoCaracteristicasControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productoCaracteristicasControllerCount(where?: { [key: string]: object; }, options?: any): AxiosPromise<LoopbackCount> {
            return localVarFp.productoCaracteristicasControllerCount(where, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {NewProductoCaracteristicas} [newProductoCaracteristicas] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productoCaracteristicasControllerCreate(newProductoCaracteristicas?: NewProductoCaracteristicas, options?: any): AxiosPromise<ProductoCaracteristicas> {
            return localVarFp.productoCaracteristicasControllerCreate(newProductoCaracteristicas, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productoCaracteristicasControllerDeleteById(id: number, options?: any): AxiosPromise<any> {
            return localVarFp.productoCaracteristicasControllerDeleteById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ProductoCaracteristicasFilter1} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productoCaracteristicasControllerFind(filter?: ProductoCaracteristicasFilter1, options?: any): AxiosPromise<Array<ProductoCaracteristicasWithRelations>> {
            return localVarFp.productoCaracteristicasControllerFind(filter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {ProductoCaracteristicasFilter} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productoCaracteristicasControllerFindById(id: number, filter?: ProductoCaracteristicasFilter, options?: any): AxiosPromise<ProductoCaracteristicasWithRelations> {
            return localVarFp.productoCaracteristicasControllerFindById(id, filter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {ProductoCaracteristicas} [productoCaracteristicas] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productoCaracteristicasControllerReplaceById(id: number, productoCaracteristicas?: ProductoCaracteristicas, options?: any): AxiosPromise<any> {
            return localVarFp.productoCaracteristicasControllerReplaceById(id, productoCaracteristicas, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {ProductoCaracteristicasPartial} [productoCaracteristicasPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productoCaracteristicasControllerUpdateAll(where?: { [key: string]: object; }, productoCaracteristicasPartial?: ProductoCaracteristicasPartial, options?: any): AxiosPromise<LoopbackCount> {
            return localVarFp.productoCaracteristicasControllerUpdateAll(where, productoCaracteristicasPartial, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {ProductoCaracteristicasPartial} [productoCaracteristicasPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productoCaracteristicasControllerUpdateById(id: number, productoCaracteristicasPartial?: ProductoCaracteristicasPartial, options?: any): AxiosPromise<any> {
            return localVarFp.productoCaracteristicasControllerUpdateById(id, productoCaracteristicasPartial, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProductoCaracteristicasControllerApi - object-oriented interface
 * @export
 * @class ProductoCaracteristicasControllerApi
 * @extends {BaseAPI}
 */
export class ProductoCaracteristicasControllerApi extends BaseAPI {
    /**
     * 
     * @param {{ [key: string]: object; }} [where] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductoCaracteristicasControllerApi
     */
    public productoCaracteristicasControllerCount(where?: { [key: string]: object; }, options?: AxiosRequestConfig) {
        return ProductoCaracteristicasControllerApiFp(this.configuration).productoCaracteristicasControllerCount(where, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {NewProductoCaracteristicas} [newProductoCaracteristicas] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductoCaracteristicasControllerApi
     */
    public productoCaracteristicasControllerCreate(newProductoCaracteristicas?: NewProductoCaracteristicas, options?: AxiosRequestConfig) {
        return ProductoCaracteristicasControllerApiFp(this.configuration).productoCaracteristicasControllerCreate(newProductoCaracteristicas, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductoCaracteristicasControllerApi
     */
    public productoCaracteristicasControllerDeleteById(id: number, options?: AxiosRequestConfig) {
        return ProductoCaracteristicasControllerApiFp(this.configuration).productoCaracteristicasControllerDeleteById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ProductoCaracteristicasFilter1} [filter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductoCaracteristicasControllerApi
     */
    public productoCaracteristicasControllerFind(filter?: ProductoCaracteristicasFilter1, options?: AxiosRequestConfig) {
        return ProductoCaracteristicasControllerApiFp(this.configuration).productoCaracteristicasControllerFind(filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {ProductoCaracteristicasFilter} [filter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductoCaracteristicasControllerApi
     */
    public productoCaracteristicasControllerFindById(id: number, filter?: ProductoCaracteristicasFilter, options?: AxiosRequestConfig) {
        return ProductoCaracteristicasControllerApiFp(this.configuration).productoCaracteristicasControllerFindById(id, filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {ProductoCaracteristicas} [productoCaracteristicas] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductoCaracteristicasControllerApi
     */
    public productoCaracteristicasControllerReplaceById(id: number, productoCaracteristicas?: ProductoCaracteristicas, options?: AxiosRequestConfig) {
        return ProductoCaracteristicasControllerApiFp(this.configuration).productoCaracteristicasControllerReplaceById(id, productoCaracteristicas, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} [where] 
     * @param {ProductoCaracteristicasPartial} [productoCaracteristicasPartial] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductoCaracteristicasControllerApi
     */
    public productoCaracteristicasControllerUpdateAll(where?: { [key: string]: object; }, productoCaracteristicasPartial?: ProductoCaracteristicasPartial, options?: AxiosRequestConfig) {
        return ProductoCaracteristicasControllerApiFp(this.configuration).productoCaracteristicasControllerUpdateAll(where, productoCaracteristicasPartial, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {ProductoCaracteristicasPartial} [productoCaracteristicasPartial] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductoCaracteristicasControllerApi
     */
    public productoCaracteristicasControllerUpdateById(id: number, productoCaracteristicasPartial?: ProductoCaracteristicasPartial, options?: AxiosRequestConfig) {
        return ProductoCaracteristicasControllerApiFp(this.configuration).productoCaracteristicasControllerUpdateById(id, productoCaracteristicasPartial, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ProductoDataControllerApi - axios parameter creator
 * @export
 */
export const ProductoDataControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} identifier 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productoDataControllerFind: async (identifier: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'identifier' is not null or undefined
            assertParamExists('productoDataControllerFind', 'identifier', identifier)
            const localVarPath = `/producto-data/{identifier}`
                .replace(`{${"identifier"}}`, encodeURIComponent(String(identifier)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProductoDataControllerApi - functional programming interface
 * @export
 */
export const ProductoDataControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProductoDataControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} identifier 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productoDataControllerFind(identifier: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Productos>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productoDataControllerFind(identifier, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ProductoDataControllerApi - factory interface
 * @export
 */
export const ProductoDataControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProductoDataControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {string} identifier 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productoDataControllerFind(identifier: string, options?: any): AxiosPromise<Productos> {
            return localVarFp.productoDataControllerFind(identifier, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProductoDataControllerApi - object-oriented interface
 * @export
 * @class ProductoDataControllerApi
 * @extends {BaseAPI}
 */
export class ProductoDataControllerApi extends BaseAPI {
    /**
     * 
     * @param {string} identifier 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductoDataControllerApi
     */
    public productoDataControllerFind(identifier: string, options?: AxiosRequestConfig) {
        return ProductoDataControllerApiFp(this.configuration).productoDataControllerFind(identifier, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ProductoDatosCatalogoControllerApi - axios parameter creator
 * @export
 */
export const ProductoDatosCatalogoControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productoDatosCatalogoControllerCount: async (where?: { [key: string]: object; }, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/producto-datos-catalogo/count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (where !== undefined) {
                localVarQueryParameter['where'] = where;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {NewProductoDatosCatalogo} [newProductoDatosCatalogo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productoDatosCatalogoControllerCreate: async (newProductoDatosCatalogo?: NewProductoDatosCatalogo, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/producto-datos-catalogo`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(newProductoDatosCatalogo, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productoDatosCatalogoControllerDeleteById: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('productoDatosCatalogoControllerDeleteById', 'id', id)
            const localVarPath = `/producto-datos-catalogo/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ProductoDatosCatalogoFilter1} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productoDatosCatalogoControllerFind: async (filter?: ProductoDatosCatalogoFilter1, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/producto-datos-catalogo`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {ProductoDatosCatalogoFilter} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productoDatosCatalogoControllerFindById: async (id: number, filter?: ProductoDatosCatalogoFilter, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('productoDatosCatalogoControllerFindById', 'id', id)
            const localVarPath = `/producto-datos-catalogo/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {ProductoDatosCatalogo} [productoDatosCatalogo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productoDatosCatalogoControllerReplaceById: async (id: number, productoDatosCatalogo?: ProductoDatosCatalogo, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('productoDatosCatalogoControllerReplaceById', 'id', id)
            const localVarPath = `/producto-datos-catalogo/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(productoDatosCatalogo, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {ProductoDatosCatalogoPartial} [productoDatosCatalogoPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productoDatosCatalogoControllerUpdateAll: async (where?: { [key: string]: object; }, productoDatosCatalogoPartial?: ProductoDatosCatalogoPartial, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/producto-datos-catalogo`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (where !== undefined) {
                localVarQueryParameter['where'] = where;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(productoDatosCatalogoPartial, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {ProductoDatosCatalogoPartial} [productoDatosCatalogoPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productoDatosCatalogoControllerUpdateById: async (id: number, productoDatosCatalogoPartial?: ProductoDatosCatalogoPartial, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('productoDatosCatalogoControllerUpdateById', 'id', id)
            const localVarPath = `/producto-datos-catalogo/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(productoDatosCatalogoPartial, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProductoDatosCatalogoControllerApi - functional programming interface
 * @export
 */
export const ProductoDatosCatalogoControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProductoDatosCatalogoControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productoDatosCatalogoControllerCount(where?: { [key: string]: object; }, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoopbackCount>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productoDatosCatalogoControllerCount(where, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {NewProductoDatosCatalogo} [newProductoDatosCatalogo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productoDatosCatalogoControllerCreate(newProductoDatosCatalogo?: NewProductoDatosCatalogo, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductoDatosCatalogo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productoDatosCatalogoControllerCreate(newProductoDatosCatalogo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productoDatosCatalogoControllerDeleteById(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productoDatosCatalogoControllerDeleteById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ProductoDatosCatalogoFilter1} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productoDatosCatalogoControllerFind(filter?: ProductoDatosCatalogoFilter1, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ProductoDatosCatalogoWithRelations>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productoDatosCatalogoControllerFind(filter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {ProductoDatosCatalogoFilter} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productoDatosCatalogoControllerFindById(id: number, filter?: ProductoDatosCatalogoFilter, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductoDatosCatalogoWithRelations>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productoDatosCatalogoControllerFindById(id, filter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {ProductoDatosCatalogo} [productoDatosCatalogo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productoDatosCatalogoControllerReplaceById(id: number, productoDatosCatalogo?: ProductoDatosCatalogo, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productoDatosCatalogoControllerReplaceById(id, productoDatosCatalogo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {ProductoDatosCatalogoPartial} [productoDatosCatalogoPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productoDatosCatalogoControllerUpdateAll(where?: { [key: string]: object; }, productoDatosCatalogoPartial?: ProductoDatosCatalogoPartial, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoopbackCount>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productoDatosCatalogoControllerUpdateAll(where, productoDatosCatalogoPartial, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {ProductoDatosCatalogoPartial} [productoDatosCatalogoPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productoDatosCatalogoControllerUpdateById(id: number, productoDatosCatalogoPartial?: ProductoDatosCatalogoPartial, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productoDatosCatalogoControllerUpdateById(id, productoDatosCatalogoPartial, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ProductoDatosCatalogoControllerApi - factory interface
 * @export
 */
export const ProductoDatosCatalogoControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProductoDatosCatalogoControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productoDatosCatalogoControllerCount(where?: { [key: string]: object; }, options?: any): AxiosPromise<LoopbackCount> {
            return localVarFp.productoDatosCatalogoControllerCount(where, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {NewProductoDatosCatalogo} [newProductoDatosCatalogo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productoDatosCatalogoControllerCreate(newProductoDatosCatalogo?: NewProductoDatosCatalogo, options?: any): AxiosPromise<ProductoDatosCatalogo> {
            return localVarFp.productoDatosCatalogoControllerCreate(newProductoDatosCatalogo, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productoDatosCatalogoControllerDeleteById(id: number, options?: any): AxiosPromise<any> {
            return localVarFp.productoDatosCatalogoControllerDeleteById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ProductoDatosCatalogoFilter1} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productoDatosCatalogoControllerFind(filter?: ProductoDatosCatalogoFilter1, options?: any): AxiosPromise<Array<ProductoDatosCatalogoWithRelations>> {
            return localVarFp.productoDatosCatalogoControllerFind(filter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {ProductoDatosCatalogoFilter} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productoDatosCatalogoControllerFindById(id: number, filter?: ProductoDatosCatalogoFilter, options?: any): AxiosPromise<ProductoDatosCatalogoWithRelations> {
            return localVarFp.productoDatosCatalogoControllerFindById(id, filter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {ProductoDatosCatalogo} [productoDatosCatalogo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productoDatosCatalogoControllerReplaceById(id: number, productoDatosCatalogo?: ProductoDatosCatalogo, options?: any): AxiosPromise<any> {
            return localVarFp.productoDatosCatalogoControllerReplaceById(id, productoDatosCatalogo, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {ProductoDatosCatalogoPartial} [productoDatosCatalogoPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productoDatosCatalogoControllerUpdateAll(where?: { [key: string]: object; }, productoDatosCatalogoPartial?: ProductoDatosCatalogoPartial, options?: any): AxiosPromise<LoopbackCount> {
            return localVarFp.productoDatosCatalogoControllerUpdateAll(where, productoDatosCatalogoPartial, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {ProductoDatosCatalogoPartial} [productoDatosCatalogoPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productoDatosCatalogoControllerUpdateById(id: number, productoDatosCatalogoPartial?: ProductoDatosCatalogoPartial, options?: any): AxiosPromise<any> {
            return localVarFp.productoDatosCatalogoControllerUpdateById(id, productoDatosCatalogoPartial, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProductoDatosCatalogoControllerApi - object-oriented interface
 * @export
 * @class ProductoDatosCatalogoControllerApi
 * @extends {BaseAPI}
 */
export class ProductoDatosCatalogoControllerApi extends BaseAPI {
    /**
     * 
     * @param {{ [key: string]: object; }} [where] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductoDatosCatalogoControllerApi
     */
    public productoDatosCatalogoControllerCount(where?: { [key: string]: object; }, options?: AxiosRequestConfig) {
        return ProductoDatosCatalogoControllerApiFp(this.configuration).productoDatosCatalogoControllerCount(where, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {NewProductoDatosCatalogo} [newProductoDatosCatalogo] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductoDatosCatalogoControllerApi
     */
    public productoDatosCatalogoControllerCreate(newProductoDatosCatalogo?: NewProductoDatosCatalogo, options?: AxiosRequestConfig) {
        return ProductoDatosCatalogoControllerApiFp(this.configuration).productoDatosCatalogoControllerCreate(newProductoDatosCatalogo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductoDatosCatalogoControllerApi
     */
    public productoDatosCatalogoControllerDeleteById(id: number, options?: AxiosRequestConfig) {
        return ProductoDatosCatalogoControllerApiFp(this.configuration).productoDatosCatalogoControllerDeleteById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ProductoDatosCatalogoFilter1} [filter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductoDatosCatalogoControllerApi
     */
    public productoDatosCatalogoControllerFind(filter?: ProductoDatosCatalogoFilter1, options?: AxiosRequestConfig) {
        return ProductoDatosCatalogoControllerApiFp(this.configuration).productoDatosCatalogoControllerFind(filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {ProductoDatosCatalogoFilter} [filter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductoDatosCatalogoControllerApi
     */
    public productoDatosCatalogoControllerFindById(id: number, filter?: ProductoDatosCatalogoFilter, options?: AxiosRequestConfig) {
        return ProductoDatosCatalogoControllerApiFp(this.configuration).productoDatosCatalogoControllerFindById(id, filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {ProductoDatosCatalogo} [productoDatosCatalogo] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductoDatosCatalogoControllerApi
     */
    public productoDatosCatalogoControllerReplaceById(id: number, productoDatosCatalogo?: ProductoDatosCatalogo, options?: AxiosRequestConfig) {
        return ProductoDatosCatalogoControllerApiFp(this.configuration).productoDatosCatalogoControllerReplaceById(id, productoDatosCatalogo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} [where] 
     * @param {ProductoDatosCatalogoPartial} [productoDatosCatalogoPartial] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductoDatosCatalogoControllerApi
     */
    public productoDatosCatalogoControllerUpdateAll(where?: { [key: string]: object; }, productoDatosCatalogoPartial?: ProductoDatosCatalogoPartial, options?: AxiosRequestConfig) {
        return ProductoDatosCatalogoControllerApiFp(this.configuration).productoDatosCatalogoControllerUpdateAll(where, productoDatosCatalogoPartial, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {ProductoDatosCatalogoPartial} [productoDatosCatalogoPartial] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductoDatosCatalogoControllerApi
     */
    public productoDatosCatalogoControllerUpdateById(id: number, productoDatosCatalogoPartial?: ProductoDatosCatalogoPartial, options?: AxiosRequestConfig) {
        return ProductoDatosCatalogoControllerApiFp(this.configuration).productoDatosCatalogoControllerUpdateById(id, productoDatosCatalogoPartial, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ProductoGeneralControllerApi - axios parameter creator
 * @export
 */
export const ProductoGeneralControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productoGeneralControllerCount: async (where?: { [key: string]: object; }, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/producto-generals/count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (where !== undefined) {
                localVarQueryParameter['where'] = where;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {NewProductoGeneral} [newProductoGeneral] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productoGeneralControllerCreate: async (newProductoGeneral?: NewProductoGeneral, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/producto-generals`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(newProductoGeneral, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productoGeneralControllerDeleteById: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('productoGeneralControllerDeleteById', 'id', id)
            const localVarPath = `/producto-generals/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ProductoGeneralFilter1} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productoGeneralControllerFind: async (filter?: ProductoGeneralFilter1, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/producto-generals`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {ProductoGeneralFilter} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productoGeneralControllerFindById: async (id: number, filter?: ProductoGeneralFilter, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('productoGeneralControllerFindById', 'id', id)
            const localVarPath = `/producto-generals/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {ProductoGeneral} [productoGeneral] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productoGeneralControllerReplaceById: async (id: number, productoGeneral?: ProductoGeneral, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('productoGeneralControllerReplaceById', 'id', id)
            const localVarPath = `/producto-generals/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(productoGeneral, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {ProductoGeneralPartial} [productoGeneralPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productoGeneralControllerUpdateAll: async (where?: { [key: string]: object; }, productoGeneralPartial?: ProductoGeneralPartial, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/producto-generals`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (where !== undefined) {
                localVarQueryParameter['where'] = where;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(productoGeneralPartial, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {ProductoGeneralPartial} [productoGeneralPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productoGeneralControllerUpdateById: async (id: number, productoGeneralPartial?: ProductoGeneralPartial, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('productoGeneralControllerUpdateById', 'id', id)
            const localVarPath = `/producto-generals/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(productoGeneralPartial, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProductoGeneralControllerApi - functional programming interface
 * @export
 */
export const ProductoGeneralControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProductoGeneralControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productoGeneralControllerCount(where?: { [key: string]: object; }, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoopbackCount>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productoGeneralControllerCount(where, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {NewProductoGeneral} [newProductoGeneral] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productoGeneralControllerCreate(newProductoGeneral?: NewProductoGeneral, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductoGeneral>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productoGeneralControllerCreate(newProductoGeneral, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productoGeneralControllerDeleteById(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productoGeneralControllerDeleteById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ProductoGeneralFilter1} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productoGeneralControllerFind(filter?: ProductoGeneralFilter1, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ProductoGeneralWithRelations>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productoGeneralControllerFind(filter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {ProductoGeneralFilter} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productoGeneralControllerFindById(id: number, filter?: ProductoGeneralFilter, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductoGeneralWithRelations>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productoGeneralControllerFindById(id, filter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {ProductoGeneral} [productoGeneral] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productoGeneralControllerReplaceById(id: number, productoGeneral?: ProductoGeneral, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productoGeneralControllerReplaceById(id, productoGeneral, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {ProductoGeneralPartial} [productoGeneralPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productoGeneralControllerUpdateAll(where?: { [key: string]: object; }, productoGeneralPartial?: ProductoGeneralPartial, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoopbackCount>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productoGeneralControllerUpdateAll(where, productoGeneralPartial, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {ProductoGeneralPartial} [productoGeneralPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productoGeneralControllerUpdateById(id: number, productoGeneralPartial?: ProductoGeneralPartial, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productoGeneralControllerUpdateById(id, productoGeneralPartial, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ProductoGeneralControllerApi - factory interface
 * @export
 */
export const ProductoGeneralControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProductoGeneralControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productoGeneralControllerCount(where?: { [key: string]: object; }, options?: any): AxiosPromise<LoopbackCount> {
            return localVarFp.productoGeneralControllerCount(where, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {NewProductoGeneral} [newProductoGeneral] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productoGeneralControllerCreate(newProductoGeneral?: NewProductoGeneral, options?: any): AxiosPromise<ProductoGeneral> {
            return localVarFp.productoGeneralControllerCreate(newProductoGeneral, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productoGeneralControllerDeleteById(id: number, options?: any): AxiosPromise<any> {
            return localVarFp.productoGeneralControllerDeleteById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ProductoGeneralFilter1} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productoGeneralControllerFind(filter?: ProductoGeneralFilter1, options?: any): AxiosPromise<Array<ProductoGeneralWithRelations>> {
            return localVarFp.productoGeneralControllerFind(filter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {ProductoGeneralFilter} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productoGeneralControllerFindById(id: number, filter?: ProductoGeneralFilter, options?: any): AxiosPromise<ProductoGeneralWithRelations> {
            return localVarFp.productoGeneralControllerFindById(id, filter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {ProductoGeneral} [productoGeneral] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productoGeneralControllerReplaceById(id: number, productoGeneral?: ProductoGeneral, options?: any): AxiosPromise<any> {
            return localVarFp.productoGeneralControllerReplaceById(id, productoGeneral, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {ProductoGeneralPartial} [productoGeneralPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productoGeneralControllerUpdateAll(where?: { [key: string]: object; }, productoGeneralPartial?: ProductoGeneralPartial, options?: any): AxiosPromise<LoopbackCount> {
            return localVarFp.productoGeneralControllerUpdateAll(where, productoGeneralPartial, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {ProductoGeneralPartial} [productoGeneralPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productoGeneralControllerUpdateById(id: number, productoGeneralPartial?: ProductoGeneralPartial, options?: any): AxiosPromise<any> {
            return localVarFp.productoGeneralControllerUpdateById(id, productoGeneralPartial, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProductoGeneralControllerApi - object-oriented interface
 * @export
 * @class ProductoGeneralControllerApi
 * @extends {BaseAPI}
 */
export class ProductoGeneralControllerApi extends BaseAPI {
    /**
     * 
     * @param {{ [key: string]: object; }} [where] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductoGeneralControllerApi
     */
    public productoGeneralControllerCount(where?: { [key: string]: object; }, options?: AxiosRequestConfig) {
        return ProductoGeneralControllerApiFp(this.configuration).productoGeneralControllerCount(where, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {NewProductoGeneral} [newProductoGeneral] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductoGeneralControllerApi
     */
    public productoGeneralControllerCreate(newProductoGeneral?: NewProductoGeneral, options?: AxiosRequestConfig) {
        return ProductoGeneralControllerApiFp(this.configuration).productoGeneralControllerCreate(newProductoGeneral, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductoGeneralControllerApi
     */
    public productoGeneralControllerDeleteById(id: number, options?: AxiosRequestConfig) {
        return ProductoGeneralControllerApiFp(this.configuration).productoGeneralControllerDeleteById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ProductoGeneralFilter1} [filter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductoGeneralControllerApi
     */
    public productoGeneralControllerFind(filter?: ProductoGeneralFilter1, options?: AxiosRequestConfig) {
        return ProductoGeneralControllerApiFp(this.configuration).productoGeneralControllerFind(filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {ProductoGeneralFilter} [filter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductoGeneralControllerApi
     */
    public productoGeneralControllerFindById(id: number, filter?: ProductoGeneralFilter, options?: AxiosRequestConfig) {
        return ProductoGeneralControllerApiFp(this.configuration).productoGeneralControllerFindById(id, filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {ProductoGeneral} [productoGeneral] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductoGeneralControllerApi
     */
    public productoGeneralControllerReplaceById(id: number, productoGeneral?: ProductoGeneral, options?: AxiosRequestConfig) {
        return ProductoGeneralControllerApiFp(this.configuration).productoGeneralControllerReplaceById(id, productoGeneral, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} [where] 
     * @param {ProductoGeneralPartial} [productoGeneralPartial] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductoGeneralControllerApi
     */
    public productoGeneralControllerUpdateAll(where?: { [key: string]: object; }, productoGeneralPartial?: ProductoGeneralPartial, options?: AxiosRequestConfig) {
        return ProductoGeneralControllerApiFp(this.configuration).productoGeneralControllerUpdateAll(where, productoGeneralPartial, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {ProductoGeneralPartial} [productoGeneralPartial] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductoGeneralControllerApi
     */
    public productoGeneralControllerUpdateById(id: number, productoGeneralPartial?: ProductoGeneralPartial, options?: AxiosRequestConfig) {
        return ProductoGeneralControllerApiFp(this.configuration).productoGeneralControllerUpdateById(id, productoGeneralPartial, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ProductoIconosControllerApi - axios parameter creator
 * @export
 */
export const ProductoIconosControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productoIconosControllerCount: async (where?: { [key: string]: object; }, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/ProductoIconos/count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (where !== undefined) {
                localVarQueryParameter['where'] = where;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {NewProductoIconos} [newProductoIconos] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productoIconosControllerCreate: async (newProductoIconos?: NewProductoIconos, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/ProductoIconos`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(newProductoIconos, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productoIconosControllerDeleteById: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('productoIconosControllerDeleteById', 'id', id)
            const localVarPath = `/ProductoIconos/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} idProducto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productoIconosControllerDeleteByIdProducto: async (idProducto: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'idProducto' is not null or undefined
            assertParamExists('productoIconosControllerDeleteByIdProducto', 'idProducto', idProducto)
            const localVarPath = `/ProductoIconosDeleteByIdProducto/{idProducto}`
                .replace(`{${"idProducto"}}`, encodeURIComponent(String(idProducto)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ProductoIconosFilter1} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productoIconosControllerFind: async (filter?: ProductoIconosFilter1, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/ProductoIconos`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {ProductoIconosFilter} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productoIconosControllerFindById: async (id: number, filter?: ProductoIconosFilter, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('productoIconosControllerFindById', 'id', id)
            const localVarPath = `/ProductoIconos/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {ProductoIconos} [productoIconos] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productoIconosControllerReplaceById: async (id: number, productoIconos?: ProductoIconos, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('productoIconosControllerReplaceById', 'id', id)
            const localVarPath = `/ProductoIconos/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(productoIconos, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {ProductoIconosPartial} [productoIconosPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productoIconosControllerUpdateAll: async (where?: { [key: string]: object; }, productoIconosPartial?: ProductoIconosPartial, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/ProductoIconos`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (where !== undefined) {
                localVarQueryParameter['where'] = where;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(productoIconosPartial, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {ProductoIconosPartial} [productoIconosPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productoIconosControllerUpdateById: async (id: number, productoIconosPartial?: ProductoIconosPartial, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('productoIconosControllerUpdateById', 'id', id)
            const localVarPath = `/ProductoIconos/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(productoIconosPartial, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProductoIconosControllerApi - functional programming interface
 * @export
 */
export const ProductoIconosControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProductoIconosControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productoIconosControllerCount(where?: { [key: string]: object; }, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoopbackCount>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productoIconosControllerCount(where, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {NewProductoIconos} [newProductoIconos] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productoIconosControllerCreate(newProductoIconos?: NewProductoIconos, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductoIconos>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productoIconosControllerCreate(newProductoIconos, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productoIconosControllerDeleteById(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productoIconosControllerDeleteById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} idProducto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productoIconosControllerDeleteByIdProducto(idProducto: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productoIconosControllerDeleteByIdProducto(idProducto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ProductoIconosFilter1} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productoIconosControllerFind(filter?: ProductoIconosFilter1, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ProductoIconosWithRelations>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productoIconosControllerFind(filter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {ProductoIconosFilter} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productoIconosControllerFindById(id: number, filter?: ProductoIconosFilter, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductoIconosWithRelations>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productoIconosControllerFindById(id, filter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {ProductoIconos} [productoIconos] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productoIconosControllerReplaceById(id: number, productoIconos?: ProductoIconos, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productoIconosControllerReplaceById(id, productoIconos, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {ProductoIconosPartial} [productoIconosPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productoIconosControllerUpdateAll(where?: { [key: string]: object; }, productoIconosPartial?: ProductoIconosPartial, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoopbackCount>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productoIconosControllerUpdateAll(where, productoIconosPartial, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {ProductoIconosPartial} [productoIconosPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productoIconosControllerUpdateById(id: number, productoIconosPartial?: ProductoIconosPartial, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productoIconosControllerUpdateById(id, productoIconosPartial, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ProductoIconosControllerApi - factory interface
 * @export
 */
export const ProductoIconosControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProductoIconosControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productoIconosControllerCount(where?: { [key: string]: object; }, options?: any): AxiosPromise<LoopbackCount> {
            return localVarFp.productoIconosControllerCount(where, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {NewProductoIconos} [newProductoIconos] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productoIconosControllerCreate(newProductoIconos?: NewProductoIconos, options?: any): AxiosPromise<ProductoIconos> {
            return localVarFp.productoIconosControllerCreate(newProductoIconos, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productoIconosControllerDeleteById(id: number, options?: any): AxiosPromise<any> {
            return localVarFp.productoIconosControllerDeleteById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} idProducto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productoIconosControllerDeleteByIdProducto(idProducto: number, options?: any): AxiosPromise<any> {
            return localVarFp.productoIconosControllerDeleteByIdProducto(idProducto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ProductoIconosFilter1} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productoIconosControllerFind(filter?: ProductoIconosFilter1, options?: any): AxiosPromise<Array<ProductoIconosWithRelations>> {
            return localVarFp.productoIconosControllerFind(filter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {ProductoIconosFilter} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productoIconosControllerFindById(id: number, filter?: ProductoIconosFilter, options?: any): AxiosPromise<ProductoIconosWithRelations> {
            return localVarFp.productoIconosControllerFindById(id, filter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {ProductoIconos} [productoIconos] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productoIconosControllerReplaceById(id: number, productoIconos?: ProductoIconos, options?: any): AxiosPromise<any> {
            return localVarFp.productoIconosControllerReplaceById(id, productoIconos, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {ProductoIconosPartial} [productoIconosPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productoIconosControllerUpdateAll(where?: { [key: string]: object; }, productoIconosPartial?: ProductoIconosPartial, options?: any): AxiosPromise<LoopbackCount> {
            return localVarFp.productoIconosControllerUpdateAll(where, productoIconosPartial, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {ProductoIconosPartial} [productoIconosPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productoIconosControllerUpdateById(id: number, productoIconosPartial?: ProductoIconosPartial, options?: any): AxiosPromise<any> {
            return localVarFp.productoIconosControllerUpdateById(id, productoIconosPartial, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProductoIconosControllerApi - object-oriented interface
 * @export
 * @class ProductoIconosControllerApi
 * @extends {BaseAPI}
 */
export class ProductoIconosControllerApi extends BaseAPI {
    /**
     * 
     * @param {{ [key: string]: object; }} [where] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductoIconosControllerApi
     */
    public productoIconosControllerCount(where?: { [key: string]: object; }, options?: AxiosRequestConfig) {
        return ProductoIconosControllerApiFp(this.configuration).productoIconosControllerCount(where, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {NewProductoIconos} [newProductoIconos] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductoIconosControllerApi
     */
    public productoIconosControllerCreate(newProductoIconos?: NewProductoIconos, options?: AxiosRequestConfig) {
        return ProductoIconosControllerApiFp(this.configuration).productoIconosControllerCreate(newProductoIconos, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductoIconosControllerApi
     */
    public productoIconosControllerDeleteById(id: number, options?: AxiosRequestConfig) {
        return ProductoIconosControllerApiFp(this.configuration).productoIconosControllerDeleteById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} idProducto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductoIconosControllerApi
     */
    public productoIconosControllerDeleteByIdProducto(idProducto: number, options?: AxiosRequestConfig) {
        return ProductoIconosControllerApiFp(this.configuration).productoIconosControllerDeleteByIdProducto(idProducto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ProductoIconosFilter1} [filter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductoIconosControllerApi
     */
    public productoIconosControllerFind(filter?: ProductoIconosFilter1, options?: AxiosRequestConfig) {
        return ProductoIconosControllerApiFp(this.configuration).productoIconosControllerFind(filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {ProductoIconosFilter} [filter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductoIconosControllerApi
     */
    public productoIconosControllerFindById(id: number, filter?: ProductoIconosFilter, options?: AxiosRequestConfig) {
        return ProductoIconosControllerApiFp(this.configuration).productoIconosControllerFindById(id, filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {ProductoIconos} [productoIconos] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductoIconosControllerApi
     */
    public productoIconosControllerReplaceById(id: number, productoIconos?: ProductoIconos, options?: AxiosRequestConfig) {
        return ProductoIconosControllerApiFp(this.configuration).productoIconosControllerReplaceById(id, productoIconos, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} [where] 
     * @param {ProductoIconosPartial} [productoIconosPartial] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductoIconosControllerApi
     */
    public productoIconosControllerUpdateAll(where?: { [key: string]: object; }, productoIconosPartial?: ProductoIconosPartial, options?: AxiosRequestConfig) {
        return ProductoIconosControllerApiFp(this.configuration).productoIconosControllerUpdateAll(where, productoIconosPartial, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {ProductoIconosPartial} [productoIconosPartial] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductoIconosControllerApi
     */
    public productoIconosControllerUpdateById(id: number, productoIconosPartial?: ProductoIconosPartial, options?: AxiosRequestConfig) {
        return ProductoIconosControllerApiFp(this.configuration).productoIconosControllerUpdateById(id, productoIconosPartial, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ProductoMultimediaControllerApi - axios parameter creator
 * @export
 */
export const ProductoMultimediaControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productoMultimediaControllerCount: async (where?: { [key: string]: object; }, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/producto-multimedias/count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (where !== undefined) {
                localVarQueryParameter['where'] = where;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {NewProductoMultimedia} [newProductoMultimedia] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productoMultimediaControllerCreate: async (newProductoMultimedia?: NewProductoMultimedia, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/producto-multimedias`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(newProductoMultimedia, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productoMultimediaControllerDeleteById: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('productoMultimediaControllerDeleteById', 'id', id)
            const localVarPath = `/producto-multimedias/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ProductoMultimediaFilter1} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productoMultimediaControllerFind: async (filter?: ProductoMultimediaFilter1, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/producto-multimedias`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {ProductoMultimediaFilter} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productoMultimediaControllerFindById: async (id: number, filter?: ProductoMultimediaFilter, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('productoMultimediaControllerFindById', 'id', id)
            const localVarPath = `/producto-multimedias/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productoMultimediaControllerQueryFullProducts: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('productoMultimediaControllerQueryFullProducts', 'id', id)
            const localVarPath = `/producto-multimedia/por-producto/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {ProductoMultimedia} [productoMultimedia] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productoMultimediaControllerReplaceById: async (id: number, productoMultimedia?: ProductoMultimedia, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('productoMultimediaControllerReplaceById', 'id', id)
            const localVarPath = `/producto-multimedias/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(productoMultimedia, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {ProductoMultimediaPartial} [productoMultimediaPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productoMultimediaControllerUpdateAll: async (where?: { [key: string]: object; }, productoMultimediaPartial?: ProductoMultimediaPartial, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/producto-multimedias`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (where !== undefined) {
                localVarQueryParameter['where'] = where;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(productoMultimediaPartial, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {ProductoMultimediaPartial} [productoMultimediaPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productoMultimediaControllerUpdateById: async (id: number, productoMultimediaPartial?: ProductoMultimediaPartial, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('productoMultimediaControllerUpdateById', 'id', id)
            const localVarPath = `/producto-multimedias/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(productoMultimediaPartial, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProductoMultimediaControllerApi - functional programming interface
 * @export
 */
export const ProductoMultimediaControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProductoMultimediaControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productoMultimediaControllerCount(where?: { [key: string]: object; }, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoopbackCount>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productoMultimediaControllerCount(where, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {NewProductoMultimedia} [newProductoMultimedia] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productoMultimediaControllerCreate(newProductoMultimedia?: NewProductoMultimedia, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductoMultimedia>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productoMultimediaControllerCreate(newProductoMultimedia, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productoMultimediaControllerDeleteById(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productoMultimediaControllerDeleteById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ProductoMultimediaFilter1} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productoMultimediaControllerFind(filter?: ProductoMultimediaFilter1, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ProductoMultimediaWithRelations>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productoMultimediaControllerFind(filter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {ProductoMultimediaFilter} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productoMultimediaControllerFindById(id: number, filter?: ProductoMultimediaFilter, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductoMultimediaWithRelations>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productoMultimediaControllerFindById(id, filter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productoMultimediaControllerQueryFullProducts(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductoMultimedia>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productoMultimediaControllerQueryFullProducts(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {ProductoMultimedia} [productoMultimedia] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productoMultimediaControllerReplaceById(id: number, productoMultimedia?: ProductoMultimedia, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productoMultimediaControllerReplaceById(id, productoMultimedia, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {ProductoMultimediaPartial} [productoMultimediaPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productoMultimediaControllerUpdateAll(where?: { [key: string]: object; }, productoMultimediaPartial?: ProductoMultimediaPartial, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoopbackCount>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productoMultimediaControllerUpdateAll(where, productoMultimediaPartial, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {ProductoMultimediaPartial} [productoMultimediaPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productoMultimediaControllerUpdateById(id: number, productoMultimediaPartial?: ProductoMultimediaPartial, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productoMultimediaControllerUpdateById(id, productoMultimediaPartial, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ProductoMultimediaControllerApi - factory interface
 * @export
 */
export const ProductoMultimediaControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProductoMultimediaControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productoMultimediaControllerCount(where?: { [key: string]: object; }, options?: any): AxiosPromise<LoopbackCount> {
            return localVarFp.productoMultimediaControllerCount(where, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {NewProductoMultimedia} [newProductoMultimedia] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productoMultimediaControllerCreate(newProductoMultimedia?: NewProductoMultimedia, options?: any): AxiosPromise<ProductoMultimedia> {
            return localVarFp.productoMultimediaControllerCreate(newProductoMultimedia, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productoMultimediaControllerDeleteById(id: number, options?: any): AxiosPromise<any> {
            return localVarFp.productoMultimediaControllerDeleteById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ProductoMultimediaFilter1} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productoMultimediaControllerFind(filter?: ProductoMultimediaFilter1, options?: any): AxiosPromise<Array<ProductoMultimediaWithRelations>> {
            return localVarFp.productoMultimediaControllerFind(filter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {ProductoMultimediaFilter} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productoMultimediaControllerFindById(id: number, filter?: ProductoMultimediaFilter, options?: any): AxiosPromise<ProductoMultimediaWithRelations> {
            return localVarFp.productoMultimediaControllerFindById(id, filter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productoMultimediaControllerQueryFullProducts(id: number, options?: any): AxiosPromise<ProductoMultimedia> {
            return localVarFp.productoMultimediaControllerQueryFullProducts(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {ProductoMultimedia} [productoMultimedia] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productoMultimediaControllerReplaceById(id: number, productoMultimedia?: ProductoMultimedia, options?: any): AxiosPromise<any> {
            return localVarFp.productoMultimediaControllerReplaceById(id, productoMultimedia, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {ProductoMultimediaPartial} [productoMultimediaPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productoMultimediaControllerUpdateAll(where?: { [key: string]: object; }, productoMultimediaPartial?: ProductoMultimediaPartial, options?: any): AxiosPromise<LoopbackCount> {
            return localVarFp.productoMultimediaControllerUpdateAll(where, productoMultimediaPartial, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {ProductoMultimediaPartial} [productoMultimediaPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productoMultimediaControllerUpdateById(id: number, productoMultimediaPartial?: ProductoMultimediaPartial, options?: any): AxiosPromise<any> {
            return localVarFp.productoMultimediaControllerUpdateById(id, productoMultimediaPartial, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProductoMultimediaControllerApi - object-oriented interface
 * @export
 * @class ProductoMultimediaControllerApi
 * @extends {BaseAPI}
 */
export class ProductoMultimediaControllerApi extends BaseAPI {
    /**
     * 
     * @param {{ [key: string]: object; }} [where] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductoMultimediaControllerApi
     */
    public productoMultimediaControllerCount(where?: { [key: string]: object; }, options?: AxiosRequestConfig) {
        return ProductoMultimediaControllerApiFp(this.configuration).productoMultimediaControllerCount(where, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {NewProductoMultimedia} [newProductoMultimedia] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductoMultimediaControllerApi
     */
    public productoMultimediaControllerCreate(newProductoMultimedia?: NewProductoMultimedia, options?: AxiosRequestConfig) {
        return ProductoMultimediaControllerApiFp(this.configuration).productoMultimediaControllerCreate(newProductoMultimedia, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductoMultimediaControllerApi
     */
    public productoMultimediaControllerDeleteById(id: number, options?: AxiosRequestConfig) {
        return ProductoMultimediaControllerApiFp(this.configuration).productoMultimediaControllerDeleteById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ProductoMultimediaFilter1} [filter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductoMultimediaControllerApi
     */
    public productoMultimediaControllerFind(filter?: ProductoMultimediaFilter1, options?: AxiosRequestConfig) {
        return ProductoMultimediaControllerApiFp(this.configuration).productoMultimediaControllerFind(filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {ProductoMultimediaFilter} [filter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductoMultimediaControllerApi
     */
    public productoMultimediaControllerFindById(id: number, filter?: ProductoMultimediaFilter, options?: AxiosRequestConfig) {
        return ProductoMultimediaControllerApiFp(this.configuration).productoMultimediaControllerFindById(id, filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductoMultimediaControllerApi
     */
    public productoMultimediaControllerQueryFullProducts(id: number, options?: AxiosRequestConfig) {
        return ProductoMultimediaControllerApiFp(this.configuration).productoMultimediaControllerQueryFullProducts(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {ProductoMultimedia} [productoMultimedia] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductoMultimediaControllerApi
     */
    public productoMultimediaControllerReplaceById(id: number, productoMultimedia?: ProductoMultimedia, options?: AxiosRequestConfig) {
        return ProductoMultimediaControllerApiFp(this.configuration).productoMultimediaControllerReplaceById(id, productoMultimedia, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} [where] 
     * @param {ProductoMultimediaPartial} [productoMultimediaPartial] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductoMultimediaControllerApi
     */
    public productoMultimediaControllerUpdateAll(where?: { [key: string]: object; }, productoMultimediaPartial?: ProductoMultimediaPartial, options?: AxiosRequestConfig) {
        return ProductoMultimediaControllerApiFp(this.configuration).productoMultimediaControllerUpdateAll(where, productoMultimediaPartial, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {ProductoMultimediaPartial} [productoMultimediaPartial] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductoMultimediaControllerApi
     */
    public productoMultimediaControllerUpdateById(id: number, productoMultimediaPartial?: ProductoMultimediaPartial, options?: AxiosRequestConfig) {
        return ProductoMultimediaControllerApiFp(this.configuration).productoMultimediaControllerUpdateById(id, productoMultimediaPartial, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ProductosControllerApi - axios parameter creator
 * @export
 */
export const ProductosControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productosControllerCount: async (where?: { [key: string]: object; }, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/productos/count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (where !== undefined) {
                localVarQueryParameter['where'] = where;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {NewProductos} [newProductos] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productosControllerCreate: async (newProductos?: NewProductos, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/productos`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(newProductos, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productosControllerDeleteById: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('productosControllerDeleteById', 'id', id)
            const localVarPath = `/productos/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ProductosFilter1} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productosControllerFind: async (filter?: ProductosFilter1, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/productos`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {ProductosFilter} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productosControllerFindById: async (id: number, filter?: ProductosFilter, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('productosControllerFindById', 'id', id)
            const localVarPath = `/productos/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productosControllerGetNextId: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/productos/getNextId`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {Productos} [productos] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productosControllerReplaceById: async (id: number, productos?: Productos, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('productosControllerReplaceById', 'id', id)
            const localVarPath = `/productos/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(productos, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productosControllerReplica: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('productosControllerReplica', 'id', id)
            const localVarPath = `/productos/replica/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {ProductosPartial} [productosPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productosControllerUpdateAll: async (where?: { [key: string]: object; }, productosPartial?: ProductosPartial, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/productos`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (where !== undefined) {
                localVarQueryParameter['where'] = where;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(productosPartial, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {ProductosPartial} [productosPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productosControllerUpdateById: async (id: number, productosPartial?: ProductosPartial, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('productosControllerUpdateById', 'id', id)
            const localVarPath = `/productos/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(productosPartial, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProductosControllerApi - functional programming interface
 * @export
 */
export const ProductosControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProductosControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productosControllerCount(where?: { [key: string]: object; }, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoopbackCount>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productosControllerCount(where, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {NewProductos} [newProductos] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productosControllerCreate(newProductos?: NewProductos, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Productos>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productosControllerCreate(newProductos, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productosControllerDeleteById(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productosControllerDeleteById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ProductosFilter1} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productosControllerFind(filter?: ProductosFilter1, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ProductosWithRelations>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productosControllerFind(filter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {ProductosFilter} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productosControllerFindById(id: number, filter?: ProductosFilter, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductosWithRelations>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productosControllerFindById(id, filter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productosControllerGetNextId(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoopbackCount>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productosControllerGetNextId(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {Productos} [productos] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productosControllerReplaceById(id: number, productos?: Productos, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productosControllerReplaceById(id, productos, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productosControllerReplica(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductosWithRelations>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productosControllerReplica(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {ProductosPartial} [productosPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productosControllerUpdateAll(where?: { [key: string]: object; }, productosPartial?: ProductosPartial, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoopbackCount>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productosControllerUpdateAll(where, productosPartial, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {ProductosPartial} [productosPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productosControllerUpdateById(id: number, productosPartial?: ProductosPartial, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productosControllerUpdateById(id, productosPartial, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ProductosControllerApi - factory interface
 * @export
 */
export const ProductosControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProductosControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productosControllerCount(where?: { [key: string]: object; }, options?: any): AxiosPromise<LoopbackCount> {
            return localVarFp.productosControllerCount(where, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {NewProductos} [newProductos] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productosControllerCreate(newProductos?: NewProductos, options?: any): AxiosPromise<Productos> {
            return localVarFp.productosControllerCreate(newProductos, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productosControllerDeleteById(id: number, options?: any): AxiosPromise<any> {
            return localVarFp.productosControllerDeleteById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ProductosFilter1} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productosControllerFind(filter?: ProductosFilter1, options?: any): AxiosPromise<Array<ProductosWithRelations>> {
            return localVarFp.productosControllerFind(filter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {ProductosFilter} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productosControllerFindById(id: number, filter?: ProductosFilter, options?: any): AxiosPromise<ProductosWithRelations> {
            return localVarFp.productosControllerFindById(id, filter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productosControllerGetNextId(options?: any): AxiosPromise<LoopbackCount> {
            return localVarFp.productosControllerGetNextId(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {Productos} [productos] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productosControllerReplaceById(id: number, productos?: Productos, options?: any): AxiosPromise<any> {
            return localVarFp.productosControllerReplaceById(id, productos, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productosControllerReplica(id: number, options?: any): AxiosPromise<ProductosWithRelations> {
            return localVarFp.productosControllerReplica(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {ProductosPartial} [productosPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productosControllerUpdateAll(where?: { [key: string]: object; }, productosPartial?: ProductosPartial, options?: any): AxiosPromise<LoopbackCount> {
            return localVarFp.productosControllerUpdateAll(where, productosPartial, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {ProductosPartial} [productosPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productosControllerUpdateById(id: number, productosPartial?: ProductosPartial, options?: any): AxiosPromise<any> {
            return localVarFp.productosControllerUpdateById(id, productosPartial, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProductosControllerApi - object-oriented interface
 * @export
 * @class ProductosControllerApi
 * @extends {BaseAPI}
 */
export class ProductosControllerApi extends BaseAPI {
    /**
     * 
     * @param {{ [key: string]: object; }} [where] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductosControllerApi
     */
    public productosControllerCount(where?: { [key: string]: object; }, options?: AxiosRequestConfig) {
        return ProductosControllerApiFp(this.configuration).productosControllerCount(where, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {NewProductos} [newProductos] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductosControllerApi
     */
    public productosControllerCreate(newProductos?: NewProductos, options?: AxiosRequestConfig) {
        return ProductosControllerApiFp(this.configuration).productosControllerCreate(newProductos, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductosControllerApi
     */
    public productosControllerDeleteById(id: number, options?: AxiosRequestConfig) {
        return ProductosControllerApiFp(this.configuration).productosControllerDeleteById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ProductosFilter1} [filter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductosControllerApi
     */
    public productosControllerFind(filter?: ProductosFilter1, options?: AxiosRequestConfig) {
        return ProductosControllerApiFp(this.configuration).productosControllerFind(filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {ProductosFilter} [filter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductosControllerApi
     */
    public productosControllerFindById(id: number, filter?: ProductosFilter, options?: AxiosRequestConfig) {
        return ProductosControllerApiFp(this.configuration).productosControllerFindById(id, filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductosControllerApi
     */
    public productosControllerGetNextId(options?: AxiosRequestConfig) {
        return ProductosControllerApiFp(this.configuration).productosControllerGetNextId(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {Productos} [productos] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductosControllerApi
     */
    public productosControllerReplaceById(id: number, productos?: Productos, options?: AxiosRequestConfig) {
        return ProductosControllerApiFp(this.configuration).productosControllerReplaceById(id, productos, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductosControllerApi
     */
    public productosControllerReplica(id: number, options?: AxiosRequestConfig) {
        return ProductosControllerApiFp(this.configuration).productosControllerReplica(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} [where] 
     * @param {ProductosPartial} [productosPartial] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductosControllerApi
     */
    public productosControllerUpdateAll(where?: { [key: string]: object; }, productosPartial?: ProductosPartial, options?: AxiosRequestConfig) {
        return ProductosControllerApiFp(this.configuration).productosControllerUpdateAll(where, productosPartial, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {ProductosPartial} [productosPartial] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductosControllerApi
     */
    public productosControllerUpdateById(id: number, productosPartial?: ProductosPartial, options?: AxiosRequestConfig) {
        return ProductosControllerApiFp(this.configuration).productosControllerUpdateById(id, productosPartial, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ProductosPorRangoControllerApi - axios parameter creator
 * @export
 */
export const ProductosPorRangoControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productosPorRangoControllerCount: async (where?: { [key: string]: object; }, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/ProductosPorRango/count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (where !== undefined) {
                localVarQueryParameter['where'] = where;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {NewProductosPorRango} [newProductosPorRango] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productosPorRangoControllerCreate: async (newProductosPorRango?: NewProductosPorRango, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/ProductosPorRango`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(newProductosPorRango, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productosPorRangoControllerDeleteById: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('productosPorRangoControllerDeleteById', 'id', id)
            const localVarPath = `/ProductosPorRango/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ProductosPorRangoFilter1} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productosPorRangoControllerFind: async (filter?: ProductosPorRangoFilter1, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/ProductosPorRango`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {ProductosPorRangoFilter} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productosPorRangoControllerFindById: async (id: number, filter?: ProductosPorRangoFilter, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('productosPorRangoControllerFindById', 'id', id)
            const localVarPath = `/ProductosPorRango/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {ProductosPorRango} [productosPorRango] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productosPorRangoControllerReplaceById: async (id: number, productosPorRango?: ProductosPorRango, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('productosPorRangoControllerReplaceById', 'id', id)
            const localVarPath = `/ProductosPorRango/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(productosPorRango, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {ProductosPorRangoPartial} [productosPorRangoPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productosPorRangoControllerUpdateAll: async (where?: { [key: string]: object; }, productosPorRangoPartial?: ProductosPorRangoPartial, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/ProductosPorRango`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (where !== undefined) {
                localVarQueryParameter['where'] = where;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(productosPorRangoPartial, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {ProductosPorRangoPartial} [productosPorRangoPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productosPorRangoControllerUpdateById: async (id: number, productosPorRangoPartial?: ProductosPorRangoPartial, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('productosPorRangoControllerUpdateById', 'id', id)
            const localVarPath = `/ProductosPorRango/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(productosPorRangoPartial, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProductosPorRangoControllerApi - functional programming interface
 * @export
 */
export const ProductosPorRangoControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProductosPorRangoControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productosPorRangoControllerCount(where?: { [key: string]: object; }, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoopbackCount>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productosPorRangoControllerCount(where, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {NewProductosPorRango} [newProductosPorRango] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productosPorRangoControllerCreate(newProductosPorRango?: NewProductosPorRango, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductosPorRango>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productosPorRangoControllerCreate(newProductosPorRango, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productosPorRangoControllerDeleteById(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productosPorRangoControllerDeleteById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ProductosPorRangoFilter1} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productosPorRangoControllerFind(filter?: ProductosPorRangoFilter1, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ProductosPorRangoWithRelations>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productosPorRangoControllerFind(filter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {ProductosPorRangoFilter} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productosPorRangoControllerFindById(id: number, filter?: ProductosPorRangoFilter, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductosPorRangoWithRelations>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productosPorRangoControllerFindById(id, filter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {ProductosPorRango} [productosPorRango] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productosPorRangoControllerReplaceById(id: number, productosPorRango?: ProductosPorRango, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productosPorRangoControllerReplaceById(id, productosPorRango, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {ProductosPorRangoPartial} [productosPorRangoPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productosPorRangoControllerUpdateAll(where?: { [key: string]: object; }, productosPorRangoPartial?: ProductosPorRangoPartial, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoopbackCount>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productosPorRangoControllerUpdateAll(where, productosPorRangoPartial, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {ProductosPorRangoPartial} [productosPorRangoPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productosPorRangoControllerUpdateById(id: number, productosPorRangoPartial?: ProductosPorRangoPartial, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productosPorRangoControllerUpdateById(id, productosPorRangoPartial, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ProductosPorRangoControllerApi - factory interface
 * @export
 */
export const ProductosPorRangoControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProductosPorRangoControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productosPorRangoControllerCount(where?: { [key: string]: object; }, options?: any): AxiosPromise<LoopbackCount> {
            return localVarFp.productosPorRangoControllerCount(where, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {NewProductosPorRango} [newProductosPorRango] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productosPorRangoControllerCreate(newProductosPorRango?: NewProductosPorRango, options?: any): AxiosPromise<ProductosPorRango> {
            return localVarFp.productosPorRangoControllerCreate(newProductosPorRango, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productosPorRangoControllerDeleteById(id: number, options?: any): AxiosPromise<any> {
            return localVarFp.productosPorRangoControllerDeleteById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ProductosPorRangoFilter1} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productosPorRangoControllerFind(filter?: ProductosPorRangoFilter1, options?: any): AxiosPromise<Array<ProductosPorRangoWithRelations>> {
            return localVarFp.productosPorRangoControllerFind(filter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {ProductosPorRangoFilter} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productosPorRangoControllerFindById(id: number, filter?: ProductosPorRangoFilter, options?: any): AxiosPromise<ProductosPorRangoWithRelations> {
            return localVarFp.productosPorRangoControllerFindById(id, filter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {ProductosPorRango} [productosPorRango] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productosPorRangoControllerReplaceById(id: number, productosPorRango?: ProductosPorRango, options?: any): AxiosPromise<any> {
            return localVarFp.productosPorRangoControllerReplaceById(id, productosPorRango, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {ProductosPorRangoPartial} [productosPorRangoPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productosPorRangoControllerUpdateAll(where?: { [key: string]: object; }, productosPorRangoPartial?: ProductosPorRangoPartial, options?: any): AxiosPromise<LoopbackCount> {
            return localVarFp.productosPorRangoControllerUpdateAll(where, productosPorRangoPartial, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {ProductosPorRangoPartial} [productosPorRangoPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productosPorRangoControllerUpdateById(id: number, productosPorRangoPartial?: ProductosPorRangoPartial, options?: any): AxiosPromise<any> {
            return localVarFp.productosPorRangoControllerUpdateById(id, productosPorRangoPartial, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProductosPorRangoControllerApi - object-oriented interface
 * @export
 * @class ProductosPorRangoControllerApi
 * @extends {BaseAPI}
 */
export class ProductosPorRangoControllerApi extends BaseAPI {
    /**
     * 
     * @param {{ [key: string]: object; }} [where] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductosPorRangoControllerApi
     */
    public productosPorRangoControllerCount(where?: { [key: string]: object; }, options?: AxiosRequestConfig) {
        return ProductosPorRangoControllerApiFp(this.configuration).productosPorRangoControllerCount(where, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {NewProductosPorRango} [newProductosPorRango] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductosPorRangoControllerApi
     */
    public productosPorRangoControllerCreate(newProductosPorRango?: NewProductosPorRango, options?: AxiosRequestConfig) {
        return ProductosPorRangoControllerApiFp(this.configuration).productosPorRangoControllerCreate(newProductosPorRango, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductosPorRangoControllerApi
     */
    public productosPorRangoControllerDeleteById(id: number, options?: AxiosRequestConfig) {
        return ProductosPorRangoControllerApiFp(this.configuration).productosPorRangoControllerDeleteById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ProductosPorRangoFilter1} [filter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductosPorRangoControllerApi
     */
    public productosPorRangoControllerFind(filter?: ProductosPorRangoFilter1, options?: AxiosRequestConfig) {
        return ProductosPorRangoControllerApiFp(this.configuration).productosPorRangoControllerFind(filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {ProductosPorRangoFilter} [filter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductosPorRangoControllerApi
     */
    public productosPorRangoControllerFindById(id: number, filter?: ProductosPorRangoFilter, options?: AxiosRequestConfig) {
        return ProductosPorRangoControllerApiFp(this.configuration).productosPorRangoControllerFindById(id, filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {ProductosPorRango} [productosPorRango] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductosPorRangoControllerApi
     */
    public productosPorRangoControllerReplaceById(id: number, productosPorRango?: ProductosPorRango, options?: AxiosRequestConfig) {
        return ProductosPorRangoControllerApiFp(this.configuration).productosPorRangoControllerReplaceById(id, productosPorRango, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} [where] 
     * @param {ProductosPorRangoPartial} [productosPorRangoPartial] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductosPorRangoControllerApi
     */
    public productosPorRangoControllerUpdateAll(where?: { [key: string]: object; }, productosPorRangoPartial?: ProductosPorRangoPartial, options?: AxiosRequestConfig) {
        return ProductosPorRangoControllerApiFp(this.configuration).productosPorRangoControllerUpdateAll(where, productosPorRangoPartial, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {ProductosPorRangoPartial} [productosPorRangoPartial] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductosPorRangoControllerApi
     */
    public productosPorRangoControllerUpdateById(id: number, productosPorRangoPartial?: ProductosPorRangoPartial, options?: AxiosRequestConfig) {
        return ProductosPorRangoControllerApiFp(this.configuration).productosPorRangoControllerUpdateById(id, productosPorRangoPartial, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * RelacionRolPermisosControllerApi - axios parameter creator
 * @export
 */
export const RelacionRolPermisosControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} id 
         * @param {NewRelacionRolesPermisos} [newRelacionRolesPermisos] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        relacionRolPermisosControllerCreate: async (id: number, newRelacionRolesPermisos?: NewRelacionRolesPermisos, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('relacionRolPermisosControllerCreate', 'id', id)
            const localVarPath = `/roles/{id}/permisos`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(newRelacionRolesPermisos, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {{ [key: string]: object; }} [where] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        relacionRolPermisosControllerDelete: async (id: number, where?: { [key: string]: object; }, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('relacionRolPermisosControllerDelete', 'id', id)
            const localVarPath = `/roles/{id}/permisos`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (where !== undefined) {
                localVarQueryParameter['where'] = where;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {{ [key: string]: object; }} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        relacionRolPermisosControllerFind: async (id: number, filter?: { [key: string]: object; }, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('relacionRolPermisosControllerFind', 'id', id)
            const localVarPath = `/roles/{id}/permisos`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {{ [key: string]: object; }} [where] 
         * @param {PermisosPartial} [permisosPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        relacionRolPermisosControllerPatch: async (id: number, where?: { [key: string]: object; }, permisosPartial?: PermisosPartial, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('relacionRolPermisosControllerPatch', 'id', id)
            const localVarPath = `/roles/{id}/permisos`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (where !== undefined) {
                localVarQueryParameter['where'] = where;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(permisosPartial, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RelacionRolPermisosControllerApi - functional programming interface
 * @export
 */
export const RelacionRolPermisosControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RelacionRolPermisosControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {NewRelacionRolesPermisos} [newRelacionRolesPermisos] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async relacionRolPermisosControllerCreate(id: number, newRelacionRolesPermisos?: NewRelacionRolesPermisos, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Permisos>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.relacionRolPermisosControllerCreate(id, newRelacionRolesPermisos, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {{ [key: string]: object; }} [where] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async relacionRolPermisosControllerDelete(id: number, where?: { [key: string]: object; }, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoopbackCount>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.relacionRolPermisosControllerDelete(id, where, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {{ [key: string]: object; }} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async relacionRolPermisosControllerFind(id: number, filter?: { [key: string]: object; }, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Permisos>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.relacionRolPermisosControllerFind(id, filter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {{ [key: string]: object; }} [where] 
         * @param {PermisosPartial} [permisosPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async relacionRolPermisosControllerPatch(id: number, where?: { [key: string]: object; }, permisosPartial?: PermisosPartial, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoopbackCount>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.relacionRolPermisosControllerPatch(id, where, permisosPartial, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * RelacionRolPermisosControllerApi - factory interface
 * @export
 */
export const RelacionRolPermisosControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RelacionRolPermisosControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {NewRelacionRolesPermisos} [newRelacionRolesPermisos] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        relacionRolPermisosControllerCreate(id: number, newRelacionRolesPermisos?: NewRelacionRolesPermisos, options?: any): AxiosPromise<Permisos> {
            return localVarFp.relacionRolPermisosControllerCreate(id, newRelacionRolesPermisos, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {{ [key: string]: object; }} [where] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        relacionRolPermisosControllerDelete(id: number, where?: { [key: string]: object; }, options?: any): AxiosPromise<LoopbackCount> {
            return localVarFp.relacionRolPermisosControllerDelete(id, where, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {{ [key: string]: object; }} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        relacionRolPermisosControllerFind(id: number, filter?: { [key: string]: object; }, options?: any): AxiosPromise<Array<Permisos>> {
            return localVarFp.relacionRolPermisosControllerFind(id, filter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {{ [key: string]: object; }} [where] 
         * @param {PermisosPartial} [permisosPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        relacionRolPermisosControllerPatch(id: number, where?: { [key: string]: object; }, permisosPartial?: PermisosPartial, options?: any): AxiosPromise<LoopbackCount> {
            return localVarFp.relacionRolPermisosControllerPatch(id, where, permisosPartial, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * RelacionRolPermisosControllerApi - object-oriented interface
 * @export
 * @class RelacionRolPermisosControllerApi
 * @extends {BaseAPI}
 */
export class RelacionRolPermisosControllerApi extends BaseAPI {
    /**
     * 
     * @param {number} id 
     * @param {NewRelacionRolesPermisos} [newRelacionRolesPermisos] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RelacionRolPermisosControllerApi
     */
    public relacionRolPermisosControllerCreate(id: number, newRelacionRolesPermisos?: NewRelacionRolesPermisos, options?: AxiosRequestConfig) {
        return RelacionRolPermisosControllerApiFp(this.configuration).relacionRolPermisosControllerCreate(id, newRelacionRolesPermisos, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {{ [key: string]: object; }} [where] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RelacionRolPermisosControllerApi
     */
    public relacionRolPermisosControllerDelete(id: number, where?: { [key: string]: object; }, options?: AxiosRequestConfig) {
        return RelacionRolPermisosControllerApiFp(this.configuration).relacionRolPermisosControllerDelete(id, where, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {{ [key: string]: object; }} [filter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RelacionRolPermisosControllerApi
     */
    public relacionRolPermisosControllerFind(id: number, filter?: { [key: string]: object; }, options?: AxiosRequestConfig) {
        return RelacionRolPermisosControllerApiFp(this.configuration).relacionRolPermisosControllerFind(id, filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {{ [key: string]: object; }} [where] 
     * @param {PermisosPartial} [permisosPartial] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RelacionRolPermisosControllerApi
     */
    public relacionRolPermisosControllerPatch(id: number, where?: { [key: string]: object; }, permisosPartial?: PermisosPartial, options?: AxiosRequestConfig) {
        return RelacionRolPermisosControllerApiFp(this.configuration).relacionRolPermisosControllerPatch(id, where, permisosPartial, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * RepuestosControllerApi - axios parameter creator
 * @export
 */
export const RepuestosControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        repuestosControllerCount: async (where?: { [key: string]: object; }, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Repuestos/count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (where !== undefined) {
                localVarQueryParameter['where'] = where;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {NewRepuestos} [newRepuestos] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        repuestosControllerCreate: async (newRepuestos?: NewRepuestos, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Repuestos`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(newRepuestos, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {string} skuPrincipal 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        repuestosControllerDeleteById: async (id: number, skuPrincipal: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('repuestosControllerDeleteById', 'id', id)
            // verify required parameter 'skuPrincipal' is not null or undefined
            assertParamExists('repuestosControllerDeleteById', 'skuPrincipal', skuPrincipal)
            const localVarPath = `/Repuestos/{id}/{skuPrincipal}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"skuPrincipal"}}`, encodeURIComponent(String(skuPrincipal)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {RepuestosFilter1} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        repuestosControllerFind: async (filter?: RepuestosFilter1, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Repuestos`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {RepuestosFilter} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        repuestosControllerFindById: async (id: number, filter?: RepuestosFilter, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('repuestosControllerFindById', 'id', id)
            const localVarPath = `/Repuestos/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {Repuestos} [repuestos] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        repuestosControllerReplaceById: async (id: number, repuestos?: Repuestos, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('repuestosControllerReplaceById', 'id', id)
            const localVarPath = `/Repuestos/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(repuestos, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {RepuestosPartial} [repuestosPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        repuestosControllerUpdateAll: async (where?: { [key: string]: object; }, repuestosPartial?: RepuestosPartial, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Repuestos`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (where !== undefined) {
                localVarQueryParameter['where'] = where;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(repuestosPartial, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {RepuestosPartial} [repuestosPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        repuestosControllerUpdateById: async (id: number, repuestosPartial?: RepuestosPartial, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('repuestosControllerUpdateById', 'id', id)
            const localVarPath = `/Repuestos/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(repuestosPartial, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RepuestosControllerApi - functional programming interface
 * @export
 */
export const RepuestosControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RepuestosControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async repuestosControllerCount(where?: { [key: string]: object; }, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoopbackCount>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.repuestosControllerCount(where, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {NewRepuestos} [newRepuestos] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async repuestosControllerCreate(newRepuestos?: NewRepuestos, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Repuestos>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.repuestosControllerCreate(newRepuestos, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {string} skuPrincipal 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async repuestosControllerDeleteById(id: number, skuPrincipal: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.repuestosControllerDeleteById(id, skuPrincipal, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {RepuestosFilter1} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async repuestosControllerFind(filter?: RepuestosFilter1, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RepuestosWithRelations>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.repuestosControllerFind(filter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {RepuestosFilter} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async repuestosControllerFindById(id: number, filter?: RepuestosFilter, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RepuestosWithRelations>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.repuestosControllerFindById(id, filter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {Repuestos} [repuestos] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async repuestosControllerReplaceById(id: number, repuestos?: Repuestos, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.repuestosControllerReplaceById(id, repuestos, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {RepuestosPartial} [repuestosPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async repuestosControllerUpdateAll(where?: { [key: string]: object; }, repuestosPartial?: RepuestosPartial, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoopbackCount>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.repuestosControllerUpdateAll(where, repuestosPartial, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {RepuestosPartial} [repuestosPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async repuestosControllerUpdateById(id: number, repuestosPartial?: RepuestosPartial, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.repuestosControllerUpdateById(id, repuestosPartial, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * RepuestosControllerApi - factory interface
 * @export
 */
export const RepuestosControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RepuestosControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        repuestosControllerCount(where?: { [key: string]: object; }, options?: any): AxiosPromise<LoopbackCount> {
            return localVarFp.repuestosControllerCount(where, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {NewRepuestos} [newRepuestos] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        repuestosControllerCreate(newRepuestos?: NewRepuestos, options?: any): AxiosPromise<Repuestos> {
            return localVarFp.repuestosControllerCreate(newRepuestos, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {string} skuPrincipal 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        repuestosControllerDeleteById(id: number, skuPrincipal: string, options?: any): AxiosPromise<any> {
            return localVarFp.repuestosControllerDeleteById(id, skuPrincipal, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RepuestosFilter1} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        repuestosControllerFind(filter?: RepuestosFilter1, options?: any): AxiosPromise<Array<RepuestosWithRelations>> {
            return localVarFp.repuestosControllerFind(filter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {RepuestosFilter} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        repuestosControllerFindById(id: number, filter?: RepuestosFilter, options?: any): AxiosPromise<RepuestosWithRelations> {
            return localVarFp.repuestosControllerFindById(id, filter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {Repuestos} [repuestos] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        repuestosControllerReplaceById(id: number, repuestos?: Repuestos, options?: any): AxiosPromise<any> {
            return localVarFp.repuestosControllerReplaceById(id, repuestos, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {RepuestosPartial} [repuestosPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        repuestosControllerUpdateAll(where?: { [key: string]: object; }, repuestosPartial?: RepuestosPartial, options?: any): AxiosPromise<LoopbackCount> {
            return localVarFp.repuestosControllerUpdateAll(where, repuestosPartial, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {RepuestosPartial} [repuestosPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        repuestosControllerUpdateById(id: number, repuestosPartial?: RepuestosPartial, options?: any): AxiosPromise<any> {
            return localVarFp.repuestosControllerUpdateById(id, repuestosPartial, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * RepuestosControllerApi - object-oriented interface
 * @export
 * @class RepuestosControllerApi
 * @extends {BaseAPI}
 */
export class RepuestosControllerApi extends BaseAPI {
    /**
     * 
     * @param {{ [key: string]: object; }} [where] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RepuestosControllerApi
     */
    public repuestosControllerCount(where?: { [key: string]: object; }, options?: AxiosRequestConfig) {
        return RepuestosControllerApiFp(this.configuration).repuestosControllerCount(where, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {NewRepuestos} [newRepuestos] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RepuestosControllerApi
     */
    public repuestosControllerCreate(newRepuestos?: NewRepuestos, options?: AxiosRequestConfig) {
        return RepuestosControllerApiFp(this.configuration).repuestosControllerCreate(newRepuestos, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {string} skuPrincipal 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RepuestosControllerApi
     */
    public repuestosControllerDeleteById(id: number, skuPrincipal: string, options?: AxiosRequestConfig) {
        return RepuestosControllerApiFp(this.configuration).repuestosControllerDeleteById(id, skuPrincipal, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RepuestosFilter1} [filter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RepuestosControllerApi
     */
    public repuestosControllerFind(filter?: RepuestosFilter1, options?: AxiosRequestConfig) {
        return RepuestosControllerApiFp(this.configuration).repuestosControllerFind(filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {RepuestosFilter} [filter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RepuestosControllerApi
     */
    public repuestosControllerFindById(id: number, filter?: RepuestosFilter, options?: AxiosRequestConfig) {
        return RepuestosControllerApiFp(this.configuration).repuestosControllerFindById(id, filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {Repuestos} [repuestos] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RepuestosControllerApi
     */
    public repuestosControllerReplaceById(id: number, repuestos?: Repuestos, options?: AxiosRequestConfig) {
        return RepuestosControllerApiFp(this.configuration).repuestosControllerReplaceById(id, repuestos, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} [where] 
     * @param {RepuestosPartial} [repuestosPartial] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RepuestosControllerApi
     */
    public repuestosControllerUpdateAll(where?: { [key: string]: object; }, repuestosPartial?: RepuestosPartial, options?: AxiosRequestConfig) {
        return RepuestosControllerApiFp(this.configuration).repuestosControllerUpdateAll(where, repuestosPartial, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {RepuestosPartial} [repuestosPartial] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RepuestosControllerApi
     */
    public repuestosControllerUpdateById(id: number, repuestosPartial?: RepuestosPartial, options?: AxiosRequestConfig) {
        return RepuestosControllerApiFp(this.configuration).repuestosControllerUpdateById(id, repuestosPartial, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * RolesControllerApi - axios parameter creator
 * @export
 */
export const RolesControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rolesControllerCount: async (where?: { [key: string]: object; }, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/roles/count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (where !== undefined) {
                localVarQueryParameter['where'] = where;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {NewRoles} [newRoles] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rolesControllerCreate: async (newRoles?: NewRoles, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/roles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(newRoles, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rolesControllerDeleteById: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('rolesControllerDeleteById', 'id', id)
            const localVarPath = `/roles/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {RolesFilter1} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rolesControllerFind: async (filter?: RolesFilter1, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/roles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {RolesFilter} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rolesControllerFindById: async (id: number, filter?: RolesFilter, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('rolesControllerFindById', 'id', id)
            const localVarPath = `/roles/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {Roles} [roles] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rolesControllerReplaceById: async (id: number, roles?: Roles, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('rolesControllerReplaceById', 'id', id)
            const localVarPath = `/roles/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(roles, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {RolesPartial} [rolesPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rolesControllerUpdateAll: async (where?: { [key: string]: object; }, rolesPartial?: RolesPartial, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/roles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (where !== undefined) {
                localVarQueryParameter['where'] = where;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(rolesPartial, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {RolesPartial} [rolesPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rolesControllerUpdateById: async (id: number, rolesPartial?: RolesPartial, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('rolesControllerUpdateById', 'id', id)
            const localVarPath = `/roles/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(rolesPartial, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RolesControllerApi - functional programming interface
 * @export
 */
export const RolesControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RolesControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rolesControllerCount(where?: { [key: string]: object; }, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoopbackCount>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rolesControllerCount(where, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {NewRoles} [newRoles] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rolesControllerCreate(newRoles?: NewRoles, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Roles>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rolesControllerCreate(newRoles, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rolesControllerDeleteById(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rolesControllerDeleteById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {RolesFilter1} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rolesControllerFind(filter?: RolesFilter1, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RolesWithRelations>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rolesControllerFind(filter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {RolesFilter} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rolesControllerFindById(id: number, filter?: RolesFilter, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RolesWithRelations>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rolesControllerFindById(id, filter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {Roles} [roles] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rolesControllerReplaceById(id: number, roles?: Roles, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rolesControllerReplaceById(id, roles, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {RolesPartial} [rolesPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rolesControllerUpdateAll(where?: { [key: string]: object; }, rolesPartial?: RolesPartial, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoopbackCount>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rolesControllerUpdateAll(where, rolesPartial, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {RolesPartial} [rolesPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rolesControllerUpdateById(id: number, rolesPartial?: RolesPartial, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rolesControllerUpdateById(id, rolesPartial, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * RolesControllerApi - factory interface
 * @export
 */
export const RolesControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RolesControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rolesControllerCount(where?: { [key: string]: object; }, options?: any): AxiosPromise<LoopbackCount> {
            return localVarFp.rolesControllerCount(where, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {NewRoles} [newRoles] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rolesControllerCreate(newRoles?: NewRoles, options?: any): AxiosPromise<Roles> {
            return localVarFp.rolesControllerCreate(newRoles, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rolesControllerDeleteById(id: number, options?: any): AxiosPromise<any> {
            return localVarFp.rolesControllerDeleteById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RolesFilter1} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rolesControllerFind(filter?: RolesFilter1, options?: any): AxiosPromise<Array<RolesWithRelations>> {
            return localVarFp.rolesControllerFind(filter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {RolesFilter} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rolesControllerFindById(id: number, filter?: RolesFilter, options?: any): AxiosPromise<RolesWithRelations> {
            return localVarFp.rolesControllerFindById(id, filter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {Roles} [roles] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rolesControllerReplaceById(id: number, roles?: Roles, options?: any): AxiosPromise<any> {
            return localVarFp.rolesControllerReplaceById(id, roles, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {RolesPartial} [rolesPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rolesControllerUpdateAll(where?: { [key: string]: object; }, rolesPartial?: RolesPartial, options?: any): AxiosPromise<LoopbackCount> {
            return localVarFp.rolesControllerUpdateAll(where, rolesPartial, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {RolesPartial} [rolesPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rolesControllerUpdateById(id: number, rolesPartial?: RolesPartial, options?: any): AxiosPromise<any> {
            return localVarFp.rolesControllerUpdateById(id, rolesPartial, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * RolesControllerApi - object-oriented interface
 * @export
 * @class RolesControllerApi
 * @extends {BaseAPI}
 */
export class RolesControllerApi extends BaseAPI {
    /**
     * 
     * @param {{ [key: string]: object; }} [where] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RolesControllerApi
     */
    public rolesControllerCount(where?: { [key: string]: object; }, options?: AxiosRequestConfig) {
        return RolesControllerApiFp(this.configuration).rolesControllerCount(where, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {NewRoles} [newRoles] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RolesControllerApi
     */
    public rolesControllerCreate(newRoles?: NewRoles, options?: AxiosRequestConfig) {
        return RolesControllerApiFp(this.configuration).rolesControllerCreate(newRoles, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RolesControllerApi
     */
    public rolesControllerDeleteById(id: number, options?: AxiosRequestConfig) {
        return RolesControllerApiFp(this.configuration).rolesControllerDeleteById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RolesFilter1} [filter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RolesControllerApi
     */
    public rolesControllerFind(filter?: RolesFilter1, options?: AxiosRequestConfig) {
        return RolesControllerApiFp(this.configuration).rolesControllerFind(filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {RolesFilter} [filter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RolesControllerApi
     */
    public rolesControllerFindById(id: number, filter?: RolesFilter, options?: AxiosRequestConfig) {
        return RolesControllerApiFp(this.configuration).rolesControllerFindById(id, filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {Roles} [roles] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RolesControllerApi
     */
    public rolesControllerReplaceById(id: number, roles?: Roles, options?: AxiosRequestConfig) {
        return RolesControllerApiFp(this.configuration).rolesControllerReplaceById(id, roles, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} [where] 
     * @param {RolesPartial} [rolesPartial] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RolesControllerApi
     */
    public rolesControllerUpdateAll(where?: { [key: string]: object; }, rolesPartial?: RolesPartial, options?: AxiosRequestConfig) {
        return RolesControllerApiFp(this.configuration).rolesControllerUpdateAll(where, rolesPartial, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {RolesPartial} [rolesPartial] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RolesControllerApi
     */
    public rolesControllerUpdateById(id: number, rolesPartial?: RolesPartial, options?: AxiosRequestConfig) {
        return RolesControllerApiFp(this.configuration).rolesControllerUpdateById(id, rolesPartial, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SincronizacionBcControllerApi - axios parameter creator
 * @export
 */
export const SincronizacionBcControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sincronizacionBcControllerSincronizaEstadoReferencia: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/sincronizaEstadoReferencia`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sincronizacionBcControllerSincronizaEstadoReferenciaManual: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/sincronizaEstadoReferenciaManual`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sincronizacionBcControllerSincronizaProductos: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/sincronizaProductos`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [idProducto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sincronizacionBcControllerSincronizaProductosManual: async (idProducto?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/sincronizaProductosManual`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (idProducto !== undefined) {
                localVarQueryParameter['idProducto'] = idProducto;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SincronizacionBcControllerApi - functional programming interface
 * @export
 */
export const SincronizacionBcControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SincronizacionBcControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sincronizacionBcControllerSincronizaEstadoReferencia(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sincronizacionBcControllerSincronizaEstadoReferencia(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sincronizacionBcControllerSincronizaEstadoReferenciaManual(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sincronizacionBcControllerSincronizaEstadoReferenciaManual(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sincronizacionBcControllerSincronizaProductos(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sincronizacionBcControllerSincronizaProductos(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [idProducto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sincronizacionBcControllerSincronizaProductosManual(idProducto?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sincronizacionBcControllerSincronizaProductosManual(idProducto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SincronizacionBcControllerApi - factory interface
 * @export
 */
export const SincronizacionBcControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SincronizacionBcControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sincronizacionBcControllerSincronizaEstadoReferencia(options?: any): AxiosPromise<object> {
            return localVarFp.sincronizacionBcControllerSincronizaEstadoReferencia(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sincronizacionBcControllerSincronizaEstadoReferenciaManual(options?: any): AxiosPromise<object> {
            return localVarFp.sincronizacionBcControllerSincronizaEstadoReferenciaManual(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sincronizacionBcControllerSincronizaProductos(options?: any): AxiosPromise<object> {
            return localVarFp.sincronizacionBcControllerSincronizaProductos(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [idProducto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sincronizacionBcControllerSincronizaProductosManual(idProducto?: string, options?: any): AxiosPromise<object> {
            return localVarFp.sincronizacionBcControllerSincronizaProductosManual(idProducto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SincronizacionBcControllerApi - object-oriented interface
 * @export
 * @class SincronizacionBcControllerApi
 * @extends {BaseAPI}
 */
export class SincronizacionBcControllerApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SincronizacionBcControllerApi
     */
    public sincronizacionBcControllerSincronizaEstadoReferencia(options?: AxiosRequestConfig) {
        return SincronizacionBcControllerApiFp(this.configuration).sincronizacionBcControllerSincronizaEstadoReferencia(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SincronizacionBcControllerApi
     */
    public sincronizacionBcControllerSincronizaEstadoReferenciaManual(options?: AxiosRequestConfig) {
        return SincronizacionBcControllerApiFp(this.configuration).sincronizacionBcControllerSincronizaEstadoReferenciaManual(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SincronizacionBcControllerApi
     */
    public sincronizacionBcControllerSincronizaProductos(options?: AxiosRequestConfig) {
        return SincronizacionBcControllerApiFp(this.configuration).sincronizacionBcControllerSincronizaProductos(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [idProducto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SincronizacionBcControllerApi
     */
    public sincronizacionBcControllerSincronizaProductosManual(idProducto?: string, options?: AxiosRequestConfig) {
        return SincronizacionBcControllerApiFp(this.configuration).sincronizacionBcControllerSincronizaProductosManual(idProducto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SincronizacionBcclubsControllerApi - axios parameter creator
 * @export
 */
export const SincronizacionBcclubsControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sincronizacionBcclubsControllerDescargaClubsDeBC: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/descargaClubsDeBC`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sincronizacionBcclubsControllerDescargaClubsDeBCManual: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/descargaClubsDeBCManual`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SincronizacionBcclubsControllerApi - functional programming interface
 * @export
 */
export const SincronizacionBcclubsControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SincronizacionBcclubsControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sincronizacionBcclubsControllerDescargaClubsDeBC(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sincronizacionBcclubsControllerDescargaClubsDeBC(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sincronizacionBcclubsControllerDescargaClubsDeBCManual(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sincronizacionBcclubsControllerDescargaClubsDeBCManual(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SincronizacionBcclubsControllerApi - factory interface
 * @export
 */
export const SincronizacionBcclubsControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SincronizacionBcclubsControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sincronizacionBcclubsControllerDescargaClubsDeBC(options?: any): AxiosPromise<object> {
            return localVarFp.sincronizacionBcclubsControllerDescargaClubsDeBC(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sincronizacionBcclubsControllerDescargaClubsDeBCManual(options?: any): AxiosPromise<object> {
            return localVarFp.sincronizacionBcclubsControllerDescargaClubsDeBCManual(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SincronizacionBcclubsControllerApi - object-oriented interface
 * @export
 * @class SincronizacionBcclubsControllerApi
 * @extends {BaseAPI}
 */
export class SincronizacionBcclubsControllerApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SincronizacionBcclubsControllerApi
     */
    public sincronizacionBcclubsControllerDescargaClubsDeBC(options?: AxiosRequestConfig) {
        return SincronizacionBcclubsControllerApiFp(this.configuration).sincronizacionBcclubsControllerDescargaClubsDeBC(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SincronizacionBcclubsControllerApi
     */
    public sincronizacionBcclubsControllerDescargaClubsDeBCManual(options?: AxiosRequestConfig) {
        return SincronizacionBcclubsControllerApiFp(this.configuration).sincronizacionBcclubsControllerDescargaClubsDeBCManual(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SincronizacionBcensambladosControllerApi - axios parameter creator
 * @export
 */
export const SincronizacionBcensambladosControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sincronizacionBcensambladosControllerSincronizaEnsamblados: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/sincronizaEnsamblados`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sincronizacionBcensambladosControllerSincronizaEnsambladosManual: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/sincronizaEnsambladosManual`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SincronizacionBcensambladosControllerApi - functional programming interface
 * @export
 */
export const SincronizacionBcensambladosControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SincronizacionBcensambladosControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sincronizacionBcensambladosControllerSincronizaEnsamblados(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sincronizacionBcensambladosControllerSincronizaEnsamblados(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sincronizacionBcensambladosControllerSincronizaEnsambladosManual(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sincronizacionBcensambladosControllerSincronizaEnsambladosManual(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SincronizacionBcensambladosControllerApi - factory interface
 * @export
 */
export const SincronizacionBcensambladosControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SincronizacionBcensambladosControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sincronizacionBcensambladosControllerSincronizaEnsamblados(options?: any): AxiosPromise<object> {
            return localVarFp.sincronizacionBcensambladosControllerSincronizaEnsamblados(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sincronizacionBcensambladosControllerSincronizaEnsambladosManual(options?: any): AxiosPromise<object> {
            return localVarFp.sincronizacionBcensambladosControllerSincronizaEnsambladosManual(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SincronizacionBcensambladosControllerApi - object-oriented interface
 * @export
 * @class SincronizacionBcensambladosControllerApi
 * @extends {BaseAPI}
 */
export class SincronizacionBcensambladosControllerApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SincronizacionBcensambladosControllerApi
     */
    public sincronizacionBcensambladosControllerSincronizaEnsamblados(options?: AxiosRequestConfig) {
        return SincronizacionBcensambladosControllerApiFp(this.configuration).sincronizacionBcensambladosControllerSincronizaEnsamblados(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SincronizacionBcensambladosControllerApi
     */
    public sincronizacionBcensambladosControllerSincronizaEnsambladosManual(options?: AxiosRequestConfig) {
        return SincronizacionBcensambladosControllerApiFp(this.configuration).sincronizacionBcensambladosControllerSincronizaEnsambladosManual(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SincronizacionBcrepuestosControllerApi - axios parameter creator
 * @export
 */
export const SincronizacionBcrepuestosControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sincronizacionBcrepuestosControllerSincronizaRepuestos: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/sincronizaRepuestos`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sincronizacionBcrepuestosControllerSincronizaRepuestosManual: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/sincronizaRepuestosManual`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SincronizacionBcrepuestosControllerApi - functional programming interface
 * @export
 */
export const SincronizacionBcrepuestosControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SincronizacionBcrepuestosControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sincronizacionBcrepuestosControllerSincronizaRepuestos(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sincronizacionBcrepuestosControllerSincronizaRepuestos(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sincronizacionBcrepuestosControllerSincronizaRepuestosManual(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sincronizacionBcrepuestosControllerSincronizaRepuestosManual(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SincronizacionBcrepuestosControllerApi - factory interface
 * @export
 */
export const SincronizacionBcrepuestosControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SincronizacionBcrepuestosControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sincronizacionBcrepuestosControllerSincronizaRepuestos(options?: any): AxiosPromise<object> {
            return localVarFp.sincronizacionBcrepuestosControllerSincronizaRepuestos(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sincronizacionBcrepuestosControllerSincronizaRepuestosManual(options?: any): AxiosPromise<object> {
            return localVarFp.sincronizacionBcrepuestosControllerSincronizaRepuestosManual(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SincronizacionBcrepuestosControllerApi - object-oriented interface
 * @export
 * @class SincronizacionBcrepuestosControllerApi
 * @extends {BaseAPI}
 */
export class SincronizacionBcrepuestosControllerApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SincronizacionBcrepuestosControllerApi
     */
    public sincronizacionBcrepuestosControllerSincronizaRepuestos(options?: AxiosRequestConfig) {
        return SincronizacionBcrepuestosControllerApiFp(this.configuration).sincronizacionBcrepuestosControllerSincronizaRepuestos(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SincronizacionBcrepuestosControllerApi
     */
    public sincronizacionBcrepuestosControllerSincronizaRepuestosManual(options?: AxiosRequestConfig) {
        return SincronizacionBcrepuestosControllerApiFp(this.configuration).sincronizacionBcrepuestosControllerSincronizaRepuestosManual(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SincronizacionBcsustitutosControllerApi - axios parameter creator
 * @export
 */
export const SincronizacionBcsustitutosControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sincronizacionBcsustitutosControllerSincronizaSustitutos: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/sincronizaSustitutos`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sincronizacionBcsustitutosControllerSincronizaSustitutosManual: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/sincronizaSustitutosManual`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SincronizacionBcsustitutosControllerApi - functional programming interface
 * @export
 */
export const SincronizacionBcsustitutosControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SincronizacionBcsustitutosControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sincronizacionBcsustitutosControllerSincronizaSustitutos(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sincronizacionBcsustitutosControllerSincronizaSustitutos(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sincronizacionBcsustitutosControllerSincronizaSustitutosManual(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sincronizacionBcsustitutosControllerSincronizaSustitutosManual(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SincronizacionBcsustitutosControllerApi - factory interface
 * @export
 */
export const SincronizacionBcsustitutosControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SincronizacionBcsustitutosControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sincronizacionBcsustitutosControllerSincronizaSustitutos(options?: any): AxiosPromise<object> {
            return localVarFp.sincronizacionBcsustitutosControllerSincronizaSustitutos(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sincronizacionBcsustitutosControllerSincronizaSustitutosManual(options?: any): AxiosPromise<object> {
            return localVarFp.sincronizacionBcsustitutosControllerSincronizaSustitutosManual(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SincronizacionBcsustitutosControllerApi - object-oriented interface
 * @export
 * @class SincronizacionBcsustitutosControllerApi
 * @extends {BaseAPI}
 */
export class SincronizacionBcsustitutosControllerApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SincronizacionBcsustitutosControllerApi
     */
    public sincronizacionBcsustitutosControllerSincronizaSustitutos(options?: AxiosRequestConfig) {
        return SincronizacionBcsustitutosControllerApiFp(this.configuration).sincronizacionBcsustitutosControllerSincronizaSustitutos(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SincronizacionBcsustitutosControllerApi
     */
    public sincronizacionBcsustitutosControllerSincronizaSustitutosManual(options?: AxiosRequestConfig) {
        return SincronizacionBcsustitutosControllerApiFp(this.configuration).sincronizacionBcsustitutosControllerSincronizaSustitutosManual(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SituacionesControllerApi - axios parameter creator
 * @export
 */
export const SituacionesControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        situacionesControllerCount: async (where?: { [key: string]: object; }, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Situaciones/count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (where !== undefined) {
                localVarQueryParameter['where'] = where;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {NewSituaciones} [newSituaciones] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        situacionesControllerCreate: async (newSituaciones?: NewSituaciones, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Situaciones`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(newSituaciones, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        situacionesControllerDeleteById: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('situacionesControllerDeleteById', 'id', id)
            const localVarPath = `/Situaciones/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SituacionesFilter1} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        situacionesControllerFind: async (filter?: SituacionesFilter1, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Situaciones`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {SituacionesFilter} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        situacionesControllerFindById: async (id: number, filter?: SituacionesFilter, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('situacionesControllerFindById', 'id', id)
            const localVarPath = `/Situaciones/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {Situaciones} [situaciones] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        situacionesControllerReplaceById: async (id: number, situaciones?: Situaciones, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('situacionesControllerReplaceById', 'id', id)
            const localVarPath = `/Situaciones/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(situaciones, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {SituacionesPartial} [situacionesPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        situacionesControllerUpdateAll: async (where?: { [key: string]: object; }, situacionesPartial?: SituacionesPartial, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Situaciones`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (where !== undefined) {
                localVarQueryParameter['where'] = where;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(situacionesPartial, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {SituacionesPartial} [situacionesPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        situacionesControllerUpdateById: async (id: number, situacionesPartial?: SituacionesPartial, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('situacionesControllerUpdateById', 'id', id)
            const localVarPath = `/Situaciones/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(situacionesPartial, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SituacionesControllerApi - functional programming interface
 * @export
 */
export const SituacionesControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SituacionesControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async situacionesControllerCount(where?: { [key: string]: object; }, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoopbackCount>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.situacionesControllerCount(where, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {NewSituaciones} [newSituaciones] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async situacionesControllerCreate(newSituaciones?: NewSituaciones, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Situaciones>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.situacionesControllerCreate(newSituaciones, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async situacionesControllerDeleteById(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.situacionesControllerDeleteById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {SituacionesFilter1} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async situacionesControllerFind(filter?: SituacionesFilter1, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SituacionesWithRelations>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.situacionesControllerFind(filter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {SituacionesFilter} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async situacionesControllerFindById(id: number, filter?: SituacionesFilter, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SituacionesWithRelations>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.situacionesControllerFindById(id, filter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {Situaciones} [situaciones] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async situacionesControllerReplaceById(id: number, situaciones?: Situaciones, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.situacionesControllerReplaceById(id, situaciones, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {SituacionesPartial} [situacionesPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async situacionesControllerUpdateAll(where?: { [key: string]: object; }, situacionesPartial?: SituacionesPartial, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoopbackCount>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.situacionesControllerUpdateAll(where, situacionesPartial, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {SituacionesPartial} [situacionesPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async situacionesControllerUpdateById(id: number, situacionesPartial?: SituacionesPartial, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.situacionesControllerUpdateById(id, situacionesPartial, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SituacionesControllerApi - factory interface
 * @export
 */
export const SituacionesControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SituacionesControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        situacionesControllerCount(where?: { [key: string]: object; }, options?: any): AxiosPromise<LoopbackCount> {
            return localVarFp.situacionesControllerCount(where, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {NewSituaciones} [newSituaciones] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        situacionesControllerCreate(newSituaciones?: NewSituaciones, options?: any): AxiosPromise<Situaciones> {
            return localVarFp.situacionesControllerCreate(newSituaciones, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        situacionesControllerDeleteById(id: number, options?: any): AxiosPromise<any> {
            return localVarFp.situacionesControllerDeleteById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SituacionesFilter1} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        situacionesControllerFind(filter?: SituacionesFilter1, options?: any): AxiosPromise<Array<SituacionesWithRelations>> {
            return localVarFp.situacionesControllerFind(filter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {SituacionesFilter} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        situacionesControllerFindById(id: number, filter?: SituacionesFilter, options?: any): AxiosPromise<SituacionesWithRelations> {
            return localVarFp.situacionesControllerFindById(id, filter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {Situaciones} [situaciones] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        situacionesControllerReplaceById(id: number, situaciones?: Situaciones, options?: any): AxiosPromise<any> {
            return localVarFp.situacionesControllerReplaceById(id, situaciones, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {SituacionesPartial} [situacionesPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        situacionesControllerUpdateAll(where?: { [key: string]: object; }, situacionesPartial?: SituacionesPartial, options?: any): AxiosPromise<LoopbackCount> {
            return localVarFp.situacionesControllerUpdateAll(where, situacionesPartial, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {SituacionesPartial} [situacionesPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        situacionesControllerUpdateById(id: number, situacionesPartial?: SituacionesPartial, options?: any): AxiosPromise<any> {
            return localVarFp.situacionesControllerUpdateById(id, situacionesPartial, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SituacionesControllerApi - object-oriented interface
 * @export
 * @class SituacionesControllerApi
 * @extends {BaseAPI}
 */
export class SituacionesControllerApi extends BaseAPI {
    /**
     * 
     * @param {{ [key: string]: object; }} [where] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SituacionesControllerApi
     */
    public situacionesControllerCount(where?: { [key: string]: object; }, options?: AxiosRequestConfig) {
        return SituacionesControllerApiFp(this.configuration).situacionesControllerCount(where, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {NewSituaciones} [newSituaciones] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SituacionesControllerApi
     */
    public situacionesControllerCreate(newSituaciones?: NewSituaciones, options?: AxiosRequestConfig) {
        return SituacionesControllerApiFp(this.configuration).situacionesControllerCreate(newSituaciones, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SituacionesControllerApi
     */
    public situacionesControllerDeleteById(id: number, options?: AxiosRequestConfig) {
        return SituacionesControllerApiFp(this.configuration).situacionesControllerDeleteById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SituacionesFilter1} [filter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SituacionesControllerApi
     */
    public situacionesControllerFind(filter?: SituacionesFilter1, options?: AxiosRequestConfig) {
        return SituacionesControllerApiFp(this.configuration).situacionesControllerFind(filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {SituacionesFilter} [filter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SituacionesControllerApi
     */
    public situacionesControllerFindById(id: number, filter?: SituacionesFilter, options?: AxiosRequestConfig) {
        return SituacionesControllerApiFp(this.configuration).situacionesControllerFindById(id, filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {Situaciones} [situaciones] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SituacionesControllerApi
     */
    public situacionesControllerReplaceById(id: number, situaciones?: Situaciones, options?: AxiosRequestConfig) {
        return SituacionesControllerApiFp(this.configuration).situacionesControllerReplaceById(id, situaciones, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} [where] 
     * @param {SituacionesPartial} [situacionesPartial] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SituacionesControllerApi
     */
    public situacionesControllerUpdateAll(where?: { [key: string]: object; }, situacionesPartial?: SituacionesPartial, options?: AxiosRequestConfig) {
        return SituacionesControllerApiFp(this.configuration).situacionesControllerUpdateAll(where, situacionesPartial, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {SituacionesPartial} [situacionesPartial] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SituacionesControllerApi
     */
    public situacionesControllerUpdateById(id: number, situacionesPartial?: SituacionesPartial, options?: AxiosRequestConfig) {
        return SituacionesControllerApiFp(this.configuration).situacionesControllerUpdateById(id, situacionesPartial, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SustitutosControllerApi - axios parameter creator
 * @export
 */
export const SustitutosControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sustitutosControllerCount: async (where?: { [key: string]: object; }, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Sustitutos/count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (where !== undefined) {
                localVarQueryParameter['where'] = where;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {NewSustitutos} [newSustitutos] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sustitutosControllerCreate: async (newSustitutos?: NewSustitutos, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Sustitutos`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(newSustitutos, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {string} skuPrincipal 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sustitutosControllerDeleteById: async (id: number, skuPrincipal: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('sustitutosControllerDeleteById', 'id', id)
            // verify required parameter 'skuPrincipal' is not null or undefined
            assertParamExists('sustitutosControllerDeleteById', 'skuPrincipal', skuPrincipal)
            const localVarPath = `/Sustitutos/{id}/{skuPrincipal}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"skuPrincipal"}}`, encodeURIComponent(String(skuPrincipal)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SustitutosFilter1} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sustitutosControllerFind: async (filter?: SustitutosFilter1, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Sustitutos`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {SustitutosFilter} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sustitutosControllerFindById: async (id: number, filter?: SustitutosFilter, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('sustitutosControllerFindById', 'id', id)
            const localVarPath = `/Sustitutos/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sustitutosControllerObtenerSkuPrincipales: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/obtenerSkuPrincipales`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {Sustitutos} [sustitutos] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sustitutosControllerReplaceById: async (id: number, sustitutos?: Sustitutos, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('sustitutosControllerReplaceById', 'id', id)
            const localVarPath = `/Sustitutos/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(sustitutos, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {SustitutosPartial} [sustitutosPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sustitutosControllerUpdateAll: async (where?: { [key: string]: object; }, sustitutosPartial?: SustitutosPartial, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Sustitutos`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (where !== undefined) {
                localVarQueryParameter['where'] = where;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(sustitutosPartial, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {SustitutosPartial} [sustitutosPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sustitutosControllerUpdateById: async (id: number, sustitutosPartial?: SustitutosPartial, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('sustitutosControllerUpdateById', 'id', id)
            const localVarPath = `/Sustitutos/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(sustitutosPartial, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SustitutosControllerApi - functional programming interface
 * @export
 */
export const SustitutosControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SustitutosControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sustitutosControllerCount(where?: { [key: string]: object; }, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoopbackCount>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sustitutosControllerCount(where, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {NewSustitutos} [newSustitutos] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sustitutosControllerCreate(newSustitutos?: NewSustitutos, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Sustitutos>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sustitutosControllerCreate(newSustitutos, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {string} skuPrincipal 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sustitutosControllerDeleteById(id: number, skuPrincipal: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sustitutosControllerDeleteById(id, skuPrincipal, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {SustitutosFilter1} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sustitutosControllerFind(filter?: SustitutosFilter1, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SustitutosWithRelations>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sustitutosControllerFind(filter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {SustitutosFilter} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sustitutosControllerFindById(id: number, filter?: SustitutosFilter, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SustitutosWithRelations>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sustitutosControllerFindById(id, filter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sustitutosControllerObtenerSkuPrincipales(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sustitutosControllerObtenerSkuPrincipales(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {Sustitutos} [sustitutos] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sustitutosControllerReplaceById(id: number, sustitutos?: Sustitutos, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sustitutosControllerReplaceById(id, sustitutos, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {SustitutosPartial} [sustitutosPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sustitutosControllerUpdateAll(where?: { [key: string]: object; }, sustitutosPartial?: SustitutosPartial, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoopbackCount>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sustitutosControllerUpdateAll(where, sustitutosPartial, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {SustitutosPartial} [sustitutosPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sustitutosControllerUpdateById(id: number, sustitutosPartial?: SustitutosPartial, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sustitutosControllerUpdateById(id, sustitutosPartial, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SustitutosControllerApi - factory interface
 * @export
 */
export const SustitutosControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SustitutosControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sustitutosControllerCount(where?: { [key: string]: object; }, options?: any): AxiosPromise<LoopbackCount> {
            return localVarFp.sustitutosControllerCount(where, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {NewSustitutos} [newSustitutos] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sustitutosControllerCreate(newSustitutos?: NewSustitutos, options?: any): AxiosPromise<Sustitutos> {
            return localVarFp.sustitutosControllerCreate(newSustitutos, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {string} skuPrincipal 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sustitutosControllerDeleteById(id: number, skuPrincipal: string, options?: any): AxiosPromise<any> {
            return localVarFp.sustitutosControllerDeleteById(id, skuPrincipal, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SustitutosFilter1} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sustitutosControllerFind(filter?: SustitutosFilter1, options?: any): AxiosPromise<Array<SustitutosWithRelations>> {
            return localVarFp.sustitutosControllerFind(filter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {SustitutosFilter} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sustitutosControllerFindById(id: number, filter?: SustitutosFilter, options?: any): AxiosPromise<SustitutosWithRelations> {
            return localVarFp.sustitutosControllerFindById(id, filter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sustitutosControllerObtenerSkuPrincipales(options?: any): AxiosPromise<object> {
            return localVarFp.sustitutosControllerObtenerSkuPrincipales(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {Sustitutos} [sustitutos] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sustitutosControllerReplaceById(id: number, sustitutos?: Sustitutos, options?: any): AxiosPromise<any> {
            return localVarFp.sustitutosControllerReplaceById(id, sustitutos, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {SustitutosPartial} [sustitutosPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sustitutosControllerUpdateAll(where?: { [key: string]: object; }, sustitutosPartial?: SustitutosPartial, options?: any): AxiosPromise<LoopbackCount> {
            return localVarFp.sustitutosControllerUpdateAll(where, sustitutosPartial, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {SustitutosPartial} [sustitutosPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sustitutosControllerUpdateById(id: number, sustitutosPartial?: SustitutosPartial, options?: any): AxiosPromise<any> {
            return localVarFp.sustitutosControllerUpdateById(id, sustitutosPartial, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SustitutosControllerApi - object-oriented interface
 * @export
 * @class SustitutosControllerApi
 * @extends {BaseAPI}
 */
export class SustitutosControllerApi extends BaseAPI {
    /**
     * 
     * @param {{ [key: string]: object; }} [where] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SustitutosControllerApi
     */
    public sustitutosControllerCount(where?: { [key: string]: object; }, options?: AxiosRequestConfig) {
        return SustitutosControllerApiFp(this.configuration).sustitutosControllerCount(where, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {NewSustitutos} [newSustitutos] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SustitutosControllerApi
     */
    public sustitutosControllerCreate(newSustitutos?: NewSustitutos, options?: AxiosRequestConfig) {
        return SustitutosControllerApiFp(this.configuration).sustitutosControllerCreate(newSustitutos, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {string} skuPrincipal 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SustitutosControllerApi
     */
    public sustitutosControllerDeleteById(id: number, skuPrincipal: string, options?: AxiosRequestConfig) {
        return SustitutosControllerApiFp(this.configuration).sustitutosControllerDeleteById(id, skuPrincipal, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SustitutosFilter1} [filter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SustitutosControllerApi
     */
    public sustitutosControllerFind(filter?: SustitutosFilter1, options?: AxiosRequestConfig) {
        return SustitutosControllerApiFp(this.configuration).sustitutosControllerFind(filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {SustitutosFilter} [filter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SustitutosControllerApi
     */
    public sustitutosControllerFindById(id: number, filter?: SustitutosFilter, options?: AxiosRequestConfig) {
        return SustitutosControllerApiFp(this.configuration).sustitutosControllerFindById(id, filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SustitutosControllerApi
     */
    public sustitutosControllerObtenerSkuPrincipales(options?: AxiosRequestConfig) {
        return SustitutosControllerApiFp(this.configuration).sustitutosControllerObtenerSkuPrincipales(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {Sustitutos} [sustitutos] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SustitutosControllerApi
     */
    public sustitutosControllerReplaceById(id: number, sustitutos?: Sustitutos, options?: AxiosRequestConfig) {
        return SustitutosControllerApiFp(this.configuration).sustitutosControllerReplaceById(id, sustitutos, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} [where] 
     * @param {SustitutosPartial} [sustitutosPartial] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SustitutosControllerApi
     */
    public sustitutosControllerUpdateAll(where?: { [key: string]: object; }, sustitutosPartial?: SustitutosPartial, options?: AxiosRequestConfig) {
        return SustitutosControllerApiFp(this.configuration).sustitutosControllerUpdateAll(where, sustitutosPartial, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {SustitutosPartial} [sustitutosPartial] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SustitutosControllerApi
     */
    public sustitutosControllerUpdateById(id: number, sustitutosPartial?: SustitutosPartial, options?: AxiosRequestConfig) {
        return SustitutosControllerApiFp(this.configuration).sustitutosControllerUpdateById(id, sustitutosPartial, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TablasControllerApi - axios parameter creator
 * @export
 */
export const TablasControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tablasControllerCount: async (where?: { [key: string]: object; }, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Tablas/count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (where !== undefined) {
                localVarQueryParameter['where'] = where;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {NewTablas} [newTablas] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tablasControllerCreate: async (newTablas?: NewTablas, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Tablas`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(newTablas, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tablasControllerDeleteById: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('tablasControllerDeleteById', 'id', id)
            const localVarPath = `/Tablas/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {TablasFilter1} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tablasControllerFind: async (filter?: TablasFilter1, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Tablas`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {TablasFilter} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tablasControllerFindById: async (id: number, filter?: TablasFilter, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('tablasControllerFindById', 'id', id)
            const localVarPath = `/Tablas/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {Tablas} [tablas] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tablasControllerReplaceById: async (id: number, tablas?: Tablas, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('tablasControllerReplaceById', 'id', id)
            const localVarPath = `/Tablas/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(tablas, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {TablasPartial} [tablasPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tablasControllerUpdateAll: async (where?: { [key: string]: object; }, tablasPartial?: TablasPartial, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Tablas`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (where !== undefined) {
                localVarQueryParameter['where'] = where;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(tablasPartial, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {TablasPartial} [tablasPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tablasControllerUpdateById: async (id: number, tablasPartial?: TablasPartial, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('tablasControllerUpdateById', 'id', id)
            const localVarPath = `/Tablas/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(tablasPartial, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TablasControllerApi - functional programming interface
 * @export
 */
export const TablasControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TablasControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tablasControllerCount(where?: { [key: string]: object; }, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoopbackCount>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.tablasControllerCount(where, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {NewTablas} [newTablas] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tablasControllerCreate(newTablas?: NewTablas, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Tablas>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.tablasControllerCreate(newTablas, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tablasControllerDeleteById(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.tablasControllerDeleteById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {TablasFilter1} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tablasControllerFind(filter?: TablasFilter1, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TablasWithRelations>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.tablasControllerFind(filter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {TablasFilter} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tablasControllerFindById(id: number, filter?: TablasFilter, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TablasWithRelations>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.tablasControllerFindById(id, filter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {Tablas} [tablas] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tablasControllerReplaceById(id: number, tablas?: Tablas, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.tablasControllerReplaceById(id, tablas, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {TablasPartial} [tablasPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tablasControllerUpdateAll(where?: { [key: string]: object; }, tablasPartial?: TablasPartial, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoopbackCount>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.tablasControllerUpdateAll(where, tablasPartial, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {TablasPartial} [tablasPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tablasControllerUpdateById(id: number, tablasPartial?: TablasPartial, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.tablasControllerUpdateById(id, tablasPartial, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TablasControllerApi - factory interface
 * @export
 */
export const TablasControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TablasControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tablasControllerCount(where?: { [key: string]: object; }, options?: any): AxiosPromise<LoopbackCount> {
            return localVarFp.tablasControllerCount(where, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {NewTablas} [newTablas] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tablasControllerCreate(newTablas?: NewTablas, options?: any): AxiosPromise<Tablas> {
            return localVarFp.tablasControllerCreate(newTablas, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tablasControllerDeleteById(id: number, options?: any): AxiosPromise<any> {
            return localVarFp.tablasControllerDeleteById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TablasFilter1} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tablasControllerFind(filter?: TablasFilter1, options?: any): AxiosPromise<Array<TablasWithRelations>> {
            return localVarFp.tablasControllerFind(filter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {TablasFilter} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tablasControllerFindById(id: number, filter?: TablasFilter, options?: any): AxiosPromise<TablasWithRelations> {
            return localVarFp.tablasControllerFindById(id, filter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {Tablas} [tablas] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tablasControllerReplaceById(id: number, tablas?: Tablas, options?: any): AxiosPromise<any> {
            return localVarFp.tablasControllerReplaceById(id, tablas, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {TablasPartial} [tablasPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tablasControllerUpdateAll(where?: { [key: string]: object; }, tablasPartial?: TablasPartial, options?: any): AxiosPromise<LoopbackCount> {
            return localVarFp.tablasControllerUpdateAll(where, tablasPartial, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {TablasPartial} [tablasPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tablasControllerUpdateById(id: number, tablasPartial?: TablasPartial, options?: any): AxiosPromise<any> {
            return localVarFp.tablasControllerUpdateById(id, tablasPartial, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TablasControllerApi - object-oriented interface
 * @export
 * @class TablasControllerApi
 * @extends {BaseAPI}
 */
export class TablasControllerApi extends BaseAPI {
    /**
     * 
     * @param {{ [key: string]: object; }} [where] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TablasControllerApi
     */
    public tablasControllerCount(where?: { [key: string]: object; }, options?: AxiosRequestConfig) {
        return TablasControllerApiFp(this.configuration).tablasControllerCount(where, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {NewTablas} [newTablas] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TablasControllerApi
     */
    public tablasControllerCreate(newTablas?: NewTablas, options?: AxiosRequestConfig) {
        return TablasControllerApiFp(this.configuration).tablasControllerCreate(newTablas, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TablasControllerApi
     */
    public tablasControllerDeleteById(id: number, options?: AxiosRequestConfig) {
        return TablasControllerApiFp(this.configuration).tablasControllerDeleteById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TablasFilter1} [filter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TablasControllerApi
     */
    public tablasControllerFind(filter?: TablasFilter1, options?: AxiosRequestConfig) {
        return TablasControllerApiFp(this.configuration).tablasControllerFind(filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {TablasFilter} [filter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TablasControllerApi
     */
    public tablasControllerFindById(id: number, filter?: TablasFilter, options?: AxiosRequestConfig) {
        return TablasControllerApiFp(this.configuration).tablasControllerFindById(id, filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {Tablas} [tablas] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TablasControllerApi
     */
    public tablasControllerReplaceById(id: number, tablas?: Tablas, options?: AxiosRequestConfig) {
        return TablasControllerApiFp(this.configuration).tablasControllerReplaceById(id, tablas, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} [where] 
     * @param {TablasPartial} [tablasPartial] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TablasControllerApi
     */
    public tablasControllerUpdateAll(where?: { [key: string]: object; }, tablasPartial?: TablasPartial, options?: AxiosRequestConfig) {
        return TablasControllerApiFp(this.configuration).tablasControllerUpdateAll(where, tablasPartial, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {TablasPartial} [tablasPartial] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TablasControllerApi
     */
    public tablasControllerUpdateById(id: number, tablasPartial?: TablasPartial, options?: AxiosRequestConfig) {
        return TablasControllerApiFp(this.configuration).tablasControllerUpdateById(id, tablasPartial, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TextosGeneradosControllerApi - axios parameter creator
 * @export
 */
export const TextosGeneradosControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        textosGeneradosControllerCount: async (where?: { [key: string]: object; }, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/TextosGenerados/count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (where !== undefined) {
                localVarQueryParameter['where'] = where;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {NewTextosGenerados} [newTextosGenerados] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        textosGeneradosControllerCreate: async (newTextosGenerados?: NewTextosGenerados, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/TextosGenerados`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(newTextosGenerados, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        textosGeneradosControllerDeleteById: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('textosGeneradosControllerDeleteById', 'id', id)
            const localVarPath = `/TextosGenerados/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {TextosGeneradosFilter1} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        textosGeneradosControllerFind: async (filter?: TextosGeneradosFilter1, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/TextosGenerados`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {TextosGeneradosFilter} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        textosGeneradosControllerFindById: async (id: number, filter?: TextosGeneradosFilter, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('textosGeneradosControllerFindById', 'id', id)
            const localVarPath = `/TextosGenerados/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [idMarketPlacePrompt] 
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        textosGeneradosControllerGuardarTextosGenerados: async (idMarketPlacePrompt?: number, body?: object, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/guardar-textos-generados`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (idMarketPlacePrompt !== undefined) {
                localVarQueryParameter['idMarketPlacePrompt'] = idMarketPlacePrompt;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [idProducto] 
         * @param {string} [categoria] 
         * @param {string} [subcategoria] 
         * @param {string} [marketplaces] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        textosGeneradosControllerObtenerTodosLosTextosGeneradosEnRevisadoParaTodosLosProductos: async (idProducto?: string, categoria?: string, subcategoria?: string, marketplaces?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/obtenerTodosLosTextosGeneradosEnRevisadoParaTodosLosProductos`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (idProducto !== undefined) {
                localVarQueryParameter['idProducto'] = idProducto;
            }

            if (categoria !== undefined) {
                localVarQueryParameter['categoria'] = categoria;
            }

            if (subcategoria !== undefined) {
                localVarQueryParameter['subcategoria'] = subcategoria;
            }

            if (marketplaces !== undefined) {
                localVarQueryParameter['marketplaces'] = marketplaces;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [idProducto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        textosGeneradosControllerObtenerTodosLosTextosGeneradosParaElProducto: async (idProducto?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/obtenerTodosLosTextosGeneradosParaElProducto`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (idProducto !== undefined) {
                localVarQueryParameter['idProducto'] = idProducto;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {TextosGenerados} [textosGenerados] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        textosGeneradosControllerReplaceById: async (id: number, textosGenerados?: TextosGenerados, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('textosGeneradosControllerReplaceById', 'id', id)
            const localVarPath = `/TextosGenerados/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(textosGenerados, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {TextosGeneradosPartial} [textosGeneradosPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        textosGeneradosControllerUpdateAll: async (where?: { [key: string]: object; }, textosGeneradosPartial?: TextosGeneradosPartial, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/TextosGenerados`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (where !== undefined) {
                localVarQueryParameter['where'] = where;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(textosGeneradosPartial, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {TextosGeneradosPartial} [textosGeneradosPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        textosGeneradosControllerUpdateById: async (id: number, textosGeneradosPartial?: TextosGeneradosPartial, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('textosGeneradosControllerUpdateById', 'id', id)
            const localVarPath = `/TextosGenerados/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(textosGeneradosPartial, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TextosGeneradosControllerApi - functional programming interface
 * @export
 */
export const TextosGeneradosControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TextosGeneradosControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async textosGeneradosControllerCount(where?: { [key: string]: object; }, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoopbackCount>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.textosGeneradosControllerCount(where, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {NewTextosGenerados} [newTextosGenerados] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async textosGeneradosControllerCreate(newTextosGenerados?: NewTextosGenerados, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TextosGenerados>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.textosGeneradosControllerCreate(newTextosGenerados, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async textosGeneradosControllerDeleteById(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.textosGeneradosControllerDeleteById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {TextosGeneradosFilter1} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async textosGeneradosControllerFind(filter?: TextosGeneradosFilter1, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TextosGeneradosWithRelations>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.textosGeneradosControllerFind(filter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {TextosGeneradosFilter} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async textosGeneradosControllerFindById(id: number, filter?: TextosGeneradosFilter, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TextosGeneradosWithRelations>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.textosGeneradosControllerFindById(id, filter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [idMarketPlacePrompt] 
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async textosGeneradosControllerGuardarTextosGenerados(idMarketPlacePrompt?: number, body?: object, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.textosGeneradosControllerGuardarTextosGenerados(idMarketPlacePrompt, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [idProducto] 
         * @param {string} [categoria] 
         * @param {string} [subcategoria] 
         * @param {string} [marketplaces] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async textosGeneradosControllerObtenerTodosLosTextosGeneradosEnRevisadoParaTodosLosProductos(idProducto?: string, categoria?: string, subcategoria?: string, marketplaces?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.textosGeneradosControllerObtenerTodosLosTextosGeneradosEnRevisadoParaTodosLosProductos(idProducto, categoria, subcategoria, marketplaces, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [idProducto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async textosGeneradosControllerObtenerTodosLosTextosGeneradosParaElProducto(idProducto?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.textosGeneradosControllerObtenerTodosLosTextosGeneradosParaElProducto(idProducto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {TextosGenerados} [textosGenerados] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async textosGeneradosControllerReplaceById(id: number, textosGenerados?: TextosGenerados, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.textosGeneradosControllerReplaceById(id, textosGenerados, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {TextosGeneradosPartial} [textosGeneradosPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async textosGeneradosControllerUpdateAll(where?: { [key: string]: object; }, textosGeneradosPartial?: TextosGeneradosPartial, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoopbackCount>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.textosGeneradosControllerUpdateAll(where, textosGeneradosPartial, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {TextosGeneradosPartial} [textosGeneradosPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async textosGeneradosControllerUpdateById(id: number, textosGeneradosPartial?: TextosGeneradosPartial, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.textosGeneradosControllerUpdateById(id, textosGeneradosPartial, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TextosGeneradosControllerApi - factory interface
 * @export
 */
export const TextosGeneradosControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TextosGeneradosControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        textosGeneradosControllerCount(where?: { [key: string]: object; }, options?: any): AxiosPromise<LoopbackCount> {
            return localVarFp.textosGeneradosControllerCount(where, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {NewTextosGenerados} [newTextosGenerados] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        textosGeneradosControllerCreate(newTextosGenerados?: NewTextosGenerados, options?: any): AxiosPromise<TextosGenerados> {
            return localVarFp.textosGeneradosControllerCreate(newTextosGenerados, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        textosGeneradosControllerDeleteById(id: number, options?: any): AxiosPromise<any> {
            return localVarFp.textosGeneradosControllerDeleteById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TextosGeneradosFilter1} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        textosGeneradosControllerFind(filter?: TextosGeneradosFilter1, options?: any): AxiosPromise<Array<TextosGeneradosWithRelations>> {
            return localVarFp.textosGeneradosControllerFind(filter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {TextosGeneradosFilter} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        textosGeneradosControllerFindById(id: number, filter?: TextosGeneradosFilter, options?: any): AxiosPromise<TextosGeneradosWithRelations> {
            return localVarFp.textosGeneradosControllerFindById(id, filter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [idMarketPlacePrompt] 
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        textosGeneradosControllerGuardarTextosGenerados(idMarketPlacePrompt?: number, body?: object, options?: any): AxiosPromise<object> {
            return localVarFp.textosGeneradosControllerGuardarTextosGenerados(idMarketPlacePrompt, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [idProducto] 
         * @param {string} [categoria] 
         * @param {string} [subcategoria] 
         * @param {string} [marketplaces] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        textosGeneradosControllerObtenerTodosLosTextosGeneradosEnRevisadoParaTodosLosProductos(idProducto?: string, categoria?: string, subcategoria?: string, marketplaces?: string, options?: any): AxiosPromise<object> {
            return localVarFp.textosGeneradosControllerObtenerTodosLosTextosGeneradosEnRevisadoParaTodosLosProductos(idProducto, categoria, subcategoria, marketplaces, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [idProducto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        textosGeneradosControllerObtenerTodosLosTextosGeneradosParaElProducto(idProducto?: number, options?: any): AxiosPromise<object> {
            return localVarFp.textosGeneradosControllerObtenerTodosLosTextosGeneradosParaElProducto(idProducto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {TextosGenerados} [textosGenerados] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        textosGeneradosControllerReplaceById(id: number, textosGenerados?: TextosGenerados, options?: any): AxiosPromise<any> {
            return localVarFp.textosGeneradosControllerReplaceById(id, textosGenerados, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {TextosGeneradosPartial} [textosGeneradosPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        textosGeneradosControllerUpdateAll(where?: { [key: string]: object; }, textosGeneradosPartial?: TextosGeneradosPartial, options?: any): AxiosPromise<LoopbackCount> {
            return localVarFp.textosGeneradosControllerUpdateAll(where, textosGeneradosPartial, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {TextosGeneradosPartial} [textosGeneradosPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        textosGeneradosControllerUpdateById(id: number, textosGeneradosPartial?: TextosGeneradosPartial, options?: any): AxiosPromise<any> {
            return localVarFp.textosGeneradosControllerUpdateById(id, textosGeneradosPartial, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TextosGeneradosControllerApi - object-oriented interface
 * @export
 * @class TextosGeneradosControllerApi
 * @extends {BaseAPI}
 */
export class TextosGeneradosControllerApi extends BaseAPI {
    /**
     * 
     * @param {{ [key: string]: object; }} [where] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TextosGeneradosControllerApi
     */
    public textosGeneradosControllerCount(where?: { [key: string]: object; }, options?: AxiosRequestConfig) {
        return TextosGeneradosControllerApiFp(this.configuration).textosGeneradosControllerCount(where, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {NewTextosGenerados} [newTextosGenerados] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TextosGeneradosControllerApi
     */
    public textosGeneradosControllerCreate(newTextosGenerados?: NewTextosGenerados, options?: AxiosRequestConfig) {
        return TextosGeneradosControllerApiFp(this.configuration).textosGeneradosControllerCreate(newTextosGenerados, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TextosGeneradosControllerApi
     */
    public textosGeneradosControllerDeleteById(id: number, options?: AxiosRequestConfig) {
        return TextosGeneradosControllerApiFp(this.configuration).textosGeneradosControllerDeleteById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TextosGeneradosFilter1} [filter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TextosGeneradosControllerApi
     */
    public textosGeneradosControllerFind(filter?: TextosGeneradosFilter1, options?: AxiosRequestConfig) {
        return TextosGeneradosControllerApiFp(this.configuration).textosGeneradosControllerFind(filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {TextosGeneradosFilter} [filter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TextosGeneradosControllerApi
     */
    public textosGeneradosControllerFindById(id: number, filter?: TextosGeneradosFilter, options?: AxiosRequestConfig) {
        return TextosGeneradosControllerApiFp(this.configuration).textosGeneradosControllerFindById(id, filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [idMarketPlacePrompt] 
     * @param {object} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TextosGeneradosControllerApi
     */
    public textosGeneradosControllerGuardarTextosGenerados(idMarketPlacePrompt?: number, body?: object, options?: AxiosRequestConfig) {
        return TextosGeneradosControllerApiFp(this.configuration).textosGeneradosControllerGuardarTextosGenerados(idMarketPlacePrompt, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [idProducto] 
     * @param {string} [categoria] 
     * @param {string} [subcategoria] 
     * @param {string} [marketplaces] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TextosGeneradosControllerApi
     */
    public textosGeneradosControllerObtenerTodosLosTextosGeneradosEnRevisadoParaTodosLosProductos(idProducto?: string, categoria?: string, subcategoria?: string, marketplaces?: string, options?: AxiosRequestConfig) {
        return TextosGeneradosControllerApiFp(this.configuration).textosGeneradosControllerObtenerTodosLosTextosGeneradosEnRevisadoParaTodosLosProductos(idProducto, categoria, subcategoria, marketplaces, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [idProducto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TextosGeneradosControllerApi
     */
    public textosGeneradosControllerObtenerTodosLosTextosGeneradosParaElProducto(idProducto?: number, options?: AxiosRequestConfig) {
        return TextosGeneradosControllerApiFp(this.configuration).textosGeneradosControllerObtenerTodosLosTextosGeneradosParaElProducto(idProducto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {TextosGenerados} [textosGenerados] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TextosGeneradosControllerApi
     */
    public textosGeneradosControllerReplaceById(id: number, textosGenerados?: TextosGenerados, options?: AxiosRequestConfig) {
        return TextosGeneradosControllerApiFp(this.configuration).textosGeneradosControllerReplaceById(id, textosGenerados, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} [where] 
     * @param {TextosGeneradosPartial} [textosGeneradosPartial] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TextosGeneradosControllerApi
     */
    public textosGeneradosControllerUpdateAll(where?: { [key: string]: object; }, textosGeneradosPartial?: TextosGeneradosPartial, options?: AxiosRequestConfig) {
        return TextosGeneradosControllerApiFp(this.configuration).textosGeneradosControllerUpdateAll(where, textosGeneradosPartial, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {TextosGeneradosPartial} [textosGeneradosPartial] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TextosGeneradosControllerApi
     */
    public textosGeneradosControllerUpdateById(id: number, textosGeneradosPartial?: TextosGeneradosPartial, options?: AxiosRequestConfig) {
        return TextosGeneradosControllerApiFp(this.configuration).textosGeneradosControllerUpdateById(id, textosGeneradosPartial, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TiposRangoControllerApi - axios parameter creator
 * @export
 */
export const TiposRangoControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tiposRangoControllerCount: async (where?: { [key: string]: object; }, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/TiposRango/count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (where !== undefined) {
                localVarQueryParameter['where'] = where;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {NewTiposRango} [newTiposRango] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tiposRangoControllerCreate: async (newTiposRango?: NewTiposRango, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/TiposRango`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(newTiposRango, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tiposRangoControllerDeleteById: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('tiposRangoControllerDeleteById', 'id', id)
            const localVarPath = `/TiposRango/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {TiposRangoFilter1} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tiposRangoControllerFind: async (filter?: TiposRangoFilter1, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/TiposRango`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {TiposRangoFilter} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tiposRangoControllerFindById: async (id: number, filter?: TiposRangoFilter, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('tiposRangoControllerFindById', 'id', id)
            const localVarPath = `/TiposRango/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {TiposRango} [tiposRango] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tiposRangoControllerReplaceById: async (id: number, tiposRango?: TiposRango, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('tiposRangoControllerReplaceById', 'id', id)
            const localVarPath = `/TiposRango/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(tiposRango, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {TiposRangoPartial} [tiposRangoPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tiposRangoControllerUpdateAll: async (where?: { [key: string]: object; }, tiposRangoPartial?: TiposRangoPartial, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/TiposRango`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (where !== undefined) {
                localVarQueryParameter['where'] = where;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(tiposRangoPartial, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {TiposRangoPartial} [tiposRangoPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tiposRangoControllerUpdateById: async (id: number, tiposRangoPartial?: TiposRangoPartial, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('tiposRangoControllerUpdateById', 'id', id)
            const localVarPath = `/TiposRango/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(tiposRangoPartial, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TiposRangoControllerApi - functional programming interface
 * @export
 */
export const TiposRangoControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TiposRangoControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tiposRangoControllerCount(where?: { [key: string]: object; }, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoopbackCount>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.tiposRangoControllerCount(where, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {NewTiposRango} [newTiposRango] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tiposRangoControllerCreate(newTiposRango?: NewTiposRango, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TiposRango>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.tiposRangoControllerCreate(newTiposRango, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tiposRangoControllerDeleteById(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.tiposRangoControllerDeleteById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {TiposRangoFilter1} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tiposRangoControllerFind(filter?: TiposRangoFilter1, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TiposRangoWithRelations>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.tiposRangoControllerFind(filter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {TiposRangoFilter} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tiposRangoControllerFindById(id: number, filter?: TiposRangoFilter, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TiposRangoWithRelations>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.tiposRangoControllerFindById(id, filter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {TiposRango} [tiposRango] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tiposRangoControllerReplaceById(id: number, tiposRango?: TiposRango, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.tiposRangoControllerReplaceById(id, tiposRango, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {TiposRangoPartial} [tiposRangoPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tiposRangoControllerUpdateAll(where?: { [key: string]: object; }, tiposRangoPartial?: TiposRangoPartial, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoopbackCount>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.tiposRangoControllerUpdateAll(where, tiposRangoPartial, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {TiposRangoPartial} [tiposRangoPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tiposRangoControllerUpdateById(id: number, tiposRangoPartial?: TiposRangoPartial, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.tiposRangoControllerUpdateById(id, tiposRangoPartial, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TiposRangoControllerApi - factory interface
 * @export
 */
export const TiposRangoControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TiposRangoControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tiposRangoControllerCount(where?: { [key: string]: object; }, options?: any): AxiosPromise<LoopbackCount> {
            return localVarFp.tiposRangoControllerCount(where, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {NewTiposRango} [newTiposRango] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tiposRangoControllerCreate(newTiposRango?: NewTiposRango, options?: any): AxiosPromise<TiposRango> {
            return localVarFp.tiposRangoControllerCreate(newTiposRango, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tiposRangoControllerDeleteById(id: number, options?: any): AxiosPromise<any> {
            return localVarFp.tiposRangoControllerDeleteById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TiposRangoFilter1} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tiposRangoControllerFind(filter?: TiposRangoFilter1, options?: any): AxiosPromise<Array<TiposRangoWithRelations>> {
            return localVarFp.tiposRangoControllerFind(filter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {TiposRangoFilter} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tiposRangoControllerFindById(id: number, filter?: TiposRangoFilter, options?: any): AxiosPromise<TiposRangoWithRelations> {
            return localVarFp.tiposRangoControllerFindById(id, filter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {TiposRango} [tiposRango] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tiposRangoControllerReplaceById(id: number, tiposRango?: TiposRango, options?: any): AxiosPromise<any> {
            return localVarFp.tiposRangoControllerReplaceById(id, tiposRango, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {TiposRangoPartial} [tiposRangoPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tiposRangoControllerUpdateAll(where?: { [key: string]: object; }, tiposRangoPartial?: TiposRangoPartial, options?: any): AxiosPromise<LoopbackCount> {
            return localVarFp.tiposRangoControllerUpdateAll(where, tiposRangoPartial, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {TiposRangoPartial} [tiposRangoPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tiposRangoControllerUpdateById(id: number, tiposRangoPartial?: TiposRangoPartial, options?: any): AxiosPromise<any> {
            return localVarFp.tiposRangoControllerUpdateById(id, tiposRangoPartial, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TiposRangoControllerApi - object-oriented interface
 * @export
 * @class TiposRangoControllerApi
 * @extends {BaseAPI}
 */
export class TiposRangoControllerApi extends BaseAPI {
    /**
     * 
     * @param {{ [key: string]: object; }} [where] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TiposRangoControllerApi
     */
    public tiposRangoControllerCount(where?: { [key: string]: object; }, options?: AxiosRequestConfig) {
        return TiposRangoControllerApiFp(this.configuration).tiposRangoControllerCount(where, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {NewTiposRango} [newTiposRango] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TiposRangoControllerApi
     */
    public tiposRangoControllerCreate(newTiposRango?: NewTiposRango, options?: AxiosRequestConfig) {
        return TiposRangoControllerApiFp(this.configuration).tiposRangoControllerCreate(newTiposRango, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TiposRangoControllerApi
     */
    public tiposRangoControllerDeleteById(id: number, options?: AxiosRequestConfig) {
        return TiposRangoControllerApiFp(this.configuration).tiposRangoControllerDeleteById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TiposRangoFilter1} [filter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TiposRangoControllerApi
     */
    public tiposRangoControllerFind(filter?: TiposRangoFilter1, options?: AxiosRequestConfig) {
        return TiposRangoControllerApiFp(this.configuration).tiposRangoControllerFind(filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {TiposRangoFilter} [filter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TiposRangoControllerApi
     */
    public tiposRangoControllerFindById(id: number, filter?: TiposRangoFilter, options?: AxiosRequestConfig) {
        return TiposRangoControllerApiFp(this.configuration).tiposRangoControllerFindById(id, filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {TiposRango} [tiposRango] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TiposRangoControllerApi
     */
    public tiposRangoControllerReplaceById(id: number, tiposRango?: TiposRango, options?: AxiosRequestConfig) {
        return TiposRangoControllerApiFp(this.configuration).tiposRangoControllerReplaceById(id, tiposRango, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} [where] 
     * @param {TiposRangoPartial} [tiposRangoPartial] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TiposRangoControllerApi
     */
    public tiposRangoControllerUpdateAll(where?: { [key: string]: object; }, tiposRangoPartial?: TiposRangoPartial, options?: AxiosRequestConfig) {
        return TiposRangoControllerApiFp(this.configuration).tiposRangoControllerUpdateAll(where, tiposRangoPartial, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {TiposRangoPartial} [tiposRangoPartial] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TiposRangoControllerApi
     */
    public tiposRangoControllerUpdateById(id: number, tiposRangoPartial?: TiposRangoPartial, options?: AxiosRequestConfig) {
        return TiposRangoControllerApiFp(this.configuration).tiposRangoControllerUpdateById(id, tiposRangoPartial, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TraduccionesControllerApi - axios parameter creator
 * @export
 */
export const TraduccionesControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        traduccionesControllerCount: async (where?: { [key: string]: object; }, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/traducciones/count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (where !== undefined) {
                localVarQueryParameter['where'] = where;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {NewTraducciones} [newTraducciones] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        traduccionesControllerCreate: async (newTraducciones?: NewTraducciones, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/traducciones`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(newTraducciones, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        traduccionesControllerDeleteById: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('traduccionesControllerDeleteById', 'id', id)
            const localVarPath = `/traducciones/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {TraduccionesFilter1} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        traduccionesControllerFind: async (filter?: TraduccionesFilter1, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/traducciones`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {TraduccionesFilter} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        traduccionesControllerFindById: async (id: number, filter?: TraduccionesFilter, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('traduccionesControllerFindById', 'id', id)
            const localVarPath = `/traducciones/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {Traducciones} [traducciones] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        traduccionesControllerReplaceById: async (id: number, traducciones?: Traducciones, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('traduccionesControllerReplaceById', 'id', id)
            const localVarPath = `/traducciones/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(traducciones, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {TraduccionesPartial} [traduccionesPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        traduccionesControllerUpdateAll: async (where?: { [key: string]: object; }, traduccionesPartial?: TraduccionesPartial, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/traducciones`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (where !== undefined) {
                localVarQueryParameter['where'] = where;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(traduccionesPartial, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {TraduccionesPartial} [traduccionesPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        traduccionesControllerUpdateById: async (id: number, traduccionesPartial?: TraduccionesPartial, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('traduccionesControllerUpdateById', 'id', id)
            const localVarPath = `/traducciones/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(traduccionesPartial, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TraduccionesControllerApi - functional programming interface
 * @export
 */
export const TraduccionesControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TraduccionesControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async traduccionesControllerCount(where?: { [key: string]: object; }, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoopbackCount>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.traduccionesControllerCount(where, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {NewTraducciones} [newTraducciones] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async traduccionesControllerCreate(newTraducciones?: NewTraducciones, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Traducciones>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.traduccionesControllerCreate(newTraducciones, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async traduccionesControllerDeleteById(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.traduccionesControllerDeleteById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {TraduccionesFilter1} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async traduccionesControllerFind(filter?: TraduccionesFilter1, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TraduccionesWithRelations>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.traduccionesControllerFind(filter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {TraduccionesFilter} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async traduccionesControllerFindById(id: number, filter?: TraduccionesFilter, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TraduccionesWithRelations>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.traduccionesControllerFindById(id, filter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {Traducciones} [traducciones] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async traduccionesControllerReplaceById(id: number, traducciones?: Traducciones, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.traduccionesControllerReplaceById(id, traducciones, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {TraduccionesPartial} [traduccionesPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async traduccionesControllerUpdateAll(where?: { [key: string]: object; }, traduccionesPartial?: TraduccionesPartial, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoopbackCount>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.traduccionesControllerUpdateAll(where, traduccionesPartial, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {TraduccionesPartial} [traduccionesPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async traduccionesControllerUpdateById(id: number, traduccionesPartial?: TraduccionesPartial, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.traduccionesControllerUpdateById(id, traduccionesPartial, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TraduccionesControllerApi - factory interface
 * @export
 */
export const TraduccionesControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TraduccionesControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        traduccionesControllerCount(where?: { [key: string]: object; }, options?: any): AxiosPromise<LoopbackCount> {
            return localVarFp.traduccionesControllerCount(where, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {NewTraducciones} [newTraducciones] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        traduccionesControllerCreate(newTraducciones?: NewTraducciones, options?: any): AxiosPromise<Traducciones> {
            return localVarFp.traduccionesControllerCreate(newTraducciones, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        traduccionesControllerDeleteById(id: number, options?: any): AxiosPromise<any> {
            return localVarFp.traduccionesControllerDeleteById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TraduccionesFilter1} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        traduccionesControllerFind(filter?: TraduccionesFilter1, options?: any): AxiosPromise<Array<TraduccionesWithRelations>> {
            return localVarFp.traduccionesControllerFind(filter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {TraduccionesFilter} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        traduccionesControllerFindById(id: number, filter?: TraduccionesFilter, options?: any): AxiosPromise<TraduccionesWithRelations> {
            return localVarFp.traduccionesControllerFindById(id, filter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {Traducciones} [traducciones] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        traduccionesControllerReplaceById(id: number, traducciones?: Traducciones, options?: any): AxiosPromise<any> {
            return localVarFp.traduccionesControllerReplaceById(id, traducciones, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {TraduccionesPartial} [traduccionesPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        traduccionesControllerUpdateAll(where?: { [key: string]: object; }, traduccionesPartial?: TraduccionesPartial, options?: any): AxiosPromise<LoopbackCount> {
            return localVarFp.traduccionesControllerUpdateAll(where, traduccionesPartial, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {TraduccionesPartial} [traduccionesPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        traduccionesControllerUpdateById(id: number, traduccionesPartial?: TraduccionesPartial, options?: any): AxiosPromise<any> {
            return localVarFp.traduccionesControllerUpdateById(id, traduccionesPartial, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TraduccionesControllerApi - object-oriented interface
 * @export
 * @class TraduccionesControllerApi
 * @extends {BaseAPI}
 */
export class TraduccionesControllerApi extends BaseAPI {
    /**
     * 
     * @param {{ [key: string]: object; }} [where] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TraduccionesControllerApi
     */
    public traduccionesControllerCount(where?: { [key: string]: object; }, options?: AxiosRequestConfig) {
        return TraduccionesControllerApiFp(this.configuration).traduccionesControllerCount(where, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {NewTraducciones} [newTraducciones] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TraduccionesControllerApi
     */
    public traduccionesControllerCreate(newTraducciones?: NewTraducciones, options?: AxiosRequestConfig) {
        return TraduccionesControllerApiFp(this.configuration).traduccionesControllerCreate(newTraducciones, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TraduccionesControllerApi
     */
    public traduccionesControllerDeleteById(id: number, options?: AxiosRequestConfig) {
        return TraduccionesControllerApiFp(this.configuration).traduccionesControllerDeleteById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TraduccionesFilter1} [filter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TraduccionesControllerApi
     */
    public traduccionesControllerFind(filter?: TraduccionesFilter1, options?: AxiosRequestConfig) {
        return TraduccionesControllerApiFp(this.configuration).traduccionesControllerFind(filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {TraduccionesFilter} [filter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TraduccionesControllerApi
     */
    public traduccionesControllerFindById(id: number, filter?: TraduccionesFilter, options?: AxiosRequestConfig) {
        return TraduccionesControllerApiFp(this.configuration).traduccionesControllerFindById(id, filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {Traducciones} [traducciones] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TraduccionesControllerApi
     */
    public traduccionesControllerReplaceById(id: number, traducciones?: Traducciones, options?: AxiosRequestConfig) {
        return TraduccionesControllerApiFp(this.configuration).traduccionesControllerReplaceById(id, traducciones, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} [where] 
     * @param {TraduccionesPartial} [traduccionesPartial] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TraduccionesControllerApi
     */
    public traduccionesControllerUpdateAll(where?: { [key: string]: object; }, traduccionesPartial?: TraduccionesPartial, options?: AxiosRequestConfig) {
        return TraduccionesControllerApiFp(this.configuration).traduccionesControllerUpdateAll(where, traduccionesPartial, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {TraduccionesPartial} [traduccionesPartial] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TraduccionesControllerApi
     */
    public traduccionesControllerUpdateById(id: number, traduccionesPartial?: TraduccionesPartial, options?: AxiosRequestConfig) {
        return TraduccionesControllerApiFp(this.configuration).traduccionesControllerUpdateById(id, traduccionesPartial, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TwFunctionsControllerApi - axios parameter creator
 * @export
 */
export const TwFunctionsControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {InsertItem} [insertItem] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        twFunctionsControllerInsertItem: async (insertItem?: InsertItem, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/InsertItem`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(insertItem, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} code 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        twFunctionsControllerNewEANCode: async (code: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'code' is not null or undefined
            assertParamExists('twFunctionsControllerNewEANCode', 'code', code)
            const localVarPath = `/NewEANCode/{code}`
                .replace(`{${"code"}}`, encodeURIComponent(String(code)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TwFunctionsControllerApi - functional programming interface
 * @export
 */
export const TwFunctionsControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TwFunctionsControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {InsertItem} [insertItem] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async twFunctionsControllerInsertItem(insertItem?: InsertItem, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InsertItem>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.twFunctionsControllerInsertItem(insertItem, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} code 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async twFunctionsControllerNewEANCode(code: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.twFunctionsControllerNewEANCode(code, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TwFunctionsControllerApi - factory interface
 * @export
 */
export const TwFunctionsControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TwFunctionsControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {InsertItem} [insertItem] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        twFunctionsControllerInsertItem(insertItem?: InsertItem, options?: any): AxiosPromise<InsertItem> {
            return localVarFp.twFunctionsControllerInsertItem(insertItem, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} code 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        twFunctionsControllerNewEANCode(code: string, options?: any): AxiosPromise<any> {
            return localVarFp.twFunctionsControllerNewEANCode(code, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TwFunctionsControllerApi - object-oriented interface
 * @export
 * @class TwFunctionsControllerApi
 * @extends {BaseAPI}
 */
export class TwFunctionsControllerApi extends BaseAPI {
    /**
     * 
     * @param {InsertItem} [insertItem] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TwFunctionsControllerApi
     */
    public twFunctionsControllerInsertItem(insertItem?: InsertItem, options?: AxiosRequestConfig) {
        return TwFunctionsControllerApiFp(this.configuration).twFunctionsControllerInsertItem(insertItem, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} code 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TwFunctionsControllerApi
     */
    public twFunctionsControllerNewEANCode(code: string, options?: AxiosRequestConfig) {
        return TwFunctionsControllerApiFp(this.configuration).twFunctionsControllerNewEANCode(code, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UsuariosControllerApi - axios parameter creator
 * @export
 */
export const UsuariosControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usuariosControllerCount: async (where?: { [key: string]: object; }, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/usuarios/count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (where !== undefined) {
                localVarQueryParameter['where'] = where;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {NewUsuarios} [newUsuarios] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usuariosControllerCreate: async (newUsuarios?: NewUsuarios, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/usuarios`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(newUsuarios, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usuariosControllerDeleteById: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('usuariosControllerDeleteById', 'id', id)
            const localVarPath = `/usuarios/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UsuariosFilter1} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usuariosControllerFind: async (filter?: UsuariosFilter1, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/usuarios`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {UsuariosFilter} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usuariosControllerFindById: async (id: number, filter?: UsuariosFilter, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('usuariosControllerFindById', 'id', id)
            const localVarPath = `/usuarios/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {Usuarios} [usuarios] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usuariosControllerReplaceById: async (id: number, usuarios?: Usuarios, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('usuariosControllerReplaceById', 'id', id)
            const localVarPath = `/usuarios/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(usuarios, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {UsuariosPartial} [usuariosPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usuariosControllerUpdateById: async (id: number, usuariosPartial?: UsuariosPartial, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('usuariosControllerUpdateById', 'id', id)
            const localVarPath = `/usuarios/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(usuariosPartial, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UsuariosControllerApi - functional programming interface
 * @export
 */
export const UsuariosControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UsuariosControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usuariosControllerCount(where?: { [key: string]: object; }, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoopbackCount>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usuariosControllerCount(where, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {NewUsuarios} [newUsuarios] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usuariosControllerCreate(newUsuarios?: NewUsuarios, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Usuarios>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usuariosControllerCreate(newUsuarios, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usuariosControllerDeleteById(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usuariosControllerDeleteById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UsuariosFilter1} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usuariosControllerFind(filter?: UsuariosFilter1, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UsuariosWithRelations>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usuariosControllerFind(filter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {UsuariosFilter} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usuariosControllerFindById(id: number, filter?: UsuariosFilter, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UsuariosWithRelations>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usuariosControllerFindById(id, filter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {Usuarios} [usuarios] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usuariosControllerReplaceById(id: number, usuarios?: Usuarios, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usuariosControllerReplaceById(id, usuarios, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {UsuariosPartial} [usuariosPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usuariosControllerUpdateById(id: number, usuariosPartial?: UsuariosPartial, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usuariosControllerUpdateById(id, usuariosPartial, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UsuariosControllerApi - factory interface
 * @export
 */
export const UsuariosControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UsuariosControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usuariosControllerCount(where?: { [key: string]: object; }, options?: any): AxiosPromise<LoopbackCount> {
            return localVarFp.usuariosControllerCount(where, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {NewUsuarios} [newUsuarios] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usuariosControllerCreate(newUsuarios?: NewUsuarios, options?: any): AxiosPromise<Usuarios> {
            return localVarFp.usuariosControllerCreate(newUsuarios, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usuariosControllerDeleteById(id: number, options?: any): AxiosPromise<any> {
            return localVarFp.usuariosControllerDeleteById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UsuariosFilter1} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usuariosControllerFind(filter?: UsuariosFilter1, options?: any): AxiosPromise<Array<UsuariosWithRelations>> {
            return localVarFp.usuariosControllerFind(filter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {UsuariosFilter} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usuariosControllerFindById(id: number, filter?: UsuariosFilter, options?: any): AxiosPromise<UsuariosWithRelations> {
            return localVarFp.usuariosControllerFindById(id, filter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {Usuarios} [usuarios] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usuariosControllerReplaceById(id: number, usuarios?: Usuarios, options?: any): AxiosPromise<any> {
            return localVarFp.usuariosControllerReplaceById(id, usuarios, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {UsuariosPartial} [usuariosPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usuariosControllerUpdateById(id: number, usuariosPartial?: UsuariosPartial, options?: any): AxiosPromise<any> {
            return localVarFp.usuariosControllerUpdateById(id, usuariosPartial, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UsuariosControllerApi - object-oriented interface
 * @export
 * @class UsuariosControllerApi
 * @extends {BaseAPI}
 */
export class UsuariosControllerApi extends BaseAPI {
    /**
     * 
     * @param {{ [key: string]: object; }} [where] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsuariosControllerApi
     */
    public usuariosControllerCount(where?: { [key: string]: object; }, options?: AxiosRequestConfig) {
        return UsuariosControllerApiFp(this.configuration).usuariosControllerCount(where, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {NewUsuarios} [newUsuarios] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsuariosControllerApi
     */
    public usuariosControllerCreate(newUsuarios?: NewUsuarios, options?: AxiosRequestConfig) {
        return UsuariosControllerApiFp(this.configuration).usuariosControllerCreate(newUsuarios, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsuariosControllerApi
     */
    public usuariosControllerDeleteById(id: number, options?: AxiosRequestConfig) {
        return UsuariosControllerApiFp(this.configuration).usuariosControllerDeleteById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UsuariosFilter1} [filter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsuariosControllerApi
     */
    public usuariosControllerFind(filter?: UsuariosFilter1, options?: AxiosRequestConfig) {
        return UsuariosControllerApiFp(this.configuration).usuariosControllerFind(filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {UsuariosFilter} [filter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsuariosControllerApi
     */
    public usuariosControllerFindById(id: number, filter?: UsuariosFilter, options?: AxiosRequestConfig) {
        return UsuariosControllerApiFp(this.configuration).usuariosControllerFindById(id, filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {Usuarios} [usuarios] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsuariosControllerApi
     */
    public usuariosControllerReplaceById(id: number, usuarios?: Usuarios, options?: AxiosRequestConfig) {
        return UsuariosControllerApiFp(this.configuration).usuariosControllerReplaceById(id, usuarios, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {UsuariosPartial} [usuariosPartial] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsuariosControllerApi
     */
    public usuariosControllerUpdateById(id: number, usuariosPartial?: UsuariosPartial, options?: AxiosRequestConfig) {
        return UsuariosControllerApiFp(this.configuration).usuariosControllerUpdateById(id, usuariosPartial, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UsuariosRolesControllerApi - axios parameter creator
 * @export
 */
export const UsuariosRolesControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usuariosRolesControllerGetRoles: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('usuariosRolesControllerGetRoles', 'id', id)
            const localVarPath = `/usuarios/{id}/roles`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UsuariosRolesControllerApi - functional programming interface
 * @export
 */
export const UsuariosRolesControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UsuariosRolesControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usuariosRolesControllerGetRoles(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Roles>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usuariosRolesControllerGetRoles(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UsuariosRolesControllerApi - factory interface
 * @export
 */
export const UsuariosRolesControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UsuariosRolesControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usuariosRolesControllerGetRoles(id: number, options?: any): AxiosPromise<Array<Roles>> {
            return localVarFp.usuariosRolesControllerGetRoles(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UsuariosRolesControllerApi - object-oriented interface
 * @export
 * @class UsuariosRolesControllerApi
 * @extends {BaseAPI}
 */
export class UsuariosRolesControllerApi extends BaseAPI {
    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsuariosRolesControllerApi
     */
    public usuariosRolesControllerGetRoles(id: number, options?: AxiosRequestConfig) {
        return UsuariosRolesControllerApiFp(this.configuration).usuariosRolesControllerGetRoles(id, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * VistaCategoriasConNivelesControllerApi - axios parameter creator
 * @export
 */
export const VistaCategoriasConNivelesControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vistaCategoriasConNivelesControllerCount: async (where?: { [key: string]: object; }, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/vista-categorias-con-niveles/count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (where !== undefined) {
                localVarQueryParameter['where'] = where;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {NewVistaCategoriasConNiveles} [newVistaCategoriasConNiveles] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vistaCategoriasConNivelesControllerCreate: async (newVistaCategoriasConNiveles?: NewVistaCategoriasConNiveles, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/vista-categorias-con-niveles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(newVistaCategoriasConNiveles, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vistaCategoriasConNivelesControllerDeleteById: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('vistaCategoriasConNivelesControllerDeleteById', 'id', id)
            const localVarPath = `/vista-categorias-con-niveles/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {VistaCategoriasConNivelesFilter1} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vistaCategoriasConNivelesControllerFind: async (filter?: VistaCategoriasConNivelesFilter1, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/vista-categorias-con-niveles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {VistaCategoriasConNivelesFilter} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vistaCategoriasConNivelesControllerFindById: async (id: number, filter?: VistaCategoriasConNivelesFilter, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('vistaCategoriasConNivelesControllerFindById', 'id', id)
            const localVarPath = `/vista-categorias-con-niveles/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {VistaCategoriasConNiveles} [vistaCategoriasConNiveles] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vistaCategoriasConNivelesControllerReplaceById: async (id: number, vistaCategoriasConNiveles?: VistaCategoriasConNiveles, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('vistaCategoriasConNivelesControllerReplaceById', 'id', id)
            const localVarPath = `/vista-categorias-con-niveles/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(vistaCategoriasConNiveles, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {VistaCategoriasConNivelesPartial} [vistaCategoriasConNivelesPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vistaCategoriasConNivelesControllerUpdateAll: async (where?: { [key: string]: object; }, vistaCategoriasConNivelesPartial?: VistaCategoriasConNivelesPartial, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/vista-categorias-con-niveles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (where !== undefined) {
                localVarQueryParameter['where'] = where;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(vistaCategoriasConNivelesPartial, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {VistaCategoriasConNivelesPartial} [vistaCategoriasConNivelesPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vistaCategoriasConNivelesControllerUpdateById: async (id: number, vistaCategoriasConNivelesPartial?: VistaCategoriasConNivelesPartial, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('vistaCategoriasConNivelesControllerUpdateById', 'id', id)
            const localVarPath = `/vista-categorias-con-niveles/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(vistaCategoriasConNivelesPartial, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * VistaCategoriasConNivelesControllerApi - functional programming interface
 * @export
 */
export const VistaCategoriasConNivelesControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = VistaCategoriasConNivelesControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async vistaCategoriasConNivelesControllerCount(where?: { [key: string]: object; }, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoopbackCount>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.vistaCategoriasConNivelesControllerCount(where, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {NewVistaCategoriasConNiveles} [newVistaCategoriasConNiveles] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async vistaCategoriasConNivelesControllerCreate(newVistaCategoriasConNiveles?: NewVistaCategoriasConNiveles, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VistaCategoriasConNiveles>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.vistaCategoriasConNivelesControllerCreate(newVistaCategoriasConNiveles, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async vistaCategoriasConNivelesControllerDeleteById(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.vistaCategoriasConNivelesControllerDeleteById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {VistaCategoriasConNivelesFilter1} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async vistaCategoriasConNivelesControllerFind(filter?: VistaCategoriasConNivelesFilter1, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<VistaCategoriasConNivelesWithRelations>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.vistaCategoriasConNivelesControllerFind(filter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {VistaCategoriasConNivelesFilter} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async vistaCategoriasConNivelesControllerFindById(id: number, filter?: VistaCategoriasConNivelesFilter, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VistaCategoriasConNivelesWithRelations>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.vistaCategoriasConNivelesControllerFindById(id, filter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {VistaCategoriasConNiveles} [vistaCategoriasConNiveles] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async vistaCategoriasConNivelesControllerReplaceById(id: number, vistaCategoriasConNiveles?: VistaCategoriasConNiveles, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.vistaCategoriasConNivelesControllerReplaceById(id, vistaCategoriasConNiveles, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {VistaCategoriasConNivelesPartial} [vistaCategoriasConNivelesPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async vistaCategoriasConNivelesControllerUpdateAll(where?: { [key: string]: object; }, vistaCategoriasConNivelesPartial?: VistaCategoriasConNivelesPartial, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoopbackCount>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.vistaCategoriasConNivelesControllerUpdateAll(where, vistaCategoriasConNivelesPartial, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {VistaCategoriasConNivelesPartial} [vistaCategoriasConNivelesPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async vistaCategoriasConNivelesControllerUpdateById(id: number, vistaCategoriasConNivelesPartial?: VistaCategoriasConNivelesPartial, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.vistaCategoriasConNivelesControllerUpdateById(id, vistaCategoriasConNivelesPartial, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * VistaCategoriasConNivelesControllerApi - factory interface
 * @export
 */
export const VistaCategoriasConNivelesControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = VistaCategoriasConNivelesControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vistaCategoriasConNivelesControllerCount(where?: { [key: string]: object; }, options?: any): AxiosPromise<LoopbackCount> {
            return localVarFp.vistaCategoriasConNivelesControllerCount(where, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {NewVistaCategoriasConNiveles} [newVistaCategoriasConNiveles] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vistaCategoriasConNivelesControllerCreate(newVistaCategoriasConNiveles?: NewVistaCategoriasConNiveles, options?: any): AxiosPromise<VistaCategoriasConNiveles> {
            return localVarFp.vistaCategoriasConNivelesControllerCreate(newVistaCategoriasConNiveles, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vistaCategoriasConNivelesControllerDeleteById(id: number, options?: any): AxiosPromise<any> {
            return localVarFp.vistaCategoriasConNivelesControllerDeleteById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {VistaCategoriasConNivelesFilter1} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vistaCategoriasConNivelesControllerFind(filter?: VistaCategoriasConNivelesFilter1, options?: any): AxiosPromise<Array<VistaCategoriasConNivelesWithRelations>> {
            return localVarFp.vistaCategoriasConNivelesControllerFind(filter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {VistaCategoriasConNivelesFilter} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vistaCategoriasConNivelesControllerFindById(id: number, filter?: VistaCategoriasConNivelesFilter, options?: any): AxiosPromise<VistaCategoriasConNivelesWithRelations> {
            return localVarFp.vistaCategoriasConNivelesControllerFindById(id, filter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {VistaCategoriasConNiveles} [vistaCategoriasConNiveles] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vistaCategoriasConNivelesControllerReplaceById(id: number, vistaCategoriasConNiveles?: VistaCategoriasConNiveles, options?: any): AxiosPromise<any> {
            return localVarFp.vistaCategoriasConNivelesControllerReplaceById(id, vistaCategoriasConNiveles, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {VistaCategoriasConNivelesPartial} [vistaCategoriasConNivelesPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vistaCategoriasConNivelesControllerUpdateAll(where?: { [key: string]: object; }, vistaCategoriasConNivelesPartial?: VistaCategoriasConNivelesPartial, options?: any): AxiosPromise<LoopbackCount> {
            return localVarFp.vistaCategoriasConNivelesControllerUpdateAll(where, vistaCategoriasConNivelesPartial, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {VistaCategoriasConNivelesPartial} [vistaCategoriasConNivelesPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vistaCategoriasConNivelesControllerUpdateById(id: number, vistaCategoriasConNivelesPartial?: VistaCategoriasConNivelesPartial, options?: any): AxiosPromise<any> {
            return localVarFp.vistaCategoriasConNivelesControllerUpdateById(id, vistaCategoriasConNivelesPartial, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * VistaCategoriasConNivelesControllerApi - object-oriented interface
 * @export
 * @class VistaCategoriasConNivelesControllerApi
 * @extends {BaseAPI}
 */
export class VistaCategoriasConNivelesControllerApi extends BaseAPI {
    /**
     * 
     * @param {{ [key: string]: object; }} [where] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VistaCategoriasConNivelesControllerApi
     */
    public vistaCategoriasConNivelesControllerCount(where?: { [key: string]: object; }, options?: AxiosRequestConfig) {
        return VistaCategoriasConNivelesControllerApiFp(this.configuration).vistaCategoriasConNivelesControllerCount(where, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {NewVistaCategoriasConNiveles} [newVistaCategoriasConNiveles] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VistaCategoriasConNivelesControllerApi
     */
    public vistaCategoriasConNivelesControllerCreate(newVistaCategoriasConNiveles?: NewVistaCategoriasConNiveles, options?: AxiosRequestConfig) {
        return VistaCategoriasConNivelesControllerApiFp(this.configuration).vistaCategoriasConNivelesControllerCreate(newVistaCategoriasConNiveles, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VistaCategoriasConNivelesControllerApi
     */
    public vistaCategoriasConNivelesControllerDeleteById(id: number, options?: AxiosRequestConfig) {
        return VistaCategoriasConNivelesControllerApiFp(this.configuration).vistaCategoriasConNivelesControllerDeleteById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {VistaCategoriasConNivelesFilter1} [filter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VistaCategoriasConNivelesControllerApi
     */
    public vistaCategoriasConNivelesControllerFind(filter?: VistaCategoriasConNivelesFilter1, options?: AxiosRequestConfig) {
        return VistaCategoriasConNivelesControllerApiFp(this.configuration).vistaCategoriasConNivelesControllerFind(filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {VistaCategoriasConNivelesFilter} [filter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VistaCategoriasConNivelesControllerApi
     */
    public vistaCategoriasConNivelesControllerFindById(id: number, filter?: VistaCategoriasConNivelesFilter, options?: AxiosRequestConfig) {
        return VistaCategoriasConNivelesControllerApiFp(this.configuration).vistaCategoriasConNivelesControllerFindById(id, filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {VistaCategoriasConNiveles} [vistaCategoriasConNiveles] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VistaCategoriasConNivelesControllerApi
     */
    public vistaCategoriasConNivelesControllerReplaceById(id: number, vistaCategoriasConNiveles?: VistaCategoriasConNiveles, options?: AxiosRequestConfig) {
        return VistaCategoriasConNivelesControllerApiFp(this.configuration).vistaCategoriasConNivelesControllerReplaceById(id, vistaCategoriasConNiveles, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} [where] 
     * @param {VistaCategoriasConNivelesPartial} [vistaCategoriasConNivelesPartial] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VistaCategoriasConNivelesControllerApi
     */
    public vistaCategoriasConNivelesControllerUpdateAll(where?: { [key: string]: object; }, vistaCategoriasConNivelesPartial?: VistaCategoriasConNivelesPartial, options?: AxiosRequestConfig) {
        return VistaCategoriasConNivelesControllerApiFp(this.configuration).vistaCategoriasConNivelesControllerUpdateAll(where, vistaCategoriasConNivelesPartial, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {VistaCategoriasConNivelesPartial} [vistaCategoriasConNivelesPartial] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VistaCategoriasConNivelesControllerApi
     */
    public vistaCategoriasConNivelesControllerUpdateById(id: number, vistaCategoriasConNivelesPartial?: VistaCategoriasConNivelesPartial, options?: AxiosRequestConfig) {
        return VistaCategoriasConNivelesControllerApiFp(this.configuration).vistaCategoriasConNivelesControllerUpdateById(id, vistaCategoriasConNivelesPartial, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * VistaMarketPlacePromptsControllerApi - axios parameter creator
 * @export
 */
export const VistaMarketPlacePromptsControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vistaMarketPlacePromptsControllerCount: async (where?: { [key: string]: object; }, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/VistaMarketPlacePrompts/count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (where !== undefined) {
                localVarQueryParameter['where'] = where;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {NewVistaMarketPlacePrompts} [newVistaMarketPlacePrompts] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vistaMarketPlacePromptsControllerCreate: async (newVistaMarketPlacePrompts?: NewVistaMarketPlacePrompts, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/VistaMarketPlacePrompts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(newVistaMarketPlacePrompts, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vistaMarketPlacePromptsControllerDeleteById: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('vistaMarketPlacePromptsControllerDeleteById', 'id', id)
            const localVarPath = `/VistaMarketPlacePrompts/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {VistaMarketPlacePromptsFilter1} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vistaMarketPlacePromptsControllerFind: async (filter?: VistaMarketPlacePromptsFilter1, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/VistaMarketPlacePrompts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {VistaMarketPlacePromptsFilter} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vistaMarketPlacePromptsControllerFindById: async (id: number, filter?: VistaMarketPlacePromptsFilter, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('vistaMarketPlacePromptsControllerFindById', 'id', id)
            const localVarPath = `/VistaMarketPlacePrompts/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [productos] 
         * @param {string} [categoria] 
         * @param {string} [subcategoria] 
         * @param {string} [marketplaces] 
         * @param {string} [idmarketplacesprompt] 
         * @param {string} [emailUsuarioLogueado] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vistaMarketPlacePromptsControllerObtenerRegistrosVistaMarketPlaceProductoGeneral: async (productos?: string, categoria?: string, subcategoria?: string, marketplaces?: string, idmarketplacesprompt?: string, emailUsuarioLogueado?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/obtenerRegistrosVistaMarketPlaceProductoGeneral`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (productos !== undefined) {
                localVarQueryParameter['productos'] = productos;
            }

            if (categoria !== undefined) {
                localVarQueryParameter['categoria'] = categoria;
            }

            if (subcategoria !== undefined) {
                localVarQueryParameter['subcategoria'] = subcategoria;
            }

            if (marketplaces !== undefined) {
                localVarQueryParameter['marketplaces'] = marketplaces;
            }

            if (idmarketplacesprompt !== undefined) {
                localVarQueryParameter['idmarketplacesprompt'] = idmarketplacesprompt;
            }

            if (emailUsuarioLogueado !== undefined) {
                localVarQueryParameter['emailUsuarioLogueado'] = emailUsuarioLogueado;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {VistaMarketPlacePrompts} [vistaMarketPlacePrompts] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vistaMarketPlacePromptsControllerReplaceById: async (id: number, vistaMarketPlacePrompts?: VistaMarketPlacePrompts, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('vistaMarketPlacePromptsControllerReplaceById', 'id', id)
            const localVarPath = `/VistaMarketPlacePrompts/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(vistaMarketPlacePrompts, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {VistaMarketPlacePromptsPartial} [vistaMarketPlacePromptsPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vistaMarketPlacePromptsControllerUpdateAll: async (where?: { [key: string]: object; }, vistaMarketPlacePromptsPartial?: VistaMarketPlacePromptsPartial, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/VistaMarketPlacePrompts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (where !== undefined) {
                localVarQueryParameter['where'] = where;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(vistaMarketPlacePromptsPartial, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {VistaMarketPlacePromptsPartial} [vistaMarketPlacePromptsPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vistaMarketPlacePromptsControllerUpdateById: async (id: number, vistaMarketPlacePromptsPartial?: VistaMarketPlacePromptsPartial, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('vistaMarketPlacePromptsControllerUpdateById', 'id', id)
            const localVarPath = `/VistaMarketPlacePrompts/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(vistaMarketPlacePromptsPartial, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * VistaMarketPlacePromptsControllerApi - functional programming interface
 * @export
 */
export const VistaMarketPlacePromptsControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = VistaMarketPlacePromptsControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async vistaMarketPlacePromptsControllerCount(where?: { [key: string]: object; }, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoopbackCount>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.vistaMarketPlacePromptsControllerCount(where, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {NewVistaMarketPlacePrompts} [newVistaMarketPlacePrompts] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async vistaMarketPlacePromptsControllerCreate(newVistaMarketPlacePrompts?: NewVistaMarketPlacePrompts, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VistaMarketPlacePrompts>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.vistaMarketPlacePromptsControllerCreate(newVistaMarketPlacePrompts, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async vistaMarketPlacePromptsControllerDeleteById(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.vistaMarketPlacePromptsControllerDeleteById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {VistaMarketPlacePromptsFilter1} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async vistaMarketPlacePromptsControllerFind(filter?: VistaMarketPlacePromptsFilter1, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<VistaMarketPlacePromptsWithRelations>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.vistaMarketPlacePromptsControllerFind(filter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {VistaMarketPlacePromptsFilter} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async vistaMarketPlacePromptsControllerFindById(id: number, filter?: VistaMarketPlacePromptsFilter, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VistaMarketPlacePromptsWithRelations>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.vistaMarketPlacePromptsControllerFindById(id, filter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [productos] 
         * @param {string} [categoria] 
         * @param {string} [subcategoria] 
         * @param {string} [marketplaces] 
         * @param {string} [idmarketplacesprompt] 
         * @param {string} [emailUsuarioLogueado] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async vistaMarketPlacePromptsControllerObtenerRegistrosVistaMarketPlaceProductoGeneral(productos?: string, categoria?: string, subcategoria?: string, marketplaces?: string, idmarketplacesprompt?: string, emailUsuarioLogueado?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<VistaMarketPlacePromptsWithRelations>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.vistaMarketPlacePromptsControllerObtenerRegistrosVistaMarketPlaceProductoGeneral(productos, categoria, subcategoria, marketplaces, idmarketplacesprompt, emailUsuarioLogueado, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {VistaMarketPlacePrompts} [vistaMarketPlacePrompts] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async vistaMarketPlacePromptsControllerReplaceById(id: number, vistaMarketPlacePrompts?: VistaMarketPlacePrompts, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.vistaMarketPlacePromptsControllerReplaceById(id, vistaMarketPlacePrompts, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {VistaMarketPlacePromptsPartial} [vistaMarketPlacePromptsPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async vistaMarketPlacePromptsControllerUpdateAll(where?: { [key: string]: object; }, vistaMarketPlacePromptsPartial?: VistaMarketPlacePromptsPartial, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoopbackCount>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.vistaMarketPlacePromptsControllerUpdateAll(where, vistaMarketPlacePromptsPartial, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {VistaMarketPlacePromptsPartial} [vistaMarketPlacePromptsPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async vistaMarketPlacePromptsControllerUpdateById(id: number, vistaMarketPlacePromptsPartial?: VistaMarketPlacePromptsPartial, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.vistaMarketPlacePromptsControllerUpdateById(id, vistaMarketPlacePromptsPartial, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * VistaMarketPlacePromptsControllerApi - factory interface
 * @export
 */
export const VistaMarketPlacePromptsControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = VistaMarketPlacePromptsControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vistaMarketPlacePromptsControllerCount(where?: { [key: string]: object; }, options?: any): AxiosPromise<LoopbackCount> {
            return localVarFp.vistaMarketPlacePromptsControllerCount(where, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {NewVistaMarketPlacePrompts} [newVistaMarketPlacePrompts] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vistaMarketPlacePromptsControllerCreate(newVistaMarketPlacePrompts?: NewVistaMarketPlacePrompts, options?: any): AxiosPromise<VistaMarketPlacePrompts> {
            return localVarFp.vistaMarketPlacePromptsControllerCreate(newVistaMarketPlacePrompts, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vistaMarketPlacePromptsControllerDeleteById(id: number, options?: any): AxiosPromise<any> {
            return localVarFp.vistaMarketPlacePromptsControllerDeleteById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {VistaMarketPlacePromptsFilter1} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vistaMarketPlacePromptsControllerFind(filter?: VistaMarketPlacePromptsFilter1, options?: any): AxiosPromise<Array<VistaMarketPlacePromptsWithRelations>> {
            return localVarFp.vistaMarketPlacePromptsControllerFind(filter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {VistaMarketPlacePromptsFilter} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vistaMarketPlacePromptsControllerFindById(id: number, filter?: VistaMarketPlacePromptsFilter, options?: any): AxiosPromise<VistaMarketPlacePromptsWithRelations> {
            return localVarFp.vistaMarketPlacePromptsControllerFindById(id, filter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [productos] 
         * @param {string} [categoria] 
         * @param {string} [subcategoria] 
         * @param {string} [marketplaces] 
         * @param {string} [idmarketplacesprompt] 
         * @param {string} [emailUsuarioLogueado] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vistaMarketPlacePromptsControllerObtenerRegistrosVistaMarketPlaceProductoGeneral(productos?: string, categoria?: string, subcategoria?: string, marketplaces?: string, idmarketplacesprompt?: string, emailUsuarioLogueado?: string, options?: any): AxiosPromise<Array<VistaMarketPlacePromptsWithRelations>> {
            return localVarFp.vistaMarketPlacePromptsControllerObtenerRegistrosVistaMarketPlaceProductoGeneral(productos, categoria, subcategoria, marketplaces, idmarketplacesprompt, emailUsuarioLogueado, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {VistaMarketPlacePrompts} [vistaMarketPlacePrompts] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vistaMarketPlacePromptsControllerReplaceById(id: number, vistaMarketPlacePrompts?: VistaMarketPlacePrompts, options?: any): AxiosPromise<any> {
            return localVarFp.vistaMarketPlacePromptsControllerReplaceById(id, vistaMarketPlacePrompts, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {VistaMarketPlacePromptsPartial} [vistaMarketPlacePromptsPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vistaMarketPlacePromptsControllerUpdateAll(where?: { [key: string]: object; }, vistaMarketPlacePromptsPartial?: VistaMarketPlacePromptsPartial, options?: any): AxiosPromise<LoopbackCount> {
            return localVarFp.vistaMarketPlacePromptsControllerUpdateAll(where, vistaMarketPlacePromptsPartial, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {VistaMarketPlacePromptsPartial} [vistaMarketPlacePromptsPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vistaMarketPlacePromptsControllerUpdateById(id: number, vistaMarketPlacePromptsPartial?: VistaMarketPlacePromptsPartial, options?: any): AxiosPromise<any> {
            return localVarFp.vistaMarketPlacePromptsControllerUpdateById(id, vistaMarketPlacePromptsPartial, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * VistaMarketPlacePromptsControllerApi - object-oriented interface
 * @export
 * @class VistaMarketPlacePromptsControllerApi
 * @extends {BaseAPI}
 */
export class VistaMarketPlacePromptsControllerApi extends BaseAPI {
    /**
     * 
     * @param {{ [key: string]: object; }} [where] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VistaMarketPlacePromptsControllerApi
     */
    public vistaMarketPlacePromptsControllerCount(where?: { [key: string]: object; }, options?: AxiosRequestConfig) {
        return VistaMarketPlacePromptsControllerApiFp(this.configuration).vistaMarketPlacePromptsControllerCount(where, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {NewVistaMarketPlacePrompts} [newVistaMarketPlacePrompts] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VistaMarketPlacePromptsControllerApi
     */
    public vistaMarketPlacePromptsControllerCreate(newVistaMarketPlacePrompts?: NewVistaMarketPlacePrompts, options?: AxiosRequestConfig) {
        return VistaMarketPlacePromptsControllerApiFp(this.configuration).vistaMarketPlacePromptsControllerCreate(newVistaMarketPlacePrompts, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VistaMarketPlacePromptsControllerApi
     */
    public vistaMarketPlacePromptsControllerDeleteById(id: number, options?: AxiosRequestConfig) {
        return VistaMarketPlacePromptsControllerApiFp(this.configuration).vistaMarketPlacePromptsControllerDeleteById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {VistaMarketPlacePromptsFilter1} [filter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VistaMarketPlacePromptsControllerApi
     */
    public vistaMarketPlacePromptsControllerFind(filter?: VistaMarketPlacePromptsFilter1, options?: AxiosRequestConfig) {
        return VistaMarketPlacePromptsControllerApiFp(this.configuration).vistaMarketPlacePromptsControllerFind(filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {VistaMarketPlacePromptsFilter} [filter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VistaMarketPlacePromptsControllerApi
     */
    public vistaMarketPlacePromptsControllerFindById(id: number, filter?: VistaMarketPlacePromptsFilter, options?: AxiosRequestConfig) {
        return VistaMarketPlacePromptsControllerApiFp(this.configuration).vistaMarketPlacePromptsControllerFindById(id, filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [productos] 
     * @param {string} [categoria] 
     * @param {string} [subcategoria] 
     * @param {string} [marketplaces] 
     * @param {string} [idmarketplacesprompt] 
     * @param {string} [emailUsuarioLogueado] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VistaMarketPlacePromptsControllerApi
     */
    public vistaMarketPlacePromptsControllerObtenerRegistrosVistaMarketPlaceProductoGeneral(productos?: string, categoria?: string, subcategoria?: string, marketplaces?: string, idmarketplacesprompt?: string, emailUsuarioLogueado?: string, options?: AxiosRequestConfig) {
        return VistaMarketPlacePromptsControllerApiFp(this.configuration).vistaMarketPlacePromptsControllerObtenerRegistrosVistaMarketPlaceProductoGeneral(productos, categoria, subcategoria, marketplaces, idmarketplacesprompt, emailUsuarioLogueado, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {VistaMarketPlacePrompts} [vistaMarketPlacePrompts] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VistaMarketPlacePromptsControllerApi
     */
    public vistaMarketPlacePromptsControllerReplaceById(id: number, vistaMarketPlacePrompts?: VistaMarketPlacePrompts, options?: AxiosRequestConfig) {
        return VistaMarketPlacePromptsControllerApiFp(this.configuration).vistaMarketPlacePromptsControllerReplaceById(id, vistaMarketPlacePrompts, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} [where] 
     * @param {VistaMarketPlacePromptsPartial} [vistaMarketPlacePromptsPartial] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VistaMarketPlacePromptsControllerApi
     */
    public vistaMarketPlacePromptsControllerUpdateAll(where?: { [key: string]: object; }, vistaMarketPlacePromptsPartial?: VistaMarketPlacePromptsPartial, options?: AxiosRequestConfig) {
        return VistaMarketPlacePromptsControllerApiFp(this.configuration).vistaMarketPlacePromptsControllerUpdateAll(where, vistaMarketPlacePromptsPartial, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {VistaMarketPlacePromptsPartial} [vistaMarketPlacePromptsPartial] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VistaMarketPlacePromptsControllerApi
     */
    public vistaMarketPlacePromptsControllerUpdateById(id: number, vistaMarketPlacePromptsPartial?: VistaMarketPlacePromptsPartial, options?: AxiosRequestConfig) {
        return VistaMarketPlacePromptsControllerApiFp(this.configuration).vistaMarketPlacePromptsControllerUpdateById(id, vistaMarketPlacePromptsPartial, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * VistaMuestraFotosOptimizadasControllerApi - axios parameter creator
 * @export
 */
export const VistaMuestraFotosOptimizadasControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vistaMuestraFotosOptimizadasControllerCount: async (where?: { [key: string]: object; }, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/vista-muestra-fotos-optimizadas/count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (where !== undefined) {
                localVarQueryParameter['where'] = where;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {VistaMuestraFotosOptimizadasFilter} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vistaMuestraFotosOptimizadasControllerFind: async (filter?: VistaMuestraFotosOptimizadasFilter, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/vista-muestra-fotos-optimizadas`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * VistaMuestraFotosOptimizadasControllerApi - functional programming interface
 * @export
 */
export const VistaMuestraFotosOptimizadasControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = VistaMuestraFotosOptimizadasControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async vistaMuestraFotosOptimizadasControllerCount(where?: { [key: string]: object; }, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoopbackCount>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.vistaMuestraFotosOptimizadasControllerCount(where, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {VistaMuestraFotosOptimizadasFilter} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async vistaMuestraFotosOptimizadasControllerFind(filter?: VistaMuestraFotosOptimizadasFilter, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<InlineResponse2004>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.vistaMuestraFotosOptimizadasControllerFind(filter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * VistaMuestraFotosOptimizadasControllerApi - factory interface
 * @export
 */
export const VistaMuestraFotosOptimizadasControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = VistaMuestraFotosOptimizadasControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vistaMuestraFotosOptimizadasControllerCount(where?: { [key: string]: object; }, options?: any): AxiosPromise<LoopbackCount> {
            return localVarFp.vistaMuestraFotosOptimizadasControllerCount(where, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {VistaMuestraFotosOptimizadasFilter} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vistaMuestraFotosOptimizadasControllerFind(filter?: VistaMuestraFotosOptimizadasFilter, options?: any): AxiosPromise<Array<InlineResponse2004>> {
            return localVarFp.vistaMuestraFotosOptimizadasControllerFind(filter, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * VistaMuestraFotosOptimizadasControllerApi - object-oriented interface
 * @export
 * @class VistaMuestraFotosOptimizadasControllerApi
 * @extends {BaseAPI}
 */
export class VistaMuestraFotosOptimizadasControllerApi extends BaseAPI {
    /**
     * 
     * @param {{ [key: string]: object; }} [where] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VistaMuestraFotosOptimizadasControllerApi
     */
    public vistaMuestraFotosOptimizadasControllerCount(where?: { [key: string]: object; }, options?: AxiosRequestConfig) {
        return VistaMuestraFotosOptimizadasControllerApiFp(this.configuration).vistaMuestraFotosOptimizadasControllerCount(where, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {VistaMuestraFotosOptimizadasFilter} [filter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VistaMuestraFotosOptimizadasControllerApi
     */
    public vistaMuestraFotosOptimizadasControllerFind(filter?: VistaMuestraFotosOptimizadasFilter, options?: AxiosRequestConfig) {
        return VistaMuestraFotosOptimizadasControllerApiFp(this.configuration).vistaMuestraFotosOptimizadasControllerFind(filter, options).then((request) => request(this.axios, this.basePath));
    }
}


