import { EnsambladosControllerApi, SustitutosControllerApi, settings } from '@api/backend'
import { displaySuccessMsg } from '@src/utility/Utils'

const prefix = 'ensamblados'

export const GET_ENSAMBLADO = `${prefix}/GET_DATA_ensamblados`
export const GET_ENSAMBLADOS = `${prefix}/GET_DATA`
export const GET_ENSAMBLADOS_ALL = `${prefix}/GET_DATA_ALL`
export const GET_ENSAMBLADOS_COUNT = `${prefix}/GET_DATA_COUNT`
export const ADD_ENSAMBLADO = `${prefix}/ADD`
export const DELETE_ENSAMBLADO = `${prefix}/DELETE`
export const UPDATE_ENSAMBLADO = `${prefix}/UPDATE`
export const GET_FILTROS_ENSAMBLADOS = `${prefix}/GET_FILTROS_ENSAMBLADOS`
export const INIT_DATA_NECESARIA = `${prefix}/INIT_DATA_NECESARIA`

const apiEnsamblados = new EnsambladosControllerApi(settings)
const apiSutitutos = new SustitutosControllerApi(settings)

export const getEnsamblados = params => {
  return async (dispatch, getState) => {
    try {
      const { page = 1, perPage = 10, filtrosBusqueda } = params
      const { skuPrincipal, skuEnsamblado, descripcion, cantidad } = filtrosBusqueda || {}

      const filter = {
        offset: (page - 1) * perPage,
        limit: perPage,
        skip: ((page - 1) * perPage),
        where: {
          and: []
        }
      }

      // Siempre incluimos estos filtros ya que tienen valores por defecto
      filter.where.and.push({ skuPrincipal: { like: skuPrincipal ? `%${skuPrincipal}%` : '%%' } })
      filter.where.and.push({ skuEnsamblado: { like: skuEnsamblado ? `%${skuEnsamblado}%` : '%%' } })
      filter.where.and.push({ descripcion: { like: descripcion ? `%${descripcion}%` : '%%' } })

      // Solo incluimos estos filtros si tienen valores proporcionados
      if (cantidad) {
        filter.where.and.push({ cantidad: { eq: `${cantidad}` } })
      }

      const { data: dataEnsamblados } = await apiEnsamblados.ensambladosControllerFind(JSON.stringify(filter))
      const { data: dataEnsambladosCount } = await apiEnsamblados.ensambladosControllerCount(JSON.stringify(filter.where))

      dispatch({
        type: GET_ENSAMBLADOS,
        ensamblados: dataEnsamblados,
        total: dataEnsambladosCount.count || 0,
        params
      })

      //Guardamos los filtros de busqueda (Inputs)
      dispatch({
        type: GET_FILTROS_ENSAMBLADOS,
        filtros: filtrosBusqueda
      })
    } catch (err) {
      console.error(err.message)
    }
  }
}

export const addEnsamblado = (ensamblado, intl) => {
  return async (dispatch) => {
    try {
      const fechaYHoraActual = new Date().toISOString()
      ensamblado.fechaCreacion = fechaYHoraActual
      ensamblado.fechaModificacion = fechaYHoraActual
      const { data } = await apiEnsamblados.ensambladosControllerCreate(ensamblado)
      displaySuccessMsg(intl.formatMessage({ id: 'Ensamblado creado' }))
      localStorage.setItem("actualizar_Ensamblado", "true")
      dispatch({
        type: ADD_ENSAMBLADO,
        data
      })
      // Limpiamos el ensamblado seleccionado
      dispatch({
        type: GET_ENSAMBLADO,
        selectedCompany: null
      })
    } catch (err) {
      console.error(err.message)
      console.log(err.message)
    }
  }
}

export const updateEnsamblado = (id, ensamblado, intl) => {
  return async (dispatch) => {
    try {
      const fechaYHoraActual = new Date().toISOString()
      ensamblado.fechaModificacion = fechaYHoraActual
      const { data } = await apiEnsamblados.ensambladosControllerUpdateById(id, ensamblado)
      displaySuccessMsg(intl.formatMessage({ id: 'Ensamblado actualizado' }))
      localStorage.setItem("actualizar_Ensamblado", "true")
      dispatch({
        type: UPDATE_ENSAMBLADO,
        data
      })
      // Limpiamos el ensamblado seleccionado
      dispatch({
        type: GET_ENSAMBLADO,
        selectedCompany: null
      })
    } catch (err) {
      console.error(err.message)
    }
  }
}

export const deleteEnsamblado = (ensambladoId, intl, datosRegistro) => {
  return async (dispatch) => {
    try {
      const { data } = await apiEnsamblados.ensambladosControllerDeleteById(ensambladoId, datosRegistro.skuPrincipal)
      displaySuccessMsg(intl.formatMessage({ id: 'Ensamblado borrado' }))
      dispatch({
        type: DELETE_ENSAMBLADO,
        data: ensambladoId
      })
    } catch (err) {
      console.error(err.message)
    }
  }
}

export const getEnsamblado = (ensamblado) => {
  return async (dispatch) => {
    try {
      const { data: dataEnsamblado } = await apiEnsamblados.ensambladosControllerFindById(ensamblado)
      dispatch({
        type: GET_ENSAMBLADO,
        selectedEnsamblado: dataEnsamblado,
        ultimoIdEnsamblado: ensamblado
      })
    } catch (err) {
      console.error(err.message)
    }
  }
}

export const initEnsamblado = () => {
  return async (dispatch) => {
    dispatch({
      type: GET_ENSAMBLADO,
      selectedCompany: null
    })
  }
}

export const obtenerSkuPrincipales = () => {
  return async () => {
    try {
      const { data: listaSKUs } = await apiSutitutos.sustitutosControllerObtenerSkuPrincipales()
      return listaSKUs
    } catch (err) {
      console.error(err.message)
    }
  }
}
