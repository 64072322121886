import { MarcaControllerApi, settings } from '@api/backend'
import { displaySuccessMsg } from '@src/utility/Utils'

const prefix = 'marcas'

export const GET_MARCA = `${prefix}/GET_DATA_marca`
export const GET_MARCAS = `${prefix}/GET_DATA`
export const GET_MARCAS_ALL = `${prefix}/GET_DATA_ALL`
export const GET_MARCAS_COUNT = `${prefix}/GET_DATA_COUNT`
export const ADD_MARCA = `${prefix}/ADD`
export const DELETE_MARCA = `${prefix}/DELETE`
export const UPDATE_MARCA = `${prefix}/UPDATE`
export const INIT_DATA_NECESARIA = `${prefix}/INIT_DATA_NECESARIA`

const apimarcas = new MarcaControllerApi(settings)

export const getMarcas = params => {
  return async (dispatch, getState) => {
    try {

      const { page = 1, perPage = 10, sortBy = "", filtrosBusqueda } = params
      const { searchNombre, searchCodigo } = filtrosBusqueda || {}
      const empresaId = getState().layout.selectedEmpresaId

      const filter = {
        offset: (page - 1) * perPage,
        limit: perPage,
        skip: ((page - 1) * perPage),
        order: sortBy || "nombre ASC",
        where: {
          and: []
        }
      }

      // Siempre incluimos estos filtros ya que tienen valores por defecto
      filter.where.and.push({ empresaId: { eq: `${empresaId}` } })
      filter.where.and.push({ nombre: { like: searchNombre ? `%${searchNombre}%` : '%%' } })
      filter.where.and.push({ codigo: { like: searchCodigo ? `%${searchCodigo}%` : '%%' } })

      const { data: dataMarcas } = await apimarcas.marcaControllerFind(JSON.stringify(filter))
      const { data: dataMarcasCount } = await apimarcas.marcaControllerCount(JSON.stringify(filter.where))

      dispatch({
        type: GET_MARCAS,
        marcas: dataMarcas,
        total: dataMarcasCount.count || 0,
        params
      })
    } catch (err) {
      console.error(err.message)
    }
  }
}

export const addmarca = (marca, intl) => {
  return async (dispatch) => {
    try {
      const { data } = await apimarcas.marcaControllerCreate(marca)
      displaySuccessMsg(intl.formatMessage({ id: 'Marca creada' }))
      localStorage.setItem("actualizar_Marcas", "true")
      dispatch({
        type: ADD_MARCA,
        data
      })
      // Limpiamos la marca seleccionada
      dispatch({
        type: GET_MARCA,
        selectedCompany: null
      })
    } catch (err) {
      console.error(err.message)
      console.log(err.message)
    }
  }
}

export const updatemarca = (id, marca, intl) => {
  return async (dispatch) => {
    try {
      const { data } = await apimarcas.marcaControllerUpdateById(id, marca)
      displaySuccessMsg(intl.formatMessage({ id: 'Marca actualizada' }))
      localStorage.setItem("actualizar_Marcas", "true")
      dispatch({
        type: UPDATE_MARCA,
        data
      })
      // Limpiamos la marca seleccionada
      dispatch({
        type: GET_MARCA,
        selectedCompany: null
      })
    } catch (err) {
      console.error(err.message)
    }
  }
}

export const deletemarca = (marcaId, intl) => {
  return async (dispatch) => {
    try {
      await apimarcas.marcaControllerDeleteById(marcaId)
      displaySuccessMsg(intl.formatMessage({ id: 'Marca eliminada' }))
      dispatch({
        type: DELETE_MARCA,
        data: marcaId
      })
    } catch (err) {
      console.error(err.message)
    }
  }
}

export const getmarca = (marca) => {
  return async (dispatch) => {
    try {
      const { data: datamarca } = await apimarcas.marcaControllerFindById(marca)
      dispatch({
        type: GET_MARCA,
        selectedmarca: datamarca,
        ultimoIdMarca: marca
      })
    } catch (err) {
      console.error(err.message)
    }
  }
}

export const initmarca = () => {
  return async (dispatch) => {
    dispatch({
      type: GET_MARCA,
      selectedCompany: null
    })
  }
}
