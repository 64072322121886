import { MultimediaControllerApi, ProductoMultimediaControllerApi, settings } from '@api/backend'
import { displaySuccessMsg } from '@src/utility/Utils'

const prefix = 'multimedia'

export const GET_MULTIMEDIA = `${prefix}/GET_MULTIMEDIA`
export const GET_MULTIMEDIAS = `${prefix}/GET_DATA`
export const GET_MULTIMEDIA_COUNT = `${prefix}/GET_DATA_COUNT`
export const ADD_MULTIMEDIA = `${prefix}/ADD`
export const DELETE_MULTIMEDIA = `${prefix}/DELETE`
export const UPDATE_MULTIMEDIA = `${prefix}/UPDATE`
export const INIT_DATA_NECESARIA = `${prefix}/INIT_DATA_NECESARIA`

const apiMultimedia = new MultimediaControllerApi(settings)
const apiMultimediaProducto = new ProductoMultimediaControllerApi(settings)

// ** Get all Data
export const getMultimedias = params => {
  return async (dispatch, getState) => {
    try {
      const { page = 1, perPage = 10, sortBy = "", q = "", filtrosBusqueda } = params
      const empresaId = getState().layout.selectedEmpresaId

      const filter = {
        offset: (page - 1) * perPage,
        limit: perPage,
        skip: ((page - 1) * perPage),
        order: sortBy || "clave ASC",
        where: {
          ['empresaId']: { eq: `${empresaId}` },
          ['or']:
            [{ ['clave']: { like: `%${filtrosBusqueda.searchNombre}%`, options: 'i' } }]
        }
      }

      if (filtrosBusqueda.grupoMultimedia !== '') {
        filter.where['tipo'] = { eq: `${filtrosBusqueda.grupoMultimedia}` }
      }

      if (filtrosBusqueda.defectoSN !== '' && filtrosBusqueda.defectoSN !== 'TODOS') {
        filter.where['defectosn'] = { eq: `${filtrosBusqueda.defectoSN }` }
      }

      if (filtrosBusqueda.categoria  !== '' && filtrosBusqueda.categoria  !== 'TODAS') {
        filter.where['categoria'] = { eq: `${filtrosBusqueda.categoria  }` }
      }

      const { data: dataMultimedia } = await apiMultimedia.multimediaControllerFind(JSON.stringify(filter))
      const { data: dataMultimediaCount } = await apiMultimedia.multimediaControllerCount(JSON.stringify(filter.where))
      // Consulto unicamente los ids de los productos que tienen multimedia
      const filterProductoMultimedia = JSON.stringify({
        where: {
          multimediaId: { inq: dataMultimedia.map(objMultimedia => objMultimedia.id) }
        }
      })
      const { data: dataProductoMultimedia } = await apiMultimediaProducto.productoMultimediaControllerFind(filterProductoMultimedia)
      dispatch({
        type: GET_MULTIMEDIAS,
        multimedia: dataMultimedia,
        todaMultimedia: dataProductoMultimedia,
        total: dataMultimediaCount.count || 0,
        params
      })
    } catch (err) {
      console.error(err.message)
    }
  }
}

export const addMultimedia = (multimedia, intl) => {
  return async (dispatch, getState) => {
    try {
      const idEmpresa = getState().layout.selectedEmpresaId
      const multimediaRellena = {
        clave: multimedia.clave,
        tipo: multimedia.tipo,
        categoria: multimedia.categoria,
        defectosn: multimedia.defectosn,
        tipoAecoc: multimedia.tipoAecoc,
        empresaId: idEmpresa
      }
      const { data } = await apiMultimedia.multimediaControllerCreate(multimediaRellena)
      localStorage.setItem("actualizar_Multimedia", "true")
      displaySuccessMsg(intl.formatMessage({ id: 'Multimedia creado' }))
      dispatch({
        type: ADD_MULTIMEDIA,
        data
      })
      // Limpiamos el multimedia seleccionado
      dispatch({
        type: GET_MULTIMEDIA,
        selectedCompany: null
      })
    } catch (err) {
      console.error(err.message)
    }
  }
}

export const updateMultimedia = (id, multimedia, intl) => {
  return async (dispatch) => {
    try {
      const { data } = await apiMultimedia.multimediaControllerUpdateById(id, multimedia)
      displaySuccessMsg(intl.formatMessage({ id: 'Multimedia actualizado' }))
      localStorage.setItem("actualizar_Multimedia", "true")
      dispatch({
        type: UPDATE_MULTIMEDIA,
        data
      })
      // Limpiamos el multimedia seleccionado
      dispatch({
        type: GET_MULTIMEDIA,
        selectedCompany: null
      })
    } catch (err) {
      console.error(err.message)
    }
  }
}

export const updateProductoMultimedia = (id, multimedia) => {
  return async (dispatch) => {
    try {
      const { data } = await apiMultimediaProducto.productoMultimediaControllerUpdateById(id, multimedia)
      dispatch({
        type: UPDATE_MULTIMEDIA,
        data
      })
    } catch (err) {
      console.error(err.message)
    }
  }
}

export const deleteMultimedia = (multimediaId, intl) => {
  return async (dispatch) => {
    try {
      await apiMultimedia.multimediaControllerDeleteById(multimediaId)
      displaySuccessMsg(intl.formatMessage({ id: 'Multimedia eliminado' }))
      dispatch({
        type: DELETE_MULTIMEDIA,
        data: multimediaId
      })
    } catch (err) {
      console.error(err.message)
    }
  }
}

export const getMultimedia = (multimedia) => {
  return async (dispatch) => {
    try {
      const { data: dataMultimedia } = await apiMultimedia.multimediaControllerFindById(multimedia)
      dispatch({
        type: GET_MULTIMEDIA,
        selectedMultimedia: dataMultimedia,
        ultimoIdMultimedia: multimedia
      })
    } catch (err) {
      console.error(err.message)
    }
  }
}

export const initMultimedia = () => {
  return async (dispatch) => {
    dispatch({
      type: GET_MULTIMEDIA,
      selectedCompany: null
    })
  }
}

//O B T I E N E - P R O D U C T O - M U L T I M E D I A - P O R - N O M B R E - D E - M U L T I M E D I A
export const obtieneProductoMultimediaPorNombreMultimedia = (claveMultimedia) => {
  return async () => {

    const filter = {
      where: {
        ['nombre']: { eq: `${claveMultimedia}` }
      }
    }

    const { data: dataMultimedia } = await apiMultimediaProducto.productoMultimediaControllerFind(JSON.stringify(filter))

    return dataMultimedia
  }
}